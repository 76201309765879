<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>

    <!-- formulario -->
    <v-row justify="center">
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Registrar Competencia
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-select
                    outlined
                    dense
                    label="Regional*"
                    :items="regionales"
                    item-value="id"
                    item-text="regional"
                    v-model="regional"
                    :rules="regionalReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    label="Centro de Formación*"
                    :items="centrosFormacion"
                    item-value="id"
                    item-text="centro_formacion"
                    v-model="centroFormacion"
                    :rules="centroFormacionReglas"
                  >
                  </v-select>

                  <v-dialog
                    ref="fechaInicio"
                    v-model="modalFechaInicio"
                    :return-value.sync="fechaInicio"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        prepend-inner-icon="mdi-calendar"
                        label="Fecha Inicio*"
                        readonly
                        v-model="fechaInicio"
                        :rules="fechaInicioReglas"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker 
                      scrollable 
                      locale="es" 
                      v-model="fechaInicio"
                    >
                      <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        color="error" 
                        @click="modalFechaInicio = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="validarFecha($refs.fechaInicio.save(fechaInicio))"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

                  <v-dialog
                    ref="fechaFin"
                    v-model="modalFechaFin"
                    :return-value.sync="fechaFin"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        prepend-inner-icon="mdi-calendar"
                        label="Fecha Fin*"
                        readonly
                        v-model="fechaFin"
                        :rules="fechaFinReglas"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                      scrollable 
                      locale="es" 
                      v-model="fechaFin"
                    >
                      <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        color="primary" 
                        @click="modalFechaFin = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="validarFecha($refs.fechaFin.save(fechaFin))"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Competencia*"
                    maxlength="100"
                    counter
                    v-model="competencia"
                    :rules="competenciaReglas"
                  >
                  </v-text-field>

                  <v-textarea
                    outlined
                    rows="4"
                    label="Descripción"
                    v-model="descripcion"
                  >
                  </v-textarea>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Lugar"
                    maxlength="50"
                    counter
                    v-model="lugar"
                    :rules="lugarReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Dirección*"
                    maxlength="50"
                    counter
                    v-model="direccion"
                    :rules="direccionReglas"
                  >
                  </v-text-field>

                  <v-btn
                    large
                    block
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="registrar"
                  >
                    Registrar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Alert from '../../../components/admin/Alert.vue'

export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    loadingForm:  false,
    form:         true,

    regional: null,
    regionalReglas: [
      v => !!v || "Seleccione una regional"
    ],

    centroFormacion: null,
    centroFormacionReglas: [
      v => !!v || "Seleccione un centro de formación"
    ],

    fechaInicio: null,
    fechaInicioReglas: [
      v => !!v || "Seleccione una fecha inicio"
    ],
    modalFechaInicio: false,

    fechaFin: null,
    fechaFinReglas: [
      v => !!v || "Seleccione una fecha fin"
    ],
    modalFechaFin: false,

    competencia: null,
    competenciaReglas: [
      v => !!v || "Escriba una competencia"
    ],
    
    descripcion: null,

    lugar: null,
    lugarReglas: [
      v => !!v || "Escriba una lugar"
    ],

    direccion: null,
    direccionReglas: [
      v => !!v || "Escriba una dirección"
    ]
  }),

  created() {
    this.$store.dispatch("crearRegionales");
    this.$store.dispatch("crearCentrosFormacion");
  },

  computed: {
    regionales() {
      return this.$store.getters.obtenerRegionales;
    },

    centrosFormacion() {
      return this.$store.getters.obtenerCentrosFormacion(this.regional);
    },
  },

  components: {
    "app-alert": Alert
  },

  methods: {
    registrar() {
      if (this.$refs.form.validate()) {
        const competencia = {
          centro_formacion_id:  this.centroFormacion,
          fecha_inicio:         this.fechaInicio,
          fecha_fin:            this.fechaFin,
          competencia:          this.competencia,
          descripcion:          this.descripcion,
          lugar:                this.lugar,
          direccion:            this.direccion
        };

        this.loading = true;

        this.$store.dispatch("registrarCompetencia", competencia)
        .then((response) => {
          if (response.status == "success") {
            this.$refs.form.reset();
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Registrada";
            this.messageAlert = response.message;
            this.titleCard    = "success--text";
            this.buttonCard   = "success";
            setTimeout(() => {
                this.$router.push({
                  name: "competencia-gestionar",
                });
              }, 2500);
          }
          else {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message.competencia[0];
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }
          
          setTimeout(() => {
            this.alert = false
          }, 1000);
        })
        .catch(() => {});
      }
    },
    validarFecha(){

      if (this.modalFechaInicio){
        this.modalFechaInicio = false
      }
      if (this.modalFechaFin){
        this.modalFechaFin = false
      }
      
      if ((this.fechaInicio != null) && (this.fechaFin != null)){
        let fecha1 = new Date(this.fechaInicio)
        let fecha2 = new Date(this.fechaFin)
        if (fecha1 >= fecha2){
            this.fechaFin     = null
            this.fechaInicio  = null
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Error...";
            this.messageAlert = "Fecha inicial mayor a fecha final";
            this.titleCard    = "error--text";
            this.buttonCard   = "error";

            setTimeout(() =>{
              this.alert = false
            }, 1000);
        }
      }
    },
    
  },
};
</script>