<template>
  <div>
    <!-- alerta -->
    <v-dialog 
      max-width="280" 
      v-model="alert"
    >
      <v-card>
        <v-card-title class="justify-center">
          <p 
            class="text-h5 font-weight-bold" 
            :class="titleCard"
          >
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn  
            :color="buttonCard" 
            class="mb-2 white--text"
            @click="alert = false"
          > 
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    <template v-if="loaderTable">
      <v-skeleton-loader
        class="mx-auto"
        type="table"
      >
      </v-skeleton-loader>
    </template>
    <template v-else>
      <!-- tabla de datos -->
      <v-row justify="center">
        <v-col cols="12">
          <router-view></router-view>
          <v-card>
            <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
              <v-row align="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      exact
                      color="primary"
                      class="mx-2"
                      v-bind="attrs" 
                      v-on="on" 
                      @click="regresar"
                    >
                      <v-icon>
                        mdi-arrow-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar</span>
                </v-tooltip>
                <v-col 
                  cols="10"
                  sm="11"
                  md="7"
                >
                  {{ categoriaCompetencia.categoria }}
                </v-col>
                
                <v-col 
                  cols="12"
                  sm="10"
                  md="3"
                >
                  <v-text-field
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    label="Buscar Cupo"
                    hide-details
                    v-model="searchTable"
                  >
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="2"
                  md="1"
                >
                  <template v-if="cupoDisponible == 0">
                    <v-btn
                      disabled
                      exact
                      color="primary"
                    >
                      Registrar
                    </v-btn> 
                  </template>

                  <template v-else>
                    <v-btn
                      exact
                      color="primary"
                      @click="open"
                    >
                      Registrar
                    </v-btn> 
                  </template>  
                </v-col>

                <v-col 
                  cols="6"
                  md="2"
                  class="ml-md-14"
                >
                  <p class="text-subtitle-1 font-weight-bold primary--text">
                    Total Cupos: {{ categoriaCompetencia.numero_participantes }}
                  </p>
                </v-col>

                <v-col
                  cols="6"
                  md="2"
                >
                  <template v-if="cupoDisponible == 0">
                    <p class="text-subtitle-1 font-weight-bold ml-8 error--text">
                      Disponibles: {{ cupoDisponible }}
                    </p>
                  </template>

                  <template v-else>
                    <p class="text-subtitle-1 font-weight-bold ml-8 success--text">
                      Disponibles: {{ cupoDisponible }}
                    </p>
                  </template>
                </v-col>
              </v-row>
            </v-card-title>

            <v-data-table
              fixed-header
              height="400px"
              class="elevation-2"
              :headers="headersTable"
              :items="cupos"
              :search="searchTable"
              :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1] }"
            >
              <template v-slot:top>
                <v-dialog 
                  persistent
                  v-model="dialogForm" 
                  max-width="520px"
                >
                  <v-card :loading="loadingForm">
                    <v-card-title class="justify-center">
                      <p class="text-h5 font-weight-bold primary--text">{{ formTitle }}</p>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col 
                            cols="12"
                            md="10"
                          >
                            <v-form 
                              ref="form" 
                              v-model="form"
                            >
                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Competencia"
                                v-model="competenciaSeleccionada"
                              >
                              </v-text-field>

                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Categoria"
                                v-model="categoriaSeleccionada"
                              >
                              </v-text-field>

                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Modalidad"
                                v-model="modalidad"
                              >
                              </v-text-field>
                              
                              <v-select
                                outlined
                                dense
                                label="Regional*"
                                :items="regionales"
                                item-value="id"
                                item-text="regional"
                                v-model="regional"
                                :rules="regionalReglas"
                              >
                              </v-select>

                              <v-select
                                outlined
                                dense
                                label="Centro de Formación*"
                                :items="centrosFormacion"
                                item-value="id"
                                item-text="centro_formacion"
                                v-model="centroFormacion"
                                :rules="centroFormacionReglas"
                              >
                              </v-select>

                              <v-text-field
                                outlined
                                dense
                                type="number"
                                label="Número de Participantes*"
                                v-model="numero_participantes"
                                :rules="numero_participantesReglas"
                              >
                              </v-text-field>
                            </v-form>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        color="error" 
                        @click="close"
                      >
                        Cancelar
                      </v-btn>
              
                      <v-btn 
                        text 
                        exact
                        color="primary" 
                        @click="registrar(numero_participantes)"
                      > 
                        Registrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog 
                  v-model="dialogDelete" 
                  max-width="480px"
                >
                  <v-card>
                    <v-card-title class="justify-center text-h5">¿Está seguro de eliminar el cupo?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        color="primary" 
                        @click="closeDelete"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn 
                        text 
                        color="error" 
                        @click="eliminar(temCupo_id)"
                      > 
                        OK
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }" >
                    <div >
                      <v-icon
                      medium
                      color="error"
                      v-bind="attrs" 
                      v-on="on"
                      @click="activarAlertaEliminar(item.id_cupo)"
                    >
                      mdi-delete
                    </v-icon>
                    </div>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template> 
    <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Desea eliminar la categoria ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="eliminar(temCupo_id)">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
   </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    loaderTable: true,
    searchTable:  null,

    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    loadingForm:  false,
    form:         true,
    dialogForm:   false,
    dialogDelete: false,
    temCupo_id:   null,

    modalidad: null,
    valorModalidad: null,
    validacionModalidad: false,

    competenciaSeleccionada: "",

    categoriaSeleccionada: "",

    regional: null,
    regionalReglas: [(v) => !!v || "Seleccione una regional"],

    centroFormacion: null,
    centroFormacionReglas: [(v) => !!v || "Seleccione un centro de formación"],

    numero_participantes: null,
    numero_participantesReglas: [
      (v) => !!v || "Escriba un número de participantes",
      (v) => v > 0 || "Escriba un número de participantes",
  ],

    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Regional",
        value: "regional",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Centro de Formación",
        value: "centro_formacion",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "# Cupos",
        value: "numero_participantes",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ],

    editedIndex: -1
  }),

  created() {
    const categoriaCompetencia = {
      competencia_id: this.$route.params.id_competencia,
      categoria_id: this.$route.params.id_categoria
    }

    this.$store.dispatch("gestionarCupos", categoriaCompetencia);
    this.$store.dispatch("detallesCategoriaCompetencia", categoriaCompetencia);
    this.$store.dispatch("crearRegionales");
    this.$store.dispatch("crearCentrosFormacion");
    
    setTimeout(() => {
      this.loaderTable = false
    }, 1000);
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Registrar Cupo' : 'Actualizar Cupo'
    },

    categoriaCompetencia() {
      return this.$store.getters.obtenerCategoriaCompetencia;
    },

    regionales() {
      return this.$store.getters.obtenerRegionales;
    },

    centrosFormacion() {
      return this.$store.getters.obtenerCentrosFormacion(this.regional);
    },

    cupos() {
      return this.$store.getters.obtenerCupos;
    },

    cupoRegistrado () {
      return this.cupos.reduce((sum, cupo) => {
        return sum + cupo.numero_participantes
      }, 0);
    },

    cupoDisponible () {
      return this.categoriaCompetencia.numero_participantes-this.cupoRegistrado
    },

    modalidades() {
      return this.$store.getters.obtenerModalidades;
    },

    competencia() {
      return this.$store.getters.obtenerCompetencia;
    },
  },

  watch: {
    dialogForm(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  methods: {
    open() {
      this.$store.dispatch("editarCategoriaCompetencia", this.categoriaCompetencia)
        .then((response) => {
          this.modalidad = response.modalidad.modalidad;
          this.valorModalidad = response.modalidad.id_modalidad;
        })
        .catch(() => {});
      this.dialogForm = true
      this.competenciaSeleccionada = this.categoriaCompetencia.competencia
      this.categoriaSeleccionada = this.categoriaCompetencia.categoria
    },

    deleteItemConfirm () {
      this.dialogDelete = false
    },

    close () {
      this.dialogForm = false
      this.$refs.form.reset();
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    alertaCupos() {
      if (this.cupoDisponible == 0) {
        this.alert        = true;
        this.titleAlert   = "Información";
        this.messageAlert = "La categoria "+ this.categoriaCompetencia.categoria + " ya no tiene cupos para registrar";
        this.titleCard    = "info--text";
        this.buttonCard   = "info";   
        
        setTimeout(() => {
          this.alert = false;
        }, 1000);
      }
    },

    registrar(numero) {
      if (numero != 0) {
        switch (this.valorModalidad) {
          case 1:
              this.validacionModalidad = true;
              break;
          case 2:
              var calculo = numero % 2;
              if (calculo == 0) {
                this.numero_participantes
                this.validacionModalidad = true;
              } else {
                this.alert = true;
                this.titleAlert = "Error";
                this.messageAlert = "El número de participantes no coincide con la modalidad";
                this.titleCard = "error--text";
                this.buttonCard = "error";
                this.validacionModalidad = false;
              }
              break;
          case 3:
              var calculo2 = numero % 3;
              if (calculo2 == 0) {
                this.numero_participantes
                this.validacionModalidad = true;
              } else {
                this.alert = true;
                this.titleAlert = "Error";
                this.messageAlert = "El número de participantes no coincide con la modalidad";
                this.titleCard = "error--text";
                this.buttonCard = "error";
                this.validacionModalidad = false;
              }
              break;
          default:
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = "Debe seleccionar una modalidad";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              this.asignarCategoria = false;
        }
      }else {
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = "El número de participantes no coincide con la modalidad";
          this.titleCard = "error--text";
          this.buttonCard = "error";
      }

      if(this.validacionModalidad === true) {
        if (this.editedIndex > -1) {
          Object.assign(this.cupos[this.editedIndex], this.editedItem)
        } 
        else {
          if (this.$refs.form.validate()) {
            const cupo = {
              competencia_id:       this.$route.params.id_competencia,
              categoria_id:         this.$route.params.id_categoria,
              centro_formacion_id:  this.centroFormacion,
              numero_participantes: this.numero_participantes
            }

            this.loadingForm = true;
            if (cupo.numero_participantes <= this.cupoDisponible)
              this.$store.dispatch("registrarCupo", cupo)
              .then((response) => {
                if (response.status == "success") {
                  this.$refs.form.reset();
                  this.loadingForm = false;
                  this.close()
                  this.alert        = true;
                  this.titleAlert   = "Registrado";
                  this.messageAlert = response.message;
                  this.titleCard    = "success--text";
                  this.buttonCard   = "success";   
                  
                  const categoriaCompetencia = {
                    competencia_id: this.$route.params.id_competencia,
                    categoria_id: this.$route.params.id_categoria
                  }
                  this.$store.dispatch("gestionarCupos", categoriaCompetencia);
                  setTimeout(() => {
                    this.loaderTable = false
                    this.alertaCupos()
                  }, 5000);
                } 
                else {
                  this.loadingForm  = false;
                  this.alert        = true;
                  this.titleAlert   = "Error";
                  this.messageAlert = response.message.cupo;
                  this.titleCard    = "error--text";
                  this.buttonCard   = "error";
                }

                setTimeout(() => {
                  this.alert = false;
                }, 1000);
              })
              .catch(() => {});
            else {
              this.loadingForm  = false;
              this.alert        = true;
              this.titleAlert   = "Error";
              this.messageAlert = "Solo tiene "+this.cupoDisponible+" cupos para asignar";
              this.titleCard    = "error--text";
              this.buttonCard   = "error";
            }
          }
        }      
      }
      
    },

    activarAlertaEliminar(cupo_id){
      this.temCupo_id   = cupo_id
      this.dialogDelete = true

    },

    eliminar(idCupo) {
      this.dialogDelete = false
      this.$store.dispatch("eliminarCupo", idCupo)
      .then((response) => {
        if (response.status == "success") {
          this.alert        = true;
          this.titleAlert   = "Eliminado";
          this.messageAlert = response.message;
          this.titleCard    = "success--text";
          this.buttonCard   = "success";   
          
          const categoriaCompetencia = {
            competencia_id: this.$route.params.id_competencia,
            categoria_id: this.$route.params.id_categoria
          }
          this.$store.dispatch("gestionarCupos", categoriaCompetencia);
          setTimeout(() => {
            this.loaderTable = false
          }, 1000);
        } 
        else {
          this.alert        = true;
          this.titleAlert   = "Error";
          this.messageAlert = response.message.cupo;
          this.titleCard    = "error--text";
          this.buttonCard   = "error";
        }

        setTimeout(() => {
          this.alert = false;
        }, 1000);
      })
      .catch(() => {});
    },

    regresar() {
      this.$router.push({
        name: "categoria-asignar"
      });
    }
  }
};
</script>