<template>
  <div>
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>
    <v-row>
      <v-col
        sm="1"
        lg="2"
        class="hidden-xs-only"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              fixed
              exact
              color="primary"
              v-bind="attrs" 
              v-on="on" 
              @click="regresar"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
      </v-col>
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Detalles Categoria
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">

              <v-col 
                cols="10" 
                sm="8" 
                lg="6"
              >
                <p class="text-h6 font-weight-bold">
                  Categoria
                </p>
                <p class="text-subtitle-1">
                  {{ categoria.categoria }}
                </p>
              </v-col>

              <v-col cols="2">
                <template v-if="categoria.logotipo != null">
                  <v-avatar size="80">
                    <v-img
                      max-height="150"
                      max-width="250"
                      :src="imageUrl + categoria.logotipo"
                    >
                    </v-img>
                  </v-avatar>
                </template>

                <template v-else>
                  <p class="text-subtitle-1 font-weight-bold">
                    Logotipo no registrado
                  </p>
                </template>
              </v-col>

              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Descripción
                </p>
                <p class="text-subtitle-1">
                  {{ categoria.descripcion }}
                </p>
              </v-col>

              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Programas de Formación
                </p>
                <p v-for="(item, i) in programasFormacion" :key="i"
                  class="text-subtitle-1"
                >
                  {{ item.programa_formacion }}
                </p>     
              </v-col>

              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Documentos de Lineamientos
                </p>
                <p class="text-subtitle-1">
                  <v-btn
                    :loading="loading3"
                    :disabled="loading3"
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="downloade()"
                  >
                    Descargar
                    <v-icon
                      right
                      dark
                    >
                      mdi-cloud-download
                    </v-icon>
                  </v-btn>
                </p>
              </v-col>
          
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</div>
</template>
<script>
import Alert from '../../../components/admin/Alert.vue'

export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    imageUrl: "http://localhost:8000/images/categorias/logotipos/",
  }),

  mounted() {
    this.$store.dispatch("detallesCategoria", this.$route.params.id_categoria)

  },

  computed: {
    categoria() {
      return this.$store.getters.obtenerCategoria;
    },

    programasFormacion() {
      return this.$store.getters.obtenerProgramasFormacionCategoria;
    },

    aliados() {
      return this.$store.getters.obtenerAliadosCategoria;
    },
    donwload(){
      return this.$store.getters.obtenerDocumentoLineamientos
    }
  },
  components: {
    "app-alert": Alert 
  },
  methods: {
    regresar() {
      this.$router.push({
        name: 'cupo-asignado'
      });
    },
    downloade () {
        this.$store.dispatch("downloadDocumentoLineamentos",{
        categoria_id: this.$route.params.id_categoria
        })
        .then((response) => {
          const urlReporte = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href= urlReporte
          link.setAttribute('download','Documento-Lineamento-'+this.categoria.categoria+'.pdf')
          document.body.appendChild(link)
          link.click();
        })
        .catch(() => {
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = "la categoria  "+this.categoria.categoria+"  no tiene documento registrado";
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
        })
      setTimeout(() => {
        this.alert = false;
      }, 1000);
    },
  }
};
</script>