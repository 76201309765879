<template>
  <div>
    <!-- cargador tablero -->
    <template v-if="loaderCard">
      <v-skeleton-loader 
        class="mx-auto" 
        type="image"
      > 
      </v-skeleton-loader>
    </template>
    <template v-else>
      <v-row>
        <v-col 
          cols="8" 
          sm="4"
        >
          <v-card 
            outlined 
            elevation="2" 
            color="indigo"
          >
            <v-card-title>
              <v-row class="d-flex justify-space-between my-1 mx-1">
                <p class="white--text text-h3">{{ participantesSenasoft }}</p>
                <v-icon 
                  x-large 
                  color="white" 
                  rigth
                >
                  mdi-account-multiple
                </v-icon>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row class="white--text text-h6 mx-1 mb-1">
                Participantes Registrados
              </v-row>
            </v-card-text>

            <!-- <v-card-actions>
              <v-btn 
                block c
                olor="white" 
                @click="cambiarInformacion(1)"
              >
                <span class="green--text">Mas información</span>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>

        <v-col 
          cols="8" 
          sm="4"
        >
          <v-card 
            outlined 
            elevation="2" 
            color="green"
          >
            <v-card-title>
              <v-row class="d-flex justify-space-between my-1 mx-1">
                <p class="white--text text-h3">{{ lideresSenasoft }}</p>
                <v-icon 
                  x-large 
                  color="white" 
                  rigth
                >
                  mdi-account-multiple
                </v-icon>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row class="white--text text-h6 mx-1 mb-1">
                Lideres Registrados
              </v-row>
            </v-card-text>

            <!-- <v-card-actions>
              <v-btn 
                block 
                color="white" 
                @click="cambiarInformacion(2)"
              >
                <span class="blue--text">Mas información</span>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>

        <v-col 
          cols="8" 
          sm="4"
        >
          <v-card 
            outlined 
            elevation="2" 
            color="#FFA726"
          >
            <v-card-title>
              <v-row class="d-flex justify-space-between my-1 mx-1">
                <p class="white--text text-h3">{{ cuposSenasoft }}</p>
                <v-icon 
                  x-large 
                  color="white" 
                  rigth
                >
                  mdi mdi-text-box-plus
                </v-icon>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row class="white--text text-h6 mx-1 mb-1">
                Cupos Asignados
              </v-row>
            </v-card-text>

            <!-- <v-card-actions>
              <v-btn 
                block 
                color="white" 
                @click="cambiarInformacion(3)"
              >
                <span class="orange--text">Mas información</span>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>

      <!-- grafico participantes x categoria -->
      <v-row>
        <v-col cols="12">
          <v-card 
            outlined 
            elevation="2" 
          >
            <v-card-title>
              <p class="text-h6 font-weight-bold indigo--text mt-2">
                Participantes Registrados
              </p>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col 
                  cols="12" 
                >
                <BarChart
                  :data="participantesCategoriaSenasoft"
                  :options="chartOptions"
                >
                </BarChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- grafico lideres x categoria -->
      <v-row>
        <v-col cols="12">
          <v-card 
            outlined 
            elevation="2" 
          >
            <v-card-title>
              <p class="text-h6 font-weight-bold green--text mt-2">
                Lideres Registrados
              </p>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col 
                  cols="12" 
                >
                <BarChart
                  :data="lideresCategoriaSenasoft"
                  :options="chartOptions"
                >
                </BarChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- grafico cupos x categoria -->
      <v-row>
        <v-col cols="12">
          <v-card 
            outlined 
            elevation="2" 
          >
            <v-card-title>
              <p class="text-h6 font-weight-bold orange--text mt-2">
                Cupos Asignados
              </p>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col 
                  cols="12" 
                >
                <BarChart
                  :data="cuposCategoriaSenasoft"
                  :options="chartOptions"
                >
                </BarChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
  
<script>
import BarChart from '@/components/admin/BarChart.vue';
  
export default {
  data: () => ({
    loaderCard: true,

    chartOptions: {
      indexAxis: 'y',
      responsive: true
    }
  }),

  components: {
    BarChart
  },

  mounted() {
    this.$store.dispatch("participantesCategoriaSenasoft")
    this.$store.dispatch("lideresCategoriaSenasoft")
    this.$store.dispatch("cuposCategoriaSenasoft")
    
    setTimeout(() => {
      this.loaderCard = false;
    }, 1000);
  },

  computed: {
    participantesSenasoft() {
      const participantes = this.$store.getters.obtenerParticipantesCategoriaSenasoft.map(result => result.participantes)
      const totalParticipantes = participantes.reduce((acumulador, valorActual) => acumulador + valorActual, 0)
      return totalParticipantes
    },

    participantesCategoriaSenasoft() {
      const categorias = this.$store.getters.obtenerParticipantesCategoriaSenasoft.map(result => result.categoria)
      const participantes = this.$store.getters.obtenerParticipantesCategoriaSenasoft.map(result => result.participantes)

      const chartData = {
        labels: categorias,
        datasets: [
          {
            label: 'Participantes',
            data: participantes,
            backgroundColor: '#C5CAE9',
            borderColor: '#5C6BC0',
            borderWidth: 2
          }
        ]
      }
      return chartData
    },

    lideresSenasoft() {
      const lideres = this.$store.getters.obtenerLideresCategoriaSenasoft.map(result => result.lideres)
      const totalLideres = lideres.reduce((acumulador, valorActual) => acumulador + valorActual, 0)
      return totalLideres
    },

    lideresCategoriaSenasoft() {
      const categorias = this.$store.getters.obtenerLideresCategoriaSenasoft.map(result => result.categoria)
      const lideres = this.$store.getters.obtenerLideresCategoriaSenasoft.map(result => result.lideres)

      const chartData = {
        labels: categorias,
        datasets: [
          {
            label: 'Lideres',
            data: lideres,
            backgroundColor: '#C8E6C9',
            borderColor: '#66BB6A',
            borderWidth: 2
          }
        ]
      }
      return chartData
    },

    cuposSenasoft() {
      const cupos = this.$store.getters.obtenerCuposCategoriaSenasoft.map(result => result.cupos)
      const totalCupos = cupos.reduce((acumulador, valorActual) => acumulador + valorActual, 0)
      return totalCupos
    },

    cuposCategoriaSenasoft() {
      const categorias = this.$store.getters.obtenerCuposCategoriaSenasoft.map(result => result.categoria)
      const cupos = this.$store.getters.obtenerCuposCategoriaSenasoft.map(result => result.cupos)

      const chartData = {
        labels: categorias,
        datasets: [
          {
            label: 'Cupos',
            data: cupos,
            backgroundColor: '#FFE0B2',
            borderColor: '#FFA726',
            borderWidth: 2
          }
        ]
      }
      return chartData
    }
  }
};
</script>
  
<style scoped>
</style>
  