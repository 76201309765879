<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>

    <!-- formulario -->
    <v-row justify="center">
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Registrar Categoria
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-select
                    outlined
                    multiple
                    small-chips
                    label="Programa de Formación*"
                    :items="programasFormacion"
                    item-value="id"
                    item-text="programa_formacion"
                    v-model="programaFormacion"
                    :rules="programaFormacionReglas"
                  >
                  </v-select>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Categoria*"
                    maxlength="100"
                    counter
                    v-model="categoria"
                    :rules="categoriaReglas"
                  >
                  </v-text-field>

                  <v-file-input
                    outlined
                    dense
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    accept="image/png, image/jpeg"
                    label="Logotipo"
                    truncate-length="50"
                    counter
                    show-size
                    v-model="logotipo"
                    :rules="logotipoReglas"
                  >
                  </v-file-input>

                  <v-textarea
                    outlined
                    rows="4"
                    label="Descripción"
                    v-model="descripcion"
                  >
                  </v-textarea>

                  <v-file-input
                    outlined
                    dense
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    accept="application/pdf"
                    label="Lineamientos"
                    truncate-length="100"
                    counter
                    show-size
                    v-model="documentoLineamientos"
                    :rules="documentoLineamientosReglas"
                  >
                  </v-file-input>

                  <v-select
                    outlined
                    multiple
                    small-chips
                    label="Aliado"
                    :items="aliados"
                    :rules="aliadoRegla"
                    item-value="id"
                    item-text="aliado"
                    v-model="aliado"
                  >
                  </v-select>

                  <v-btn
                    large
                    block
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="registrar"
                  >
                    Registrar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Alert from '../../../components/admin/Alert.vue'

export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    loadingForm:  false,
    form:         true,

    programaFormacion: null,
    programaFormacionReglas: [
      v => !!v || "Seleccione uno o varios programas de formación"
    ],

    categoria: null,
    categoriaReglas: [
      v => !!v || "Escriba una categoria"
    ],

    logotipo: null,
    logotipoReglas: [
      v => !v || v.size < 36000000 || "El tamaño del logotipo debe ser menor que 1 MB"
    ],
    
    descripcion: null,

    documentoLineamientos: null,
    documentoLineamientosReglas: [
      v => !v || v.size < 5000000 || "El tamaño del documento debe ser menor que 5 MB"
    ],

    aliado: null,
    aliadoRegla:[
      v=>!!v || "Elije un  aliado"
    ]
  }),

  created() {
    this.$store.dispatch("crearProgramasFormacion")
    this.$store.dispatch("gestionarAliados")
  },

  computed: {
    areasTematicas() {
      return this.$store.getters.obtenerAreasTematicas
    },

    programasFormacion() {
      return this.$store.getters.obtenerProgramasFormacion
    },

    aliados() {
      return this.$store.getters.obtenerAliados
    }
  },

  components: {
    "app-alert": Alert
  },

  methods: {
    registrar() {
      if (this.$refs.form.validate()) {
        const categoria = new FormData();
        categoria.set("programa_formacion_id",  this.programaFormacion);
        categoria.set("categoria",              this.categoria);
        categoria.set("direccion",              this.direccion);
        categoria.set("logotipo",               this.logotipo);
        categoria.set("descripcion",            this.descripcion);
        categoria.set("doc_lineamiento_categoria", 7);
        categoria.set("documento_lineamientos", this.documentoLineamientos);
        categoria.set("aliado_id",              this.aliado);

        this.loadingForm = true;
        this.$store.dispatch("registrarCategoria", categoria)
        .then((response) => {
          if (response.status == "success") {
            this.$refs.form.reset();
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Registrada";
            this.messageAlert = response.message;
            this.titleCard    = "success--text";
            this.buttonCard   = "success";
            setTimeout(() => {
                this.$router.push({
                  name: "categoria-gestionar",
                });
              }, 2500);
          } 
          else {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message.categoria[0];
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }

          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {
        });
      }
    },
  },
};
</script>