import axios from 'axios'

const expertos = {
    state: () => ({
        expertosRegionales: [],
        expertoRegional: {}
    }),

    getters: {
        obtenerExpertosRegionalesWorldSkills : (state) => (competencia, habilidad) => {
            return state.expertosRegionales.filter(expertoRegional => expertoRegional.competencia_id == competencia && expertoRegional.categoria_id == habilidad)
        },

        obtenerExpertoRegionalWorldSkills: (state) => (id) => {
            return state.expertosRegionales.find(expertoRegional => expertoRegional.id == id)
        },

        obtenerExpertoRegionalCompetencia: (state) => (competencia, identificacion) => {
            return state.expertosRegionales.find(expertoRegional => expertoRegional.competencia_id == competencia && expertoRegional.identificacion == identificacion)
        }
    },

    mutations: {
        enviarExpertosRegionales(state, expertosRegionales) {
            state.expertosRegionales = expertosRegionales
        },

        enviarExpertoRegional(state, expertoRegional) {
            state.expertoRegional = expertoRegional
        }
    },

    actions: {
        registrarExpertoRegional(context, experto) {
            return new Promise((resolve, reject) => {
                axios.post("api/lideresWorldskills", experto)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarExpertosRegionales(context) {
            axios.get("api/lideresWorldskills")
            .then((response) => {
                const expertosRegionales = []
                for (let i = 0; i < response.data.results.length; i++) {
                    expertosRegionales.push({
                        indice: (i+1),
                        id:                     response.data.results[i].id_lider,
                        competencia_id:         response.data.results[i].competencia.id_competencia,
                        competencia:            response.data.results[i].competencia.competencia,
                        categoria_id:           response.data.results[i].categoria.id_categoria,
                        categoria:              response.data.results[i].categoria.categoria,
                        tipo_lider_id:          response.data.results[i].tipo_lider.id_tipo_lider,
                        tipo_lider:             response.data.results[i].tipo_lider.tipo_lider,
                        centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
                        regional_id:            response.data.results[i].centro_formacion.regional.id_regional,
                        regional:               response.data.results[i].centro_formacion.regional.regional,
                        cargo:                  response.data.results[i].cargo,
                        talla_camiseta:         response.data.results[i].talla_camiseta,
                        persona_id:             response.data.results[i].persona.id_persona,
                        identificacion:         response.data.results[i].persona.identificacion,
                        nombres:                response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre,
                        apellidos:              response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido,
                        telefono_movil:         response.data.results[i].persona.telefono_movil,
                        correo:                 response.data.results[i].persona.correo,
                        fecha_nacimiento:       response.data.results[i].persona.fecha_nacimiento,
                        genero:                 response.data.results[i].persona.genero.genero
                    })
                }
                context.commit("enviarExpertosRegionales", expertosRegionales)
            })
            .catch(() => {})
        },
        
        detallesExpertoRegional(context, expertoRegional) {
            axios.get("api/lideresWorldskills/"+expertoRegional.id)
            .then((response) => {
                const expertoRegional = {
                    id:                     response.data.results.id_lider,
                    competencia_id:         response.data.results.competencia.id_competencia,
                    competencia:            response.data.results.competencia.competencia,
                    categoria_id:           response.data.results.categoria.id_categoria,
                    categoria:              response.data.results.categoria.categoria,
                    tipo_lider_id:          response.data.results.tipo_lider.id_tipo_lider,
                    tipo_lider:             response.data.results.tipo_lider.tipo_lider,
                    centro_formacion_id:    response.data.results.centro_formacion.id_centro_formacion,
                    centro_formacion:       response.data.results.centro_formacion.centro_formacion,
                    regional_id:            response.data.results.centro_formacion.regional.id_regional,
                    regional:               response.data.results.centro_formacion.regional.regional,
                    persona_id:             response.data.results.persona.id_persona,
                    identificacion:         response.data.results.persona.identificacion,
                    nombres:                response.data.results.persona.primer_nombre+" "+response.data.results.persona.segundo_nombre,
                    apellidos:              response.data.results.persona.primer_apellido+" "+response.data.results.persona.segundo_apellido,
                    telefono_movil:         response.data.results.persona.telefono_movil,
                    fecha_nacimiento:       response.data.results.persona.fecha_nacimiento,
                    correo:                 response.data.results.persona.correo,
                    genero:                 response.data.results.persona.genero.genero,
                    cargo:                  response.data.results.cargo,
                    talla_camiseta:         response.data.results.talla_camiseta
                }

                context.commit("enviarExpertoRegional", expertoRegional)
            })
        },

        editarExpertoRegional(context, experto) {
            return new Promise((resolve, reject) => {
                axios.get("api/lideresWorldskills/"+experto+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarExpertoRegional(context, experto) {
            return new Promise((resolve, reject) => {
                axios.put("api/lideresWorldskills/"+experto.id, experto)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        eliminarExpertoRegional(context, experto) {
            return new Promise((resolve, reject) => {
                axios.delete("api/lideresWorldskills/"+experto)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        // expertos regionales adjudicar
        adjudicarExpertosRegionalesWorldskills(context, competencia) {
            return new Promise((resolve, reject) => {
                axios.post("api/expertosRegionalesWorldskillsAdjudicar", competencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        }
    }
}

export default expertos