<template>
  <div>
    <!-- alerta -->
    <v-dialog max-width="390" v-model="alertParticipante">
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            No se encuentra a la persona
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          Deseas registrarla?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="mb-2 white--text"
            @click="registroPersona"
          >
            Si
          </v-btn>
          <v-btn
            color="error"
            class="mb-2 white--text"
            @click="alertParticipante = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="390" v-model="alertParticipante">
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            No se encuentra a la persona
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          Deseas registrarla?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="mb-2 white--text"
            @click="registroPersona"
          >
            Si
          </v-btn>
          <v-btn
            color="error"
            class="mb-2 white--text"
            @click="alertParticipante = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog 
      max-width="280" 
      v-model="alert"
    >
      <v-card>
        <v-card-title class="justify-center">
          <p 
            class="text-h5 font-weight-bold" 
            :class="titleCard"
          >
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn  
            :color="buttonCard" 
            class="mb-2 white--text"
            @click="alert = false"
          > 
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    <!-- tabla de datos -->
    <v-row justify="center">
      <v-col cols="12">
        <router-view></router-view>
        <v-card>
          <v-card-title class="text-h5 font-weight-bold primary--text">
            <v-row align="center">
              <v-col 
                cols="2"
                md="1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      exact
                      color="primary"
                      v-bind="attrs" 
                      v-on="on" 
                      @click="regresar"
                    >
                      <v-icon>
                        mdi-arrow-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar</span>
                </v-tooltip>
              </v-col>
              <v-col 
                cols="10"
                md="6"
              >
                {{ competencia.competencia }}
              </v-col>
              
              <v-col 
                cols="12"
                md="3"
              >
                <v-text-field
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  label="Buscar Lider"
                  hide-details
                  v-model="searchTable"
                >
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="2"
                align="end"
              >
                <v-btn
                  exact
                  color="primary"
                  @click="open"
                >
                  Registrar Lider
                </v-btn> 
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            fixed-header
            locale="es"
            height="460px"
            class="elevation-2"
            :loading="loadingTable"
            :headers="headersTable"
            :items="lideresCategoria"
            :search="searchTable"
            no-data-text="No hay lideres registrados"
            :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1] }"
          >
            <template v-slot:top>
              <v-dialog 
                persistent
                scrollable
                max-width="520px"
                v-model="dialogForm"                 
              >
                <v-card :loading="loadingForm">
                  <v-card-title class="justify-center">
                    <p class="text-h5 font-weight-bold primary--text">{{ formTitle }}</p>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row justify="center">
                        <v-col 
                          cols="12"
                          md="10"
                        >
                          <v-form 
                            ref="form" 
                            v-model="form"
                          >
                            <v-text-field
                              outlined
                              dense
                              disabled
                              type="text"
                              label="Competencia"
                              v-model="competenciaSeleccionada"
                            >
                            </v-text-field>

                            <!-- <v-select
                              outlined
                              dense
                              label="Categoria*"
                              :items="categoriasCompetencia"
                              item-value="categoria_id"
                              item-text="categoria"
                              v-model="categoria"
                              :rules="categoriaReglas"
                            >
                            </v-select> -->

                             <v-select
                              outlined
                              dense
                              label="Tipo de Lider*"
                              :items="tiposLider"
                              item-value="id"
                              item-text="tipo_lider"
                              v-model="tipoLider"
                              :rules="tipoLiderReglas"
                            >
                            </v-select>

                            <v-text-field
                              outlined
                              dense
                              append-icon="mdi-magnify"
                              type="number"
                              label="Número de Identificación"
                              v-model="identificacion"
                              :rules="identificacionReglas"
                              :loading="loadingTextField"
                              :error-messages="mensajeIdentificacion"
                              @keydown.enter="persona(identificacion)"
                              @click:append="persona(identificacion)"
                            >
                            </v-text-field>

                            <template v-if="validarPersona == true">
                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Nombres"
                                v-model="nombres"
                              >
                              </v-text-field>

                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Apellidos"
                                v-model="apellidos"
                              >
                              </v-text-field>

                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Fecha de Nacimiento"
                                v-model="fecha_nacimiento"
                              >
                              </v-text-field>

                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Genero"
                                v-model="genero"
                              >
                              </v-text-field>

                              <v-select
                                outlined
                                dense
                                label="Talla de Camiseta*"
                                :items="tallas"
                                v-model="talla"
                                :rules="tallaReglas"
                              >
                              </v-select>
                              <v-text-field
                              outlined
                              dense
                              type="text"
                              label="Cargo del lider*"
                              counter
                              maxlength="100"
                              v-model="cargo"
                              :rules="cargoRegla"
                              >
                              </v-text-field>
                              <!-- <v-select
                                outlined
                                dense
                                label="Cargo del lider*"
                                :items="cargos"
                                item-value="id"
                                item-text="cargo"
                                v-model="cargo"
                                :rules="cargoRegla"
                              > 
                              </v-select> -->
                            </template>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                      text 
                      color="error" 
                      @click="close"
                    >
                      Cancelar
                    </v-btn>
            
                    <v-btn 
                      text 
                      exact
                      color="primary" 
                      @click="registrar"
                    > 
                      Registrar 
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="error"
                    v-bind="attrs" 
                    v-on="on"
                    @click="activarAlertaEliminar(item.id_lider)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="justify-center text-h5">¿ Desea eliminar el lider ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                  text 
                  color="primary" 
                  @click="closeDelete"
              >Cancelar
              </v-btn>
              <v-btn 
                  text 
                  color="error" 
                  @click="eliminarLider(temLider_id)">OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
   </v-dialog>
  </div>
  
</template>

<script>
export default {
  data: () => ({
    loadingTable: true,
    searchTable:  null,
    loadingTextField: false,

    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    loadingForm:  false,
    form:         true,

    dialogForm: false,
    dialogDelete: false,

    competenciaSeleccionada: "",

    categoria: null,
    categoriaReglas: [(v) => !!v || "Seleccione una categoria"],

    tipoLider: null,
    tipoLiderReglas: [(v) => !!v || "Seleccione una tipo de lider"],

    centroFormacionSeleccionado: "",

    regional: null,
    regionalReglas: [(v) => !!v || "Seleccione una regional"],

    municipio: null,
    municipioReglas: [(v) => !!v || "Seleccione un municipio"],

    centroFormacion: null,
    centroFormacionReglas: [(v) => !!v || "Seleccione un centro de formación"],

    identificacion: null,
    identificacionReglas: [(v) => !!v || "Escriba un número de identificación"],
    mensajeIdentificacion: "",

    cargo: null,
    cargoRegla: [(v) => !!v || "Escriba el cargo"],

    validarPersona: false,
    idPersona: null,
    nombres: null,
    apellidos: null,
    fecha_nacimiento: null,
    genero: null,
    talla: null,
    tallas: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    tallaReglas: [(v) => !!v || "Seleccione una talla"],
    mensajetalla: "",

    temLider_id: null,

    alertParticipante:  false,

    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Nombres",
        value: "nombres",
        align: "start",
        width: "12%",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Apellidos",
        value: "apellidos",
        align: "start",
        width: "12%",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Correo Electrónico",
        value: "correo",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Tipo de Lider",
        value: "tipo_lider",
        align: "start",
        sortable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Categoria",
        value: "categoria",
        align: "start",
        sortable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Centro de Formación",
        value: "centro_formacion",
        align: "start",
        width: "20%",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ],

    editedIndex: -1
  }),

  created () {
    this.$store.dispatch("detallesCompetencia", this.$route.params.id_competencia);

    const categoria = {
      competencia_id:       this.$route.params.id_competencia,
      centro_formacion_id:  this.usuario.centro_formacion_id
    }
    this.$store.dispatch("gestionarLideresCategoria", categoria);
    this.$store.dispatch("gestionarCategoriasCompetencia", this.$route.params.id_competencia);
    this.$store.dispatch("crearTiposLider");
    setTimeout(() => {
      this.loadingTable = false;
    }, 1000);
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Registrar Lider' : 'Actualizar Lider'
    },

    competencia() {
      return this.$store.getters.obtenerCompetencia;
    },

    lideresCategoria() {
      return this.$store.getters.obtenerLideresCategoria;
    },

    usuario() {
      return this.$store.getters.usuario;
    },

    categoriasCompetencia() {
      return this.$store.getters.obtenerCategoriasCompetencia;
    },

    tiposLider() {
      return this.$store.getters.obtenerTiposLiderCategoria;
    },
  },

  watch: {
    dialogForm(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  methods: {
    open() {
      this.dialogForm = true
      this.competenciaSeleccionada = this.competencia.competencia
      this.liderSeleccionado = this.tiposLider.tipo_lider
    },

    close() {
      this.dialogForm = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs.form.reset();
      this.validarPersona = false
      this.mensajeIdentificacion = ""
    },

    closeDelete () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    persona(identificacion) {
      this.$store.dispatch("crearPersonas", identificacion)
      .then( (response) => {
        this.loadingTextField = true
        if (response != null) {
          this.mensajeIdentificacion = ""
          setTimeout( () => {
            const segundoNombre = response.segundo_nombre != null ? response.segundo_nombre : '' 
            const segundoApellido = response.segundo_apellido != null ? response.segundo_apellido : ''

            this.idPersona = response.id_persona
            this.nombres   = response.primer_nombre+" "+segundoNombre
            this.apellidos = response.primer_apellido+" "+segundoApellido
            this.fecha_nacimiento = response.fecha_nacimiento
            this.genero = response.genero.genero
            
            this.loadingTextField = false
          }, 500)
          this.validarPersona = true
        }
        else {
          this.mensajeIdentificacion = "Número de identificación no encontrada"
          setTimeout( () => {
            this.loadingTextField = false
          }, 500)
          this.validarPersona = false,
          this.alertParticipante=  true
        }
      })
      .catch( () => {});
    },

    registrar() {
      if (this.editedIndex > -1) {
        Object.assign(this.cupos[this.editedIndex], this.editedItem)
      } 
      else {
        if (this.$refs.form.validate()) {
          if (this.validarPersona) {

            const liderCompetencia = {
              competencia_id:       this.$route.params.id_competencia,
              categoria_id:         156,
              tipo_lider_id:        this.tipoLider,
              centro_formacion_id:  this.usuario.centro_formacion_id,
              persona_id:           this.idPersona,
              talla_camiseta:       this.talla,
              cargo:                this.cargo
            }

            this.loadingForm = true;

            this.$store.dispatch("registrarLiderCompetencia", liderCompetencia)
            .then((response) => {
              if (response.status == "success") {
                this.close()
                this.loadingForm  = false;
                this.alert        = true;
                this.titleAlert   = "Registrado";
                this.messageAlert = response.message;
                this.titleCard    = "success--text";
                this.buttonCard   = "success";   
              
                const categoria = {
                  competencia_id:       this.$route.params.id_competencia,
                  centro_formacion_id:  this.usuario.centro_formacion_id
                }
                this.$store.dispatch("gestionarLideresCategoria", categoria);
                setTimeout(() => {
                  this.loadingTable = false;
                }, 1000);
              } 
              else {
                this.loadingForm  = false;
                this.alert        = true;
                this.titleAlert   = "Error";
                this.messageAlert = response.message.lider;
                this.titleCard    = "error--text";
                this.buttonCard   = "error";
              }

              setTimeout(() => {
                this.alert = false;
              }, 1000);
            })
            .catch(() => {});
          }
          else {
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = "Número de identificación no encontrada";
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
            setTimeout(() => {
              this.alert = false;
            }, 1000);
          }
        }
      }      
    },

    activarAlertaEliminar( lider_id){
      this.temLider_id   = lider_id
      this.dialogDelete = true

    },

    eliminarLider(idlider) {

      this.dialogDelete = false
      // const liderCompetencia = {
      //   competencia_id: this.$route.params.id_competencia,
      //   persona_id: idPersona
      // }

      this.$store.dispatch("eliminarLiderCompetencia", idlider)
      .then((response) => {
        if (response.status == "success") {
          this.alert        = true;
          this.titleAlert   = "Eliminado";
          this.messageAlert = response.message;
          this.titleCard    = "success--text";
          this.buttonCard   = "success";   
          
          const categoria = {
            competencia_id:       this.$route.params.id_competencia,
            centro_formacion_id:  this.usuario.centro_formacion_id
          }
          this.$store.dispatch("gestionarLideresCategoria", categoria);
          setTimeout(() => {
            this.loadingTable = false;
          }, 1000);
        } 
        else {
          this.alert        = true;
          this.titleAlert   = "Error";
          this.messageAlert = response.message.lider;
          this.titleCard    = "error--text";
          this.buttonCard   = "error";
        }

        setTimeout(() => {
          this.alert = false;
        }, 1000);
      })
      .catch(() => {});
    },
    
    regresar() {
      this.$router.push({
        name: "inscripcion-gestionar"
      });
    },
    registroPersona(){
      this.$router.push({
        name: "personas-registrar",
        params:{experto:'0'}
      })
    },
  },
};
</script>