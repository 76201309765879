<template>
  <div>
    <app-alert :alert="alert" :titleAlert="titleAlert" :messageAlert="messageAlert" :titleCard="titleCard"
      :buttonCard="buttonCard">
    </app-alert>
    <v-row>
      <v-col sm="1" lg="2" class="hidden-xs-only">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small fixed exact color="primary" v-bind="attrs" v-on="on" @click="regresar">
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" sm="10" lg="8">
        <v-card outlined elevation="2">
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Actualizar Participante
            </p>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="form">
              <v-row justify="center">
                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Competencia</p>
                  <v-text-field outlined dense type="text" disabled counter v-model="competenciaSeleccionada">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Categoria</p>
                  <v-text-field outlined dense type="text" disabled counter v-model="categoriaSeleccionada">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Programa de Formación</p>
                  <v-select outlined dense :items="programasFormacion" item-value="id" item-text="programa_formacion"
                    v-model="programaFormacion" :rules="programaFormacionReglas">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Número de Ficha</p>
                  <v-text-field outlined dense type="number" maxlength="10" counter v-model="numeroFicha">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Identificación</p>
                  <v-text-field outlined disabled dense type="number" v-model="identificacion">
                  </v-text-field>
                </v-col>

                <template>
                  <v-col cols="12" sm="10" lg="8">
                    <p class="text-h6 font-weight-bold">Nombres</p>
                    <v-text-field outlined disabled dense type="text" counter v-model="nombres">
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="10" lg="8">
                    <p class="text-h6 font-weight-bold">Apellidos</p>
                    <v-text-field outlined dense disabled type="text" counter v-model="apellidos">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="10" lg="8">
                    <p class="text-h6 font-weight-bold">Fecha de Nacimiento</p>
                    <v-text-field outlined dense type="text" disabled counter v-model="fecha_nacimiento">
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="10" lg="8">
                    <p class="text-h6 font-weight-bold">Genero</p>
                    <v-text-field outlined dense type="text" disabled counter v-model="genero">
                    </v-text-field>
                  </v-col>
                </template>
                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Talla de Camiseta</p>
                  <v-select outlined dense label="Talla de Camiseta*" :items="tallas" v-model="talla"
                    :rules="tallaReglas">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="10" lg="8">
                  <v-btn large exact block elevation="2" color="primary" class="mt-1 mb-3"
                    @click="actualizarParticipante">
                    Actualizar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Alert from '../../../components/admin/Alert.vue'
export default {
  data: () => ({
    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    competenciaSeleccionada: "",
    categoriaSeleccionada: "",
    programaFormacion: null,
    programaFormacionReglas: [
      v => !!v || "Seleccione un programa de formación"
    ],

    numeroFicha: null,
    numeroFichaReglas: [
      v => !!v || "Escriba un número de ficha"
    ],

    identificacion: null,
    identificacionReglas: [
      v => !!v || "Escriba un número de identificación"
    ],
    mensajeIdentificacion: "",
    form: true,

    nombres: null,
    apellidos: null,
    fecha_nacimiento: null,
    genero: null,
    talla: null,
    tallas: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    tallaReglas: [(v) => !!v || "Seleccione una talla"],

  }),
  created() {
    this.$store.dispatch("detallesParticipante", this.$route.params.id_participante)
    this.$store.dispatch("editarParticipante", this.$route.params.id_participante)
      .then((response) => {
        this.programaFormacion = response.programa_formacion_id,
          this.categoriaSeleccionada = response.categoria.categoria,
          this.competenciaSeleccionada = response.competencia.competencia,
          this.numeroFicha = response.numero_ficha,
          this.talla = response.talla_camiseta,
          this.identificacion = response.persona.identificacion,
          this.nombres = response.persona.primer_nombre + " " + response.persona.segundo_nombre,
          this.apellidos = response.persona.primer_apellido + " " + response.persona.segundo_apellido,
          this.fecha_nacimiento = response.persona.fecha_nacimiento,
          this.genero = response.persona.genero.genero
      })
      .catch(() => { })

  },
  computed: {
    participante() {
      return this.$store.getters.obtenerParticipante;
    },

    programasFormacion() {
      return this.$store.getters.obtenerProgramasFormacion
    }
  },
  components: {
    "app-alert": Alert
  },
  methods: {
    actualizarParticipante() {
      this.$store.dispatch("actualizarParticipante", {
        id_participante: this.$route.params.id_participante,
        competencia_id: this.participante.competencia_id,
        categoria_id: this.participante.categoria_id,
        centro_formacion_id: this.participante.centro_formacion_id,
        programa_formacion_id: this.programaFormacion,
        numero_ficha: this.numeroFicha,
        persona_id: this.participante.persona_id,
        talla_camiseta: this.talla
      })
      .then((response) => {
        if (response.status == "success") {
          this.alert = true;
          this.titleAlert = "Actualizado";
          this.messageAlert = response.message;
          this.titleCard = "success--text";
          this.buttonCard = "success";
        }
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = response.message.participante;
          this.titleCard = "error--text";
          this.buttonCard = "error";
        }

        setTimeout(() => {
          this.alert = false;
        }, 1000);
      })

      .catch(() => { });
    },
    regresar() {
      this.$router.push({
        name: "participante-gestionar",
        params: { id_competencia: this.participante.competencia_id, id_categoria: this.participante.categoria_id }
      });
    }

  },

}
</script>