<template>
  <div v-if="auth">
      <app-administrativo></app-administrativo>
  </div>
  <div v-else>
    <app-publico></app-publico>
  </div>
</template>

<script>
import Publico from './components/layouts/Publico'
import Administrativo from './components/layouts/Administrativo'

export default {
  name: "App",

  data:() => ({
    //
  }),

  created() {
    this.$store.dispatch("autenticar")
    .then(() => {})
    .catch(() => {}),
    this.verificarAcceso()
  },

  computed: {
    auth() {
      return this.$store.getters.auth
    }
  },

  components: {
    'app-publico': Publico,
    'app-administrativo': Administrativo
  },

  methods: {
    verificarAcceso() {
      if (this.$route.meta.requiresAuth) {
        if (!this.$store.getters.auth || !this.$route.meta.roles.includes(this.$store.getters.role)) {
          this.$router.push({ name: 'inicio' })
        }
      }
    }
  }
  
};
</script>
