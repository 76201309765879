<template>
  <v-row>
    <v-col
      sm="1"
      lg="2"
      class="hidden-xs-only"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            fixed
            exact
            color="primary"
            v-bind="attrs" 
            v-on="on" 
            @click="regresar"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>Regresar</span>
      </v-tooltip>
    </v-col>
    <v-col 
      cols="12" 
      sm="10" 
      lg="8"
    >
      <v-card 
        outlined 
        elevation="2"
      >
        <v-card-title class="justify-center">
          <p class="text-h4 font-weight-bold primary--text mt-2">
            Detalles {{ titleCard }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col 
              v-for="(item, i) in itemsCard" :key="i"
              :cols="item.col" 
              :sm="item.colSm" 
              :lg="item.colLg"
            >
              <template v-if="item.label != 1">
                <p class="text-h6 font-weight-bold">
                  {{ item.label }}
                </p>

                <p class="text-subtitle-1">
                  {{ item.value }}
                </p>
              </template>

              <template v-else>
                  <template v-if="item.value != null">
                    <v-avatar size="80">
                      <v-img
                        max-height="150"
                        max-width="250"
                        :src="resourcesUrl[0] + item.value"
                      >
                      </v-img>
                    </v-avatar>
                  </template>

                  <template v-else>
                    <p class="text-subtitle-1 font-weight-bold">
                      Logotipo no registrado
                    </p>
                  </template>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    titleCard:    String,
    itemsCard:    Array,
    resourcesUrl: Array,
    rutaRegresar: String
  },

  methods: {
    regresar() {
      this.$router.push({
        name: this.rutaRegresar
      });
    }
  }
};
</script>