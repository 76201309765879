<template>
  <main>

      <v-card class="mx-auto pb-4" max-width="2000" max-height="1000">
        <v-card
          class="overflow-y-auto overflow-x-hidden mx-auto"
          max-height="450"
          permanent
        >
          <v-row justify="space-around">
            <v-card-text>
              <div class="font-weight-bold ml-8 mb-2 mt-2">Preguntas</div>

              <v-timeline align-top dense id="card-msg" class="p-0">
                <v-timeline-item
                  v-for="mensaje in mensajes"
                  :key="mensaje.id_message"
                  class=""
                  size="small"
                  icon="mdi-help"
                >
                  <v-card
                    class="pl-5"
                    max-height="800"
                    max-width="889"
                    elevation="15"
                  >
                    <v-card-title>
                      <strong v-if="mensaje.user_id == usuario.id_usuario"
                        >Tú</strong
                      >
                      <strong v-else>
                        {{ mensaje.nombres }}
                      </strong>
                    </v-card-title>
                    <v-card-subtitle>
                      {{ mensaje.hora_envio }}
                    </v-card-subtitle>
                    <v-card-text>
                      {{ mensaje.message }}
                    </v-card-text>
                    <v-card-actions class="d-flex justify-start flex-wrap">
                      <v-col>
                        <v-btn
                          v-if="
                            usuario.perfil_id == 4 ||
                            usuario.id_usuario == mensaje.id_usuario
                          "
                          class=""
                          fab
                          dark
                          small
                          color="red"
                          @click="
                            id_messageR(mensaje.id_message, mensaje.nombres,mensaje.user_id,usuario.id_usuario )
                          "
                        >
                          <v-icon dark>mdi-message-text-outline</v-icon></v-btn
                        >
                      </v-col>
                      <template v-if="mensaje.documento != null">
                        <template class="d-flex justify-end">
                          <v-col
                            cols="12"
                            v-if="
                              mensaje.documento.endsWith('.jpg') ||
                              mensaje.documento.endsWith('.png') ||
                              mensaje.documento.endsWith('.jpeg')
                            "
                          >
                            <v-card
                              @click="imgEspandida(mensaje.documento)"
                              class="d-flex"
                              max-height="300"
                              max-width="300"
                            >
                              <v-img
                                :src="img + mensaje.documento"
                                max-height="290"
                                max-width="300"
                              ></v-img>
                            </v-card>
                          </v-col>
                          <v-chip
                            v-else
                            @click="
                              descargar(mensaje.id_message, mensaje.documento)
                            "
                          >
                            {{ mensaje.documento }}
                          </v-chip>
                        </template>
                      </template>
                    </v-card-actions>
                  </v-card>

                  <v-timeline align-top dense id="card-msg" class="ml-2">
                    <v-timeline-item
                      v-for="respuesta in mensaje.respuestas"
                      :key="respuesta.id_message"
                      small
                      color="red"
                    >
                      <v-card
                        max-height="1000"
                        max-width="785"
                        class="px-5 pt-3 pb-3"
                      >
                      <v-card-subtitle v-if="respuesta.usuario.id_usuario  == usuario.id_usuario"
                        >@Tú</v-card-subtitle
                      >
                        <v-card-subtitle v-else>
                          @{{ respuesta.usuario.nombres }}
                        </v-card-subtitle>
                        <v-card-text>
                          {{ respuesta.message }}
                        </v-card-text>
                        <v-card-action>
                          <v-row no-gutters>
                            <template v-if="respuesta.documento != null">
                              <v-col
                                v-if="
                                  respuesta.documento
                                    .toLowerCase()
                                    .endsWith('.jpg') ||
                                  respuesta.documento
                                    .toLowerCase()
                                    .endsWith('.png') ||
                                  respuesta.documento
                                    .toLowerCase()
                                    .endsWith('.jpeg')
                                "
                                md="12"
                              >
                                <v-card
                                  @click="imgEspandida(respuesta.documento)"
                                  class="d-flex"
                                  max-height="300"
                                  max-width="300"
                                >
                                  <v-img
                                    :src="img + respuesta.documento"
                                    max-height="300"
                                    max-width="300"
                                  ></v-img>
                                </v-card>
                              </v-col>
                              <v-col v-else md="5">
                                <v-chip
                                  @click="
                                    descargar(
                                      respuesta.id_message,
                                      respuesta.documento
                                    )
                                  "
                                >
                                  {{ respuesta.documento }}
                                </v-chip>
                              </v-col>
                            </template>
                            <v-col class="d-flex justify-end">
                              <p class="pt-2">
                                {{ respuesta.hora_envio }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-card-action>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-row>
        </v-card>
        <v-row justify="center"> </v-row>
        <template>
          <v-card width="900" class="mx-auto mt-5" elevation="20">
            <div class="d-flex justify-space-between pa-4 pb-0">
              <v-btn-toggle multiple variant="outlined" divided>
                <v-btn @click="cambiarLetra('font-italic')">
                  <v-icon>mdi-format-italic</v-icon>
                </v-btn>
                <v-btn @click="cambiarLetra('font-weight-bold')">
                  <v-icon>mdi-format-bold</v-icon>
                </v-btn>

                <v-btn>
                  <v-file-input
                    counter
                    hide-input
                    truncate-length="15"
                    class="mb-4 ml-1"
                    v-model="file"
                    ref="inputFile"
                    @change="nombreFile(file)"
                  ></v-file-input>
                </v-btn>
                <!-- <v-btn>
                  <div class="d-flex align-center flex-column justify-center">
                    <v-icon icon="mdi-format-color-text"></v-icon>

                    <v-sheet
                      tile
                      height="4"
                      width="26"
                      color="purple"
                    ></v-sheet>
                  </div>
                </v-btn> -->
                <div
                  class="d-flex align-center flex-column justify-center ml-3"
                >
                  <v-alert
                    type="info"
                    close-text="Close Alert"
                    dismissible
                    v-model="alert"
                    class="font-italic"
                    @click="cerrarPregunta()"
                    >{{ estadoMensaje }}
                  </v-alert>
                </div>
              </v-btn-toggle>

              <!-- <v-btn-toggle variant="outlined" divided>
                <v-btn>
                  <v-icon> mdi-format-align-center </v-icon>
                </v-btn>

                <v-btn>
                  <v-icon> mdi-format-align-left </v-icon>
                </v-btn>

                <v-btn>
                  <v-icon> mdi-format-align-right </v-icon>
                </v-btn>
              </v-btn-toggle> -->
            </div>

            <v-sheet class="pa-4 text-center">
              <v-row>
                <v-col cols="9">
                  <v-textarea
                    v-if="!seding"
                    @keyup.enter="registrar"
                    v-model="message"
                    rows="3"
                    :class="tipoLetra"
                    ref="textarea"
                    :rules="mensajeRegla"
                  >
                    <v-textarea v-if="seding" disabled>
                      <template v-slot:label>
                        <div>
                          Enviando....
                        </div>
                      </template>
                    </v-textarea>
                  </v-textarea>
                </v-col>
                <v-col cols="3">
                  <template>
                    <v-btn
                      block
                      large
                      exact
                      elevation="2"
                      color="primary"
                      class="mt-2 mb-6"
                      @click="registrar"
                      @keyup.alt.enter="addSpace"
                    >
                      <span v-if="id === null">Preguntar</span>
                      <span v-else>Responder</span>
                    </v-btn>
                    <template v-if="file != null">
                      <v-chip small label color="primary" dismissible>
                        {{ texto }}
                      </v-chip>
                      <v-chip @click="limpiarFile()"> limpiar </v-chip>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-sheet>
          </v-card>
        </template>
      </v-card>
    <v-dialog
      v-model="abrirImg"
      max-width="1000"
      max-height="1000"
      @change="abrirImg = false"
    >
      <v-card max-height="1000" max-width="1000">
        <v-img
          :src="img + nombreImg"
          max-height="1000"
          max-width="1000"
        ></v-img>
      </v-card>
    </v-dialog>
  </main>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    abrirImg: false,
    nombreImg: null,
    tipoLetra: null,
    content: null,
    message: "",
    mensajes: [],
    mensajeRegla: [(v) => !!v || "llena el campo para enviar el mensaje"],
    seding: false,
    id: null,
    estadoMensaje: null,
    alert: false,
    file: null,
    texto: null,
    valueFile: 0,
    img: `${axios.defaults.baseURL}/img/foro/`,
    comment: "",
    formattedComment: [],
    format: "normal",
  }),
  mounted() {
    this.getAllMesasgues(this.$route.params.id_forum);
    setInterval(() => {
      this.getAllMesasgues(this.$route.params.id_forum);
  }, 3000);
  },
  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },
  },

  methods: {
    functiondiv(content) {
      console.log(content.data);
    },
    cambiarLetra(font_family) {
      this.tipoLetra = font_family;
    },
    imgEspandida(nombreImagen) {
      (this.nombreImg = nombreImagen), (this.abrirImg = true);
    },
    cerrarPregunta() {
      this.id = null;
      this.alert = false;
    },
    limpiarFile() {
      this.$refs.inputFile.reset();
    },
    id_messageR(id_message, nombre,mensajeUser,userId) {
      this.alert = true;
      this.id = id_message;
      if(mensajeUser==userId){
        this.estadoMensaje = `Estas comentando tu pregunta`;
      }else{
        this.estadoMensaje = `Le estas respondiendo a ${nombre}`;
      }
    },
    nombreFile(text) {
      if (text != null) {
        this.texto = text.name;
      } else {
        this.texto = null;
      }
    },
    registrar() {
      if (this.$refs.textarea.validate()) {
        this.seding = true;
        let today = new Date();
        const mensaje = new FormData();
        mensaje.set("message", this.message);
        mensaje.set(
          "hora_envio",
          today.toLocaleString().split(",")[1].toString()
        );
        mensaje.set("forum_id", this.$route.params.id_forum);
        mensaje.set("user_id", this.usuario.id_usuario);
        mensaje.set("documento", this.file);
        if (this.alert === true) {
          mensaje.set("pregunta_id", this.id);
        }
        this.cerrarPregunta();
        this.limpiarFile();
        this.$store
          .dispatch("registrarMensajes", mensaje)
          .then((response) => {
            this.message = "";
            if (response.status == "200") {
              this.getAllMesasgues(this.$route.params.id_forum);
            }
            return this.$store.getters.obtenermensajes;
          })
          .catch(() => {})
          .finally(() => {
            this.seding = false;
          });
      }
    },
    getAllMesasgues(id_forum) {
      axios
        .get(`/api/getmessages/${id_forum}`)
        .then((res) => {
          this.mensajes = res.data.results;
          this.scrollHeight();
        })
        .catch(() => {});
    },

    addSpace() {
      this.message.concat("\n");
    },

    scrollHeight() {
      console.log(
        document
          .getElementById("card-msg")
          .scrollTop(document.getElementById("card-msg"))
      );
    },

    descargar(id_message, documento) {
      this.$store.dispatch("descargarDocumento", id_message).then((res) => {
        const urlReporte = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = urlReporte;
        link.setAttribute("download", documento);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>