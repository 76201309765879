<template>
  <div>
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>
    <v-row>
      <v-col 
        sm="1" 
        lg="2" 
        class="hidden-xs-only"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              fixed
              exact
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="regresar"
            >
              <v-icon> mdi-arrow-left </v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
      </v-col>

      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Permisos {{ perfil.perfil }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-list
                    flat
                    subheader
                  >
                    <v-list-item-group
                      multiple
                      active-class=""
                    >
                      <!-- 1 nivel -->
                      <div v-for="moduloPadre in modulos" 
                        :key="moduloPadre.id_modulo"
                      >
                        <v-list-item>
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title>{{ moduloPadre.modulo }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-checkbox 
                                :input-value="active" 
                                v-model="permisos"
                                :value="moduloPadre.id_modulo"
                              >
                              </v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>

                        <!-- 2 nivel -->
                        <div v-for="moduloHijo in moduloPadre.modulo_hijo" 
                          :key="moduloHijo.id_modulo"
                        >
                          <v-list-item class="ml-4">
                            <template v-slot:default="{ active }">
                              <v-list-item-content>
                                <v-list-item-title>{{ moduloHijo.modulo }}</v-list-item-title>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-checkbox 
                                  :input-value="active"
                                  v-model="permisos"
                                  :value="moduloHijo.id_modulo"
                                >
                                </v-checkbox>
                              </v-list-item-action>
                            </template>
                          </v-list-item>
                        
                          <!-- 3 nivel -->
                          <div v-for="itemModuloHijo in moduloHijo.modulo_hijo" 
                            :key="itemModuloHijo.id_modulo"
                          >
                            <v-list-item class="ml-8">
                              <template v-slot:default="{ active }">
                                <v-list-item-content>
                                  <v-list-item-title>{{ itemModuloHijo.modulo }}</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-checkbox 
                                    :input-value="active"
                                    v-model="permisos"
                                    :value="itemModuloHijo.id_modulo"
                                  >        
                                  </v-checkbox>
                                </v-list-item-action>
                              </template>
                            </v-list-item>
                          </div>
                        </div>
                      </div>
                    </v-list-item-group>
                  </v-list>

                  <v-btn
                    large
                    block
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="actualizar"
                  >
                    Actualizar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Alert from '@/components/admin/Alert.vue'

export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,

    form:         true,
    loadingForm:  false,

    permisos:     []
  }),

  mounted() {
    this.$store.dispatch("crearModulos")
    this.$store.dispatch("gestionarPerfiles")

    this.$store.dispatch("editarPermiso", this.$route.params.id_perfil)
    .then((response) => {
      response.forEach(permiso => {
        this.permisos.push(permiso.modulo_id)
      })
    })
    .catch(() => {});
  },
  
  components: {
    "app-alert": Alert
  },

  computed: {
    modulos() {
      return this.$store.getters.obtenerModulos;
    },

    perfil() {
      return this.$store.getters.obtenerPerfil(this.$route.params.id_perfil);
    }
  },

  methods: {
    actualizar() {
      this.loadingForm = true
      const permisosPerfil = {
        perfil:   this.$route.params.id_perfil,
        permisos: this.permisos
      }

      this.$store.dispatch("actualizarPermiso", permisosPerfil)
      .then((response) => {
        if (response.status == "success") {
          this.loadingForm  = false;
          this.alert        = true;
          this.titleAlert   = "Actualizados";
          this.messageAlert =  response.message;
          this.titleCard    = "success--text";
          this.buttonCard   = "success";

          setTimeout(() => {
            this.$router.push({
              name: "perfil-gestionar" 
            })
          }, 1000)
        } 
        else {
          this.loadingForm = false;
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = response.message.permisos;
          this.titleCard = "error--text";
          this.buttonCard = "error";
        }

        setTimeout(() => {
          this.alert = false;
        }, 2000);
      })
      .catch(() => {});
    },

    regresar() {
      this.$router.push({
        name: "perfil-gestionar" 
      })
    }
  }
}
</script>
  