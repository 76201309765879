<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>

    <v-row justify="center">
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Reportes
            </p>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                cols="4"
                offset="4"
              >
                <v-select
                  outlined
                  label="Competencia"
                  :items="competencias"
                  item-value="id"
                  item-text="competencia"
                  v-model="competencia"
                  :rules="competenciaReglas"
                >
                </v-select>

                <v-checkbox
                  v-model="senasoft"
                  label="Participantes"
                  value="participante"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="senasoft"
                  label="Lideres"
                  value="lider"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="senasoft"
                  label="Categorias"
                  value="categoria"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="senasoft"
                  label="Cupos"
                  value="cupo"
                >
                </v-checkbox>

                <v-btn
                  large
                  block
                  exact
                  elevation="2"
                  color="primary"
                  class="mt-2 mb-6"
                  @click="reporteSenasoft"
                >
                  Descargar
                </v-btn>
              </v-col>
            </v-row>

            <!--
            <v-row>
              <v-col 
                cols="5"
                offset="1">
                <p class="text-h6 font-weight-bold primary--text">
                  Categorias
                </p>

                <v-checkbox
                  v-model="categoria"
                  label="Modalidad"
                  value="modalidad"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="categoria"
                  label="Número de Participantes"
                  value="numero_participantes"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="categoria"
                  label="Número de Grupos"
                  value="cantidad_grupos"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="categoria"
                  label="Competencia"
                  value="competencia"
                >
                </v-checkbox>

                <v-btn
                  large
                  block
                  exact
                  elevation="2"
                  color="primary"
                  class="mt-2 mb-6"
                  @click="reporteCategorias"
                >
                  Descargar
                </v-btn>
              </v-col>

              <v-col cols="5">
                <p class="text-h6 font-weight-bold primary--text">
                  Cupos
                </p>

                <v-checkbox
                  v-model="cupo"
                  label="Competencia"
                  value="competencia"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="cupo"
                  label="Categoria"
                  value="categoria"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="cupo"
                  label="Regional"
                  value="regional"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="cupo"
                  label="Centro de Formación"
                  value="centro_formacion"
                >
                </v-checkbox>

                <v-btn
                  large
                  block
                  exact
                  elevation="2"
                  color="primary"
                  class="mt-2 mb-6"
                  @click="reporteCupos"
                >
                  Descargar
                </v-btn>
              </v-col>

              <v-col 
                cols="5"
                offset="1"
              >
                <p class="text-h6 font-weight-bold primary--text">
                  Participantes
                </p>

                <v-checkbox
                  v-model="participante"
                  label="Regional"
                  value="regional"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="participante"
                  label="Centro de Formación"
                  value="centro_formacion"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="participante"
                  label="Programa de Formación"
                  value="programa_formacion"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="participante"
                  label="Número de Ficha"
                  value="numero_ficha"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="participante"
                  label="Competencia"
                  value="competencia"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="participante"
                  label="Categoria"
                  value="categoria"
                >
                </v-checkbox>

                <v-btn
                  large
                  block
                  exact
                  elevation="2"
                  color="primary"
                  class="mt-2 mb-6"
                  @click="reporteParticipantes"
                >
                  Descargar
                </v-btn>
              </v-col>

              <v-col cols="5">
                <p class="text-h6 font-weight-bold primary--text">
                  Lideres
                </p>

                <v-checkbox
                  v-model="lider"
                  label="Regional"
                  value="regional"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="lider"
                  label="Centro de Formación"
                  value="centro_formacion"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="lider"
                  label="Tipo de Lider"
                  value="tipo_lider"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="lider"
                  label="Competencia"
                  value="competencia"
                >
                </v-checkbox>

                <v-checkbox
                  v-model="lider"
                  label="Categoria"
                  value="categoria"
                >
                </v-checkbox>

                <v-btn
                  large
                  block
                  exact
                  elevation="2"
                  color="primary"
                  class="mt-5"
                  @click="reporteLideres"
                >
                  Descargar
                </v-btn>
              </v-col> 
            </v-row>
            -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
  
</template>

<script>
import Alert from '../../../components/admin/Alert.vue'

export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,

    competencia: null,
    competenciaReglas: [
      v => !!v || "Seleccione una competencia"
    ],

    senasoft: [],
    
    categoria: [],
    cupo: [],
    participante: [],
    lider: [],
  }),

  components: {
    "app-alert": Alert
  },

  mounted() {
    this.$store.dispatch("gestionarCompetencias")
  },

  computed: {
    competencias() {
      const competencias = []
      this.$store.getters.obtenerCompetencias.forEach(competencia => {
        competencias.push({
          id:           competencia.id,
          competencia:  competencia.competencia
        })
      })
      return competencias
    }
  },

  methods: {
    reporteSenasoft() {
      if (this.senasoft.length > 0) {
        const reporte = {
          id_competencia: this.competencia,
          reporte:        this.senasoft
        }

        this.$store.dispatch("reporteSenasoft", reporte)
        .then((response) => {
          const urlReporte = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href= urlReporte
          link.setAttribute('download', 'Reporte Senasoft.xlsx')
          document.body.appendChild(link)
          link.click();
          this.senasoft = []
        })
        .catch(() => {})
      }
      else {
        this.alert        = true;
        this.titleAlert   = "Error";
        this.messageAlert = "Seleccione items para descargar";
        this.titleCard    = "error--text";
        this.buttonCard   = "error";
      }

      setTimeout(() => {
        this.alert = false;
      }, 1000);
    },

    // reporteCategorias() {
    //   if (this.categoria.length > 0) {
    //     const categoria = {
    //       categoria: this.categoria
    //     }
    //     this.$store.dispatch("descargarReporteCategorias", categoria)
    //     .then((response) => {
    //       const urlReporte = window.URL.createObjectURL(new Blob([response]))
    //       const link = document.createElement('a')
    //       link.href= urlReporte
    //       link.setAttribute('download', 'Categorias.xlsx')
    //       document.body.appendChild(link)
    //       link.click();
    //       this.categoria = []
    //     })
    //     .catch(() => {})
    //   }
    //   else {
    //     this.alert        = true;
    //     this.titleAlert   = "Error";
    //     this.messageAlert = "Seleccione items para descargar";
    //     this.titleCard    = "error--text";
    //     this.buttonCard   = "error";
    //   }

    //   setTimeout(() => {
    //     this.alert = false;
    //   }, 1000);
    // },

    // reporteCupos () {
    //   if (this.cupo.length > 0) {
    //     const cupo = {
    //       cupo: this.cupo
    //     }
    //     this.$store.dispatch("descargarReporteCupos", cupo)
    //     .then((response) => {
    //       const urlReporte = window.URL.createObjectURL(new Blob([response]))
    //       const link = document.createElement('a')
    //       link.href= urlReporte
    //       link.setAttribute('download', 'Cupos.xlsx')
    //       document.body.appendChild(link)
    //       link.click();
    //       this.cupo = []
    //     })
    //     .catch(() => {})
    //   }
    //   else {
    //     this.alert        = true;
    //     this.titleAlert   = "Error";
    //     this.messageAlert = "Seleccione items para descargar";
    //     this.titleCard    = "error--text";
    //     this.buttonCard   = "error";
    //   }

    //   setTimeout(() => {
    //     this.alert = false;
    //   }, 1000);
    // },

    // reporteParticipantes () {
    //   if (this.participante.length > 0) {
    //     const participante = {
    //       participante: this.participante
    //     }
    //     this.$store.dispatch("descargarReporteParticipantes", participante)
    //     .then((response) => {
    //       const urlReporte = window.URL.createObjectURL(new Blob([response]))
    //       const link = document.createElement('a')
    //       link.href= urlReporte
    //       link.setAttribute('download', 'Participantes.xlsx')
    //       document.body.appendChild(link)
    //       link.click();
    //       this.participante = []
    //     })
    //     .catch(() => {})
    //   }
    //   else {
    //     this.alert        = true;
    //     this.titleAlert   = "Error";
    //     this.messageAlert = "Seleccione items para descargar";
    //     this.titleCard    = "error--text";
    //     this.buttonCard   = "error";
    //   }

    //   setTimeout(() => {
    //     this.alert = false;
    //   }, 1000);
    // },

    // reporteLideres () {
    //   if (this.lider.length > 0) {
    //     const lider = {
    //       lider: this.lider
    //     }
    //     this.$store.dispatch("descargarReporteLideres", lider)
    //     .then((response) => {
    //       const urlReporte = window.URL.createObjectURL(new Blob([response]))
    //       const link = document.createElement('a')
    //       link.href= urlReporte
    //       link.setAttribute('download', 'Lideres.xlsx')
    //       document.body.appendChild(link)
    //       link.click();
    //       this.lider = []
    //     })
    //     .catch(() => {})
    //   }
    //   else {
    //     this.alert        = true;
    //     this.titleAlert   = "Error";
    //     this.messageAlert = "Seleccione items para descargar";
    //     this.titleCard    = "error--text";
    //     this.buttonCard   = "error";
    //   }

    //   setTimeout(() => {
    //     this.alert = false;
    //   }, 1000);
    // }
  }
}
</script>

<style scoped>
</style>