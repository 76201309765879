<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>
    
    <!-- formulario -->
    <v-row>
      <v-col
        sm="1"
        lg="2"
        class="hidden-xs-only"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              fixed
              exact
              color="primary"
              v-bind="attrs" 
              v-on="on" 
              @click="regresar"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
      </v-col>
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Actualizar Usuario
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-select
                    outlined
                    dense
                    label="Perfil*"
                    :items="perfiles"
                    item-value="id"
                    item-text="perfil"
                    v-model="perfil"
                    :rules="perfilReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    label="Regional*"
                    :items="regionales"
                    item-value="id"
                    item-text="regional"
                    v-model="regional"
                    :rules="regionalReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    label="Centro de Formación*"
                    :items="centrosFormacion"
                    item-value="id"
                    item-text="centro_formacion"
                    v-model="centroFormacion"
                    :rules="centroFormacionReglas"
                  >
                  </v-select>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Número de Identificación*"
                    min="6"
                    max="10"
                    maxlength="10"
                    counter
                    v-model="identificacion"
                    :rules="identificacionReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Nombres*"
                    maxlength="50"
                    counter
                    v-model="nombres"
                    :rules="nombresReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Apellidos*"
                    maxlength="50"
                    counter
                    v-model="apellidos"
                    :rules="apellidosReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Teléfono*"
                    min="0"
                    max="10"
                    maxlength="10"
                    counter
                    v-model="telefono"
                    :rules="telefonoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Correo Electrónico*"
                    maxlength="50"
                    counter
                    v-model="correo"
                    :rules="correoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    disabled
                    type="text"
                    label="Contraseña"
                    v-model="identificacion"
                  >
                  </v-text-field>

                  <v-btn
                    block
                    large
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="actualizar"
                  >
                    Actualizar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Alert from '../../../components/admin/Alert.vue'

export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    loadingForm:  false,
    form:         true,

    perfil: null,
    perfilReglas: [
      v => !!v || "Seleccione un perfil"
    ],

    regional: null,
    regionalReglas: [
      v => !!v || "Seleccione una regional"
    ],

    centroFormacion: null,
    centroFormacionReglas: [
      v => !!v || "Seleccione un centro de formación"
    ],

    identificacion: null,
    identificacionReglas: [
      v => !!v || "Escriba un número de identificación"
    ],

    nombres: null,
    nombresReglas: [
      v => !!v || "Escriba nombres"
    ],

    apellidos: null,
    apellidosReglas: [
      v => !!v || "Escriba apellidos"
    ],

    telefono: null,
    telefonoReglas: [
      v => !!v || "Escriba un número de telefono"
    ],

    correo: null,
    correoReglas: [
      v => !!v || "Escriba un correo electrónico",
      v => /.+@.+\..+/.test(v) || "Correo electrónico no válido",
    ]
  }),

  created() {
    this.$store.dispatch("gestionarPerfiles")
    this.$store.dispatch("crearRegionales")
    this.$store.dispatch("crearCentrosFormacion")
    this.$store.dispatch("editarUsuario", this.$route.params.id_usuario)
    .then((response) => {
      this.perfil           = response.perfil_id,
      this.regional         = response.centro_formacion.regional_id
      this.centroFormacion  = response.centro_formacion_id
      this.identificacion   = response.identificacion;
      this.nombres          = response.nombres;
      this.apellidos        = response.apellidos;
      this.telefono         = response.telefono;
      this.correo           = response.correo;
    })
    .catch(() => {});
  },

  computed: {
    perfiles() {
      return this.$store.getters.obtenerPerfiles
    },

    regionales() {
      return this.$store.getters.obtenerRegionales;
    },

    centrosFormacion() {
      return this.$store.getters.obtenerCentrosFormacion(this.regional);
    }
  },

  components: {
    "app-alert": Alert
  },

  methods: {
    actualizar() {
      if (this.$refs.form.validate()) {
        const usuario = {
          id:                   this.$route.params.id_usuario,
          perfil_id:            this.perfil,
          centro_formacion_id:  this.centroFormacion,
          identificacion:       this.identificacion,
          nombres:              this.nombres,
          apellidos:            this.apellidos,
          telefono:             this.telefono,
          correo:               this.correo,
          password:             this.identificacion
        }

        this.loadingForm = true;

        this.$store.dispatch("actualizarUsuario", usuario)
        .then((response) => {
          if (response.status == "success") {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Actualizado";
            this.messageAlert = response.message;
            this.titleCard    = "success--text";
            this.buttonCard   = "success";
          } 
          else {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message.identificacion[0];
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }

          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {});
      }
    },

    regresar() {
      this.$router.push({
        name: "usuario-gestionar"
      });
    }
  }
};
</script>