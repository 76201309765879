import axios from 'axios'

const competencias = {
    state: () => ({
        competencias: [],
        competencia: {},

        competenciasWorldSkills: [],
        competenciaWorldSkills: {},

        documentos: [],
        documento: {}
    }),
    
    getters: {
        obtenerCompetencias(state) {
            return state.competencias
        },

        obtenerCompetencia(state) {
            return state.competencia
        },


        obtenerCompetenciasWorldSkills(state) {
            return state.competenciasWorldSkills
        },

        obtenerCompetenciaWorldSkills(state) {
            return state.competenciaWorldSkills
        },

        obtenerDocumentosCompetencias(state) {
            return state.documentos
        },

        obtenerDocumentoCompetencia(state) {
            return state.documento
        },

        obtenerDocumentosGenerales(state) {
            return state.documentos
        }
    },

    mutations: {
        enviarCompetencias(state, competencias) {
            state.competencias = competencias
        },

        enviarCompetencia(state, competencia) {
            state.competencia = competencia
        },


        enviarCompetenciasWorldSkills(state, competencia){
            state.competenciasWorldSkills = competencia
        },

        enviarCompetenciaWorldSkills(state, competencia) {
            state.competenciaWorldSkills = competencia
        },
        

        enviarDocumentosCompetencias(state, documentos){
            state.documentos = documentos
        },

        enviarDocumentoCompetencia(state, documento){
            state.documento = documento
        },

        enviarDocumentosGenerales(state, documentos){
            state.documentos = documentos
        }
    },

    actions: {
        registrarCompetencia(context, competencia) {
            return new Promise((resolve, reject) => {
                axios.post("api/competencias", competencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarCompetencias(context) {
            axios.get("api/competencias")
            .then((response) => {
                const competencias = []
                let num = 1
                for (let i = response.data.results.length -1; i >=  0; i--) {
                    competencias.push({
                        indice: (num),
                        id:                     response.data.results[i].id_competencia,
                        centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion, 
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion, 
                        fecha_inicio:           response.data.results[i].fecha_inicio,
                        fecha_fin:              response.data.results[i].fecha_fin,
                        competencia:            response.data.results[i].competencia,
                        descripcion:            response.data.results[i].descripcion,
                        lugar:                  response.data.results[i].lugar,
                        direccion:              response.data.results[i].direccion
                    })
                    num++
                }
                context.commit("enviarCompetencias", competencias)
            })
            .catch(() => {})
        },

        detallesCompetencia(context, competencia) {
            axios.get("api/competencias/"+competencia)
            .then((response) => {
                const competencia =  {
                    id:                     response.data.results.id_competencia,
                    regional_id:            response.data.results.centro_formacion.regional.id_regional, 
                    regional:               response.data.results.centro_formacion.regional.regional, 
                    centro_formacion_id:    response.data.results.centro_formacion.id_centro_formacion, 
                    centro_formacion:       response.data.results.centro_formacion.centro_formacion, 
                    fecha_inicio:           response.data.results.fecha_inicio,
                    fecha_fin:              response.data.results.fecha_fin,
                    competencia:            response.data.results.competencia,
                    descripcion:            response.data.results.descripcion,
                    lugar:                  response.data.results.lugar,
                    direccion:              response.data.results.direccion
                }
                context.commit("enviarCompetencia", competencia)
            })
            .catch(() => {})
        },

        editarCompetencia(context, competencia) {
            return new Promise((resolve, reject) => {
                axios.get("api/competencias/"+competencia+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarCompetencia(context, competencia) {
            return new Promise((resolve, reject) => {
                axios.put("api/competencias/"+competencia.id, competencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        registrarCompetenciaWorldSkills(context, competencia) {
            return new Promise((resolve, reject) => {
                axios.post("api/competenciasWorldSkills", competencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },
        
        gestionarCompetenciaWorldSkills(context) {
            axios.get("api/competenciasWorldSkills")
            .then((response) => {
                const competenciasWorldSkills = []
                response.data.results.forEach((response) => {
                    competenciasWorldSkills.push({
                        id:                     response.id_competencia,
                        centro_formacion_id:    response.centro_formacion.id_centro_formacion, 
                        centro_formacion:       response.centro_formacion.centro_formacion, 
                        regional_id:            response.centro_formacion.regional.id_regional, 
                        regional:               response.centro_formacion.regional.regional,
                        tipo_competencia_id:    response.tipo_competencia.id_tipo_competencia,
                        tipo_competencia:       response.tipo_competencia.tipo_competencia,
                        competencia:            response.competencia,
                        fecha_inicio:           response.fecha_inicio,
                        fecha_fin:              response.fecha_fin,
                        descripcion:            response.descripcion,
                        lugar:                  response.lugar,
                        direccion:              response.direccion
                    })
                });
                context.commit("enviarCompetenciasWorldSkills", competenciasWorldSkills)
            })
            .catch(() => {})
        },

        detalleCompetenciaWorldSkills(context, competencia) {
            axios.get("api/competenciasWorldSkills/"+competencia)
            .then((response) => {
                let respuesta = response.data.results

                const sedes = []
                respuesta.competencia_sede.forEach((res) => { 
                    sedes.push({
                        sede: res.sede.sede
                    })
                })
                
                const competenciaWorldSkills = {
                    id:                     respuesta.id_competencia,
                    centro_formacion_id:    respuesta.centro_formacion.id_centro_formacion, 
                    centro_formacion:       respuesta.centro_formacion.centro_formacion, 
                    regional_id:            respuesta.centro_formacion.regional.id_regional, 
                    regional:               respuesta.centro_formacion.regional.regional,
                    tipo_competencia_id:    respuesta.tipo_competencia.id_tipo_competencia,
                    tipo_competencia:       respuesta.tipo_competencia.tipo_competencia,
                    competencia:            respuesta.competencia,
                    fecha_inicio:           respuesta.fecha_inicio,
                    fecha_fin:              respuesta.fecha_fin,
                    descripcion:            respuesta.descripcion,
                    lugar:                  respuesta.lugar,
                    direccion:              respuesta.direccion,
                    sedes:                  sedes
                }
                
                context.commit("enviarCompetenciaWorldSkills", competenciaWorldSkills)
            })
            .catch(() => {})
        },

        editarCompetenciaWorldSkills(context, competencia) {
            return new Promise((resolve, reject) => {
                axios.get("api/competenciasWorldSkills/"+competencia)
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarCompetenciaWorldSkills(context, competencia) {
            return new Promise((resolve, reject) => {
                axios.put("api/competenciasWorldSkills/"+competencia.id_competencia, competencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        registrarDocumentoCompetencia(context, documento) {
            return new Promise((resolve, reject) => {
                axios.post("api/registrodocumentocompetencia/"+documento.get("competencia_id"), documento, { headers: {'Content-Type': 'multipart/form-data'}})
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarDocumentosCompetencia(context, competencia){
            axios.get("api/documentocompetencia/"+competencia)
            .then((response) => {
                const documentos = []
                for(let i = 0; i< response.data.results.length; i++){
                    documentos.push({
                        indice: (i+1),
                        id:                 response.data.results[i].id_documento_competencia,
                        nombre_documento:   response.data.results[i].nombre_documento,
                        competencia_id:     response.data.results[i].competencia_id
                    });
                }
                context.commit("enviarDocumentosCompetencias", documentos)
            })
        },

        gestionarDocumentosGenerales(context){
            axios.get("api/documentosgenerales")
            .then((response) => {
                const documentos = []
                for(let i = 0; i< response.data.results.length; i++){
                    documentos.push({
                        indice: (i+1),
                        id:                                 response.data.results[i].id_documento_competencia,
                        nombre_documento:                   response.data.results[i].nombre_documento,
                        competencia_id:                     response.data.results[i].competencia_id
                    });
                }
                context.commit("enviarDocumentosGenerales", documentos)
            })
        },

        eliminarDocumentoCompetencia(context, documento) {
            return new Promise((resolve, reject) => {
                axios.delete("api/eliminardocumentocompetencia/"+documento.competencia_id+"/documento/"+documento.id_documento_competencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        async downloadeCompetencia(context, competencia){
            return new Promise((resolve, reject) => {
               axios.get("api/competenciasDoc/"+competencia.competencia_id+"/"+competencia.nombre_documento, {responseType: 'blob'})
                .then((response) => {
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
            })        
        }
    }
}

export default competencias