<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- cargador tabla -->
        <template v-if="loaderTable">
          <v-skeleton-loader 
          class="mx-auto" 
          type="table"
        > 
        </v-skeleton-loader>
        </template>
        <template v-else>
          <!-- tabla -->
          <v-card>
            <v-card-title class="text-h5 font-weight-bold primary--text">
              <v-row>
                <v-col
                  cols="12"
                  sm="9"
                >
                  Gestionar Perfiles
                </v-col>

                <template v-if="perfiles.length > 0">
                  <v-col 
                    cols="12" 
                    sm="3"
                  >
                    <v-text-field
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      label="Buscar Perfil"
                      hide-details
                      v-model="searchTable"
                    >
                    </v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>

            <v-data-table
              fixed-header
              locale="es"
              height="480px"
              class="elevation-2"
              :headers="headersTable"
              :items="perfiles"
              :search="searchTable"
              no-data-text="No hay perfiles registrados"
              :footer-props="{ 'items-per-page-options': [20, 50 -1] }"
            >
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="actualizar(item.id)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Actualizar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="asociarPermisos(item.id)"
                    >
                      mdi-playlist-plus
                    </v-icon>
                  </template>
                  <span>Asociar Permisos</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    loaderTable: true,
    loadingTable: false,
    searchTable:  "",

    headersTable: [
      {
        text: "Perfil",
        value: "perfil",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      }
    ]
  }),

  mounted() {
    this.$store.dispatch("gestionarPerfiles");
    this.$store.dispatch("modulosPermisos");

    setTimeout(() => {
      this.loaderTable = false;
    }, 1000);
  },

  computed: {
    perfiles() {
      return this.$store.getters.obtenerPerfiles
    }
  },
  
  methods: {
    actualizar(id) {
      this.$router.push({
        name: "perfil-actualizar",
        params: { id_perfil: id }
      })
    },
    
    asociarPermisos(id) {
      this.$router.push({
        name: "perfil-permiso",
        params: { id_perfil: id }
      });
    }
  }
};
</script>