const search = {
    state:()=>({
        searchTable : "",
    }),

    getters:{
        obtenerSearch(state){
            return state.searchTable
        }
    },
    mutations:{
        enviarSearch(state, search){
            state.searchTable = search
        },
        clearSearch(state) {
            state.searchTable = '';
          },
    },
    actions:{
        guardarSearch(context, search){
            context.commit('enviarSearch', search)
        },
        clearSearchB(context){
            context.commit('clearSearch')
        }
    }
}

export default search