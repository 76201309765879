<template>
  <div>
    <!-- cargador tablero -->
    <template v-if="loaderCard">
      <v-skeleton-loader 
        class="mx-auto" 
        type="image"
      > 
      </v-skeleton-loader>
    </template>

    <!-- tablero -->
    <template v-else>
      <v-row justify="center">
        <v-col
          cols="6"
          offset="3"
          align="center"
        >
        <span class="text-h6 text-md-h5 font-weight-bold primary--text">
          {{ competenciaSeleccionada }}
        </span>
        </v-col>

        <v-col
          cols="3"
        >
          <v-select
            outlined
            dense
            label="Competencia*"
            :items="competenciasWorldSkills"
            item-value="id"
            item-text="competencia"
            v-model="competencia"
            @change="cambiarCompetencia"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col 
          cols="8" 
          sm="3"
        >
          <v-card 
            outlined 
            elevation="2" 
            color="indigo"
          >
            <v-card-title>
              <v-row class="d-flex justify-space-between my-1 mx-1">
                <p class="white--text text-h3">{{ participantesWorldSkillsContador }}</p>
                <v-icon 
                  x-large 
                  color="white" 
                  rigth
                >
                  mdi-account-multiple
                </v-icon>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row class="white--text text-h6 ml-1 mb-1">
                Participantes Registrados
              </v-row>
            </v-card-text>

            <!-- <v-card-actions>
              <v-btn 
                block c
                olor="white" 
                @click="cambiarInformacion(1)"
              >
                <span class="green--text">Mas información</span>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>

        <v-col 
          cols="8" 
          sm="3"
        >
          <v-card 
            outlined 
            elevation="2" 
            color="green"
          >
            <v-card-title>
              <v-row class="d-flex justify-space-between my-1 mx-1">
                <p class="white--text text-h3">{{ ganadoresWorldSkillsContador }}</p>
                <v-icon 
                  x-large 
                  color="white" 
                  rigth
                >
                  mdi-account-multiple
                </v-icon>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row class="white--text text-h6 ml-1 mb-1">
                Ganadores Registrados
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col 
          cols="8" 
          sm="3"
        >
          <v-card 
            outlined 
            elevation="2" 
            color="#FFA726"
          >
            <v-card-title>
              <v-row class="d-flex justify-space-between my-1 mx-1">
                <p class="white--text text-h3">{{ expertosWorldSkillsContador }}</p>
                <v-icon 
                  x-large 
                  color="white" 
                  rigth
                >
                  mdi mdi-text-box-plus
                </v-icon>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row class="white--text text-h6 ml-1 mb-1">
                Expertos Registrados
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col 
          cols="8" 
          sm="3"
        >
          <v-card 
            outlined 
            elevation="2" 
            color="purple"
          >
            <v-card-title>
              <v-row class="d-flex justify-space-between my-1 mx-1">
                <p class="white--text text-h3">{{ acompanantesWorldSkillsContador }}</p>
                <v-icon 
                  x-large 
                  color="white" 
                  rigth
                >
                  mdi-account-multiple
                </v-icon>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row class="white--text text-h6 ml-1 mb-1">
                Acompañantes Registrados
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- grafico participantes x habilidad -->
      <v-row>
        <v-col cols="12">
          <v-card 
            outlined 
            elevation="2" 
          >
            <v-card-title>
              <p class="text-h6 font-weight-bold indigo--text mt-2">
                Participantes Registrados
              </p>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col 
                  cols="12" 
                >
                <BarChart
                  :data="participantesHabilidadWorldSkills"
                  :options="chartOptions"
                >
                </BarChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- grafico ganadores x habilidad -->
      <v-row>
        <v-col cols="12">
          <v-card 
            outlined 
            elevation="2" 
          >
            <v-card-title>
              <p class="text-h6 font-weight-bold green--text mt-2">
                Ganadores Registrados
              </p>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col 
                  cols="12" 
                >
                <BarChart
                  :data="ganadoresHabilidadWorldSkills"
                  :options="chartOptions"
                >
                </BarChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- grafico expertos x tipo -->
      <v-row>
        <v-col cols="12">
          <v-card 
            outlined 
            elevation="2" 
          >
            <v-card-title>
              <p class="text-h6 font-weight-bold orange--text mt-2">
                Expertos Registrados
              </p>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col 
                  cols="12" 
                >
                <BarChart
                  :data="expertosTipoWorldSkills"
                  :options="chartOptionsX"
                >
                </BarChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- grafico acompanantes x tipo -->
      <v-row>
        <v-col cols="12">
          <v-card 
            outlined 
            elevation="2" 
          >
            <v-card-title>
              <p class="text-h6 font-weight-bold purple--text mt-2">
                Acompañantes Registrados
              </p>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col 
                  cols="12" 
                >
                <BarChart
                  :data="acompanantesTipoWorldSkills"
                  :options="chartOptionsX"
                >
                </BarChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import BarChart from '@/components/admin/BarChart.vue';

export default {
  data: () => ({
    loaderCard: true,

    competencia: null,
    competenciaSeleccionada: null,

    chartOptions: {
      indexAxis: 'y',
      responsive: true
    },

    chartOptionsX: {
      responsive: true
    }
  }),

  components: {
    BarChart
  },

  created() {
    this.$store.dispatch("gestionarCompetenciaWorldSkills")
  },

  mounted() {
    setTimeout(() => {
      this.$store.dispatch("participantesHabilidadWorldSkills", this.competenciasWorldSkills[0].id)
      this.$store.dispatch("ganadoresHabilidadWorldSkills", this.competenciasWorldSkills[0].id)
      this.$store.dispatch("expertosTipoWorldSkills", this.competenciasWorldSkills[0].id)
      this.$store.dispatch("acompanantesTipoWorldSkills", this.competenciasWorldSkills[0].id)

      this.competenciaSeleccionada = this.competenciasWorldSkills[0].competencia
      this.competencia = this.competenciasWorldSkills[0].id
      this.loaderCard = false
    }, 1000);
  },

  computed: {
    competenciasWorldSkills() {
      return this.$store.getters.obtenerCompetenciasWorldSkills
    },

    participantesWorldSkillsContador() {
      const participantes = this.$store.getters.obtenerParticipantesHabilidadWorldSkills.map(result => result.participantes)
      const totalParticipantes = participantes.reduce((acumulador, valorActual) => acumulador + valorActual, 0)
      return totalParticipantes
    },

    participantesHabilidadWorldSkills() {
      const habilidades = this.$store.getters.obtenerParticipantesHabilidadWorldSkills.map(result => result.habilidades)
      const participantes = this.$store.getters.obtenerParticipantesHabilidadWorldSkills.map(result => result.participantes)

      const chartData = {
        labels: habilidades,
        datasets: [
          {
            label: 'Participantes',
            data: participantes,
            backgroundColor: '#C5CAE9',
            borderColor: '#5C6BC0',
            borderWidth: 2
          }
        ]
      }
      return chartData
    },

    ganadoresWorldSkillsContador() {
      const ganadores = this.$store.getters.obtenerGanadoresHabilidadWorldSkills.map(result => result.ganadores)
      const totalGanadores = ganadores.reduce((acumulador, valorActual) => acumulador + valorActual, 0)
      return totalGanadores
    },

    ganadoresHabilidadWorldSkills() {
      const habilidades = this.$store.getters.obtenerGanadoresHabilidadWorldSkills.map(result => result.habilidades)
      const ganadores = this.$store.getters.obtenerGanadoresHabilidadWorldSkills.map(result => result.ganadores)

      const chartData = {
        labels: habilidades,
        datasets: [
          {
            label: 'Ganadores',
            data: ganadores,
            backgroundColor: '#C8E6C9',
            borderColor: '#66BB6A',
            borderWidth: 2
          }
        ]
      }
      return chartData
    },

    expertosWorldSkillsContador() {
      const expertos = this.$store.getters.obtenerExpertosTipoWorldSkills.map(result => result.expertos)
      const totalExpertos = expertos.reduce((acumulador, valorActual) => acumulador + valorActual, 0)
      return totalExpertos
    },

    expertosTipoWorldSkills() {
      const tiposExperto = this.$store.getters.obtenerExpertosTipoWorldSkills.map(result => result.tiposExperto)
      const expertos = this.$store.getters.obtenerExpertosTipoWorldSkills.map(result => result.expertos)

      const chartData = {
        labels: tiposExperto,
        datasets: [
          {
            label: 'Expertos',
            data: expertos,
            backgroundColor: '#FFE0B2',
            borderColor: '#FFA726',
            borderWidth: 2
          }
        ]
      }
      return chartData
    },

    acompanantesWorldSkillsContador() {
      const acompanantes = this.$store.getters.obtenerAcompanantesTipoWorldSkills.map(result => result.acompanantes)
      const totalAcompanantes = acompanantes.reduce((acumulador, valorActual) => acumulador + valorActual, 0)
      return totalAcompanantes
    },

    acompanantesTipoWorldSkills() {
      const tiposAcompanante = this.$store.getters.obtenerAcompanantesTipoWorldSkills.map(result => result.tiposAcompanante)
      const acompanantes = this.$store.getters.obtenerAcompanantesTipoWorldSkills.map(result => result.acompanantes)

      const chartData = {
        labels: tiposAcompanante,
        datasets: [
          {
            label: 'Acompañantes',
            data: acompanantes,
            backgroundColor: '#E1BEE7',
            borderColor: '#AB47BC',
            borderWidth: 2
          }
        ]
      }
      return chartData
    }
  },

  methods: {
    cambiarCompetencia() {
      this.loaderCard = true
      this.$store.dispatch("participantesHabilidadWorldSkills", this.competencia)
      this.$store.dispatch("ganadoresHabilidadWorldSkills", this.competencia)
      this.$store.dispatch("expertosTipoWorldSkills", this.competencia)
      this.$store.dispatch("acompanantesTipoWorldSkills", this.competencia)

      const competenciaWorldSkills = this.competenciasWorldSkills.find(competencia => competencia.id == this.competencia)
      this.competenciaSeleccionada = competenciaWorldSkills.competencia
      
      setTimeout(() => {
        this.loaderCard = false
      }, 1000);
    }
  }
}
</script>

<style>
</style>