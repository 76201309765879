<template>
    <div>
      <v-row>
        <v-col
          sm="1"
          lg="2"
          class="hidden-xs-only"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                fixed
                exact
                color="primary"
                v-bind="attrs" 
                v-on="on" 
                @click="regresar"
              >
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </template>
            <span>Regresar</span>
          </v-tooltip>
        </v-col>
        <v-col 
          cols="12" 
          sm="10" 
          lg="8"
        >
          <v-card 
            outlined 
            elevation="2"
          >
            <v-card-title class="justify-center">
              <p class="text-h4 font-weight-bold primary--text mt-2">
                Detalles Acompañante
              </p>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
  
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Identificación
                  </p>
                  <p class="text-subtitle-1">
                    {{ identificacion }}
                  </p>
                </v-col>
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Nombres
                  </p>
                  <p class="text-subtitle-1">
                    {{ nombres }}
                  </p>
                </v-col>
  
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Apellidos
                  </p>
                  <p class="text-subtitle-1">
                    {{ apellidos }}
                  </p>     
                </v-col>
  
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Telefono
                  </p>
                  <p>
                    {{telefono_movil}}
                  </p>
                  </v-col>
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Correo Electrónico
                  </p>
                  <p class="text-subtitle-1">
                    {{ correo }}
                  </p>
                </v-col>
  
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Fecha de Nacimiento
                  </p>
                  <p class="text-subtitle-1">
                    {{ fecha_nacimiento }}
                  </p>
                </v-col>
  
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Genero
                  </p>
                  <p class="text-subtitle-1">
                    {{ genero }}
                  </p>
                </v-col>
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Regional
                  </p>
                  <p class="text-subtitle-1">
                    {{ regional }}
                  </p>
                </v-col>
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Centro de Formación
                  </p>
                  <p class="text-subtitle-1">
                    {{ centro_formacion }}
                  </p>
                </v-col>
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Cargo
                  </p>
                  <p class="text-subtitle-1">
                    {{ cargo }}
                  </p>
                </v-col>
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Talla de Camiseta
                  </p>
                  <p class="text-subtitle-1">
                    {{ talla_camiseta }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </template>
  <script>
  export default {
    data: () => ({
        alert:        false,
        titleAlert:   null,
        messageAlert: null,
        titleCard:    null,
        buttonCard:   null,
        identificacion: "",
        nombres: "",
        apellidos: "",
        telefono_movil:"",
        correo: "",
        fecha_nacimiento: "",
        genero:"",
        talla_camiseta: "",
        regional: "",
        centro_formacion: "",
        cargo: ""
    }),
  
    mounted() {
      this.$store.dispatch("editarAcompanante", this.$route.params.id_acompanante)
        .then((response) => {
            this.identificacion         = response.persona.identificacion,
            this.nombres                = response.persona.primer_nombre+" "+response.persona.segundo_nombre,
            this.apellidos              = response.persona.primer_apellido+" "+response.persona.segundo_apellido,
            this.telefono_movil          = response.persona.telefono_movil,
            this.correo                 = response.persona.correo,
            this.fecha_nacimiento       = response.persona.fecha_nacimiento,
            this.genero                 = response.persona.genero.genero,
            this.talla_camiseta         = response.talla_camiseta,
            this.regional               = response.centro_formacion.regional.regional,
            this.centro_formacion       = response.centro_formacion.centro_formacion,
            this.cargo                  = response.cargo

        })      
        .catch(() => {});
    },
    
    computed: {
      usuario() {
        return this.$store.getters.usuario
      }
    },
  
    methods: {
      regresar() {
          this.$router.push({
            name: 'gestionar-acompanante',
            params: { id_competencia: this.$route.params.id_competencia }
          }); 
      },
    }
  };
  </script>