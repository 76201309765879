import axios from 'axios'

const sedes = {
    state: () => ({
        sedes: [],
        sede: {},
        sedesCompetencia: []
    }),

    getters: {
        obtenerSedes(state){
            return state.sedes
        },

        obtenerSede(state){
            return state.sede
        },

        obtenerSedesCompetencia(state){
            return state.sedesCompetencia
        }
    },

    mutations:{
        enviarSedes(state, sedes){
            state.sedes = sedes
        },

        enviarSede(state, sede){
            state.sede = sede
        },

        enviarSedesCompetencia(state, sedesCompetencia){
            state.sedesCompetencia = sedesCompetencia
        }
    },

    actions: {
        registrarSede(context, sede) {
            return new Promise((resolve, reject) => {
                axios.post("api/sedes", sede)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarSedes(context,competencia){
            axios.get("api/sede/"+competencia)
            .then((response) =>{
                const sedes = []
                for(let i = 0; i < response.data.results.length; i++){
                    sedes.push({
                        indice: (i+1),
                        id:                     response.data.results[i].id_sede,
                        sede:                   response.data.results[i].sede,
                        lugar:                  response.data.results[i].lugar,
                        direccion:              response.data.results[i].direccion,
                        responsable:            response.data.results[i].responsable,
                        telefono_responsable:   response.data.results[i].telefono_responsable,
                        correo_responsable:     response.data.results[i].correo_responsable,
                        regional_id:            response.data.results[i].centro_formacion.regional.id_regional,
                        regional:               response.data.results[i].centro_formacion.regional.regional,
                        centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion
                    })
                }
                context.commit("enviarSedes", sedes)
            })
            .catch(() =>{})
        },

        detallesSedes(context, sede){
            axios.get("api/sedes/"+sede)
            .then((response) => {
                const sede = {
                    id:                     response.data.results.id_sede,
                    sede:                   response.data.results.sede,
                    lugar:                  response.data.results.lugar,
                    direccion:              response.data.results.direccion,
                    responsable:            response.data.results.responsable,
                    telefono_responsable:   response.data.results.telefono_responsable,
                    correo_responsable:     response.data.results.correo_responsable,
                    regional:               response.data.results.centro_formacion.regional.regional,
                    centro_formacion:       response.data.results.centro_formacion.centro_formacion
                }
                context.commit("enviarSede", sede)
            })
            .catch(() => {})
        },

        editarsede(context, sede) {
            return new Promise((resolve, reject) => {
                axios.get("api/sedes/"+sede+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarSede(context, sede) {
            return new Promise((resolve, reject) => {
                axios.put("api/sedes/"+sede.id_sede, sede)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },
        
        gestionarSedesCompetencia(context, competencia){
            axios.get("api/competenciaSede/"+competencia)
            .then((response) =>{
                const sedesCompetencia = []
                for(let i = 0; i < response.data.results.length; i++){
                    sedesCompetencia.push({
                        indice: (i+1),
                        id:                      response.data.results[i].sede.id_sede,
                        sede:                    response.data.results[i].sede.sede,                    
                    })
                }
                context.commit("enviarSedesCompetencia", sedesCompetencia)
            })
            .catch(() =>{})
        },

        eliminarSede(context, competenciaSede) {
            return new Promise((resolve, reject) => {
                axios.delete("api/competencia/"+competenciaSede.competencia_id+"/sede/"+competenciaSede.sede_id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        }
    }
}

export default sedes