import axios from 'axios'

const acompanantes = {
  state: () => ({
    acompanantes: [],
    acompanante: {},
  }),

  getters: {
    obtenerAcompanantes: (state) => (competencia) => {
      return state.acompanantes.filter(acompanante => acompanante.competencia_id == competencia)
    },

    obtenerAcompanante(state) {
      return state.acompanante
    }
  },

  mutations: {
    enviarAcompanantes(state, acompanantes) {
      state.acompanantes = acompanantes
    },

    enviarAcompanante(state, acompanante) {
      state.acompanante = acompanante
    }
  },

  actions: {
    registrarAcompanante(context, acompanante) {
      return new Promise((resolve, reject) => {
        axios.post("api/acompanantes", acompanante)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },

    gestionarAcompanante(context) {
      axios.get("api/acompanantes")
      .then((response) => {
        const acompanantes = []
        response.data.results.forEach((response) => {
          acompanantes.push({
            id:                   response.id_acompanante,
            competencia_id:       response.competencia.id_competencia,
            competencia:          response.competencia.competencia,
            tipo_acompanante_id:  response.tipo_acompanante.id_tipo_acompanante,
            tipo_acompanante:     response.tipo_acompanante.tipo_acompanante,
            centro_formacion_id:  response.centro_formacion.id_centro_formacion,
            centro_formacion:     response.centro_formacion.centro_formacion,
            regional_id:          response.centro_formacion.regional.id_regional,
            regional:             response.centro_formacion.regional.regional,
            persona_id:           response.persona.id_persona,
            nombres:              response.persona.primer_nombre+" "+response.persona.segundo_nombre,
            apellidos:            response.persona.primer_apellido+" "+response.persona.segundo_apellido,
            correo:               response.persona.correo,
            telefono_movil:       response.persona.telefono_movil,
            fecha_nacimiento:     response.persona.fecha_nacimiento,
            genero:               response.persona.genero.genero,
            cargo:                response.cargo,
            talla_camiseta:       response.talla_camiseta
          })
        })

        context.commit("enviarAcompanantes", acompanantes)
      })
      .catch(() => { })
    },

    editarAcompanante(context, acompanante) {
      return new Promise((resolve, reject) => {
        axios.get("api/acompanantes/" + acompanante)
        .then((response) => {
          resolve(response.data.results)
        })
        .catch(() => {
          reject()
        })
      })
    },

    actualizarAcompanante(context, acompanante) {
      return new Promise((resolve, reject) => {
        axios.put("api/acompanantes/" + acompanante.id, acompanante)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },

    eliminarAcompanante(context, acompanante) {
      return new Promise((resolve, reject) => {
        axios.delete("api/acompanantes/" + acompanante)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },

    adjudicarAcompanantesWorldskills(context, competencia) {
      return new Promise((resolve, reject) => {
        axios.post("api/acompanantesWorldskillsAdjudicar", competencia)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    }
  }
}

export default acompanantes