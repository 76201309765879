<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleCard="titleCard"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :buttonCard="buttonCard"
    >
    </app-alert>

    <!-- formulario -->
    <v-row justify="center">
      <v-col cols="12" sm="10" lg="8">
        <v-card outlined elevation="2" :loading="loadingForm">
          <v-card-title class="justify-center">
            <p class="font-weight-bold text-h4 primary--text mt-4">
              Registrar Foro
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" sm="10" lg="8">
                <v-form ref="form" v-model="form">

                 
                  <v-select
                    outlined
                    dense
                    disabled
                    label="Competencia"
                    :items="obtenerHabiliadPorExperto"
                    item-value="competencia_id"
                    item-text="competencia"
                    v-model="obtenerHabiliadPorExperto"
                  

                  >
                  </v-select>
                  <v-select
                    outlined
                    dense
                    disabled
                    label="Habilidad*"
                    :items="obtenerHabiliadPorExperto"
                    item-value="categoria_id"
                    item-text="categoria"
                    v-model="obtenerHabiliadPorExperto"
                  
                  >
                  </v-select>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Foro*"
                    maxlength="100"
                    counter
                    autofocus
                    v-model="foro"
                    :rules="foroRules"
                  >
                  </v-text-field>
                  <!-- 
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Descripcion*"
                    maxlength="100"
                    counter
                    v-model="descripcion"
                    :rules="descripcionRules"
                  >
                  </v-text-field> -->

                  <v-textarea
                    outlined
                    dense
                    type="text"
                    rows="4"
                    label="Descripción*"
                    v-model="descripcion"
                    maxlength="100"
                    counter
                    :rules="descripcionRules"
                  >
                  </v-textarea>
                  <v-btn
                    block
                    large
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="registrar"
                  >
                    Registrar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
import Alert from "@/components/admin/Alert.vue";
export default {
  data: () => ({
    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    loadingForm: false,
    form: true,
    dialogForm: false,
    //competenciaSeleccionada: "",
    competencia: null,
    categorias: null,
    categoriaRules: [(v) => !!v || "seleccione una Habilidad"],
    foro: null,
    foroRules: [(v) => !!v || "Escriba el nombre del Foro"],

    descripcion: null,
    descripcionRules: [(v) => !!v || "Escriba una descripción"],
  }),

  mounted() {
    this.$store.dispatch("gestionarExpertosRegionales") 
  },

  computed: { 
    usuario() {
      return this.$store.getters.usuario;
    },
    obtenerHabiliadPorExperto(){
      return this.$store.getters.obtenerHabiliadPorExperto(this.usuario.identificacion)
    }
  },

  components: {
    "app-alert": Alert,
  },

  methods: {
    registrar() {
      if (this.$refs.form.validate()) {
        const foro = {
          usuario_id: this.usuario.id_usuario,
          categoria_id: this.obtenerHabiliadPorExperto.categoria_id,
          foro: this.foro,
          descripcion: this.descripcion,
          estado: "true",
          competencia_id: this.obtenerHabiliadPorExperto.competencia_id,
        };
        this.loadingForm = true;
        this.$store
          .dispatch("registrarForo", foro)
          .then((response) => {
            if (response.code == "success") {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Registrada";
              this.messageAlert = response.message;
              this.titleCard = "success--text";
              this.buttonCard = "success";
              this.$refs.form.reset();
              setTimeout(() => {
                this.$router.go(-1);
              }, 2500);
              
            } else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = response.message.foro[0];
              this.titleCard = "error--text";
              this.buttonCard = "error";
            }
            setTimeout(() => {
              this.alert = false;
            }, 1000);
          })
          .catch(() => {});
      }
    }
  },
};
</script>
  
  <style>
</style>