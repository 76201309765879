<template>
    <div>
      <!-- alerta -->
      <v-dialog max-width="320" v-model="alert">
        <v-card>
          <v-card-title class="justify-center">
            <p class="text-h5 font-weight-bold" :class="titleCard">
              {{ titleAlert }}
            </p>
          </v-card-title>
          <v-card-text class="text-h6 text-center">
            {{ messageAlert }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              :color="buttonCard"
              class="mb-2 white--text"
              @click="alert = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <template v-if="loaderTable">
        <v-skeleton-loader class="mx-auto" type="table"> </v-skeleton-loader>
      </template>
      <template v-else>
        <!-- tabla de datos -->
        <v-row justify="center">
          <v-col cols="12">
            <router-view></router-view>
            <v-card>
              <v-card-title class="text-h6 text-sm-h5 font-weight-bold primary--text">
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          exact
                          color="primary"
                          class="mx-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>
  
                  <v-col 
                    cols="8" 
                    sm="11" 
                    md="6"
                  >
                    Documentos: {{ habilidad.categoria }}
                  </v-col>
  
                  <v-col 
                    cols="8" 
                    sm="10" 
                    md="3"
                  >
                    <v-text-field v-if="documentos.length > 0"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      label="Buscar Documento"
                      hide-details
                      v-model="searchTable"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col 
                    cols="4" 
                    sm="2"
                    align="end"
                  >
                    <v-btn 
                      exact 
                      color="primary" 
                      @click="abrirDialog"
                    >
                      Registrar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table 
                fixed-header
                height="460px"
                class="elevation-2"
                :headers="headersTable"
                :items="documentos"
                :search="searchTable"
                no-data-text="No hay documentos registrados"
                :footer-props="{
                  'items-per-page-options': [10, 20, 50, 100, -1],
                }"
              >
                <template v-slot:top>
                  <v-dialog 
                    persistent 
                    v-model="dialogForm" 
                    max-width="520px"
                  >
                    <v-card :loading="loadingForm">
                      <v-card-title class="justify-center">
                        <p class="text-h5 font-weight-bold primary--text">
                          {{ formTitle }}
                        </p>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row justify="center">
                            <v-col cols="12" sm="10">
                              <v-form ref="form" v-model="form">
                                <v-text-field 
                                  outlined 
                                  dense 
                                  disabled
                                  type="text" 
                                  label="Habilidad*" 
                                  v-model="categoria"
                                >
                                </v-text-field>
                                
                                <v-text-field
                                  outlined
                                  dense
                                  type="text"
                                  label="Nombre del Documento*"
                                  maxlength="50"
                                  counter
                                  autofocus
                                  v-model="nombreDocumento"
                                  :rules="nombreDocumentoReglas"
                                >
                                </v-text-field>

                                <v-file-input
                                  outlined
                                  dense
                                  prepend-icon=""
                                  append-icon="mdi-file-pdf-box"
                                  accept="application/pdf"
                                  label="Documento*"
                                  truncate-length="100"
                                  counter
                                  show-size
                                  v-model="documento"
                                  :rules="documentoReglas"
                                >
                                </v-file-input>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
  
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="error" @click="cerrarDialog">
                          Cancelar
                        </v-btn>
  
                        <template v-if="editedIndex === -1">
                          <v-btn text exact color="primary" @click="asignar">
                            Registrar
                          </v-btn>
                        </template>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
  
                <template v-slot:[`item.acciones`]="{ item }">  
                    <v-tooltip top >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        medium
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="downloadeReglamentoGeneral(item.categoria_id, item.nombre_documento)"
                      >
                        mdi-cloud-download
                      </v-icon>
                    </template>
                    <span>Descargar</span>
                  </v-tooltip>

                  <v-tooltip top >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        medium
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="activarAlertaEliminar(item.categoria_id, item.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="justify-center text-h5">¿Desea eliminar el documento?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeDelete">Cancelar</v-btn>
            <v-btn
              text
              color="error"
              @click="eliminar(temCategoria_id, temId_documento)"
            >
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
    
  <script>
  export default {
    data: () => ({
      alert:        false,
      titleAlert:   null,
      messageAlert: null,
      titleCard:    null,
      buttonCard:   null,

      loaderTable: true,
      searchTable: null,

      loadingForm:  false,
      form:         true,
  
      dialogForm:   false,
      dialogDelete: false,
  
      temCategoria_id: null,
      temId_documento: null,
  
      headersTable: [
        {
          text: "#",
          value: "indice",
          align: "start",
          sortable: false,
          filterable: false,
          class: ["text-subtitle-1", "font-weight-black"]
        },

        {
          text: "Nombre del documento",
          value: "nombre_documento",
          align: "start",
          class: ["text-subtitle-1", "font-weight-black"]
        },

        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
          filterable: false,
          class: ["text-subtitle-1", "font-weight-black"]
        }
      ],  
      
      categoria: null,

      nombreDocumento: null,
      nombreDocumentoReglas: [
        (v) => !!v || "Escriba un nombre para el documento"
      ],

      documento: null,
      documentoReglas: [
        (v) => !!v || "Cargue un documento",
        (v) => !v || v.size < 5000000 || "El tamaño del documento debe ser menor de 5 MB"
      ],
  
      editedIndex: -1,
    }),

    mounted() {
      this.$store.dispatch("gestionarDocumentos", this.$route.params.id_habilidad)
      this.$store.dispatch("gestionarHabilidades")
      this.$store.dispatch("detallesHabilidad", this.$route.params.id_habilidad)

      setTimeout(() => {
        this.loaderTable = false
      }, 1000)
    },
  
    computed: {
      formTitle() {
        return this.editedIndex === -1
          ? "Registrar Documento"
          : "Actualizar Documento"
      },

      documentos(){
        return this.$store.getters.obtenerDocumentos
      },

      habilidad() {
        return this.$store.getters.obtenerHabilidad;
      }
    },
  
    watch: {
      dialog(val) {
        val || this.cerrarDialog();
      },

      dialogDelete(val) {
        val || this.closeDelete();
      }
    },
  
    methods: {
      abrirDialog() {
        this.dialogForm = true;
        this.categoria = this.habilidad.categoria
      },
  
      deleteItem(item) {
        this.editedIndex = this.documentos.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },
  
      deleteItemConfirm() {
        this.documentos.splice(this.editedIndex, 1);
        this.closeDelete();
      },
  
      cerrarDialog() {
        this.dialogForm = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
        this.$refs.form.reset();
      },
  
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      asignar() {
        if (this.$refs.form.validate()) {
          const documento = new FormData();
          documento.set("categoria_id",     this.$route.params.id_habilidad)
          documento.set("nombre_documento", this.nombreDocumento)
          documento.set("documentos",       this.documento)
  
          this.$store.dispatch("registrarDocumento", documento)
            .then((response) => {
              if (response.status == "success") {
                this.loadingForm = false;
                this.cerrarDialog();

                this.alert        = true;
                this.titleAlert   = "Registrado";
                this.messageAlert = response.message;
                this.titleCard    = "success--text";
                this.buttonCard   = "success";

                this.$store.dispatch("gestionarDocumentos", this.$route.params.id_habilidad);
              } 
              else {
                this.loadingForm  = false;
                this.alert        = true;
                this.titleAlert   = "Error";
                this.messageAlert = response.message;
                this.titleCard    = "error--text";
                this.buttonCard   = "error";
              }
  
              setTimeout(() => {
                this.alert = false;
              }, 1000);
            })
            .catch(() => {});
        }
      },
  
      activarAlertaEliminar(categoria_id, id_documento) {
        this.temCategoria_id = categoria_id;
        this.temId_documento = id_documento;
        this.dialogDelete = true;
      },
  
      eliminar(idHabilidad, idDocumento) {
        this.dialogDelete = false;
        const HabilidadDocumento = {
          categoria_id: idHabilidad,
          id_documento: idDocumento,
        };
        
        this.$store.dispatch("eliminarDocumentoHabilidad", HabilidadDocumento)
          .then((response) => {
            if (response.status == "success") {
              this.alert        = true;
              this.titleAlert   = "Eliminado";
              this.messageAlert = response.message;
              this.titleCard    = "success--text";
              this.buttonCard   = "success";
              this.$store.dispatch("gestionarDocumentos", this.$route.params.id_habilidad);
            } 
            else {
              this.alert        = true;
              this.titleAlert   = "Error";
              this.messageAlert = response.message;
              this.titleCard    = "error--text";
              this.buttonCard   = "error";
            }
            setTimeout(() => {
              this.alert = false;
            }, 1000);
          })
          .catch(() => {});
      },

      downloadeReglamentoGeneral (idhabilidad, nombredocumento) {
        this.$store.dispatch("downloadeHabilidad", {
          categoria_id: idhabilidad,
          nombre_documento: nombredocumento
        })
        .then((response) => {
          const urlReporte = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href= urlReporte
          link.setAttribute('download',nombredocumento+'.pdf')
          document.body.appendChild(link)
          link.click();
        })
        .catch(() => {
          this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = "El documento "+nombredocumento+ " no tiene documento registrado";
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
        })
        setTimeout(() => {
          this.alert = false;
        }, 6000);
      },
  
      regresar() {
        history.back()
      }
    }
  }
</script>
    