<template>
  <div>
    <v-row>
      <v-col cols="12">
        <template v-if="loaderTable">
          <v-skeleton-loader class="mx-auto" type="table"> </v-skeleton-loader>
        </template>
        <template v-else>
          <!-- tabla -->
          <v-card>
            <v-card-title class="text-h5 font-weight-bold primary--text">
              <v-row>
                <v-col
                  v-if="usuario.perfil_id == 1 || usuario.perfil_id == 3"
                  cols="12"
                  sm="9"
                >
                  Gestionar Habilidades
                </v-col>
                <v-col
                  v-if="
                    usuario.perfil_id == 4 ||
                    usuario.perfil_id == 6 ||
                    usuario.perfil_id == 7
                  "
                  cols="12"
                  sm="9"
                >
                  Gestionar Habilidad
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-if="habilidades.length > 0"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    label="Buscar Habilidad"
                    hide-details
                    v-model="searchTable"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-title>

            <v-data-table
              v-if="usuario.perfil_id == 3 || usuario.perfil_id == 1"
              fixed-header
              locale="es"
              height="460px"
              class="elevation-2"
              :headers="headersTable"
              :search="searchTable"
              :items="habilidades"
              no-data-text="No hay habilidades registradas"
              :footer-props="{ 'items-per-page-options': [20, 50, 100, -1] }"
            >
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="detallesHabilidad(item.id)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Detalles</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="actualizarHabilidad(item.id)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Actualizar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="registrarDocumentos(item.id)"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </template>
                  <span>Registrar Documentos</span>
                </v-tooltip>
              </template>
            </v-data-table>

            <v-data-table
              v-if="usuario.perfil_id == 4"
              fixed-header
              locale="es"
              height="460px"
              class="elevation-2"
              :loading="loaderTable"
              :headers="headersTable"
              :items="habilidadExpertoNacional"
              no-data-text="No hay habilidad asignada"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 100, -1],
              }"
            >
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="detallesHabilidad(item.id)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Detalles</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="actualizarHabilidad(item.id)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Actualizar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="registrarDocumentos(item.id)"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </template>
                  <span>Registrar Documentos</span>
                </v-tooltip>
              </template>
            </v-data-table>

            <!-- <v-data-table 
              v-if="usuario.perfil_id == 6" 
              fixed-header 
              locale="es" 
              height="460px" 
              class="elevation-2"
              :loading="loaderTable" 
              :headers="headersTable" 
              :items="expertosRegionales"
              textTable="No hay habilidades registradas" 
              :items-per-page="10"
              :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1] }"
              >
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                      medium 
                      color="primary" 
                      class="mr-2" 
                      v-bind="attrs" 
                      v-on="on"
                      @click="detallesHabilidad(item.categoria_id)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Detalles</span>
                </v-tooltip>
              </template>
            </v-data-table> -->
          </v-card>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    loaderTable: true,
    searchTable: null,
    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Codigo",
        value: "codigo_habilidad",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Habilidad",
        value: "categoria",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ],
  }),

  mounted() {
    this.$store.dispatch("gestionarHabilidades");
    this.$store.dispatch("gestionarExpertosNacionales");
    this.$store.dispatch("gestionarExpertosRegionales");
    this.loaderTable = false;

    // Restaurar el valor de búsqueda desde el almacenamiento local si la ruta base es usuarios
    setTimeout(() => {
      this.loaderTable = false
      const storedSearch = this.$store.getters.obtenerSearch;
      if (storedSearch) {
        this.searchTable = storedSearch;
      }
    }, 2)
  },

  watch: {
    searchTable(newSearch) {
      this.$store.dispatch("guardarSearch", newSearch);
    },
  },

  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },

    habilidades() {
      return this.$store.getters.obtenerHabilidades;
    },

    expertoNacional() {
      return this.$store.getters.obtenerExpertoNacionalIdentificacion(this.usuario.identificacion);
    },

    habilidadExpertoNacional() {
      if (this.expertoNacional != null) {
        return this.$store.getters.obtenerHabilidadExpertoNacional(this.expertoNacional.categoria_id);
      }
      return  []
    },

    expertosRegionales() {
      return this.$store.getters.obtenerExpertosRegionales.filter((c) => {
        if (c.identificacion == this.usuario.identificacion) {
          c.indice = 0;
          c.indice++;
          return c;
        }
      });
    },
  },

  methods: {
    detallesHabilidad(habilidad) {
      this.$router.push({
        name: "habilidad-detalles",
        params: { id_habilidad: habilidad },
      });
    },

    actualizarHabilidad(habilidad) {
      this.$router.push({
        name: "habilidad-actualizar",
        params: { id_habilidad: habilidad },
      });
    },

    registrarDocumentos(habilidad) {
      this.$router.push({
        name: "habilidad-documentos",
        params: { id_habilidad: habilidad },
      });
    },
  },
};
</script>
