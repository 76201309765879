import axios from 'axios'

const aliados = {
    state: () => ({
        aliados: []
    }),

    getters: {
        obtenerAliados(state) {
            return state.aliados
        },
    },

    mutations: {
        enviarAliados(state, aliados) {
            state.aliados = aliados
        },
    },

    actions: {
        registrarAliado(context, aliado) {
            return new Promise((resolve, reject) => {
                axios.post("api/aliados", aliado, { headers: {'Content-Type': 'multipart/form-data'} })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarAliados(context) {
            axios.get("api/aliados")
            .then((response) => {
                const aliados = []
                for (let i = 0; i < response.data.results.length; i++) {
                    aliados.push({
                        indice: (i+1),
                        id:                         response.data.results[i].id_aliado,
                        aliado:                     response.data.results[i].aliado,
                        logotipo:                   response.data.results[i].logotipo,
                        descripcion:                response.data.results[i].descripcion,
                        direccion:                  response.data.results[i].direccion,
                        telefono:                   response.data.results[i].telefono,
                        correo:                     response.data.results[i].correo,
                        contacto_aliado:            response.data.results[i].contacto_aliado,
                        telefono_contacto_aliado:   response.data.results[i].telefono_contacto_aliado,
                        correo_contacto_aliado:     response.data.results[i].correo_contacto_aliado
                    })
                }
                context.commit("enviarAliados", aliados)
            })
            .catch(() => {})
        },

        detallesAliado(context, aliado) {
            return new Promise((resolve, reject) => {
                axios.get("api/aliados/"+aliado)
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        editarAliado(context, aliado) {
            return new Promise((resolve, reject) => {
                axios.get("api/aliados/"+aliado+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },
        
        actualizarAliado(context, aliado) {
            return new Promise((resolve, reject) => {
                axios.post("api/aliados/"+aliado.get("id"), aliado, { headers: {'Content-Type': 'multipart/form-data'} })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        }
    }
}

export default aliados