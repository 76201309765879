<template>
  <div>
    <!-- alerta -->
    <app-alert 
      :alert="alert" 
      :titleAlert="titleAlert" 
      :messageAlert="messageAlert" 
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>

    <!-- formulario -->
    <v-row>
      <v-col 
        sm="1" 
        lg="2" 
        class="hidden-xs-only"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              fab 
              small 
              fixed 
              exact 
              color="primary" 
              v-bind="attrs" 
              v-on="on" @click="regresar"
              >
              <v-icon> mdi-arrow-left </v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
      </v-col>
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Actualizar Habilidad
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-text-field 
                    outlined 
                    dense 
                    type="number" 
                    label="Codigo de Habilidad*" 
                    maxlength="3" 
                    counter
                    v-model="codigoHabilidad" 
                    :rules="codigoHabilidadReglas"
                  >
                  </v-text-field>

                  <template v-if="usuario.perfil_id == 4">
                    <v-text-field 
                      outlined 
                      dense 
                      disabled
                      type="text" 
                      label="Habilidad" 
                      maxlength="100" 
                      counter 
                      v-model="habilidad"
                      :rules="habilidadReglas"
                    >
                    </v-text-field>
                  </template>
                  <template v-else>
                    <v-text-field 
                      outlined 
                      dense 
                      type="text" 
                      label="Habilidad" 
                      maxlength="100" 
                      counter 
                      v-model="habilidad"
                      :rules="habilidadReglas"
                    >
                    </v-text-field>
                  </template>

                  <v-autocomplete 
                    outlined 
                    multiple 
                    small-chips 
                    label="Programas de Formación"
                    :items="programasFormacion" 
                    item-value="id" 
                    item-text="programa_formacion" 
                    v-model="programaFormacion"
                  >
                  </v-autocomplete>

                  <v-textarea 
                    outlined 
                    type="text" 
                    rows="4" 
                    label="Descripción" 
                    v-model="descripcion"
                  >
                  </v-textarea>

                  <v-btn 
                    large 
                    block 
                    exact 
                    elevation="2" 
                    color="primary" 
                    class="mt-2 mb-6" 
                    @click="actualizarHabilidad"
                  >
                    Actualizar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Alert from '../../../components/admin/Alert.vue'

export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,

    loadingForm:  false,
    form:         true,
    
    codigoHabilidad: "",
    codigoHabilidadReglas: [
      v => !!v || "Escriba un codigo para la habilidad",
      v => (v.length <= 3) || "La cantidad maxima de números es 3",
      v => (v > 0) || "El número debe ser mayor a cero"
    ],
    
    habilidad: null,
    habilidadReglas: [
      v => !!v || "Escriba un nombre para la habilidad"
    ],

    programaFormacion: [],
    
    descripcion: null
  }),

  mounted() {
    this.$store.dispatch("editarHabilidad", this.$route.params.id_habilidad)
    .then((response) => {
      this.codigoHabilidad  = response.codigo_habilidad
      this.habilidad        = response.categoria
      this.descripcion      = response.descripcion

      response.programa_formacion_categoria.forEach((response) => {
        this.programaFormacion.push(response.programa_formacion.id_programa_formacion)
      })

    })
    .catch(() => {})

    this.$store.dispatch("crearProgramasFormacion")
  },

  computed: {
    usuario() {
      return this.$store.getters.usuario
    },

    programasFormacion() {
      return this.$store.getters.obtenerProgramasFormacion
    }
  },

  components: {
    "app-alert": Alert
  },

  methods: {
    actualizarHabilidad() {
      if (this.$refs.form.validate()) {
        this.loadingForm = true
        const habilidad = {
          id:                     this.$route.params.id_habilidad,
          codigo_habilidad:       this.codigoHabilidad,
          categoria:              this.habilidad[0].toUpperCase() + this.habilidad.toLowerCase().substring(1),
          programa_formacion_id:  this.programaFormacion,
          descripcion:            this.descripcion
        }

        this.$store.dispatch("actualizarHabilidad", habilidad)
        .then((response) => {
          if (response.status == "success") {
            this.loadingForm  = false
            this.alert        = true
            this.titleAlert   = "Actualizada"
            this.messageAlert = response.message
            this.titleCard    = "success--text"
            this.buttonCard   = "success"

            setTimeout(() => {
              this.$router.push({
                name: "habilidad-gestionar"
              });
            }, 1000)
          } 
          else {
            this.loadingForm  = false
            this.alert        = true
            this.titleAlert   = "Error"
            for (const error in response.message) {
              this.messageAlert = response.message[error][0]
            }
            this.titleCard  = "error--text"
            this.buttonCard = "error"
          }

          setTimeout(() => {
            this.alert = false
          }, 1000)
        })
        .catch(() => {})
      }
    },

    regresar() {
      this.$router.push({
        name: "habilidad-gestionar"
      })
    }
  }
}
</script>
