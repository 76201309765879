import axios from 'axios'

const tablero = {
  state: () => ({
    participantesCategoriaSenasoft: [],
    lideresCategoriaSenasoft: [],
    cuposCategoriaSenasoft: [],

    participantesHabilidadWorldSkills: [],
    ganadoresHabilidadWorldSkills: [],
    expertosTipoWorldSkills: [],
    acompanantesTipoWorldSkills: []
  }),

  getters: {
    obtenerParticipantesCategoriaSenasoft(state) {
      return state.participantesCategoriaSenasoft
    },

    obtenerLideresCategoriaSenasoft(state) {
      return state.lideresCategoriaSenasoft
    },

    obtenerCuposCategoriaSenasoft(state) {
      return state.cuposCategoriaSenasoft
    },

    obtenerParticipantesHabilidadWorldSkills(state) {
      return state.participantesHabilidadWorldSkills
    },

    obtenerGanadoresHabilidadWorldSkills(state) {
      return state.ganadoresHabilidadWorldSkills
    },

    obtenerExpertosTipoWorldSkills(state) {
      return state.expertosTipoWorldSkills
    },

    obtenerAcompanantesTipoWorldSkills(state) {
      return state.acompanantesTipoWorldSkills
    }
  },

  mutations: {
    enviarParticipantesCategoriaSenasoft(state, participantesCategoria) {
      state.participantesCategoriaSenasoft = participantesCategoria
    },

    enviarLideresCategoriaSenasoft(state, lideresCategoria) {
      state.lideresCategoriaSenasoft = lideresCategoria
    },
    
    enviarCuposCategoriaSenasoft(state, cuposCategoria) {
      state.cuposCategoriaSenasoft = cuposCategoria
    },


    enviarParticipantesHabilidadWorldSkills(state, participantes) {
      state.participantesHabilidadWorldSkills = participantes
    },

    enviarGanadoresHabilidadWorldSkills(state, ganadores) {
      state.ganadoresHabilidadWorldSkills = ganadores
    },

    enviarExpertosTipoWorldSkills(state, expertos) {
      state.expertosTipoWorldSkills = expertos
    },

    enviarAcompanantesTipoWorldSkills(state, acompanantes) {
      state.acompanantesTipoWorldSkills = acompanantes
    }
  },

  actions: {
    participantesCategoriaSenasoft(context) {
      axios.get("api/tablero/participantesCategoria")
      .then((response) => {
        const participantesCategoria = []
        response.data.results.forEach(result => {
          participantesCategoria.push({
            categoria: result.categoria,
            participantes: result.participantes
          })
        })
        context.commit("enviarParticipantesCategoriaSenasoft", participantesCategoria)
      })
      .catch(() => {})
    },

    lideresCategoriaSenasoft(context) {
      axios.get("api/tablero/lideresCategoria")
      .then((response) => {
        const lideresCategoria = []
        response.data.results.forEach(result => {
          lideresCategoria.push({
            categoria: result.categoria,
            lideres: result.lideres
          })
        })
        context.commit("enviarLideresCategoriaSenasoft", lideresCategoria)
      })
      .catch(() => {})
    },

    cuposCategoriaSenasoft(context) {
      axios.get("api/tablero/cuposCategoria")
      .then((response) => {
        const cuposCategoria = []
        response.data.results.forEach(result => {
          cuposCategoria.push({
            categoria: result.categoria,
            cupos: result.cupos
          })
        })
        context.commit("enviarCuposCategoriaSenasoft", cuposCategoria)
      })
      .catch(() => {})
    },

    participantesHabilidadWorldSkills(context, competencia) {
      axios.get("api/tableroWorldSkillsParticipantes/"+competencia)
      .then((response) => {
        const participantes = []
        response.data.results.forEach(result => {
          participantes.push({
            habilidades:    result.categoria,
            participantes:  result.participantes
          })
        })
        context.commit("enviarParticipantesHabilidadWorldSkills", participantes)
      })
      .catch(() => {})
    },

    ganadoresHabilidadWorldSkills(context, competencia) {
      axios.get("api/tableroWorldSkillsGanadores/"+competencia)
      .then((response) => {
        const ganadores = []
        response.data.results.forEach(result => {
          ganadores.push({
            habilidades:  result.categoria,
            ganadores:    result.ganadores
          })
        })
        context.commit("enviarGanadoresHabilidadWorldSkills", ganadores)
      })
      .catch(() => {})
    },

    expertosTipoWorldSkills(context, competencia) {
      axios.get("api/tableroWorldSkillsExpertos/"+competencia)
      .then((response) => {
        const expertos = []
        response.data.results.forEach(result => {
          expertos.push({
            tiposExperto: result.tipos_experto,
            expertos:     result.expertos
          })
        })
        context.commit("enviarExpertosTipoWorldSkills", expertos)
      })
      .catch(() => {})
    },

    acompanantesTipoWorldSkills(context, competencia) {
      axios.get("api/tableroWorldSkillsAcompanantes/"+competencia)
      .then((response) => {
        const acompanantes = []
        response.data.results.forEach(result => {
          acompanantes.push({
            tiposAcompanante: result.tipos_acompanante,
            acompanantes:     result.acompanantes
          })
        })
        context.commit("enviarAcompanantesTipoWorldSkills", acompanantes)
      })
      .catch(() => {})
    }
  }
}

export default tablero
