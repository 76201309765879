<template>
  <div>
    <template v-if="loaderTable">
      <v-skeleton-loader
        class="mx-auto"
        type="table"
      >
      </v-skeleton-loader>
    </template>
    <template v-else>
      <!-- tabla de datos -->
      <app-data-table
        titleTable="Gestionar Competencias"
        titleSearch="Buscar Competencia"
        :headersTable="headersTable"
        :itemsTable="competencias"
        textTable="No hay competencias registradas"
        :acciones="acciones"
        :rutas="rutas"
      >
      </app-data-table>
    </template>
  </div>
</template>

<script>
import DataTable from '../../../components/admin/DataTable.vue'

export default {
  data: () => ({
    loaderTable: true,
    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Competencia",
        value: "competencia",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Fecha Inicio",
        value: "fecha_inicio",
        align: "start",
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Fecha Fin",
        value: "fecha_fin",
        align: "start",
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Centro de Formación",
        value: "centro_formacion",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },
      
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ],

    acciones: [
      {
        tooltip: "Detalles",
        icono: "mdi-eye",
        color: "primary",
        margen: "mr-2"
      },

      {
        tooltip: "Actualizar",
        icono: "mdi-pencil",
        color: "primary",
        margen: "mr-2"
      },

      {
        tooltip: "Asignar Categorias",
        icono: "mdi-playlist-plus",
        color: "success",
        margen: "mr-2"
      },

      {
        tooltip: "Gestionar Lideres",
        icono: "mdi-account-plus",
        color: "success",
        margen: "mr-2"
      },

      {
        tooltip: "Participantes",
        icono: "mdi-account-group",
        color: "success",
      }

    ],

    rutas: [
      "/competencias/detalles/",
      "/competencias/actualizar/",
      "/categorias/asignar/",
      "/lideres/",
      "/participantes/inscritos/"
    ]
  }),

  mounted () {
    this.$store.dispatch("gestionarCompetencias")
    setTimeout(() => {
      this.loaderTable = false;
    }, 1000);
  },

  computed: {
    competencias () {
      return this.$store.getters.obtenerCompetencias;
    }
  },

  components: {
    "app-data-table": DataTable
  },
};
</script>