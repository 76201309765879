import axios from 'axios'

const ganadores = {
    state: () => ({
        ganadores: []
    }),

    getters: {
        obtenerGanadoresWorldSkills: (state) => (competencia, habilidad) =>  {
            return state.ganadores.filter(ganador => ganador.competencia_id == competencia && ganador.categoria_id == habilidad)
        },

        obtenerGanadorWorldSkills: (state) => (id) =>  {
            return state.ganadores.find(ganador => ganador.id == id)
        }
    },

    mutations: {
        enviarGanadoresWorldSkills(state, ganadores){
            state.ganadores = ganadores
        }
    },

    actions: {
        registrarGanadorWorldSkills(context, ganador) {
            return new Promise((resolve, reject) => {
                axios.post("api/ganadoresWorldSkills", ganador)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarGanadorWorldSkills(context) {
            axios.get("api/ganadoresWorldSkills")
            .then((response) => {
                const ganadores = []
                response.data.results.forEach((response) => {
                    ganadores.push({
                        id:                     response.id_ganador,
                        competencia_id:         response.participante.competencia.id_competencia,
                        competencia:            response.participante.competencia.competencia,
                        categoria_id:           response.participante.categoria.id_categoria,
                        categoria:              response.participante.categoria.categoria,
                        medalla:                response.medalla,
                        puntaje:                response.puntaje,
                        descripcion:            response.descripcion,
                        participante_id:        response.participante_id,
                        centro_formacion_id:    response.participante.centro_formacion.id_centro_formacion, 
                        centro_formacion:       response.participante.centro_formacion.centro_formacion,
                        regional_id:            response.participante.centro_formacion.regional.id_regional,
                        regional:               response.participante.centro_formacion.regional.regional,
                        programa_formacion_id:  response.participante.programa_formacion.id_programa_formacion,
                        programa_formacion:     response.participante.programa_formacion.programa_formacion,
                        numero_ficha:           response.participante.numero_ficha,
                        talla_camiseta:         response.participante.talla_camiseta,
                        identificacion:         response.participante.persona.identificacion,
                        nombres:                response.participante.persona.primer_nombre+" "+response.participante.persona.segundo_nombre,
                        apellidos:              response.participante.persona.primer_apellido+" "+response.participante.persona.segundo_apellido,
                        correo:                 response.participante.persona.correo,                        
                        telefono:               response.participante.persona.telefono_movil,   
                        fecha_nacimiento:       response.participante.persona.fecha_nacimiento,
                        genero:                 response.participante.persona.genero.genero
                    })
                });
                context.commit("enviarGanadoresWorldSkills", ganadores)
            })
            .catch(() => {})
        },

        editarGanadorWorldSkills(context, ganador){
            return new Promise((resolve,reject)=>{
            axios.get("api/ganadoresWorldSkills/"+ganador+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(()=>{
                    reject()
                })
            })
        },

        actualizarGanadorWorldSkills(context, ganador){
            return new Promise((resolve,reject)=>{
            axios.put("api/ganadoresWorldSkills/"+ganador.id, ganador)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(()=>{
                    reject()
                })
            })
        },

        eliminarGanadorWorldSkills(context, ganador) {
            return new Promise((resolve, reject) => {
                axios.delete("api/ganadoresWorldSkills/"+ganador)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        }
    }
}

export default ganadores