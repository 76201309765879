import '@mdi/font/css/materialdesignicons.css' 
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es'
    },

    theme: {
        themes: {
            light: {
                primary: '#39A900',
                blu: '#1E88E5'
            }
        }
    },
    
    icons: {
        iconfont: 'mdi',
      },
});
