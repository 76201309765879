import axios from 'axios'


const lideres = {
    state: () => ({
        lideresCompetencia: [],
        lideresCategoria:   [],
        expertosRegionales: [],
    }),


    getters: {
        obtenerLideresCompetencia(state) {
            return state.lideresCompetencia
        },


        obtenerLideresCategoria(state) {
            return state.lideresCategoria
        },
       
        obtenerExpertosRegionales(state){
            return state.expertosRegionales
        },
    },


    mutations: {
        enviarLideresCompetencia(state, lideresCompetencia) {
            state.lideresCompetencia = lideresCompetencia
        },


        enviarLideresCategoria(state, lideresCategoria) {
            state.lideresCategoria = lideresCategoria
        },
        enviarExpertosRegionales(state,expertosRegionales){
            state.expertosRegionales=expertosRegionales
        },
    },


    actions: {




        registrarLiderCompetencia(context, liderCompetencia) {
            return new Promise((resolve, reject) => {
                axios.post("api/lideres", liderCompetencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },


        // registrarLiderCompetencia(context, liderCompetencia) {
        //     return new Promise((resolve, reject) => {
        //         axios.post("api/competencias/"+liderCompetencia.competencia_id+"/lideres", liderCompetencia)
        //         .then((response) => {
        //             resolve(response.data)
        //         })
        //         .catch(() => {
        //             reject()
        //         })
        //     })
        // },




        gestionarLideresCompetencia(context, competencia) {
            axios.get("api/lideres/competencia/"+competencia)
            .then((response) => {
               
                const lideresCompetencia = []
                for (let i = 0; i < response.data.results.length; i++) {
                    lideresCompetencia.push({
                        indice: (i+1),
                        idlider:                response.data.results[i].id_lider,
                        competencia_id:         response.data.results[i].id_competencia,
                        //competencia:             response.data.results[i].competencia.competencia,
                        // categoria_id:           response.data.results[i].categoria.id_categoria,
                        categoria:              response.data.results[i].categoria.categoria,
                        // tipo_lider_id:          response.data.results[i].tipo_lider.id_tipo_lider,
                        tipo_lider:             response.data.results[i].tipo_lider.tipo_lider,
                        // centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
                        // persona_id:             response.data.results[i].persona.id_persona,
                        nombres:                response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre=='null'?response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre:response.data.results[i].persona.primer_nombre,
                        apellidos:              response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido=='null'?response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido:response.data.results[i].persona.primer_apellido,
                        correo:                 response.data.results[i].persona.correo,
                        // telefono_movil:         response.data.results[i].persona.telefono_movil,
                        //cargo:                   response.data.results[i].cargo.cargo
                    })
                }
                context.commit("enviarLideresCompetencia", lideresCompetencia)
            })
            .catch(() => {})
        },


        // gestionarLideresCompetencia(context, competencia) {
        //     axios.get("api/competencias/"+competencia+"/lideres")
        //     .then((response) => {
        //         const lideresCompetencia = []
        //         for (let i = 0; i < response.data.results.length; i++) {
        //             lideresCompetencia.push({
        //                 indice: (i+1),
        //                 competencia_id:         response.data.results[i].competencia.id_competencia,
        //                 competencia:            response.data.results[i].competencia.competencia,
        //                 categoria_id:           response.data.results[i].categoria.id_categoria,
        //                 categoria:              response.data.results[i].categoria.categoria,
        //                 tipo_lider_id:          response.data.results[i].tipo_lider.id_tipo_lider,
        //                 tipo_lider:             response.data.results[i].tipo_lider.tipo_lider,
        //                 centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
        //                 centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
        //                 persona_id:             response.data.results[i].persona.id_persona,
        //                 nombres:                response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre,
        //                 apellidos:              response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido,
        //                 correo:                 response.data.results[i].persona.correo,
        //                 telefono_movil:         response.data.results[i].persona.telefono_movil,
        //                 cargo:                  response.data.results[i].cargo.cargo
        //             })
        //         }
        //         context.commit("enviarLideresCompetencia", lideresCompetencia)
        //     })
        //     .catch(() => {})
        // },




        eliminarLiderCompetencia(context, id_lider) {
            return new Promise((resolve, reject) => {
                axios.delete("api/lideres/"+id_lider)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },


        // eliminarLiderCompetencia(context, liderCompetencia) {
        //     return new Promise((resolve, reject) => {
        //         axios.delete("api/competencias/"+liderCompetencia.competencia_id+"/lideres/"+liderCompetencia.persona_id)
        //         .then((response) => {
        //             resolve(response.data)
        //         })
        //         .catch(() => {
        //             reject()
        //         })
        //     })
        // },

        importarLideres(context, usr) {
            const formData = new FormData();
            formData.append('liderImport', usr);
            return new Promise((resolve, reject) => {
                axios.post("api/importar/lideres", formData, { headers: {'Content-Type': 'multipart/form-data'} })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },
        async downloaddocLider(){
            return new Promise((resolve, reject) => {
               axios.get("api/documento/lideres",{responseType: 'blob'})
                .then((response) => {
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
            })
        },
        
        gestionarLideresCategoria(context, categoria) {
            axios.get("api/lideres/categoria/"+categoria.competencia_id+"/"+categoria.centro_formacion_id)
            .then((response) => {
                const lideresCategoria = []
                for (let i = 0; i < response.data.results.length; i++) {
                    lideresCategoria.push({
                        indice: (i+1),
                        competencia_id:         response.data.results[i].competencia.id_competencia,
                        competencia:            response.data.results[i].competencia.competencia,
                        categoria_id:           response.data.results[i].categoria.id_categoria,
                        categoria:              response.data.results[i].categoria.categoria,
                        tipo_lider_id:          response.data.results[i].tipo_lider.id_tipo_lider,
                        tipo_lider:             response.data.results[i].tipo_lider.tipo_lider,
                        centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
                        persona_id:             response.data.results[i].persona.id_persona,
                        nombres:                response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre=='null'?response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre:response.data.results[i].persona.primer_nombre,
                        apellidos:              response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido=='null'?response.data.persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido:response.data.results[i].persona.primer_apellido,
                        correo:                 response.data.results[i].persona.correo,
                        telefono_movil:         response.data.results[i].persona.telefono_movil,
                        id_lider:               response.data.results[i].id_lider,

                    })
                }
                context.commit("enviarLideresCategoria", lideresCategoria)
            })
            .catch(() => {})
        },


        // gestionarLideresCategoria(context, categoria) {
        //     axios.get("api/competencias/"+categoria.competencia_id+"/lideres/"+categoria.centro_formacion_id)
        //     .then((response) => {
        //         const lideresCategoria = []
        //         for (let i = 0; i < response.data.results.length; i++) {
        //             lideresCategoria.push({
        //                 indice: (i+1),
        //                 competencia_id:         response.data.results[i].competencia.id_competencia,
        //                 competencia:            response.data.results[i].competencia.competencia,
        //                 categoria_id:           response.data.results[i].categoria.id_categoria,
        //                 categoria:              response.data.results[i].categoria.categoria,
        //                 tipo_lider_id:          response.data.results[i].tipo_lider.id_tipo_lider,
        //                 tipo_lider:             response.data.results[i].tipo_lider.tipo_lider,
        //                 centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
        //                 centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
        //                 persona_id:             response.data.results[i].persona.id_persona,
        //                 nombres:                response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre,
        //                 apellidos:              response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido,
        //                 correo:                 response.data.results[i].persona.correo,
        //                 telefono_movil:         response.data.results[i].persona.telefono_movil
        //             })
        //         }
        //         context.commit("enviarLideresCategoria", lideresCategoria)
        //     })
        //     .catch(() => {})
        // },
       
        gestionarExpertoHabilad(context, categoria) {
            axios.get("api/competencias/"+categoria.competencia_id+"/lideres/"+categoria.centro_formacion_id)
            .then((response) => {
                const lideresCategoria = []
                for (let i = 0; i < response.data.results.length; i++) {
                    lideresCategoria.push({
                        indice: (i+1),
                        competencia_id:         response.data.results[i].competencia.id_competencia,
                        competencia:            response.data.results[i].competencia.competencia,
                        categoria_id:           response.data.results[i].categoria.id_categoria,
                        categoria:              response.data.results[i].categoria.categoria,
                        tipo_lider_id:          response.data.results[i].tipo_lider.id_tipo_lider,
                        tipo_lider:             response.data.results[i].tipo_lider.tipo_lider,
                        centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
                        persona_id:             response.data.results[i].persona.id_persona,
                        nombres:                response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre=='null'?response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre:response.data.results[i].persona.primer_nombre,
                        apellidos:              response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido=='null'?response.data.persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido:response.data.results[i].persona.primer_apellido,
                        correo:                 response.data.results[i].persona.correo,
                        telefono_movil:         response.data.results[i].persona.telefono_movil
                    })
                }
                context.commit("enviarLideresCategoria", lideresCategoria)
            })
            .catch(() => {})
        },


        gestionarExpertoCompetencia(context, categoria) {
            axios.get("api/competencias/"+categoria.competencia_id+"/lideres/"+categoria.centro_formacion_id)
            .then((response) => {
                const lideresCategoria = []
                for (let i = 0; i < response.data.results.length; i++) {
                    lideresCategoria.push({
                        indice: (i+1),
                        competencia_id:         response.data.results[i].competencia.id_competencia,
                        competencia:            response.data.results[i].competencia.competencia,
                        categoria_id:           response.data.results[i].categoria.id_categoria,
                        categoria:              response.data.results[i].categoria.categoria,
                        tipo_lider_id:          response.data.results[i].tipo_lider.id_tipo_lider,
                        tipo_lider:             response.data.results[i].tipo_lider.tipo_lider,
                        centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
                        persona_id:             response.data.results[i].persona.id_persona,
                        nombres:                response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre=='null'?response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre:response.data.results[i].persona.primer_nombre,
                        apellidos:              response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido=='null'?response.data.persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido:response.data.results[i].persona.primer_apellido,
                        correo:                 response.data.results[i].persona.correo,
                        telefono_movil:         response.data.results[i].persona.telefono_movil
                    })
                }
                context.commit("enviarLideresCategoria", lideresCategoria)
            })
            .catch(() => {})
        }
    }
}


export default lideres
