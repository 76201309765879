<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>

    <!-- boton atras -->
    <v-row>
      <v-col
        sm="1"
        lg="2"
        class="hidden-xs-only"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              fixed
              exact
              color="primary"
              v-bind="attrs" 
              v-on="on" 
              @click="regresar"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
      </v-col>

      <!-- formulario -->
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Actualizar Competencia
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-select
                    outlined
                    dense
                    label="Tipo de competencia*"
                    :items="tiposCompetencia"
                    item-value="id"
                    item-text="tipo_competencia"
                    v-model="tipoCompetencia"
                    :rules="tipoCompetenciaReglas"
                  >
                  </v-select>

                  <v-text-field 
                    outlined 
                    dense 
                    type="text" 
                    label="Competencia*" 
                    maxlength="200" 
                    counter
                    v-model="competencia" 
                    :rules="competenciaReglas"
                  >
                  </v-text-field>

                  <v-dialog 
                    ref="fechaInicio" 
                    persistent
                    width="300px"
                    v-model="dialogFechaInicio" 
                    :return-value.sync="fechaInicio"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        outlined 
                        dense 
                        readonly
                        prepend-inner-icon="mdi-calendar" 
                        label="Fecha de inicio*" 
                        v-model="fechaInicio" 
                        :rules="fechaInicioReglas" 
                        v-bind="attrs" 
                        v-on="on"
                      >
                      </v-text-field>
                    </template>

                    <v-date-picker 
                      scrollable 
                      locale="es" 
                      v-model="fechaInicio"
                    >
                      <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        color="error" 
                        @click="dialogFechaInicio = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn 
                        text 
                        color="primary" 
                        @click="validarFechaCompetencia($refs.fechaInicio.save(fechaInicio))"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

                  <v-dialog 
                    ref="fechaFin" 
                    persistent
                    width="300px"
                    v-model="dialogFechaFin" 
                    :return-value.sync="fechaFin" 
                  >
                    <template 
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field 
                        outlined 
                        dense 
                        readonly
                        prepend-inner-icon="mdi-calendar" 
                        label="Fecha de finalización*" 
                        v-model="fechaFin" 
                        :rules="fechaFinReglas" 
                        v-bind="attrs" 
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    
                    <v-date-picker 
                      scrollable 
                      locale="es" 
                      v-model="fechaFin"
                    >
                      <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        color="error" 
                        @click="dialogFechaFin = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn 
                        text 
                        color="primary" 
                        @click="validarFechaCompetencia($refs.fechaFin.save(fechaFin))"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

                  <v-textarea 
                    outlined 
                    rows="4" 
                    label="Descripción" 
                    v-model="descripcion"
                  >
                  </v-textarea>

                  <!-- <v-text-field
                    outlined 
                    dense 
                    type="text" 
                    label="Lugar de Competencia*" 
                    maxlength="100" 
                    counter 
                    v-model="lugar"
                    :rules="lugarReglas"
                  >
                  </v-text-field> -->

                  <!-- <v-text-field 
                    outlined 
                    dense 
                    type="text" 
                    label="Dirección*" 
                    maxlength="50" 
                    counter 
                    v-model="direccion"
                    :rules="direccionReglas"
                  >
                  </v-text-field> -->

                  <v-btn 
                    large 
                    block 
                    exact 
                    elevation="2" 
                    color="primary" 
                    class="mt-2 mb-6" 
                    @click="actualizarCompetencia"
                  >
                    Actualizar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Alert from '@/components/admin/Alert.vue'
  
export default {
  data: () => ({
    alert: false,
    titleAlert: null,
    messageAlert: null,

    titleCard: null,
    buttonCard: null,

    loadingForm: false,
    form: true,

    centroFormacion: null,

    tipoCompetencia: null,
    tipoCompetenciaReglas: [
      (v) => !!v || "Seleccione un tipo de competencia"
    ],

    competencia: null,
    competenciaReglas: [
      (v) => !!v || "Escriba un nombre para la competencia"
    ],

    dialogFechaInicio: false,
    fechaInicio: null,
    fechaInicioReglas: [
      (v) => !!v || "Seleccione una fecha de inicio"
    ],

    dialogFechaFin: false,
    fechaFin: null,
    fechaFinReglas: [
      (v) => !!v || "Seleccione una fecha de finalización"
    ],

    descripcion: null,

    // lugar: null,
    // lugarReglas: [
    //   (v) => !!v || "Escriba un lugar"
    // ],

    // direccion: null,
    // direccionReglas: [
    //   (v) => !!v || "Escriba una dirección"
    // ]
  }),

  components: {
    "app-alert": Alert 
  },

  mounted() {
    this.$store.dispatch("crearTiposCompetencia");
    this.$store.dispatch("editarCompetenciaWorldSkills", this.$route.params.id_competencia)
    .then((response) => {
      this.tipoCompetencia  = response.tipo_competencia_id
      this.competencia      = response.competencia
      this.fechaInicio      = response.fecha_inicio
      this.fechaFin         = response.fecha_fin
      this.descripcion      = response.descripcion
      this.lugar            = response.lugar
      this.direccion        = response.direccion
    })      
    .catch(() => {})
  },

  computed: {
    tiposCompetencia() {
      return this.$store.getters.obtenerTipoCompetencias.filter(competencia => competencia.id != 1);
    },

    usuario() {
      return this.$store.getters.usuario
    }
  },

  methods: {
    actualizarCompetencia() {
      if (this.$refs.form.validate()) {
        this.loadingForm = true
        const competencia = {
          id_competencia:       this.$route.params.id_competencia, 
          centro_formacion_id:  this.usuario.centro_formacion_id,
          tipo_competencia_id:  this.tipoCompetencia,
          competencia:          this.competencia[0].toUpperCase() + this.competencia.toLowerCase().substring(1),
          fecha_inicio:         this.fechaInicio,
          fecha_fin:            this.fechaFin,
          descripcion:          this.descripcion
        }

        this.$store.dispatch("actualizarCompetenciaWorldSkills", competencia)
        .then((response) => {
          if (response.status == "success") {
            let message = response.message.split(',')
            this.loadingForm = false
            this.alert = true
            this.titleAlert = message[0]
            this.messageAlert = message[1]
            this.titleCard = "success--text"
            this.buttonCard = "success"

            setTimeout(() => {
              this.$router.push({
                name: "competenciaws-gestionar"
              })
            }, 1000)
          }
          else {
            this.loadingForm = false
            this.alert = true
            this.titleAlert = "Error"
            this.titleCard = "error--text"
            this.buttonCard = "error"
            for (const error in response.message) {
              this.messageAlert = response.message[error][0]
            }
          }

          setTimeout(() => {
            this.alert = false
          }, 2000)
        })
        .catch(() => {})
      }
    },

    validarFechaCompetencia() {
      if(this.fechaInicio != null && this.fechaFin != null) {
        let fechaInicioCompetencia = new Date(this.fechaInicio)
        let fechaFinCompetencia = new Date(this.fechaFin)
        if(fechaFinCompetencia <= fechaInicioCompetencia) {
          this.loadingForm = false
          this.fechaInicio = null
          this.modalFechaInicio = false
          this.fechaFin = null
          this.modalFechaFin = false
          this.alert = true
          this.titleAlert = "Error"
          this.messageAlert = "La fecha de finalización es menor a la fecha de inicio"
          this.titleCard = "error--text"
          this.buttonCard = "error"

          setTimeout(() => {
            this.alert = false
          }, 2000)
        }
      }
    },

    regresar() {
      this.$router.push({
        name: "competenciaws-gestionar" 
      })
    }
  }
}
</script>