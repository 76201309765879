<template>
  <div>
    <v-row>
      <v-col
        sm="1"
        lg="2"
        class="hidden-xs-only"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              fixed
              exact
              color="primary"
              v-bind="attrs" 
              v-on="on" 
              @click="regresar"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
      </v-col>
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Detalles Competencia
            </p> 
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Competencia
                </p>
                <p class="text-subtitle-1">
                  {{ competencia.competencia }}
                </p>     
              </v-col>

              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Tipo de Competencia
                </p>
                <p class="text-subtitle-1">
                  {{ competencia.tipo_competencia }}
                </p>
              </v-col>

              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Fecha de Inicio
                </p>
                <p class="text-subtitle-1">
                  {{ competencia.fecha_inicio }}
                </p>
              </v-col>

              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Fecha de Finalización
                </p>
                <p class="text-subtitle-1">
                  {{ competencia.fecha_fin }}
                </p>
              </v-col>

              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Descripción
                </p>
                <template v-if="competencia.descripcion != null">
                  <p>
                    {{ competencia.descripcion }}
                  </p>
                </template>
                <template v-else>
                  <p class="text-subtitle-1">
                    No registrada
                  </p>
                </template>
              </v-col>

              <template v-if="(competencia.tipo_competencia_id == 2)">
                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <p class="text-h6 font-weight-bold">
                    Sedes
                  </p>
                  <p 
                    v-for="sede in competencia.sedes" 
                    :key="sede.sede"
                    class="text-subtitle-1"
                  >
                    {{ sede.sede }}
                  </p>
                </v-col>

                <v-col 
                  cols="12" 
                  sm="10" 
                  lg="8"
                >
                  <div 
                    v-for="documento in documentos" 
                    :key="documento.id"
                  >
                    <p class="text-h6 font-weight-bold">
                      {{ documento.nombre_documento }}
                    </p>
                    <v-btn
                      color="primary"
                      class="white--text mb-4"
                      @click="downloadeDocumentos(documento.nombre_documento, documento.competencia_id)"
                    >
                      Descargar
                      <v-icon right dark>mdi-cloud-download</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    alert: false,
    titleAlert: null,
    messageAlert: null,

    titleCard: null,
    buttonCard: null
  }),

  mounted() {
    this.$store.dispatch("detalleCompetenciaWorldSkills", this.$route.params.id_competencia)
    this.$store.dispatch("gestionarDocumentosCompetencia", this.$route.params.id_competencia)
  },
  
  computed: {
    competencia() {
      return this.$store.getters.obtenerCompetenciaWorldSkills
    },

    documentos() {
      return this.$store.getters.obtenerDocumentosCompetencias
    }
  },

  methods: {
    regresar() {
      this.$router.push({
        name: 'competenciaws-gestionar'
      }); 
    },

    downloadeDocumentos(nombredocumento) {
      this.$store.dispatch("downloadeCompetencia", {
        competencia_id: this.$route.params.id_competencia,
        nombre_documento: nombredocumento
      })
      .then((response) => {
        const urlReporte = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href= urlReporte
        link.setAttribute('download',nombredocumento+'.pdf')
        document.body.appendChild(link)
        link.click();
      })
      .catch(() => {
        this.alert        = true;
        this.titleAlert   = "Error";
        this.messageAlert = "El documento "+nombredocumento+" no tiene documento registrado";
        this.titleCard    = "error--text";
        this.buttonCard   = "error";
      })
      setTimeout(() => {
        this.alert = false;
      }, 1000);
    }
  }
};
</script>