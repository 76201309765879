<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          small
          fixed
          exact
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="regresar"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
      </template>
      <span>Regresar</span>
    </v-tooltip>
    <v-row justify="center">
      <v-col cols="12" sm="10" lg="8">
        <v-card outlined elevation="2" :loading="loadingForm">
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Actualizar Sede
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" sm="10" lg="8">
                <v-form ref="form" v-model="form">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Sede*"
                    maxlength="100"
                    counter
                    autofocus
                    v-model="sede"
                    :rules="sedeRules"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Lugar*"
                    maxlength="100"
                    counter
                    v-model="lugar"
                    :rules="lugarRules"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Dirección*"
                    maxlength="50"
                    counter
                    v-model="direccion"
                    :rules="direccionRules"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Responsable*"
                    maxlength="100"
                    counter
                    v-model="responsable"
                    :rules="responsableRules"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Teléfono*"
                    v-model="telefono"
                    maxlength="10"
                    counter
                    :rules="telefonoRules"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Correo Electrónico*"
                    maxlength="50"
                    counter
                    v-model="correo"
                    :rules="correoRules"
                  >
                  </v-text-field>

                  <v-btn
                    block
                    large
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="actualizar"
                  >
                    Actualizar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
  
  
<script>
import Alert from "@/components/admin/Alert.vue";

export default {
  data: () => ({
    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    loadingForm: false,
    form: true,

    sede: null,
    sedeRules: [(v) => !!v || "Escriba una sede"],

    lugar: null,
    lugarRules: [(v) => !!v || "Escriba un lugar"],

    direccion: null,
    direccionRules: [(v) => !!v || "Escriba una direccion"],

    responsable: null,
    responsableRules: [(v) => !!v || "Escriba un responsable"],

    telefono: null,
    telefonoRules: [(v) => !!v || "Escriba un telefono"],

    correo: null,
    correoRules: [
      (v) => !!v || "Escriba un correo",
      (v) => /.+@.+\..+/.test(v) || "Correo electrónico no válido",
    ],
  }),

  mounted() {
    this.$store
      .dispatch("editarsede", this.$route.params.id_sede)
      .then((response) => {
        this.sede = response.sede;
        this.lugar = response.lugar;
        this.direccion = response.direccion;
        this.responsable = response.responsable;
        this.telefono = response.telefono_responsable;
        this.correo = response.correo_responsable;
      })
      .catch(() => {});
  },

  components: {
    "app-alert": Alert,
  },

  methods: {
    components: {
      "app-alert": Alert,
    },

    actualizar() {
      if (this.$refs.form.validate()) {
        const sede = {
          id_sede: this.$route.params.id_sede,
          sede: this.sede,
          lugar: this.lugar,
          direccion: this.direccion,
          responsable: this.responsable,
          telefono_responsable: this.telefono,
          correo_responsable: this.correo,
        };
        this.loadingForm = true;

        this.$store
          .dispatch("actualizarSede", sede)
          .then((response) => {
            if (response.status == "success") {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Actualizada";
              this.messageAlert = response.message;
              this.titleCard = "success--text";
              this.buttonCard = "success";
              this.$store.dispatch("gestionarSedes", this.$route.params.id_competencia);
              setTimeout(() => {
                this.loaderTable = false
            }, 1000);
            } 
            else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = response.message.sede[0];
              this.titleCard = "error--text";
              this.buttonCard = "error";
            }

            setTimeout(() => {
              this.alert = false;
            }, 1000);
          })
          .catch(() => {});
      }
    },

    regresar() {
        window.history.back()
    },
  },
};
</script>
  