<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list dense nav>
        <v-subheader class="title primary--text my-2">SIGEF</v-subheader>
        <v-divider></v-divider>
        <!-- 1 nivel -->
        <div v-for="moduloPadre in modulos" :key="moduloPadre.id_modulo">
          <template v-if="moduloPadre.ruta == '#'">
            <v-list-group :prepend-icon="moduloPadre.icono">
              <template v-slot:activator>
                <v-list-item-title
                  v-text="moduloPadre.modulo"
                  class="font-weight-bold text-subtitle-2 text-left"
                >
                </v-list-item-title>
              </template>

              <!-- 2 nivel -->
              <div
                v-for="moduloHijo in moduloPadre.hijos"
                :key="moduloHijo.id_modulo"
              >
                <template v-if="moduloHijo.ruta == '#'">
                  <v-list-group
                    no-action
                    sub-group
                    prepend-icon="mdi-chevron-down"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="moduloHijo.modulo"
                          class="text-subtitle-2 text-left"
                        >
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <!-- 3 nivel -->
                    <v-list-item
                      v-for="item in moduloHijo.hijos"
                      :key="item.id_modulo"
                      :to="{ name: item.ruta }"
                      exact
                      @click="clearSearch"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.modulo"
                          class="text-subtitle-2 text-left"
                        >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </template>

                <template v-else>
                  <v-list-item-group color="primary">
                    <v-list-item :to="{ name: moduloHijo.ruta }" exact>
                      <v-list-item-icon class="mr-4">
                        <v-icon class="ml-4" v-text="moduloHijo.icono">
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="moduloHijo.modulo"
                          class="text-subtitle-2"
                        >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </template>
              </div>
            </v-list-group>
          </template>

          <template v-else>
            <v-list-item-group color="primary">
              <v-list-item :to="{ name: moduloPadre.ruta }" exact>
                <v-list-item-icon>
                  <v-icon v-text="moduloPadre.icono"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="moduloPadre.modulo"
                    class="font-weight-bold text-subtitle-2 text-left"
                  >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </template>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="primary"
        class="hidden-lg-and-up"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="primary" v-bind="attrs" v-on="on">
            {{ usuario.nombres }}
            <v-icon large color="primary" class="ml-2">
              mdi-account-circle
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="this.usuario.perfil_id == 1">
            <v-list-item-title>
              <v-btn text exact color="primary" @click="perfil">
                Mi Perfil
              </v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="this.usuario.perfil_id != 1">
            <v-list-item-title>
              <v-btn text exact color="primary" @click="perfilWs">
                Mi Perfil
              </v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-btn exact text color="primary" @click="cerrarSesion">
                Cerrar Sesión
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-4">
      <v-container fluid class="mt-4">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
  }),

  mounted() {
    this.$store.dispatch("crearModulosUsuario", this.usuario.id_usuario);
    this.$store.dispatch("crearCentrosFormacion")
  },

  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },

    modulos() {
      return this.$store.getters.obtenerModulosUsuario;
    },
  },

  methods: {
    clearSearch() {
      this.$store.dispatch("clearSearchB");
    },
    perfil() {
      this.$router.push({
        name: "usuario-perfil",
        params: { id_usuario: this.usuario.id_usuario },
      });
    },

    perfilWs() {
      this.$router.push({
        name: "usuariows-perfil",
        params: { id_usuario: this.usuario.id_usuario },
      });
    },

    async cerrarSesion() {
      await this.$router.push({ name: "inicio" }).then(() => {
        this.$store.dispatch("cerrarSesion").catch(() => {
        });
      });
    },
  },
};
</script>
