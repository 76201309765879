import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import {routes} from './routes'
import {store} from './data/store'
import axios from 'axios'

Vue.config.productionTip = false

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: ''
})

axios.defaults.baseURL = "https://sigef.cloudsenactpi.net/"
// axios.defaults.baseURL = "http://localhost/sigef/public"
axios.defaults.withCredentials = true

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
      if (store.getters.auth) {
        const userRole = store.getters.role
        if (to.meta.roles.includes(userRole)) {
          next()
        } else {
          next({ name: 'tablero' })
        }
      } else {
        next({ name: 'inicio-sesion' })
      }
    } 
 else {
    next()
  }
})