import axios from 'axios'

const participantes = {
  state: () => ({
    participantes: [],
    participante: "",
    participantesCentroFormacion: [],
    participantesWorldskills: []
  }),

  getters: {
    obtenerParticipantes(state) {
      return state.participantes
    },

    obtenerParticipante(state) {
      return state.participante
    },

    obtenerParticipantesCentroFormacion(state) {
      return state.participantesCentroFormacion
    },

    obtenerParticipantesWorldskills: (state) => (competencia, habilidad) => {
      return state.participantesWorldskills.filter(participante => participante.competencia_id == competencia && participante.categoria_id == habilidad)
    },

    obtenerParticipanteWorldskills: (state) => (id) => {
      return state.participantesWorldskills.find(participante => participante.id == id)
    }
  },

  mutations: {
    enviarParticipantes(state, participantes) {
      state.participantes = participantes
    },

    enviarParticipante(state, participante) {
      state.participante = participante
    },

    enviarParticipantesCentroFormacion(state, participantesCentroFormacion) {
      state.participantesCentroFormacion = participantesCentroFormacion
    },

    enviarParticipantesWorldskills(state, participantesWorldskills) {
      state.participantesWorldskills = participantesWorldskills
    }
  },

  actions: {
    registrarParticipante(context, participante) {
      return new Promise((resolve, reject) => {
        axios.post("api/participantes", participante)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },

    gestionarParticipantes(context) {
      axios.get("api/participantes")
      .then((response) => {
        const participantes = []
        for (let i = 0; i < response.data.results.length; i++) {
          participantes.push({
            indice: (i+1),
            id:                     response.data.results[i].id_participante,
            regional_id:            response.data.results[i].centro_formacion.regional.id_regional,
            regional:               response.data.results[i].centro_formacion.regional.regional,
            centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
            centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
            programa_formacion_id:  response.data.results[i].programa_formacion.id_programa_formacion,
            programa_formacion:     response.data.results[i].programa_formacion.programa_formacion,
            categoria_id:           response.data.results[i].categoria.id_categoria,
            categoria:              response.data.results[i].categoria.categoria,
            persona_id:             response.data.results[i].persona.id_persona,
            nombres:                response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre,
            apellidos:              response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido,
            correo:                 response.data.results[i].persona.correo,
            telefono_movil:         response.data.results[i].persona.telefono_movil,
            competencia_id:         response.data.results[i].competencia.id_competencia,
            competencia:            response.data.results[i].competencia.competencia
          })
        }
        context.commit("enviarParticipantes", participantes)
      })
      .catch(() => {})
    },

    detallesParticipante(context, participante) {
      axios.get("api/participantes/"+participante)
      .then((response) => {
        const participante = {
          id:                     response.data.results.id_participante,
          persona_id:             response.data.results.persona.id_persona,
          identificacion:         response.data.results.persona.identificacion,
          nombres:                response.data.results.persona.primer_nombre+" "+response.data.results.persona.segundo_nombre,
          apellidos:              response.data.results.persona.primer_apellido+" "+response.data.results.persona.segundo_apellido,
          correo:                 response.data.results.persona.correo,
          telefono_movil:         response.data.results.persona.telefono_movil,
          fecha_nacimiento:       response.data.results.persona.fecha_nacimiento,
          genero:                 response.data.results.persona.genero.genero,
          programa_formacion_id:  response.data.results.programa_formacion.id_programa_formacion,
          programa_formacion:     response.data.results.programa_formacion.programa_formacion,
          numero_ficha:           response.data.results.numero_ficha,
          talla_camiseta:         response.data.results.talla_camiseta,
          centro_formacion_id:    response.data.results.centro_formacion.id_centro_formacion,
          centro_formacion:       response.data.results.centro_formacion.centro_formacion,
          regional_id:            response.data.results.centro_formacion.regional.id_regional,
          regional:               response.data.results.centro_formacion.regional.regional,
          categoria_id:           response.data.results.categoria.id_categoria,
          categoria:              response.data.results.categoria.categoria,
          competencia_id:         response.data.results.competencia.id_competencia,
          competencia:            response.data.results.competencia.competencia
        }
        context.commit("enviarParticipante", participante)
      })
      .catch(() => {})
    },

    editarParticipante(context,participante){
      return new Promise((resolve,reject)=>{
        axios.get("api/participantes/"+participante+"/editar")
        .then((response)=>{
          resolve(response.data.results)
        })
        .catch(()=>{
          reject()
        })
      })
    },

    actualizarParticipante(context, participante){
      return new Promise((resolve, reject) => {
        axios.put("api/participantes/actualizar/"+ participante.id_participante,participante)
        .then((response) =>{
          resolve(response.data)
        })
        .catch(()=>{
          reject()
        })
      })
    },

    gestionarParticipantesCentroFormacion(context, participante) {
      axios.get("api/participantes/"+participante.competencia_id+"/"+participante.categoria_id+"/"+participante.centro_formacion_id)
      .then((response) => {
        const participantesCentroFormacion = []
        for (let i = 0; i < response.data.results.length; i++) {
          participantesCentroFormacion.push({
            indice: (i+1),
            id_participante:        response.data.results[i].id_participante,
            centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
            centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
            programa_formacion_id:  response.data.results[i].programa_formacion.id_programa_formacion,
            programa_formacion:     response.data.results[i].programa_formacion.programa_formacion,
            categoria_id:           response.data.results[i].categoria.id_categoria,
            categoria:              response.data.results[i].categoria.categoria,
            persona_id:             response.data.results[i].persona.id_persona,
            nombres:                response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre,
            apellidos:              response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido,
            correo:                 response.data.results[i].persona.correo,
            telefono_movil:         response.data.results[i].persona.telefono_movil,
            competencia_id:         response.data.results[i].competencia.id_competencia,
            competencia:            response.data.results[i].competencia.competencia
          })
        }
        context.commit("enviarParticipantesCentroFormacion", participantesCentroFormacion)
      })
      .catch(() => {})
    },

    //revisar
    gestionarParticipantesCompetencia(context, id_competencia) {
      axios.get("api/participantes/competencia/"+id_competencia)
      .then((response) => {
        const participantes = []
        for (let i = 0; i < response.data.results.length; i++) {
          participantes.push({
            indice: (i+1),
            id:                     response.data.results[i].id_participante,
            regional_id:            response.data.results[i].centro_formacion,
            regional:               response.data.results[i].centro_formacion.regional.regional,
            // centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
            // centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
            // programa_formacion_id:  response.data.results[i].programa_formacion.id_programa_formacion,
            // programa_formacion:     response.data.results[i].programa_formacion.programa_formacion,
            // categoria_id:           response.data.results[i].categoria.id_categoria,
            categoria:              response.data.results[i].categoria.categoria,
            // persona_id:             response.data.results[i].persona.id_persona,
            nombres:                response.data.results[i].persona.primer_nombre+" "+response.data.results[i].persona.segundo_nombre,
            apellidos:              response.data.results[i].persona.primer_apellido+" "+response.data.results[i].persona.segundo_apellido,
            correo:                 response.data.results[i].persona.correo,
            // telefono_movil:         response.data.results[i].persona.telefono_movil,
            // competencia_id:         response.data.results[i].competencia.id_competencia,
            // competencia:            response.data.results[i].competencia.competencia
          })
        }
        context.commit("enviarParticipantes", participantes)
      })
      .catch(() => {})
    },

    eliminarParticipante(context, participante){
      return new Promise((resolve, reject) => {
        axios.delete("api/participantes/eliminar/"+participante.persona_id+"/"+participante.competencia_id)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },


    // Worldskills
    registrarParticipanteWorldskills(context, participante) {
      return new Promise((resolve, reject) => {
        axios.post("api/participantesWorldskills", participante)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },

    gestionarParticipantesWorldskills(context) {
      axios.get("api/participantesWorldskills")
      .then((response) => {
        const participantesWorldskills = []
        response.data.results.forEach((response) => {
          participantesWorldskills.push({
            id:                     response.id_participante,
            competencia_id:         response.id_competencia,
            competencia:            response.competencia,
            categoria_id:           response.id_categoria,
            categoria:              response.categoria,
            centro_formacion_id:    response.id_centro_formacion,
            centro_formacion:       response.centro_formacion,
            regional_id:            response.id_regional,
            regional:               response.regional,
            programa_formacion_id:  response.id_programa_formacion,
            programa_formacion:     response.programa_formacion,
            numero_ficha:           response.numero_ficha,
            talla_camiseta:         response.talla_camiseta,
            persona_id:             response.id_persona,
            identificacion:         response.identificacion,
            nombres:                response.primer_nombre+" "+response.segundo_nombre,
            apellidos:              response.primer_apellido+" "+response.segundo_apellido,
            telefono_movil:         response.telefono_movil,
            correo:                 response.correo,
            fecha_nacimiento:       response.fecha_nacimiento,
            genero:                 response.genero
          })
        })
        context.commit("enviarParticipantesWorldskills", participantesWorldskills)
      })
      .catch(() => {})
    },

    actualizarParticipanteWorldskills(context, participante) {
      return new Promise((resolve, reject) => {
        axios.put("api/participantesWorldskills/"+ participante.id, participante)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },

    eliminarParticipantesWorldSkills(context, participante) {
      return new Promise((resolve, reject) => {
        axios.delete("api/participantesWorldskills/"+participante)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },

    participantesRegistradosNacional() {
      return new Promise((resolve, reject) => {
        axios.post("api/reportes/GanadoresWorldSkills", { responseType: 'blob' })
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },

    adjudicarParticipantesWorldSkills(context, competencia) {
      return new Promise((resolve, reject) => {
        axios.post("api/participantesWorldskillsAdjudicar", competencia)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    }
  }
}

export default participantes