<template>
  <div>
    <!-- alerta -->
    <v-dialog max-width="390" v-model="alertParticipante">
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            No se encuentra a la persona
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          Deseas registrarla?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="mb-2 white--text"
            @click="registroPersona"
          >
            Si
          </v-btn>
          <v-btn
            color="error"
            class="mb-2 white--text"
            @click="alertParticipante = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog 
      max-width="280" 
      v-model="alert"
    >
      <v-card>
        <v-card-title class="justify-center">
          <p 
            class="text-h5 font-weight-bold" 
            :class="titleCard"
          >
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn  
            :color="buttonCard" 
            class="mb-2 white--text"
            @click="alert = false"
          > 
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    <!-- tabla de datos -->
    <v-row justify="center">
      <v-col cols="12">
        <router-view></router-view>
        <v-card>
          <v-card-title class="text-h5 font-weight-bold primary--text">
            <v-row align="center">
              <v-col 
                cols="2"
                md="1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      exact
                      color="primary"
                      v-bind="attrs" 
                      v-on="on" 
                      @click="regresar"
                    >
                      <v-icon>
                        mdi-arrow-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar</span>
                </v-tooltip>
              </v-col>
              <v-col 
                cols="10"
                md="5"
              >
                {{ detalleCupoCategoria.categoria }}
              </v-col>
              
              <v-col 
                cols="12"
                md="3"
                align="end"
              >
                <v-text-field
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  label="Buscar Participante"
                  hide-details
                  v-model="searchTable"
                >
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
                align="end"
              >
                <template v-if="cuposCategoriaDisponibles == 0">
                  <v-btn
                    disabled
                    exact
                    color="primary"
                  >
                    Registrar Participante
                  </v-btn> 
                </template>

                <template v-else>
                  <v-btn
                    exact
                    color="primary"
                    @click="open"
                  >
                    Registrar Participante
                  </v-btn> 
                </template>  
              </v-col>

              <v-col 
                cols="6"
                md="2"
                offset-md="1"
              >
                <p class="text-subtitle-1 font-weight-bold primary--text">
                  Cupos Asignados: {{ detalleCupoCategoria.numero_participantes }}
                </p>
              </v-col>

              <v-col
                cols="6"
                md="2"
              >
                <template v-if="cuposCategoriaDisponibles == 0">
                  <p class="text-subtitle-1 font-weight-bold ml-8 error--text">
                    Disponibles: {{ cuposCategoriaDisponibles }}
                  </p>
                </template>

                <template v-else>
                  <p class="text-subtitle-1 font-weight-bold ml-8 success--text">
                    Disponibles: {{ cuposCategoriaDisponibles }}
                  </p>
                </template>
              </v-col>
              
            </v-row>
          </v-card-title>

          <v-data-table
            fixed-header
            height="460px"
            class="elevation-2"
            :loading="loadingTable"
            :headers="headersTable"
            :items="participantes"
            :search="searchTable"
            no-data-text="No hay participantes registrados"
            :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1] }"
          >
            <template v-slot:top>
              <v-dialog 
                persistent
                scrollable
                max-width="520px"
                v-model="dialogForm" 
              >
                <v-card :loading="loadingForm">
                  <v-card-title class="justify-center">
                    <p class="text-h5 font-weight-bold primary--text">{{ formTitle }}</p>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row justify="center">
                        <v-col 
                          cols="12"
                          md="10"
                        >
                          <v-form 
                            ref="form" 
                            v-model="form"
                          >
                            <v-text-field
                              outlined
                              dense
                              disabled
                              type="text"
                              label="Competencia"
                              v-model="competenciaSeleccionada"
                            >
                            </v-text-field>

                            <v-text-field
                              outlined
                              dense
                              disabled
                              type="text"
                              label="Categoria"
                              v-model="categoriaSeleccionada"
                            >
                            </v-text-field>

                            <v-autocomplete 
                              outlined 
                              small-chips 
                              label="Programa de Formación*"
                              :items="programasFormacion"
                              item-value="id"
                              item-text="programa_formacion"
                              v-model="programaFormacion"
                              :rules="programaFormacionReglas"
                            >
                            </v-autocomplete >

                            <v-text-field
                              outlined
                              dense
                              type="number"
                              maxlength="10"
                              counter
                              label="Número de Ficha"
                              :rules="numeroFichaReglas"
                              v-model="numeroFicha"
                            >
                            </v-text-field>

                            <v-text-field
                              outlined
                              dense
                              append-icon="mdi-magnify"
                              type="number"
                              label="Número de Identificación"
                              v-model="identificacion"
                              :rules="identificacionReglas"
                              :loading="loadingTextField"
                              :error-messages="mensajeIdentificacion"
                              @keydown.enter="persona(identificacion)"
                              @click:append="persona(identificacion)"
                            >
                            </v-text-field> 

                            <template v-if="validarPersona == true">
                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Nombres"
                                v-model="nombres"
                              >
                              </v-text-field>

                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Apellidos"
                                v-model="apellidos"
                              >
                              </v-text-field>

                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Fecha de Nacimiento"
                                v-model="fecha_nacimiento"
                              >
                              </v-text-field>

                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Genero"
                                v-model="genero"
                              >
                              </v-text-field>

                              <v-select
                                outlined
                                dense
                                label="Talla de Camiseta*"
                                :items="tallas"
                                v-model="talla"
                                :rules="tallaReglas"
                              >
                              </v-select>
                            </template>

                          </v-form>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                      text 
                      color="error" 
                      @click="close"
                    >
                      Cancelar
                    </v-btn>
            
                    <v-btn 
                      text 
                      exact
                      color="primary" 
                      @click="registrar"
                    > 
                      Registrar 
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="primary"
                    v-bind="attrs" 
                    v-on="on"
                    @click="detalles(item.id_participante)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Detalles</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="primary"
                    class="ml-2"
                    v-bind="attrs" 
                    v-on="on"
                    @click="actualizar(item.id_participante)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Actualizar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="error"
                    class="ml-2"
                    v-bind="attrs" 
                    v-on="on"
                    @click="activarAlertaEliminar(item.persona_id, item.competencia_id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">Desea eliminar el cupo del participante ?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="red darken-1" text @click="eliminar(temPersona_id,temCompetencia_id)">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    loadingTable: true,
    searchTable:  null,
    loadingTextField: false,

    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    loadingForm:  false,
    form:         true,

    dialogForm: false,
    dialogDelete: false,

    competenciaSeleccionada: "",
    categoriaSeleccionada: "",

    alertParticipante:  false,


    programaFormacion: null,
    programaFormacionReglas: [
      v => !!v || "Seleccione un programa de formación"
    ],

    numeroFicha: null,
    numeroFichaReglas: [
      v => !!v || "Escriba un número de ficha",
      v => v > 0 || "No puede ser un número menor a 0",
      v => !v || v.length <= 10 || "La cantidad de numeros debe ser menor a 10",
    ],

    categoria: null,
    categoriaReglas: [
      v => !!v || "Seleccione una categoria"
    ],

    identificacion: null,
    identificacionReglas: [
      v => !!v || "Escriba un número de identificación"
    ],
    mensajeIdentificacion: "",

    validarPersona: false,
    idPersona: null,
    nombres: null,
    apellidos: null,
    fecha_nacimiento: null,
    genero: null,
    talla: null,
    tallas: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    tallaReglas: [(v) => !!v || "Seleccione una talla"],
    mensajetalla: "",

    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Nombres",
        value: "nombres",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Apellidos",
        value: "apellidos",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Correo Electrónico",
        value: "correo",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Programa de Formación",
        value: "programa_formacion",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Categoria",
        value: "categoria",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },
  
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      }
    ],

    editedIndex: -1,
    temPersona_id:null,
    temCompetencia_id:null
  }),

  created () {
    const categoriaCompetencia = {
      competencia_id: this.$route.params.id_competencia,
      categoria_id: this.$route.params.id_categoria,
      centro_formacion_id: this.usuario.centro_formacion_id
    }
    this.$store.dispatch("detalleCupoCentroFormacion", categoriaCompetencia)

    this.$store.dispatch("crearProgramasFormacion")

    this.$store.dispatch("gestionarParticipantesCentroFormacion", categoriaCompetencia);

    setTimeout(() => {
      this.loadingTable = false;
    }, 1000);
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Registrar Participante' : 'Actualizar Participante'
    },

    detallesCategoria() {
      return this.$store.getters.obtenerCategoriaCompetencia;
    },

    detalleCupoCategoria() {
      return this.$store.getters.obtenerCupoCentroFormacion;
    },

    usuario() {
      return this.$store.getters.usuario;
    },

    competencias() {
      return this.$store.getters.obtenerCompetencias;
    },

    programasFormacion() {
      return this.$store.getters.obtenerProgramasFormacion
    },

    cupoCategorias() {
      return this.$store.getters.obtenerCuposCentroFormacion;
    },

    participantes() {
      return this.$store.getters.obtenerParticipantesCentroFormacion;
    },

    cuposCategoriaDisponibles() {
      let cuposCategoria = this.detalleCupoCategoria.numero_participantes

      let cupoDisponible = cuposCategoria-this.participantes.length

      return cupoDisponible
    }

  },

  watch: {
    dialogForm(val) {
      val || this.close()
    },

    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  methods: {
    open() {
      this.dialogForm = true
      this.competenciaSeleccionada = this.detalleCupoCategoria.competencia
      this.categoriaSeleccionada = this.detalleCupoCategoria.categoria
    },

    close() {
      this.dialogForm = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs.form.reset();
      this.validarPersona = false
      this.mensajeIdentificacion = ""
    },

    persona(identificacion) {
      this.$store.dispatch("crearPersonas", identificacion)
      .then((response) => {
        this.loadingTextField = true
        if (response != null) {
          this.mensajeIdentificacion = ""
          setTimeout(() => {
            const segundoNombre = response.segundo_nombre != null ? response.segundo_nombre : '' 
            const segundoApellido = response.segundo_apellido != null ? response.segundo_apellido : ''

            this.idPersona = response.id_persona
            this.nombres   = response.primer_nombre+" "+segundoNombre
            this.apellidos = response.primer_apellido+" "+segundoApellido
            this.fecha_nacimiento = response.fecha_nacimiento
            this.genero = response.genero.genero

            this.loadingTextField = false
          }, 1000)
          this.validarPersona = true
        }
        else {
          this.mensajeIdentificacion = "Número de identificación no encontrada"
          setTimeout(() => {
            this.loadingTextField = false
          }, 1000)
          this.validarPersona = false
          this.alertParticipante=true
        }
      })
      .catch(() => {});
    },

    alertaCupos() {
      if (this.cuposCategoriaDisponibles == 0) {
        this.alert        = true;
        this.titleAlert   = "Información";
        this.messageAlert = "La categoria "+ this.detalleCupoCategoria.categoria + " ya no tiene cupos para registrar";
        this.titleCard    = "info--text";
        this.buttonCard   = "info";   
        
        setTimeout(() => {
          this.alert = false;
        }, 1000);
      }
    },

    registrar() {
      if (this.$refs.form.validate()) {
        if (this.validarPersona) {
          const participante = {
              competencia_id:         this.$route.params.id_competencia,
              categoria_id:           this.$route.params.id_categoria,
              centro_formacion_id:    this.cupoCategorias[0].centro_formacion_id,
              programa_formacion_id:  this.programaFormacion,
              numero_ficha:           this.numeroFicha,
              persona_id:             this.idPersona,
              talla_camiseta:         this.talla
          }

          this.loadingForm = true;

          this.$store.dispatch("registrarParticipante", participante)
          .then((response) => {
            if (response.status == "success") {
              this.loadingForm  = false;
              this.close();
              this.alert        = true;
              this.titleAlert   = "Registrado";
              this.messageAlert = response.message;
              this.titleCard    = "success--text";
              this.buttonCard   = "success";

              const categoriaCompetencia = {
                competencia_id: this.$route.params.id_competencia,
                categoria_id: this.$route.params.id_categoria,
                centro_formacion_id: this.usuario.centro_formacion_id
              }

              this.$store.dispatch("gestionarParticipantesCentroFormacion", categoriaCompetencia);
              setTimeout(() => {
                this.loadingTable = false;
                this.alertaCupos()
              }, 1000);
            } 
            else {
              this.loadingForm  = false;
              this.alert        = true;
              this.titleAlert   = "Error";
              this.messageAlert = response.message.participante;
              this.titleCard    = "error--text";
              this.buttonCard   = "error";
            }

            setTimeout(() => {
              this.alert = false;
            }, 1000);
          })
          .catch(() => {
          });
        }
        else {
          this.alert        = true;
          this.titleAlert   = "Error";
          this.messageAlert = "Número de identificación no encontrada";
          this.titleCard    = "error--text";
          this.buttonCard   = "error";
          setTimeout(() => {
            this.alert = false;
          }, 1000);
        }
      }
    },

    detalles(idParticipante) {
      this.$router.push({
        name: "participantews-detalles",
        params: { id_participante: idParticipante }
      });
    },

    activarAlertaEliminar(persona_id, competencia_id){
      this.temPersona_id   = persona_id
      this.temCompetencia_id = competencia_id
      this.dialogDelete = true
    },

    eliminar(idPersona, idCompetencia) {
      this.dialogDelete = false
      const participante = {
        persona_id:     idPersona,      
        competencia_id: idCompetencia
      }
      this.$store.dispatch("eliminarParticipante", participante).then((response) => {
        if (response.status == "success") {
          this.alert        = true;
          this.titleAlert   = "Eliminado";
          this.messageAlert = response.message;
          this.titleCard    = "success--text";
          this.buttonCard   = "success";   

          const categoriaCompetencia = {
            competencia_id: this.$route.params.id_competencia,
            categoria_id: this.$route.params.id_categoria,
            centro_formacion_id: this.usuario.centro_formacion_id
          }
          
          this.$store.dispatch("gestionarParticipantesCentroFormacion", categoriaCompetencia);
          setTimeout(() => {
            this.loadingTable = false;
          }, 1000);
        } 
        else {
          this.alert        = true;
          this.titleAlert   = "Error";
          this.messageAlert = response.message.participante;
          this.titleCard    = "error--text";
          this.buttonCard   = "error";
        }

        setTimeout(() => {
          this.alert = false;
        }, 1000);
      })
      .catch(() => {});
    },

    closeDelete () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    regresar() {
      this.$router.push({
        name: "cupo-asignado"
      });
    },
    actualizar(idPersona) {
      this.$router.push({
        name: "participante-actualizar",
        params: { id_participante: idPersona }
      });
    },
    registroPersona(){
      this.$router.push({
        name: "personas-registrar",
        params:{experto:'0'}
      })
    },
  },
};
</script>