<template>
  <div>
    <!-- alerta -->
    <v-dialog 
      max-width="340" 
      v-model="alert"
    >
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :color="buttonCard"
            class="mb-2 white--text"
            @click="alert = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- alerta persona -->
    <v-dialog 
      max-width="380" 
      v-model="alertParticipante"
    >
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            No se encuentra a la persona
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          ¿Desea registrarla?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="mb-2 white--text"
            @click="registroPersona"
          >
            Si
          </v-btn>
          <v-btn
            color="error"
            class="mb-2 white--text"
            @click="alertParticipante = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cargador tabla -->
    <template v-if="loaderTable">
      <v-skeleton-loader 
        class="mx-auto" 
        type="table"
      > 
      </v-skeleton-loader>
    </template>
    <template v-else>
      <!-- tabla -->
      <v-row justify="center">
        <v-col cols="12">
          <router-view></router-view>
          <v-card>
            <!-- lider regional -->
            <template v-if="usuario.perfil_id == 5">
              <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          exact
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>

                  <v-col 
                    cols="10" 
                    sm="9"
                  >
                    Acompañantes: {{ competenciaWorldSkills.competencia }}
                  </v-col>

                  <v-col 
                    cols="12" 
                    sm="2"
                    align="end"
                  >  
                    <v-btn
                      exact
                      color="primary"
                      @click="open"
                    >
                      Registrar
                    </v-btn>  
                  </v-col>

                  <template v-if="acompanantesWorldSkillsRegional.length > 0">
                    <v-col 
                      cols="12"
                      sm="4"
                      md="3"
                      offset-sm="8"
                      offset-md="9"
                    >
                      <v-text-field
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="Buscar Acompañante"
                        hide-details
                        v-model="searchTable"
                      >
                      </v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>

              <v-data-table
                fixed-header
                height="480px"
                class="elevation-2"
                :headers="headersTable"
                :items="acompanantesWorldSkillsRegional"
                :search="searchTable"
                no-data-text="No hay acompañantes registrados"
                :footer-props="{ 'items-per-page-options': [10, 20, -1] }"
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="detalles(item.id, item.competencia_id)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Detalles</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="editar(item.id)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Actualizar</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="activarAlertaEliminar(item.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>

                <template v-slot:top>
                  <v-dialog 
                    persistent 
                    v-model="dialogForm" 
                    max-width="520px"
                  >
                    <v-card :loading="loadingForm">
                      <v-card-title class="justify-center">
                        <p class="text-h5 font-weight-bold primary--text">
                          {{ formTitle }}
                        </p>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row justify="center">
                            <v-col 
                              cols="12" 
                              md="10"
                            >
                              <v-form 
                                ref="form" 
                                v-model="form"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Competencia"
                                  v-model="competenciaSeleccionada"
                                >
                                </v-text-field>

                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Regional"
                                  v-model="regionalUsuario"
                                >
                                </v-text-field>

                                <v-select
                                  outlined
                                  dense
                                  label="Centro de Formación*"
                                  :items="centrosFormacionRegional"
                                  item-value="id"
                                  item-text="centro_formacion"
                                  v-model="centroFormacion"
                                  :rules="centroFormacionReglas"
                                >
                                </v-select>

                                <v-select
                                  outlined
                                  dense
                                  label="Rol de Acompañante*"
                                  :items="tiposAcompanante"
                                  item-value="id"
                                  item-text="tipo_acompanante"
                                  v-model="tipoAcompanante"
                                  :rules="tipoAcompananteReglas"
                                >
                                </v-select>

                                <v-text-field
                                  outlined
                                  dense
                                  :disabled="disabledForm"
                                  append-icon="mdi-magnify"
                                  type="number"
                                  label="Número de Identificación"
                                  v-model="identificacion"
                                  :rules="identificacionReglas"
                                  :loading="loadingTextField"
                                  :error-messages="mensajeIdentificacion"
                                  @keydown.enter="persona(identificacion)"
                                  @click:append="persona(identificacion)"
                                >
                                </v-text-field>

                                <template v-if="validarPersona == true">
                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Nombres"
                                    v-model="nombres"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Apellidos"
                                    v-model="apellidos"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Fecha de Nacimiento"
                                    v-model="fecha_nacimiento"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Genero"
                                    v-model="genero"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    type="text"
                                    label="Cargo*"
                                    maxlength="100"
                                    v-model="cargo"
                                    :rules="cargoReglas"
                                  >
                                  </v-text-field>

                                  <v-select
                                    outlined
                                    dense
                                    label="Talla de Camiseta*"
                                    :items="tallas"
                                    v-model="talla"
                                    :rules="tallaReglas"
                                  >
                                  </v-select>
                                </template>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="error" @click="close">
                          Cancelar
                        </v-btn>

                        <template v-if="editedIndex === -1">
                          <v-btn text exact color="primary" @click="registrar">
                            Registrar
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-btn text exact color="primary" @click="actualizar">
                            Actualizar
                          </v-btn>
                        </template>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogDelete" max-width="480px">
                    <v-card>
                      <v-card-title class="justify-center text-h5">
                        ¿Desea eliminar el acompañante?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          color="primary" 
                          @click="closeDelete"
                        >
                          Cancelar
                        </v-btn>

                        <v-btn 
                          text 
                          color="error" 
                          @click="eliminar(id_acompanantes)"
                        > 
                          OK 
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
            </template>

            <!-- experto nacional - lider nacional -->
            <template v-if="usuario.perfil_id == 4 || usuario.perfil_id == 3 || usuario.perfil_id == 1">
              <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          exact
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>

                  <v-col 
                    cols="10" 
                    sm="11" 
                    md="8"
                  >
                    Acompañantes: {{ competenciaWorldSkills.competencia }}
                  </v-col>

                  <v-col 
                    cols="12" 
                    sm="8" 
                    md="3"
                    align="end"
                  >  
                  <template v-if="competenciaWorldSkills.tipo_competencia_id != 3">
                    <v-btn
                      exact
                      color="primary"
                      class="mr-4"
                      @click="adjudicarAcompanante"
                    >
                      Adjudicar
                    </v-btn> 
                  </template> 

                    <v-btn
                      exact
                      color="primary"
                      @click="open"
                    >
                      Registrar
                    </v-btn>  
                  </v-col>

                  <template v-if="acompanantesWorldSkillsNacional.length > 0">
                    <v-col 
                      cols="12"
                      sm="4"
                      md="3"
                      offset-md="9"
                    >
                      <v-text-field
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="Buscar Acompañante"
                        hide-details
                        v-model="searchTable"
                      >
                      </v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>

              <v-data-table
                fixed-header
                height="480px"
                class="elevation-2"
                :headers="headersTable"
                :items="acompanantesWorldSkillsNacional"
                :search="searchTable"
                no-data-text="No hay acompañantes registrados"
                :footer-props="{ 'items-per-page-options': [20, 50, -1] }"
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="detalles(item.id, item.competencia_id)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Detalles</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="editar(item.id)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Actualizar</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="activarAlertaEliminar(item.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>

                <template v-slot:top>
                  <v-dialog 
                    persistent 
                    v-model="dialogForm" 
                    max-width="520px"
                  >
                    <v-card :loading="loadingForm">
                      <v-card-title class="justify-center">
                        <p class="text-h5 font-weight-bold primary--text">
                          {{ formTitle }}
                        </p>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row justify="center">
                            <v-col 
                              cols="12" 
                              md="10"
                            >
                              <v-form 
                                ref="form" 
                                v-model="form"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Competencia"
                                  v-model="competenciaSeleccionada"
                                >
                                </v-text-field>

                                <v-select
                                  outlined
                                  dense
                                  label="Regional*"
                                  :items="regionales"
                                  item-value="id"
                                  item-text="regional"
                                  v-model="regional"
                                  :rules="regionalReglas"
                                >
                                </v-select>

                                <v-select
                                  outlined
                                  dense
                                  label="Centro de Formación*"
                                  :items="centrosFormacionNacional"
                                  item-value="id"
                                  item-text="centro_formacion"
                                  v-model="centroFormacion"
                                  :rules="centroFormacionReglas"
                                >
                                </v-select>

                                <v-select
                                  outlined
                                  dense
                                  label="Rol de Acompañante*"
                                  :items="tiposAcompanante"
                                  item-value="id"
                                  item-text="tipo_acompanante"
                                  v-model="tipoAcompanante"
                                  :rules="tipoAcompananteReglas"
                                >
                                </v-select>

                                <v-text-field
                                  outlined
                                  dense
                                  :disabled="disabledForm"
                                  append-icon="mdi-magnify"
                                  type="number"
                                  label="Número de Identificación"
                                  v-model="identificacion"
                                  :rules="identificacionReglas"
                                  :loading="loadingTextField"
                                  :error-messages="mensajeIdentificacion"
                                  @keydown.enter="persona(identificacion)"
                                  @click:append="persona(identificacion)"
                                >
                                </v-text-field>

                                <template v-if="validarPersona == true">
                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Nombres"
                                    v-model="nombres"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Apellidos"
                                    v-model="apellidos"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Fecha de Nacimiento"
                                    v-model="fecha_nacimiento"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Genero"
                                    v-model="genero"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    type="text"
                                    label="Cargo*"
                                    maxlength="100"
                                    v-model="cargo"
                                    :rules="cargoReglas"
                                  >
                                  </v-text-field>

                                  <v-select
                                    outlined
                                    dense
                                    label="Talla de Camiseta*"
                                    :items="tallas"
                                    v-model="talla"
                                    :rules="tallaReglas"
                                  >
                                  </v-select>
                                </template>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="error" @click="close">
                          Cancelar
                        </v-btn>

                        <template v-if="editedIndex === -1">
                          <v-btn text exact color="primary" @click="registrar">
                            Registrar
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-btn text exact color="primary" @click="actualizar">
                            Actualizar
                          </v-btn>
                        </template>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogDelete" max-width="480px">
                    <v-card>
                      <v-card-title class="justify-center text-h5">
                        ¿Desea eliminar el acompañante?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          color="primary" 
                          @click="closeDelete"
                        >
                          Cancelar
                        </v-btn>

                        <v-btn 
                          text 
                          color="error" 
                          @click="eliminar(id_acompanantes)"
                        > 
                          OK 
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,

    loaderTable: true,
    searchTable: null,
    loadingTextField: false,
    
    loadingForm: false,
    disabledForm: false,
    form: true,
    dialogForm: false,

    alertParticipante: false,
    dialogDelete: false,
    id_acompanantes: null,
    id_acompanante: null,
    competenciaSeleccionada: "",
    regionalUsuario: null,

    regional: null,
    regionalReglas: [
      (v) => !!v || "Seleccione una regional"
    ],   

    centroFormacion: null,
    centroFormacionReglas: [
      (v) => !!v || "Seleccione un centro de formación"
    ],

    tipoAcompanante: null,
    tipoAcompananteReglas: [
      (v) => !!v || "Seleccione un rol de acompañante"
    ],

    identificacion: null,
    identificacionReglas: [
      (v) => !!v || "Escriba un número de identificación"
    ],
    mensajeIdentificacion: "",

    cargo: null,
    cargoReglas: [
      (v) => !!v || "Escriba un cargo"
    ],

    talla: null,
    tallas: ["XS", "S", "M", "L", "XL", "XXL"],
    tallaReglas: [
      (v) => !!v || "Seleccione una talla"
    ],

    validarPersona: false,
    idPersona: null,
    nombres: null,
    apellidos: null,
    fecha_nacimiento: null,
    genero: null,

    headersTable: [
      {
        text: "Nombres",
        value: "nombres",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Apellidos",
        value: "apellidos",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Rol de Acompañante",
        value: "tipo_acompanante",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Regional",
        value: "regional",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Centro de Formacion",
        value: "centro_formacion",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ],

    editedIndex: -1,
  }),

  mounted() {
    this.$store.dispatch("crearRegionales");
    this.$store.dispatch("crearCentrosFormacion");
    this.$store.dispatch("crearTipoAcompanante");
    this.$store.dispatch("gestionarAcompanante");
    this.$store.dispatch("detalleCompetenciaWorldSkills", this.$route.params.id_competencia);
    
    setTimeout(() => {
      this.loaderTable = false;
    }, 1000);
  },

  watch: {
    dialogForm(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },

    centroFormacionUsuario() {
      return this.$store.getters.obtenerCentroFormacion(this.usuario.centro_formacion_id)
    },

    acompanantesWorldSkillsRegional() {
      const acompanantesWorldSkills = this.$store.getters.obtenerAcompanantes(this.$route.params.id_competencia)
      const acompanantes = acompanantesWorldSkills.filter(acompanante => acompanante.regional_id == this.centroFormacionUsuario.regional_id)
      return acompanantes
    },

    acompanantesWorldSkillsNacional() {
      return this.$store.getters.obtenerAcompanantes(this.$route.params.id_competencia)
    },

    formTitle() {
      return this.editedIndex === -1 ? "Registrar Acompañante" : "Actualizar Acompañante";
    },

    competenciaWorldSkills() {
      return this.$store.getters.obtenerCompetenciaWorldSkills
    },

    tiposAcompanante() {
      return this.$store.getters.obtenerTipoAcompanante;
    },
    
    regionales() {
      return this.$store.getters.obtenerRegionales
    },
 
    centrosFormacionRegional() {
      return this.$store.getters.obtenerCentrosFormacion(this.centroFormacionUsuario.regional_id)
    },

    centrosFormacionNacional() {
      return this.$store.getters.obtenerCentrosFormacion(this.regional)
    }
  },

  methods: {
    open() {
      this.dialogForm = true;
      this.competenciaSeleccionada = this.competenciaWorldSkills.competencia;
      this.regionalUsuario = this.centroFormacionUsuario.regional;
    },

    deleteItemConfirm() {
      this.dialogDelete = false;
    },

    close() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.validarPersona = false;
      });
      this.disabledForm = false
      this.$refs.form.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    persona(identificacion) {
      this.$store.dispatch("crearPersonas", identificacion)
      .then((response) => {
        this.loadingTextField = true;
        if (response != null) {
          this.mensajeIdentificacion = "";
          setTimeout(() => {
            this.idPersona        = response.id_persona;
            this.nombres          = response.primer_nombre + " " + response.segundo_nombre;
            this.apellidos        = response.primer_apellido + " " + response.segundo_apellido;
            this.fecha_nacimiento = response.fecha_nacimiento;
            this.genero           = response.genero.genero;

            this.loadingTextField = false;
          }, 1000);
          this.validarPersona = true;
        } 
        else {
          this.mensajeIdentificacion = "Número de identificación no encontrado";
          setTimeout(() => {
            this.loadingTextField = false;
          }, 1000)

          this.validarPersona = false;
          this.alertParticipante = true;
        }
      })
      .catch(() => {});
    },

    registrar() {
      if(this.$refs.form.validate()) {
        if(this.validarPersona) {
          const acompanante = {
            competencia_id:       this.$route.params.id_competencia,
            tipo_acompanante_id:  this.tipoAcompanante,
            centro_formacion_id:  this.centroFormacion,
            persona_id:           this.idPersona,
            cargo:                this.cargo,
            talla_camiseta:       this.talla
          };

          this.loadingForm = true;
          this.$store.dispatch("registrarAcompanante", acompanante)
          .then((response) => {
            if (response.status == "success") {
              let message = response.message.split(',')
              this.$refs.form.reset();
              this.loadingForm = false;
              this.close();
              this.alert = true;
              this.titleAlert = message[0];
              this.messageAlert = message[1];
              this.titleCard = "success--text";
              this.buttonCard = "success";
              this.loaderTable = true;

              this.$store.dispatch("gestionarAcompanante");
              setTimeout(() => {
                this.loaderTable = false;
              }, 1000);
            } 
            else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              for (const error in response.message) {
                this.messageAlert = response.message[error][0]
              }
            }

            setTimeout(() => {
              this.alert = false;
            }, 2000);
          })
          .catch(() => {});
        } 
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = "Número de identificación no encontrado";
          this.titleCard = "error--text";
          this.buttonCard = "error";
          setTimeout(() => {
            this.alert = false;
          }, 2000);
          this.alertParticipante = true;
        }
      }
    },

    detalles(idAcompanante, competencia) {
      this.$router.push({
        name: "detalles-acompanante",
        params: { id_acompanante: idAcompanante, id_competencia: competencia },
      });
    },

    editar(id) {
      this.dialogForm = true;
      this.disabledForm = true;
      this.editedIndex = 1;
      this.competenciaSeleccionada = this.competenciaWorldSkills.competencia;

      this.$store.dispatch("editarAcompanante", id)
      .then((response) => {
        this.id_acompanante   = response.id_acompanante;
        this.regionalUsuario  = this.centroFormacionUsuario.regional;
        this.regional         = response.centro_formacion.regional_id
        this.centroFormacion  = response.centro_formacion_id;
        this.tipoAcompanante  = response.tipo_acompanante_id;
        this.persona_id       = response.persona_id;
        this.identificacion   = response.persona.identificacion;
        this.nombres          = response.persona.primer_nombre+" "+response.persona.segundo_nombre;
        this.apellidos        = response.persona.primer_apellido+" "+response.persona.segundo_apellido;
        this.fecha_nacimiento = response.persona.fecha_nacimiento;
        this.genero           = response.persona.genero.genero;
        this.cargo            = response.cargo;
        this.talla            = response.talla_camiseta;
        this.validarPersona   = true;
      })
      .catch(() => {});
    },

    actualizar() {
      if (this.$refs.form.validate()) {
        if (this.validarPersona) {
          const acompanante = {
            id:                   this.id_acompanante,
            competencia_id:       this.$route.params.id_competencia,
            tipo_acompanante_id:  this.tipoAcompanante,
            centro_formacion_id:  this.centroFormacion,
            persona_id:           this.persona_id,
            cargo:                this.cargo,
            talla_camiseta:       this.talla
          };

          this.loadingForm = true;
          this.$store.dispatch("actualizarAcompanante", acompanante)
          .then((response) => {
            if(response.status == "success") {
              let message = response.message.split(',')
              this.$refs.form.reset();
              this.loadingForm = false;
              this.close();
              this.alert = true;
              this.titleAlert = message[0];
              this.messageAlert = message[1];
              this.titleCard = "success--text";
              this.buttonCard = "success";
              this.loaderTable = true;

              this.$store.dispatch("gestionarAcompanante");
              setTimeout(() => {
                this.loaderTable = false;
              }, 1000);
            } 
            else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              for (const error in response.message) {
                this.messageAlert = response.message[error][0]
              }
            }

            setTimeout(() => {
              this.alert = false;
            }, 2000);
          })
          .catch(() => {});
        } 
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.alertParticipante = true;
          this.messageAlert = "Número de identificación no encontrado";
          this.titleCard = "error--text";
          this.buttonCard = "error";

          setTimeout(() => {
            this.alert = false;
          }, 2000);
        }
      }
    },

    activarAlertaEliminar(idAcompanante) {
      this.id_acompanantes = idAcompanante;
      this.dialogDelete = true;
    },

    eliminar() {
      this.closeDelete()
      this.$store.dispatch("eliminarAcompanante", this.id_acompanantes)
      .then((response) => {
        if (response.status == "success") {
          let message = response.message.split(',')
          this.alert = true;
          this.titleAlert = message[0];
          this.messageAlert = message[1];
          this.titleCard = "success--text";
          this.buttonCard = "success";
          this.loaderTable = true;

          this.$store.dispatch("gestionarAcompanante");
          setTimeout(() => {
            this.loaderTable = false;
          }, 1000);
        } 
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.titleCard = "error--text";
          this.buttonCard = "error";
          for (const error in response.message) {
            this.messageAlert = response.message[error][0]
          }
        }

        setTimeout(() => {
          this.alert = false;
        }, 2000);
      })
      .catch(() => {});
    },

    adjudicarAcompanante() {
      const competencia = {
        competencia_id: this.$route.params.id_competencia
      }

      this.$store.dispatch("adjudicarAcompanantesWorldskills", competencia)
      .then((response) => {
        if(response.status == "success") {
          let message = response.message.split(',')
          this.alert = true;
          this.titleAlert = message[0];
          this.messageAlert = message[1];
          this.titleCard = "success--text";
          this.buttonCard = "success";
          this.loaderTable = true;
        } 
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = response.message;
          this.titleCard = "error--text";
          this.buttonCard = "error";
        }

        this.$store.dispatch("gestionarAcompanante");

        setTimeout(() => {
          this.alert = false;
          this.loaderTable = false;
        }, 1000);
      })
      .catch(() => {});
    },

    regresar() {
      this.$router.push({
        name: "competenciaws-gestionar",
      });
    },

    registroPersona() {
      this.$router.push({
        name: "personas-registrar",
        params: { experto: "0" },
      });
    },
  },
};
</script>
