<template>
    <div>
      <!-- alerta -->
      <app-alert :alert="alert" :titleAlert="titleAlert" :messageAlert="messageAlert" :titleCard="titleCard"
        :buttonCard="buttonCard">
      </app-alert>
  
      <!-- formulario -->
      <v-row justify="center">
        <v-col cols="12" sm="10" lg="8">
          <v-card outlined elevation="2" :loading="loadingForm">
            <v-card-title class="justify-center">
              <p class="text-h4 font-weight-bold primary--text mt-2">
                Importar Lideres
              </p>
            </v-card-title>
            <v-card-text>
              <v-row justify="center" align="center">
                <v-col cols="12" sm="10" lg="10">
                  <v-row justify="center">
                    <v-col cols="11">
                  <v-file-input outlined dense prepend-icon="" append-icon="mdi-paperclip" accept=".xlsx"
                    label="Importar archivo excel..." truncate-length="100" counter show-size v-model="usuarios">
                  </v-file-input>
                </v-col>
                  <v-col lg="1">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon medium color="primary" v-bind="attrs" v-on="on" @click="descargaPlantillaUsuarios()">
                        mdi-cloud-download
                      </v-icon>
                    </template>
                    <span>Descargar Plantilla</span>
                  </v-tooltip>
                </v-col>
                </v-row>
                  <v-btn block large exact elevation="2" color="primary" class="mt-2 mb-6" @click="importarExcel()">
                    Importar Archivo
                  </v-btn>
                </v-col>
                
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  
  
    </div>
  </template>
  <script>
  import Alert from '../../../components/admin/Alert.vue'
  //import axios from "axios";
  
  
  export default {
    data: () => ({
      alert: false,
      titleAlert: null,
      messageAlert: null,
      titleCard: null,
      buttonCard: null,
      loadingForm: false,
  
      res: null,
      usuarios: null,  
  
    }),
  
    created() {
  
    },
  
    computed: {
  
    },
  
    components: {
      "app-alert": Alert
    },
  
    methods: {
  
      importarExcel() {
        if (this.usuarios != null) {
          this.loadingForm = true;
  
          this.$store.dispatch("importarLideres", this.usuarios)
            .then((response) => {
              this.res = response.data;
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = response.action;
              this.messageAlert = response.message;
              this.titleCard = "success--text";
              this.buttonCard = "success";
            })
            .catch(() => {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = 'Datos no válidos...';
              this.titleCard = "error--text";
              this.buttonCard = "error";
            })
        } else {
          this.loadingForm = false;
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = "No ha seleccionado un archivo";
          this.titleCard = "error--text";
          this.buttonCard = "error";
        }
        setTimeout(() => {
          this.alert = false;
        }, 4000);
      },
  
      descargaPlantillaUsuarios () {
          this.$store.dispatch("downloaddocLider")
          .then((response) => {
            const urlReporte = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href= urlReporte
            // link.setAttribute('download',usuarios+'.xsl')
            link.setAttribute('download', 'PlantillaLideres.xlsx')
            document.body.appendChild(link)
            link.click();
          })
        setTimeout(() => {
          this.alert = false;
        }, 6000);
      }
    },
  };
  </script>