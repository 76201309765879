<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>

    <!-- formulario -->
    <v-row justify="center">
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Registrar Usuario
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-select
                    outlined
                    dense
                    label="Perfil*"
                    :items="perfilesws"
                    item-value="id"
                    item-text="perfil"
                    v-model="perfil"
                    :rules="perfilReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    label="Regional*"
                    :items="regionales"
                    item-value="id"
                    item-text="regional"
                    v-model="regional"
                    :rules="regionalReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    label="Centro de Formación*"
                    :items="centrosFormacion"
                    item-value="id"
                    item-text="centro_formacion"
                    v-model="centroFormacion"
                    :rules="centroFormacionReglas"
                  >
                  </v-select>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Número de Identificación*"
                    min="6"
                    max="10"
                    maxlength="10"
                    counter
                    v-model="identificacion"
                    :rules="identificacionReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Nombres*"
                    maxlength="50"
                    counter
                    v-model="nombres"
                    :rules="nombresReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Apellidos*"
                    maxlength="50"
                    counter
                    v-model="apellidos"
                    :rules="apellidosReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Teléfono*"
                    maxlength="10"
                    counter
                    v-model="telefono"
                    :rules="telefonoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Correo Electrónico*"
                    maxlength="50"
                    counter
                    v-model="correo"
                    :rules="correoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    disabled
                    type="text"
                    label="Contraseña"
                    v-model="identificacion"
                  >
                  </v-text-field>

                  <v-btn
                    block
                    large
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="registrar"
                  >
                    Registrar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Alert from '@/components/admin/Alert.vue'

export default {
  data: () => ({    
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    loadingForm:  false,
    form:         true,

    perfil: null,
    perfilReglas: [
      (v) => !!v || "Seleccione un perfil"
    ],

    regional: null,
    regionalReglas: [
      (v) => !!v || "Seleccione una regional"
    ],

    centroFormacion: null,
    centroFormacionReglas: [
      (v) => !!v || "Seleccione un centro de formación"
    ],

    identificacion: null,
    identificacionReglas: [
      (v) => !!v || "Escriba un número de identificación",
      (v) => (v && v.length <= 10) || "maximo 10 digitos",      
      (v) => (v && v.length >= 6) || "minimo 6 digitos"
    ],

    nombres: null,
    nombresReglas: [
      (v) => !!v || "Escriba nombres"
    ],

    apellidos: null,
    apellidosReglas: [
      (v) => !!v || "Escriba apellidos"
    ],

    telefono: null,
    telefonoReglas: [
      (v) => !!v || "Escriba un número de telefono",
      (v) => (v && v.length <= 10) || "maximo 10 digitos"
    ],

    correo: null,
    correoReglas: [
      v => !!v || "Escriba un correo electrónico",
      v => /.+@.+\..+/.test(v) || "Correo electrónico no válido"
    ],

    file: '',
    res: null
  }),

  mounted() {
    this.$store.dispatch("gestionarPerfilesWs")
    this.$store.dispatch("crearRegionales")
    this.$store.dispatch("crearCentrosFormacion")
  },

  computed: {
    perfilesws() {
      return this.$store.getters.obtenerPerfilesWs.filter(perfil => perfil.id > 2)
    },

    regionales() {
      return this.$store.getters.obtenerRegionales;
    },

    centrosFormacion() {
      return this.$store.getters.obtenerCentrosFormacion(this.regional);
    }
  },

  components: {
    "app-alert": Alert
  },

  methods: {
    persona(identificacion) {
      const persona   = this.$store.getters.obtenerPersona(identificacion);
      this.personaId  = persona.id
      this.nombres    = persona.primer_nombre+" "+persona.segundo_nombre
      this.apellidos  = persona.primer_apellido+" "+persona.segundo_apellido
      this.telefono   = persona.telefono_movil
      this.correo     = persona.correo
      this.password   = this.identificacion

      this.validarPersona = true
    },

    registrar() {
      if (this.$refs.form.validate()) {
        const usuariows = {
            perfil_id:              this.perfil,
            centro_formacion_id:    this.centroFormacion,
            identificacion:         this.identificacion,
            nombres:                this.nombres,
            apellidos:              this.apellidos,
            telefono:               this.telefono,
            correo:                 this.correo,
            password:               this.identificacion
        }

        this.loadingForm = true;

        this.$store.dispatch("registrarUsuarioWs", usuariows)
        .then((response) => {
          if (response.status == "success") {
            this.$refs.form.reset();
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Registrado";
            this.messageAlert = response.message;
            this.titleCard    = "success--text";
            this.buttonCard   = "success";
            setTimeout(() => (
              this.$router.push('/usuariows/gestionar')
            ), 1000)
          } 
          else {
            this.loadingForm  = false;  
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message.identificacion[0];
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }

          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {});
      }
    }
  }
};
</script>