<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>

    <!-- formulario -->
    <v-row justify="center">
      <v-col cols="12" sm="10" lg="8">
        <v-card outlined elevation="2" :loading="loadingForm">
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              {{titleType}} Persona 
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" sm="10" lg="8">
                <v-form ref="form" v-model="form">
                  <v-select
                    outlined
                    dense
                    label="Tipo de Identificación*"
                    :items="tipoIdentificacion"
                    item-value="id"
                    item-text="tipo_identificacion"
                    v-model="tipoIdentificacionId"
                    :rules="tipoIdentificacionReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    label="Tipo de Libreta Militar*"
                    :items="tipoLibretaMilitar"
                    item-value="id"
                    item-text="tipo_libreta_militar"
                    v-model="tipoLibretaMilitarId"
                    :rules="tipoLibretaMilitarReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    label="Género*"
                    :items="generos"
                    item-value="id"
                    item-text="genero"
                    v-model="generoId"
                    :rules="generoReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    label="Estado Civil*"
                    :items="estadosCiviles"
                    item-value="id"
                    item-text="estado_civil"
                    v-model="estadoCivilId"
                    :rules="estadoCivilReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    label="Zona*"
                    :items="zonas"
                    item-value="id"
                    item-text="zona"
                    v-model="zonaId"
                    :rules="zonaReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    label="Discapacidad*"
                    :items="discapacidades"
                    item-value="id"
                    item-text="discapacidad"
                    v-model="discapacidadId"
                    :rules="discapacidadReglas"
                  >
                  </v-select>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Número de Identificación*"
                    min="6"
                    max="10"
                    maxlength="10"
                    counter
                    v-model="identificacion"
                    :rules="identificacionReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Primer Nombre*"
                    maxlength="50"
                    counter
                    v-model="primerNombre"
                    :rules="primerNombreReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Segundo Nombre"
                    maxlength="50"
                    counter
                    v-model="segundoNombre"
                    :rules="segundoNombreReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Primer Apellido*"
                    maxlength="50"
                    counter
                    v-model="primerApellido"
                    :rules="primerApellidoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Segundo Apellido"
                    maxlength="50"
                    counter
                    v-model="segundoApellido"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Direccion*"
                    maxlength="50"
                    counter
                    v-model="direccion"
                    :rules="direccionReglas"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    type="date"
                    label="Fecha de Nacimiento*"
                    maxlength="50"
                    counter
                    v-model="fecha_nacimiento"
                    :rules="fecha_nacimientoReglas"
                  >
                  </v-text-field>
                  <v-select
                    outlined
                    dense
                    label="Estrato*"
                    :items="estrato"
                    item-value="id"
                    item-text="estrato"
                    v-model="estratoId"
                    :rules="estratoReglas"
                  >
                  </v-select>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Telefono Fijo*"
                    maxlength="50"
                    counter
                    v-model="telefonoFijo"
                    :rules="telefonoFijoReglas"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Telefono Movil*"
                    maxlength="50"
                    counter
                    v-model="telefonoMovil"
                    :rules="telefonoMovilReglas"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Correo*"
                    maxlength="50"
                    counter
                    v-model="correo"
                    :rules="correoReglas"
                  >
                  </v-text-field>
                  <v-btn
                    block
                    large
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6" 
                     @click="$route.name==='personas-editar' ? actualizar() : registrar()"
                     >
                    {{ titleType }}
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="10" lg="8">
              <v-card outlined dense elevation="2" :loading="loadingForm">
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Alert from "../../../components/admin/Alert.vue";

export default {
  data: () => ({
    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    loadingForm: true,
    form: true,
    identificacion: null,
    titleType:'Registrar',

    identificacionReglas: [
      (v) => !!v || "Escriba un número de identificación",
      (v) => (v && v.length <= 10) || "maximo 10 digitos",
      (v) => (v && v.length >= 6) || "minimo 6 digitos",
    ],

    correo: null,
    correoReglas: [
      (v) => !!v || "Escriba un correo electrónico",
      (v) => /.+@.+\..+/.test(v) || "Correo electrónico no válido",
    ],

    file: "",
    res: null,

    tipoIdentificacionId: null,
    tipoIdentificacionReglas: [
      (v) => !!v || "Seleccione un tipo de Identififcacion",
    ],

    tipoIdentificacion: [
      { tipo_identificacion: "T.I", id: 1 },
      { tipo_identificacion: "C.C", id: 2 },
    ],

    tipoLibretaMilitarId: null,
    tipoLibretaMilitarReglas: [
      (v) => !!v || "Seleccione un tipo de Libreta Militar",
    ],
    tipoLibretaMilitar: [
      { tipo_libreta_militar: "No tiene", id: -1 },
      { tipo_libreta_militar: "Libreta Primera Clase", id: 1 },
      { tipo_libreta_militar: "Libreta Segunda Clase", id: 2 },
      { tipo_libreta_militar: "Libreta Tercera Clase", id: 3 },
    ],

    generoId: null,
    generoReglas: [(v) => !!v || "Seleccione un tipo de Genero"],
    generos: [
      { genero: "Femenino", id: 1 },
      { genero: "Masculino", id: 2 },
    ],

    estadoCivilId: null,
    estadoCivilReglas: [(v) => !!v || "Seleccione un estado sivil"],
    estadosCiviles: [
      { estado_civil: "Viudo", id: 1 },
      { estado_civil: "Separado", id: 2 },
      { estado_civil: "Unión Libre", id: 3 },
      { estado_civil: "Casado", id: 4 },
      { estado_civil: "Soltero", id: 5 },
      { estado_civil: "Indiferente", id: 6 },
      // {estado_civil:'No Ingresado',id:7},
    ],

    zonaId: null,
    zonaReglas: [(v) => !!v || "Seleccione un tipo de zona"],
    zonas: [
      { zona: "Urbana", id: 1 },
      { zona: "Rural", id: 2 },
    ],

    discapacidadId: null,
    discapacidadReglas: [(v) => !!v || "Seleccione una opción"],
    discapacidades: [
      { discapacidad: "Ninguna", id: 1 },
      { discapacidad: "Cognitiva", id: 2 },
      { discapacidad: "Fisica", id: 3 },
    ],
    estratoId: null,
    estratoReglas: [(v) => !!v || "Seleccione una opción"],
    estrato: [
      { estrato: "1", id: 1 },
      { estrato: "2", id: 2 },
      { estrato: "3", id: 3 },
      { estrato: "4", id: 4 },
      { estrato: "5", id: 5 },
      { estrato: "6", id: 6 },
    ],

    primerNombre: null,
    primerNombreReglas: [(v) => !!v || "este campo es requerido"],

    segundoNombre: null,

    primerApellido: null,
    primerApellidoReglas: [(v) => !!v || "este campo es requerido"],

    segundoApellido: null,

    direccion: null,
    direccionReglas: [(v) => !!v || "este campo es requerido"],

    fecha_nacimiento: null,
    fecha_nacimientoReglas: [(v) => !!v || "este campo es requerido"],

    telefonoFijo: null,

    telefonoMovil: null,
    telefonoMovilReglas: [(v) => !!v || "este campo es requerido"],
  }),

  mounted() {
    if(this.$route.name === 'personas-editar') {
      this.$store.dispatch("detallePersona", this.$route.params.id).then(() => {
      this.dataPersona()
      this.loadingForm=false
      this.titleType="Actualizar"
    });
    }
    else {
      this.loadingForm=false
    }
    console.log(this.$route.name);
  },

  computed: {
    persona() {
      return this.$store.getters.obtenerPersona;
    },
  },

  components: {
    "app-alert": Alert,
  },

  methods: {
    dataPersona() {
      this.identificacion = this.persona.identificacion;
      this.tipoIdentificacionId = this.persona.tipo_identificacion_id;
      this.tipoLibretaMilitarId = this.persona.tipo_libreta_militar_id;
      this.generoId = this.persona.genero_id;
      this.estadoCivilId = this.persona.estado_civil_id;
      this.zonaId = this.persona.zona_id;
      this.discapacidadId = this.persona.discapacidad_id;
      this.estratoId = this.persona.estrato;
      this.primerNombre = this.persona.primer_nombre;
      this.segundoNombre = this.persona.segundo_nombre;
      this.primerApellido = this.persona.primer_apellido;
      this.segundoApellido = this.persona.segundo_apellido;
      this.direccion = this.persona.direccion;
      this.fecha_nacimiento = this.persona.fecha_nacimiento;
      this.telefonoFijo = this.persona.telefono_fijo;
      this.telefonoMovil = this.persona.telefono_movil;
      this.correo=this.persona.correo;
    },

    registrar() {
      if (this.$refs.form.validate()) {
        const persona = {
          tipo_identificacion_id: this.tipoIdentificacionId,
          tipo_libreta_militar_id: this.tipoLibretaMilitarId,
          genero_id: this.generoId,
          estado_civil_id: this.estadoCivilId,
          zona_id: this.zonaId,
          discapacidad_id: this.discapacidadId,
          identificacion: this.identificacion,
          primer_nombre: this.primerNombre,
          segundo_nombre: this.segundoNombre,
          primer_apellido: this.primerApellido,
          segundo_apellido: this.segundoApellido,
          direccion: this.direccion,
          fecha_nacimiento: this.fecha_nacimiento,
          estrato: this.estratoId,
          telefono_fijo: this.telefonoFijo,
          telefono_movil: this.telefonoMovil,
          correo: this.correo,
          perfil_id: 6,
        };

        this.loadingForm = true;

        this.$store
          .dispatch("registrarPersona", persona)
          .then((response) => {
            if (response.status == "success") {
              this.$refs.form.reset();
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Registrado";
              this.messageAlert = response.message;
              this.titleCard = "success--text";
              this.buttonCard = "success";
              setTimeout(() => this.$router.go(-1), 1000);
            } else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = response.message.identificacion.map((e)=>{return e});
              this.titleCard = "error--text";
              this.buttonCard = "error";
            }

            setTimeout(() => {
              this.alert = false;
            }, 1000);
          })
          .catch(() => {});
      }
    },

    actualizar(){
      if (this.$refs.form.validate()) {
        const persona = {
          id:this.$route.params.id,
          tipo_identificacion_id: this.tipoIdentificacionId,
          tipo_libreta_militar_id: this.tipoLibretaMilitarId,
          genero_id: this.generoId,
          estado_civil_id: this.estadoCivilId,
          zona_id: this.zonaId,
          discapacidad_id: this.discapacidadId,
          identificacion: this.identificacion,
          primer_nombre: this.primerNombre,
          segundo_nombre: this.segundoNombre,
          primer_apellido: this.primerApellido,
          segundo_apellido: this.segundoApellido,
          direccion: this.direccion,
          fecha_nacimiento: this.fecha_nacimiento,
          estrato: this.estratoId,
          telefono_fijo: this.telefonoFijo,
          telefono_movil: this.telefonoMovil,
          correo: this.correo,
        }
        this.loadingForm=true
        this.$store
          .dispatch("actulaizarPersona", persona) 
          .then((res) => {
            if (res.status == "success") {
              this.$refs.form.reset();
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Actualizado";
              this.messageAlert = res.message[0];
              this.titleCard = "success--text";
              this.buttonCard = "success";
              setTimeout(() => this.$router.go(-1), 1000);
            } else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = res.message[0];
              this.titleCard = "error--text";
              this.buttonCard = "error";
            }
            setTimeout(() => {
              this.alert = false;
            }, 1000);
          })
          .catch((err) => {console.log(err);});
      }
    },
    eliminar() {
      
    }
  },
};
</script>