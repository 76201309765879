<template>
  <div>
    <!-- alerta -->
    <v-dialog 
      max-width="320" 
      v-model="alert"
    >
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :color="buttonCard"
            class="mb-2 white--text"
            @click="alert = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="loaderTable">
      <v-skeleton-loader 
        class="mx-auto" 
        type="table"
      > 
      </v-skeleton-loader>
    </template>
    <template v-else>
      <!-- tabla -->
      <v-row justify="center">
        <v-col cols="12">
          <router-view></router-view>
          <v-card>
            <v-card-title class="text-h6 text-sm-h5 font-weight-bold primary--text">
              <v-row align="center">
                <v-col 
                  cols="2" 
                  sm="1"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        small
                        exact
                        color="primary"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="regresar"
                      >
                        <v-icon> mdi-arrow-left </v-icon>
                      </v-btn>
                    </template>
                    <span>Regresar</span>
                  </v-tooltip>
                </v-col>

                <v-col 
                  cols="10" 
                  sm="11" 
                  md="6"
                >
                  Documentos: {{ competenciaWorldSkills.competencia }}
                </v-col>

                <v-col 
                  cols="8" 
                  sm="10" 
                  md="3"
                >
                  <template v-if="documentos.length > 0">
                    <v-text-field
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      label="Buscar Documento"
                      hide-details
                      v-model="searchTable"
                    >
                    </v-text-field>
                  </template>
                </v-col>

                <v-col 
                  cols="4" 
                  sm="2"
                  align="end"
                >
                  <v-btn 
                    exact 
                    color="primary" 
                    @click="abrirDialog"
                  >
                    Registrar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table 
              fixed-header
              height="480px"
              class="elevation-2"
              :headers="headersTable"
              :items="documentos"
              :search="searchTable"
              no-data-text="No hay documentos registrados"
              :footer-props="{
                'items-per-page-options': [10, 50, -1],
              }"
            >
              <template v-slot:[`item.acciones`]="{ item }">  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="descargarDocumento(item.competencia_id, item.nombre_documento)"
                    >
                    mdi-cloud-download
                    </v-icon>
                  </template>
                  <span>Descargar Documento</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      @click="activarAlertaEliminar(item.competencia_id, item.id)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>

              <template v-slot:top>
                <v-dialog 
                  persistent 
                  v-model="dialogForm" 
                  max-width="520px"
                >
                  <v-card :loading="loadingForm">
                    <v-card-title class="justify-center">
                      <p class="text-h5 font-weight-bold primary--text">
                        {{ formTitle }}
                      </p>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col 
                            cols="12" 
                            md="10"
                          >
                            <v-form 
                              ref="form" 
                              v-model="form"
                            >    
                              <v-text-field
                                  outlined
                                  dense
                                  type="text"
                                  label="Nombre del documento"
                                  maxlength="50"
                                  counter
                                  autofocus
                                  v-model="nombreDocumento"
                                  :rules="nombreDocumentoReglas"
                              >
                              </v-text-field>

                              <v-file-input
                                  outlined
                                  dense
                                  prepend-icon=""
                                  append-icon="mdi-file-pdf-box"
                                  accept="application/pdf"
                                  label="Documento"
                                  truncate-length="100"
                                  counter
                                  show-size
                                  v-model="documento"
                                  :rules="documentoReglas"
                              >
                              </v-file-input>
                            </v-form>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        color="error" 
                        @click="cerrarDialog"
                      >
                        Cancelar
                      </v-btn>

                      <template v-if="editedIndex === -1">
                        <v-btn 
                          text 
                          exact 
                          color="primary" 
                          @click="registrar"
                        >
                          Registrar
                        </v-btn>
                      </template>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center text-h5">¿Desea eliminar el documento?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeDelete">Cancelar</v-btn>
          <v-btn
            text
            color="error"
            @click="eliminar(temCompetencia_id, temId_documento_competencia)"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script>
export default {
  data: () => ({
    loaderTable: true,
    searchTable: null,

    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    loadingForm: false,
    form: true,

    dialogForm: false,
    dialogDelete: false,

    temCompetencia_id: null,
    temId_documento_competencia: null,

    headersTable: [
      {
        text: "Nombre del documento",
        value: "nombre_documento",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      }
    ],  

    nombreDocumento: null,
    nombreDocumentoReglas: [
      (v) => !!v || "Escriba un nombre para el documento"
    ],

    documento: null,
    documentoReglas: [
      (v) => !!v || "Seleccione un documento",
      (v) => !v || v.size <= 10000000 || "El tamaño del documento debe ser menor de 10 MB"
    ],

    editedIndex: -1,
  }),

  mounted() {
    this.$store.dispatch("gestionarDocumentosCompetencia", this.$route.params.id_competencia);
    this.$store.dispatch("detalleCompetenciaWorldSkills", this.$route.params.id_competencia);
    
    setTimeout(() => {
      this.loaderTable = false
    }, 1000);
  },

  computed: {
    documentos() {
      return this.$store.getters.obtenerDocumentosCompetencias
    },

    competenciaWorldSkills() {
      return this.$store.getters.obtenerCompetenciaWorldSkills
    },

    formTitle() {
      return this.editedIndex === -1 ? "Registrar Documento" : "Actualizar Documento";
    }
  },

  watch: {
    dialog(val) {
      val || this.cerrarDialog();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  methods: {
    abrirDialog() {
      this.dialogForm = true;
    },

    deleteItem(item) {
      this.editedIndex = this.asociarProgramasFpi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.asociarProgramasFpi.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    cerrarDialog() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    registrar() {
      if (this.$refs.form.validate()) {
        const documento = new FormData();
        documento.set("competencia_id",   this.$route.params.id_competencia);  
        documento.set("nombre_documento", this.nombreDocumento[0].toUpperCase() + this.nombreDocumento.toLowerCase().substring(1));
        documento.set("documentos",       this.documento);

        this.$store.dispatch("registrarDocumentoCompetencia", documento)
        .then((response) => {
          if(response.status == "success") {
            this.loadingForm = false;
            this.cerrarDialog();
            this.alert = true;
            this.titleAlert   = "Registrado";
            this.messageAlert = response.message;
            this.titleCard    = "success--text";
            this.buttonCard   = "success";
            this.$store.dispatch("gestionarDocumentosCompetencia", this.$route.params.id_competencia);
          } 
          else {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message;
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }

          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {});
      }
    },

    activarAlertaEliminar(categoria_id, id_documento) {
      this.temCompetencia_id = categoria_id;
      this.temId_documento_competencia = id_documento;
      this.dialogDelete = true;
    },

    eliminar(competencia_id, idDocumento) {
      this.dialogDelete = false;
      const HabilidadDocumento = {
        competencia_id: competencia_id,
        id_documento_competencia: idDocumento,
      };
      
      this.$store.dispatch("eliminarDocumentoCompetencia", HabilidadDocumento)
        .then((response) => {
          if (response.status == "success") {
            this.alert        = true;
            this.titleAlert   = "Eliminado";
            this.messageAlert = response.message;
            this.titleCard    = "success--text";
            this.buttonCard   = "success";
            this.$store.dispatch("gestionarDocumentosCompetencia", this.$route.params.id_competencia);
          } 
          else {
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message;
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }
          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {});
    },

    descargarDocumento(idcompetencia, nombredocumento) {
      this.$store.dispatch("downloadeCompetencia", {
        competencia_id: idcompetencia,
        nombre_documento: nombredocumento
      })
      .then((response) => {
        const urlReporte = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href= urlReporte
        link.setAttribute('download',nombredocumento+'.pdf')
        document.body.appendChild(link)
        link.click();
      })
      .catch(() => {
        this.alert        = true;
        this.titleAlert   = "Error";
        this.messageAlert = "El documento "+nombredocumento+ " no tiene documento registrado";
        this.titleCard    = "error--text";
        this.buttonCard   = "error";
      })

      setTimeout(() => {
        this.alert = false;
      }, 1000);
    },

    regresar() {
      this.$router.push({
        name: "competenciaws-gestionar"
      })
    },
  }
};
</script>
    