<template>
  <div>
    <v-row>
      <v-col 
        sm="1" 
        lg="2" 
        class="hidden-xs-only"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              fixed
              exact
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="regresar"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
      </v-col>
      <v-col
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Detalles Habilidad
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Codigo de Habilidad
                </p>
                <p class="text-subtitle-1">
                  {{ habilidad.codigo_habilidad }}
                </p>
              </v-col>
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Habilidad
                </p>
                <p class="text-subtitle-1">
                  {{ habilidad.categoria }}
                </p>
              </v-col>

              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Programas de Formación
                </p>
                <template v-if="habilidad.programa_formacion_categoria != 0">
                  <p
                    v-for="programa_formacion_categoria in habilidad.programa_formacion_categoria" 
                    :key="programa_formacion_categoria.programa_formacion_id"
                    class="text-subtitle-1" 
                  >
                    {{ programa_formacion_categoria.programa_formacion.programa_formacion }}
                  </p>
                </template>
                <template v-else>
                  <p class="text-subtitle-1">
                    No registrados
                  </p>
                </template>
              </v-col>

              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <p class="text-h6 font-weight-bold">
                  Descripción
                </p>
                <div v-if="habilidad.descripcion != null">
                  <p class="text-subtitle-1">
                    {{ habilidad.descripcion }}
                  </p>
                </div>
                <div v-else>
                  <p class="text-subtitle-1">
                    No registrada
                  </p>
                </div>
              </v-col>

              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <div 
                  v-for="documento in documentos" 
                  :key="documento.id"
                >
                  <p class="text-subtitle-1">
                    {{ documento.nombre_documento }}
                  </p>
                  <v-btn
                    color="primary"
                    class="white--text mb-4"
                    @click="downloadeDocumentos(documento.nombre_documento)"
                  >
                    Descargar
                    <v-icon right dark>mdi-cloud-download</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),

  mounted() {
    this.$store.dispatch("detallesHabilidad", this.$route.params.id_habilidad)
    this.$store.dispatch("gestionarDocumentos", this.$route.params.id_habilidad)
  },

  computed: {
    habilidad() {
      return this.$store.getters.obtenerHabilidad
    },
    
    documentos() {
      return this.$store.getters.obtenerDocumentos
    }
  },

  methods: {
    regresar() {
      this.$router.go(-1)
    },

    downloadeDocumentos(nombredocumento) {
      this.$store.dispatch("downloadeHabilidad", {
        categoria_id: this.$route.params.id_habilidad,
        nombre_documento: nombredocumento
      })
      .then((response) => {
        const urlReporte = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href= urlReporte
        link.setAttribute('download',nombredocumento+'.pdf')
        document.body.appendChild(link)
        link.click();
      })
      .catch(() => {
        this.alert        = true;
          this.titleAlert   = "Error";
          this.messageAlert = "El documento "+nombredocumento+" no tiene documento registrado";
          this.titleCard    = "error--text";
          this.buttonCard   = "error";
      })
      setTimeout(() => {
        this.alert = false;
      }, 1000);
    }
  }
}
</script>