<template>
  <main>
    <v-card elevation="24" class="pt-3 overflow-y-auto overflow-x-hidden">
      <v-col cols="11" class="d-flex justify-end">
        <v-btn
          v-if="usuarios.perfil_id == 4"
          elevation="2"
          color="primary"
          class=""
          @click="Registrar()"
        >
          Registrar Foro
        </v-btn>
      </v-col>
      <template v-if="foros[0].mensaje == null">
        <v-card
          class="mx-auto mt-5 mb-3"
          color="#26c6da"
          dark
          max-width="1000"
          v-for="foro in foros"
          :key="(a = foro.id)"
          @click="irAMesagues(foro.id)"
        >
          <v-card-title>
            <v-icon large left> mdi-human-male-board </v-icon>
            <label class="text-h6 font-weight-light"
              ><span class="text-h5 font-weight-bold">Foro: </span>
              {{ foro.foro }}</label
            >
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold">
            <p class="text-h6 font-weight-bold">Descripcion:</p>
            {{ foro.descripcion }}
          </v-card-text>
          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >Experto Nacional:
                  <span class="font-weight-light">{{
                    foro.usuario
                  }}</span></v-list-item-title
                >
              </v-list-item-content>
              <!-- <v-row>
                <v-icon class="mr-1">mdi-message-text-outline</v-icon>
                <span class="subheading mr-2">{{
                  mensajesContador(foro.id)
                }}</span>
              </v-row> -->
            </v-list-item>
          </v-card-actions>
        </v-card>
      </template>
      <v-alert v-else border="right" colored-border type="error" elevation="2">
        {{ foros[0].mensaje }}
      </v-alert>
    </v-card>
  </main>
</template>
<script>
export default {
  data: () => ({
    varaibles: [],
    a: null,
  }),
  mounted() {
    this.$store.dispatch("gestionarForos", this.$route.params);
    // this.$store.dispatch("gestionarMensajes", this.foro.id);
  },
  computed: {
    foros() {
      return this.$store.getters.obtenerforos;
    },
    obtenermensajes() {
      return this.$store.getters.obtenermensajes;
    },
    usuarios() {
      return this.$store.getters.usuario;
    },
  },
  methods: {
    irAMesagues(id_foro) {
      return this.$router.push({
        name: "mensaje-foro",
        params: { id_forum: id_foro },
      });
    },
    mensajescontador(idForo) {
      const mensajesForo = this.obtenermensajes.filter(
        (mensaje) => mensaje.id_foro === idForo
      );
      console.log(mensajesForo.length);
    },
    Registrar() {
      return this.$router.push({
        name: "registrar-forows",
        params: { id_competencia: this.$route.params.id_competencia },
      });
    },
  },
};
</script>