<template>
  <div>
    <!-- alerta -->
    <app-alert 
      :alert="alert" 
      :titleAlert="titleAlert" 
      :messageAlert="messageAlert" 
      :titleCard="titleCard"
      :buttonCard="buttonCard">
    </app-alert>

    <template v-if="loaderTable">
      <v-skeleton-loader 
        class="mx-auto" 
        type="table"
      > 
      </v-skeleton-loader>
    </template>
    <template v-else>
      <!-- tabla -->
      <v-row justify="center">
        <v-col cols="12">
          <router-view></router-view>
          <!-- lider regional -->
          <template v-if="usuario.perfil_id == 5">
            <v-card>
              <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                          fab 
                          small 
                          exact 
                          color="primary" 
                          v-bind="attrs" 
                          v-on="on" 
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>

                  <v-col 
                    cols="8" 
                    sm="11" 
                    md="8"
                  >
                    Habilidades: {{ competencia.competencia }}
                  </v-col>

                  <template v-if="habilidadesCompetenciaWorldSkillsRegional.length > 0">
                    <v-col 
                      cols="10" 
                      md="3"
                    >
                      <v-text-field 
                        outlined 
                        dense 
                        append-icon="mdi-magnify" 
                        label="Buscar Habilidad" 
                        hide-details
                        v-model="searchTable"
                      >
                      </v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>

              <v-data-table 
                fixed-header 
                height="480px" 
                class="elevation-2"
                :headers="headersTable" 
                :items="habilidadesCompetenciaWorldSkillsRegional" 
                :search="searchTable"
                no-data-text="No hay habilidades asociadas" 
                :footer-props="{ 'items-per-page-options': [50, 100, -1] }"
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        medium 
                        color="primary" 
                        class="mr-2" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="detallesHabilidad(item.categoria_id)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Detalles Habilidad</span>
                  </v-tooltip>

                  <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                          medium 
                          color="primary" 
                          class="mr-2" 
                          v-bind="attrs" 
                          v-on="on"
                          @click="registrarParticipante(item.categoria_id, item.competencia_id)"
                        >
                          mdi-account-group
                        </v-icon>
                      </template>
                      <span>Registrar Participantes</span>
                    </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        medium 
                        color="primary" 
                        class="mr-2" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="registrarGanador(item.categoria_id, item.competencia_id)"
                      >
                        mdi-flag-checkered
                      </v-icon>
                    </template>
                    <span>Registrar Ganadores</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        medium 
                        color="primary" 
                        class="mr-2" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="registrarExpertoRegional(item.competencia_id, item.categoria_id)"
                      >
                        mdi-account-plus
                      </v-icon>
                    </template>
                    <span>Registrar Experto Regional</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </template>

          <!-- experto nacional -->
          <template v-if="usuario.perfil_id == 4">
            <v-card>
              <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                          fab 
                          small 
                          exact 
                          color="primary" 
                          v-bind="attrs" 
                          v-on="on" 
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>

                  <v-col 
                    cols="10" 
                    sm="11" 
                  >
                    Habilidad: {{ competencia.competencia }}
                  </v-col>
                </v-row>
              </v-card-title>

              <v-data-table 
                fixed-header 
                height="480px" 
                class="elevation-2"
                :headers="headersTable" 
                :items="habilidadCompetenciaExpertoNacional" 
                :search="searchTable"
                no-data-text="No hay habilidad asociada" 
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        medium 
                        color="primary" 
                        class="mr-2" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="detallesHabilidad(item.categoria_id)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Detalles Habilidad</span>
                  </v-tooltip>

                  <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                          medium 
                          color="primary" 
                          class="mr-2" 
                          v-bind="attrs" 
                          v-on="on"
                          @click="registrarParticipante(item.categoria_id, item.competencia_id)"
                        >
                          mdi-account-group
                        </v-icon>
                      </template>
                      <span>Registrar Participantes</span>
                    </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        medium 
                        color="primary" 
                        class="mr-2" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="registrarGanador(item.categoria_id, item.competencia_id)"
                      >
                        mdi-flag-checkered
                      </v-icon>
                    </template>
                    <span>Registrar Ganadores</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        medium 
                        color="primary" 
                        class="mr-2" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="registrarExpertoRegional(item.competencia_id, item.categoria_id)"
                      >
                        mdi-account-plus
                      </v-icon>
                    </template>
                    <span>Registrar Experto Regional</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </template>

          <!-- lider nacional -->
          <template v-if="usuario.perfil_id == 3 || usuario.perfil_id == 1">
            <v-card>
              <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                          fab 
                          small 
                          exact 
                          color="primary" 
                          v-bind="attrs" 
                          v-on="on" 
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>

                  <v-col 
                    cols="10" 
                    sm="11" 
                    md="6"
                  >
                    Habilidades: {{ competencia.competencia }}
                  </v-col>

                  <v-col 
                    cols="8" 
                    sm="10"
                    md="3"
                  >
                    <template v-if="habilidadesCompetenciaWorldSkillsNacional.length > 0">
                      <v-text-field 
                        outlined 
                        dense 
                        append-icon="mdi-magnify" 
                        label="Buscar Habilidad" 
                        hide-details
                        v-model="searchTable"
                      >
                      </v-text-field>
                    </template>
                  </v-col>

                  <v-col 
                    cols="4" 
                    sm="2" 
                    align="end"
                  >
                    <v-btn 
                      exact 
                      color="primary" 
                      @click="abrirDialog"
                    >
                      Asociar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-data-table 
                fixed-header 
                height="480px" 
                class="elevation-2"
                :headers="headersTable" 
                :items="habilidadesCompetenciaWorldSkillsNacional" 
                :search="searchTable"
                no-data-text="No hay habilidades asociadas" 
                :footer-props="{ 'items-per-page-options': [50, 100, -1] }"
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        color="primary" 
                        class="mr-2" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="detallesHabilidad(item.categoria_id)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Detalles Habilidad</span>
                  </v-tooltip>

                  <template v-if="item.tipo_competencia_id != 3">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon  
                          color="primary" 
                          class="mr-2" 
                          v-bind="attrs" 
                          v-on="on"
                          @click="registrarExpertoNacional(item.categoria_id, item.competencia_id)">
                          mdi-account-multiple-plus
                        </v-icon>
                      </template>
                      <span>Registrar Experto Nacional</span>
                    </v-tooltip>
                  </template>

                  <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                          color="primary" 
                          class="mr-2" 
                          v-bind="attrs" 
                          v-on="on"
                          @click="registrarParticipante(item.categoria_id, item.competencia_id)"
                        >
                          mdi-account-group
                        </v-icon>
                      </template>
                      <span>Registrar Participantes</span>
                    </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        color="primary" 
                        class="mr-2" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="registrarGanador(item.categoria_id, item.competencia_id)"
                      >
                        mdi-flag-checkered
                      </v-icon>
                    </template>
                    <span>Registrar Ganadores</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        color="primary" 
                        class="mr-2" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="registrarExpertoRegional(item.competencia_id, item.categoria_id)"
                      >
                        mdi-account-plus
                      </v-icon>
                    </template>
                    <span>Registrar Experto Regional</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        color="error" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="activarAlertaEliminar(item.categoria_id, item.competencia_id)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>

                <template v-slot:top>
                  <v-dialog 
                    persistent 
                    v-model="dialogForm" 
                    max-width="520px"
                  >
                    <v-card :loading="loadingForm">
                      <v-card-title class="justify-center">
                        <p class="text-h5 font-weight-bold primary--text">
                          {{ formTitle }}
                        </p>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row justify="center">
                            <v-col 
                              cols="12" 
                              sm="10"
                            >
                              <v-form 
                                ref="form" 
                                v-model="form"
                              >
                                <v-text-field 
                                  outlined 
                                  dense 
                                  disabled 
                                  type="text" 
                                  label="Competencia"
                                  v-model="competenciaSeleccionada"
                                >
                                </v-text-field>

                                <v-select 
                                  outlined 
                                  dense 
                                  label="Habilidad*" 
                                  :items="habilidades" 
                                  item-value="id"
                                  item-text="categoria" 
                                  v-model="habilidad" 
                                  :rules="habilidadReglas"
                                >
                                </v-select>
                                
                                <v-select 
                                  outlined 
                                  dense 
                                  label="Modalidad*" 
                                  :items="modalidades" 
                                  item-value="id"
                                  item-text="modalidad" 
                                  v-model="modalidad" 
                                  :rules="modalidadReglas"
                                >
                                </v-select>

                                <v-text-field 
                                  outlined 
                                  dense 
                                  type="number" 
                                  label="Número de Participantes*"
                                  v-model="numeroParticipantes" 
                                  :rules="numeroParticipantesReglas"
                                  @keyup.prevent="gruposParticipantes(numeroParticipantes)"
                                  @change="gruposParticipantes(numeroParticipantes)"
                                >
                                </v-text-field>

                                <v-text-field 
                                  outlined 
                                  dense 
                                  disabled 
                                  type="number" 
                                  label="Número de Grupos"
                                  v-model="cantidadGrupos"
                                >
                                </v-text-field>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          color="error" 
                          @click="cerrarDialog"
                        >
                          Cancelar
                        </v-btn>

                        <template v-if="editedIndex === -1">
                          <v-btn 
                            text 
                            exact 
                            color="primary" 
                            @click="asignar"
                          >
                            Asociar
                          </v-btn>
                        </template>

                        <template v-else>
                          <v-btn 
                            text 
                            exact 
                            color="primary" 
                            @click="actualizar"
                          >
                            Actualizar
                          </v-btn>
                        </template>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
            </v-card>
          </template>

          <!-- experto regional -->
          <template v-if="usuario.perfil_id == 6">
            <v-card>
              <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                          fab 
                          small 
                          exact 
                          color="primary" 
                          v-bind="attrs" 
                          v-on="on" 
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>

                  <v-col 
                    cols="10" 
                    sm="11" 
                  >
                    Habilidad: {{ competencia.competencia }}
                  </v-col>
                </v-row>
              </v-card-title>

              <v-data-table 
                fixed-header 
                height="480px" 
                class="elevation-2"
                :headers="headersTable" 
                :items="habilidadCompetenciaExpertoRegional" 
                :search="searchTable"
                no-data-text="No hay habilidad asociada" 
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        medium 
                        color="primary" 
                        class="mr-2" 
                        v-bind="attrs" 
                        v-on="on"
                        @click="detallesHabilidad(item.categoria_id)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Detalles Habilidad</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
  
<script>
import Alert from '@/components/admin/Alert.vue'

export default {
  data: () => ({
    loaderTable: true,
    searchTable: null,

    alert: false,
    titleAlert: null,
    messageAlert: null,

    titleCard: null,
    buttonCard: null,

    headersTable: [
      {
        text: "Habilidad",
        value: "categoria",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Modalidad",
        value: "modalidad",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "# Participantes",
        value: "numero_participantes",
        align: "start",
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "# Grupos",
        value: "cantidad_grupos",
        align: "start",
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      }
    ],

    loadingForm: false,
    form: true,

    dialogForm: false,
    dialogDelete: false,

    temCategoria_id: null,
    temCompetencia_id: null,

    competenciaSeleccionada: "",

    habilidad: null,
    habilidadReglas: [
      (v) => !!v || "Seleccione una habilidad"
    ],

    modalidad: null,
    modalidadReglas: [
      (v) => !!v || "Seleccione una modalidad"
    ],

    sede: "",
    sedeReglas: [
      (v) => !!v || "Seleccione una sede"
    ],

    cantidadGrupos: null,

    numeroParticipantes: null,
    numeroParticipantesReglas: [
      (v) => !!v || "Escriba un número de participantes",
      (v) => v > 0 || "El número debe ser mayor a cero"
    ],

    editedIndex: -1
  }),

  components: {
    "app-alert": Alert
  },

  mounted() {
    this.$store.dispatch("crearModalidades")
    this.$store.dispatch("gestionarHabilidades")
    this.$store.dispatch("gestionarExpertosNacionales")
    this.$store.dispatch("gestionarExpertosRegionales")
    this.$store.dispatch("gestionarHabilidadesCompetencia", this.$route.params.id_competencia)
    this.$store.dispatch("gestionarSedesCompetencia", this.$route.params.id_competencia)
    this.$store.dispatch("detalleCompetenciaWorldSkills", this.$route.params.id_competencia)
   
    setTimeout(() => {
      this.loaderTable = false
      const storedSearch = this.$store.getters.obtenerSearch;
      if (storedSearch) {
        this.searchTable = storedSearch;
      }
    }, 1000)
  },

  computed: {
    habilidadesCompetenciaWorldSkillsRegional() {
      return this.$store.getters.obtenerHabilidadesCompetencia;
    },

    habilidadesCompetenciaWorldSkillsNacional() {
      return this.$store.getters.obtenerHabilidadesCompetencia;
    },

    usuario() {
      return this.$store.getters.usuario
    },

    expertoNacional() {
      return this.$store.getters.obtenerExpertoNacionalCompetencia(this.$route.params.id_competencia, this.usuario.identificacion)
    },

    habilidadCompetenciaExpertoNacional() {
      if (this.expertoNacional != null) {
        return this.$store.getters.obtenerHabilidadesCompetencia.filter(habilidadCompetencia => habilidadCompetencia.categoria_id == this.expertoNacional.categoria_id)
      }
      return []
    },

    expertoRegional() {
      return this.$store.getters.obtenerExpertoRegionalCompetencia(this.$route.params.id_competencia, this.usuario.identificacion)
    },

    habilidadCompetenciaExpertoRegional() {
      if (this.expertoRegional != null) {
        return this.$store.getters.obtenerHabilidadesCompetencia.filter(habilidadCompetencia => habilidadCompetencia.categoria_id == this.expertoRegional.categoria_id)
      }
      return []
    },
   
    formTitle() {
      return this.editedIndex === -1 ? "Asociar Habilidad" : "Actualizar Habilidad"
    },

    competencia() {
      return this.$store.getters.obtenerCompetenciaWorldSkills;
    },

    habilidades() {
      return this.$store.getters.obtenerHabilidades;
    },

    competenciaSede() {
      return this.$store.getters.obtenerSedesCompetencia;
    },

    modalidades() {
      return this.$store.getters.obtenerModalidades.filter((modalidad) => {
        if (modalidad.id != 3) {
          return modalidad
        }
      })
    }
  },

  watch: {
    searchTable(newSearch) {
      this.$store.dispatch("guardarSearch", newSearch)
    },

    dialog(val) {
      val || this.cerrarDialog()
    },

    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  methods: {
    abrirDialog() {
      this.dialogForm = true;
      this.competenciaSeleccionada = this.competencia.competencia
    },

    gruposParticipantes(participantes) {
      if (this.modalidad == 2) {
        const calculo = participantes % 2;
        if (calculo == 0) {
          this.cantidadGrupos = participantes / 2;
        }
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = "El número de participantes no coincide con la modalidad";
          this.titleCard = "error--text";
          this.buttonCard = "error";
        }
      }
      else {
        this.cantidadGrupos = this.numeroParticipantes
      }
    },

    deleteItem(item) {
      this.editedIndex = this.habilidadesCompetenciaWorldSkillsNacional.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.habilidadesCompetenciaWorldSkillsNacional.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    cerrarDialog() {
      this.dialogForm = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      });

      this.$refs.form.reset()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    asignar() {
      if (this.$refs.form.validate()) {
        const habilidadCompetencia = {
          competencia_id:       this.$route.params.id_competencia,
          categoria_id:         this.habilidad,
          modalidad_id:         this.modalidad,
          numero_participantes: this.numeroParticipantes,
          cantidad_grupos:      this.cantidadGrupos,
          sede_id:              this.sede
        }

        if (this.usuario.perfil_id == 3 || this.usuario.perfil_id == 1 || this.cantidadGrupos > 0) {
          this.$store.dispatch("asignarHabilidadCompetencia", habilidadCompetencia)
            .then((response) => {
              if (response.status == "success") {
                this.loadingForm = false;
                this.cerrarDialog();
                this.alert = true;
                this.titleAlert = "Asociada";
                this.messageAlert = response.message;
                this.titleCard = "success--text";
                this.buttonCard = "success";

                this.$store.dispatch("gestionarHabilidadesCompetencia", this.$route.params.id_competencia);
              }
              else {
                this.loadingForm = false;
                this.alert = true;
                this.titleAlert = "Error";
                this.messageAlert = response.message.competenciaHabilidad;
                this.titleCard = "error--text";
                this.buttonCard = "error";
              }
              setTimeout(() => {
                this.alert = false;
              }, 3000);
            })
            .catch(() => { });
        }
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = "El número de participantes no coincide con la modalidad";
          this.titleCard = "error--text";
          this.buttonCard = "error";
        }
      }
    },

    activarAlertaEliminar(categoria_id, competencia_id) {
      this.temCategoria_id = categoria_id;
      this.temCompetencia_id = competencia_id;
      this.dialogDelete = true;
    },

    eliminar(idHabilidad, idCompetencia) {
      this.dialogDelete = false;
      const habilidadCompetencia = {
        categoria_id: idHabilidad,
        competencia_id: idCompetencia
      };

      this.$store.dispatch("eliminarHabilidadCompetencia", habilidadCompetencia)
        .then((response) => {
          if (response.status == "success") {
            this.alert = true;
            this.titleAlert = "Eliminada";
            this.messageAlert = response.message;
            this.titleCard = "success--text";
            this.buttonCard = "success";
            this.$store.dispatch("gestionarHabilidadesCompetencia", this.$route.params.id_competencia)
          }
          else {
            this.alert = true;
            this.titleAlert = "Error";
            this.messageAlert = response.message.competenciaHabilidad;
            this.titleCard = "error--text";
            this.buttonCard = "error";
          }
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        })
        .catch(() => { });
    },

    detallesHabilidad(habilidad) {
      this.$router.push({
        name: "habilidad-detalles",
        params: { id_habilidad: habilidad }
      });
    },

    registrarParticipante(categoria, competencia) {
      this.$router.push({
        name: "participantews-gestionar",
        params: { id_habilidad: categoria, id_competencia: competencia }
      })
    },

    registrarGanador(categoria, competencia) {
      this.$router.push({
        name: "ganador-gestionar",
        params: { id_categoria: categoria, id_competencia: competencia }
      })
    },

    registrarExpertoRegional(competencia, categoria) {
      this.$router.push({
        name: "expertows-gestionar",
        params: { id_competencia: competencia, id_categoria: categoria }
      })
    },

    registrarExpertoNacional(categoria, competencia) {
      this.$router.push({
        name: "expertows-nacional-gestionar",
        params: { id_competencia: competencia, id_categoria: categoria }
      })
    },

    regresar() {
      this.$router.push({
        name: "competenciaws-gestionar"
      })
    }
  }
}
</script>