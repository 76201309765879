<template>
    <div>
      <v-row>
        <v-col sm="1" lg="2" class="hidden-xs-only">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                fixed
                exact
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="regresar"
              >
                <v-icon> mdi-arrow-left </v-icon>
              </v-btn>
            </template>
            <span>Regresar</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="10" lg="8">
          <v-card outlined elevation="2">
            <v-card-title class="justify-center">
              <p class="text-h4 font-weight-bold primary--text mt-2">
                Detalles Sede
              </p>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Sede</p>
                  <p class="text-subtitle-1">
                    {{ sede.sede }}
                  </p>
                </v-col>
                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Lugar</p>
                  <p class="text-subtitle-1">
                    {{ sede.lugar }}
                  </p>
                </v-col>
  
                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Dirección</p>
                  <p class="text-subtitle-1">
                    {{ sede.direccion }}
                  </p>
                </v-col>

                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Responsable</p>
                  <p class="text-subtitle-1">
                    {{ sede.responsable }}
                  </p>
                </v-col>

                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Teléfono Responsable</p>
                  <p class="text-subtitle-1">
                    {{ sede.telefono_responsable }}
                  </p>
                </v-col>

                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Correo Responsable</p>
                  <p class="text-subtitle-1 mb-4">
                    {{ sede.correo_responsable }}
                  </p>
                </v-col>

                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Regional</p>
                  <p class="text-subtitle-1 mb-4">
                    {{ sede.regional }}
                  </p>
                </v-col>

                <v-col cols="12" sm="10" lg="8">
                  <p class="text-h6 font-weight-bold">Centro de Formación</p>
                  <p class="text-subtitle-1 mb-4">
                    {{ sede.centro_formacion }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </template>
  <script>
  export default {
    data: () => ({
    }),
  
    mounted() {
      this.$store.dispatch("detallesSedes", this.$route.params.id_sede);
    },
  
    computed: {
      sede() {
        return this.$store.getters.obtenerSede;
      }
    },
 
    methods: {
      regresar() {
        window.history.back()
      }
    }
  };
  </script>