<template>
  <app-detail-card
    titleCard="Aliado"
    :itemsCard="aliado"
    :resourcesUrl="resourcesUrl"
    rutaRegresar="aliado-gestionar"
  >
  </app-detail-card>
</template>

<script>
import DetailCard from '../../../components/admin/DetailCard.vue'

export default {
  data: () => ({
    detalles: "",
    resourcesUrl: [
      "http://localhost:8000/images/aliados/logotipos/"
    ]
  }),

  created() {
    this.$store.dispatch("detallesAliado", this.$route.params.id_aliado)
    .then((response) => {
      this.detalles = response
    })
    .catch(() => {});
  },

  computed: {
    aliado() {
      const aliado = [
        {
          col:    "12",
          colSm:  "10",
          colLg:  "6",
          label:  "Aliado",
          value:  this.detalles.aliado
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "2",
          label:  1,
          value:  this.detalles.logotipo,
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Descripción",
          value:  this.detalles.descripcion
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Dirección",
          value:  this.detalles.direccion
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Teléfono",
          value:  this.detalles.telefono
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Correo Electrónico",
          value:  this.detalles.correo
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Contacto del Aliado",
          value:  this.detalles.contacto_aliado
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Teléfono",
          value:  this.detalles.telefono_contacto_aliado
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Correo Electrónico",
          value:  this.detalles.correo_contacto_aliado
        },
      ]
    
      return aliado;
    },
  },

  components: {
    "app-detail-card": DetailCard
  }
};
</script>