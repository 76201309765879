import axios from 'axios'

const habilidades = {
    state: () => ({
        habilidades: [],
        habilidad: {},
        habilidadesCompetencia: [],
        habilidadCompetencia: {},
        programasFormacionHabilidad: [],
        documentos: [],
        documento: {}
    }),

    getters: {
        obtenerHabilidades(state) {
            return state.habilidades
        },

        obtenerHabilidad(state) {
            return state.habilidad
        },

        obtenerHabilidadesCompetencia(state) {
            return state.habilidadesCompetencia
        },

        obtenerHabilidadCompetencia(state) {
            return state.habilidadCompetencia
        },

        obtenerHabilidadCompetenciaWorldSkills: (state) => (competencia, habilidad) => {
            return state.habilidadesCompetencia.find(habilidadCompetencia => habilidadCompetencia.competencia_id == competencia && habilidadCompetencia.categoria_id == habilidad)
        },

        obtenerHabilidadExpertoNacional: (state) => (id) => {
            return state.habilidades.filter(habilidad => habilidad.id == id)
        },
        
        obtenerDocumentos(state) {
            return state.documentos
        },

        obtenerDocumento(state) {
            return state.documento
        }
    },

    mutations: {
        enviarHabilidades(state, habilidades) {
            state.habilidades = habilidades
        },

        enviarHabilidad(state, habilidad) {
            state.habilidad = habilidad
        },

        enviarHabilidadesCompetencia(state, habilidadesCompetencia) {
            state.habilidadesCompetencia = habilidadesCompetencia
        },

        enviarHabilidadCompetencia(state, habilidadCompetencia) {
            state.habilidadCompetencia = habilidadCompetencia
        },

        enviarDocumentos(state, documentos) {
            state.documentos = documentos
        },

        enviarDocumento(state, documento) {
            state.documento = documento
        }
    },

    actions: {
        registrarHabilidad(context, habilidad) {
            return new Promise((resolve, reject) => {
                axios.post("api/habilidades", habilidad)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarHabilidades(context) {
            axios.get("api/habilidades")
            .then((response) => {
                const habilidades = []
                response.data.results.forEach((response, index) => {
                    habilidades.push({
                        indice:             (index+1),
                        id:                 response.id_categoria,
                        codigo_habilidad:   response.codigo_habilidad,
                        categoria:          response.categoria,
                        descripcion:        response.descripcion
                    })
                })
                context.commit("enviarHabilidades", habilidades)
            })
            .catch(() => { })
        },

        detallesHabilidad(context, habilidad) {
            axios.get("api/habilidades/"+habilidad)
            .then((response) => {
                const habilidad = {
                    id:                             response.data.results.id_categoria,
                    codigo_habilidad:               response.data.results.codigo_habilidad,
                    categoria:                      response.data.results.categoria,
                    descripcion:                    response.data.results.descripcion,
                    programa_formacion_categoria:   response.data.results.programa_formacion_categoria
                }
                context.commit("enviarHabilidad", habilidad)
            })
        },

        editarHabilidad(context, habilidad) {
            return new Promise((resolve, reject) => {
                axios.get("api/habilidades/"+habilidad+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarHabilidad(context, habilidad) {
            return new Promise((resolve, reject) => {
                axios.put("api/habilidades/"+habilidad.id, habilidad)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        downloadeHabilidad(context, categoria) {
            return new Promise((resolve, reject) => {
                axios.get("api/habilidadesDoc/" + categoria.categoria_id + "/" + categoria.nombre_documento, { responseType: 'blob' })
                .then((response) => {
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarHabilidadesCompetencia(context, competencia) {
            axios.get("api/competencias/"+competencia+"/habilidades")
            .then((response) => {
                const habilidadesCompetencia = []
                response.data.results.forEach((response, index) => {
                    habilidadesCompetencia.push({
                        indice:                 (index+1),
                        categoria_id:           response.categoria.id_categoria,
                        categoria:              response.categoria.categoria,
                        competencia_id:         response.competencia.id_competencia,
                        competencia:            response.competencia.competencia,
                        tipo_competencia_id:    response.competencia.tipo_competencia_id,
                        modalidad_id:           response.modalidad.id_modalidad,
                        modalidad:              response.modalidad.modalidad,
                        numero_participantes:   response.numero_participantes,
                        cantidad_grupos:        response.cantidad_grupos,
                        sede_id:                response.sede_id != null ? response.sede.id_sede : null,
                        sede:                   response.sede_id != null ? response.sede.sede : null,
                        estado_inscripcion:     response.estado_inscripcion
                    })
                })                
                context.commit("enviarHabilidadesCompetencia", habilidadesCompetencia)
            })
        },

        asignarHabilidadCompetencia(context, habilidadCompetencia) {
            return new Promise((resolve, reject) => {
                axios.post("api/competencias/"+habilidadCompetencia.competencia_id+"/habilidades", habilidadCompetencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        detallesHabilidadCompetencia(context, habilidadCompetencia) {
            axios.get("api/competencias/"+habilidadCompetencia.competencia_id+"/habilidades/"+habilidadCompetencia.categoria_id)
            .then((response) => {
                let respuesta = response.data.results

                const programasFormacion = []
                respuesta.categoria.programa_formacion_categoria.forEach((res) => { 
                    programasFormacion.push({
                        id_programa_formacion: res.programa_formacion.id_programa_formacion,
                        programa_formacion: res.programa_formacion.programa_formacion
                    })
                })

                const habilidad = {
                    competencia_id:         respuesta.competencia.id_competencia,
                    competencia:            respuesta.competencia.competencia,
                    categoria_id:           respuesta.categoria.id_categoria,
                    categoria:              respuesta.categoria.categoria,
                    modalidad_id:           respuesta.modalidad.id_modalidad,
                    modalidad:              respuesta.modalidad.modalidad,
                    numero_participantes:   respuesta.numero_participantes,
                    cantidad_grupos:        respuesta.cantidad_grupos,
                    sede_id:                respuesta.sede_id != null ? respuesta.sede.id_sede : null,
                    sede:                   respuesta.sede_id != null ? respuesta.sede.sede : null,
                    programas_formacion:    programasFormacion
                }
                context.commit("enviarHabilidadCompetencia", habilidad)
            })
            .catch(() => { })
        },

        editarHabilidadCompetencia(context, habilidadCompetencia) {
            return new Promise((resolve, reject) => {
                axios.get("api/competencias/" + habilidadCompetencia.competencia_id + "/habilidades/" + habilidadCompetencia.categoria_id)
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarHabilidadCompetencia(context, habilidadCompetencia) {
            return new Promise((resolve, reject) => {
                axios.put("api/competencias/" + habilidadCompetencia.competencia_id + "/habilidades/" + habilidadCompetencia.categoria_id, habilidadCompetencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarEstadoInscripcionHabilidad(context, habilidadCompetencia) {
            return new Promise((resolve, reject) => {
                axios.put("api/competencias/" + habilidadCompetencia.competencia + "/habilidades/" + habilidadCompetencia.habilidad + "/inscripcion", habilidadCompetencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        eliminarHabilidadCompetencia(context, habilidadCompetencia) {
            return new Promise((resolve, reject) => {
                axios.delete("api/competencias/" + habilidadCompetencia.competencia_id + "/habilidades/" + habilidadCompetencia.categoria_id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },
    
        registrarDocumento(context, documento) {
            return new Promise((resolve, reject) => {
                axios.post("api/registrodocumentohabilidad/" + documento.get("categoria_id"), documento, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarDocumentos(context, categoria) {
            axios.get("api/documentohabilidad/" + categoria)
            .then((response) => {
                const documentos = []
                for (let i = 0; i < response.data.results.length; i++) {
                    documentos.push({
                        indice: (i + 1),
                        id:                 response.data.results[i].id_documento,
                        nombre_documento:   response.data.results[i].nombre_documento,
                        categoria_id:       response.data.results[i].categoria_id
                    });
                }
                context.commit("enviarDocumentos", documentos)
            })
        },

        eliminarDocumentoHabilidad(context, documento) {
            return new Promise((resolve, reject) => {
                axios.delete("api/eliminardocumento/" + documento.categoria_id + "/documento/" + documento.id_documento)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        }
    }
}

export default habilidades