<template>
  <div>
    <!-- alerta -->
    <v-dialog max-width="320" v-model="alert">
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :color="buttonCard"
            class="mb-2 white--text"
            @click="alert = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cargador tabla -->
    <template v-if="loaderTable">
      <v-skeleton-loader 
      class="mx-auto" 
      type="table"
    > 
    </v-skeleton-loader>
    </template>
    <template v-else>
      <!-- tabla -->
      <v-row justify="center">
        <v-col cols="12">
          <router-view></router-view>
          <!-- lider regional -->
          <template v-if="usuario.perfil_id == 5">
            <v-card>
              <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          exact
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>
                  <v-col 
                    cols="10" 
                    sm="9"
                  >
                    Ganadores: {{ categoriaCompetencia.categoria }}
                  </v-col>

                  <v-col 
                    cols="12" 
                    sm="2"
                    align="end"
                  >
                    <v-btn 
                      exact 
                      color="primary" 
                      @click="open"
                    >
                      Registrar
                    </v-btn>
                  </v-col>

                  <template v-if="ganadoresWorldSkillsRegional.length > 0">
                    <v-col 
                      cols="12"
                      sm="4"
                      md="3"
                      offset-sm="8"
                      offset-md="9"
                    >
                      <v-text-field
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="Buscar Ganador"
                        hide-details
                        v-model="searchTable"
                      >
                      </v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>

              <v-data-table
                fixed-header
                height="480px"
                class="elevation-2"
                :headers="headersTable"
                :items="ganadoresWorldSkillsRegional"
                :search="searchTable"
                no-data-text="No hay ganadores registrados"
                :footer-props="{ 'items-per-page-options': [10, 30, -1] }"
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="detalles(item.id)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Detalles</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="editar(item.id)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Actualizar</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="activarAlertaEliminar(item.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>

                <template v-slot:top>
                  <v-dialog
                    persistent
                    scrollable
                    max-width="520px"
                    v-model="dialogForm"
                  >
                    <v-card :loading="loadingForm">
                      <v-card-title class="justify-center">
                        <p class="text-h5 font-weight-bold primary--text">
                          {{ formTitle }}
                        </p>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row justify="center">
                            <v-col 
                              cols="12" 
                              md="10"
                            >
                              <v-form 
                                ref="form" 
                                v-model="form"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Competencia"
                                  v-model="competenciaSeleccionada"
                                >
                                </v-text-field>

                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Habilidad"
                                  v-model="habilidadSeleccionada"
                                >
                                </v-text-field>

                                <v-text-field
                                  outlined
                                  dense
                                  :disabled="disabledForm"
                                  append-icon="mdi-magnify"
                                  type="number"
                                  label="Número de Identificación"
                                  v-model="identificacion"
                                  :rules="identificacionReglas"
                                  :loading="loadingTextField"
                                  :error-messages="mensajeIdentificacion"
                                  @keydown.enter="persona(identificacion)"
                                  @click:append="persona(identificacion)"
                                >
                                </v-text-field>

                                <template v-if="validarPersona == true">
                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Nombres"
                                    v-model="nombres"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Apellidos"
                                    v-model="apellidos"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Fecha de Nacimiento"
                                    v-model="fecha_nacimiento"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Género"
                                    v-model="genero"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Programa de Formación"
                                    v-model="programaFormacion"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="number"
                                    label="Número de Ficha"
                                    v-model="numeroFicha"
                                  >
                                  </v-text-field>

                                  <v-select
                                    outlined
                                    dense
                                    label="Tipo de Medalla*"
                                    :items="medallas"
                                    v-model="medalla"
                                    :rules="medallaReglas"
                                  >
                                  </v-select>

                                  <v-text-field
                                    outlined
                                    dense
                                    type="number"
                                    label="Puntaje"
                                    v-model="puntaje"
                                  >
                                  </v-text-field>

                                  <v-textarea
                                    outlined
                                    rows="4"
                                    label="Descripción"
                                    v-model="descripcion"
                                  >
                                  </v-textarea>
                                </template>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          color="error" 
                          @click="close"
                        >
                          Cancelar
                        </v-btn>

                        <template v-if="editedIndex === -1">
                          <v-btn 
                            text 
                            exact 
                            color="primary" 
                            @click="registrar"
                          >
                            Registrar
                          </v-btn>
                        </template>

                        <template v-else>
                          <v-btn 
                            text 
                            exact 
                            color="primary" 
                            @click="actualizar"
                          >
                            Actualizar
                          </v-btn>
                        </template>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
            </v-card>
          </template>

           <!-- experto nacional -->
           <template v-if="usuario.perfil_id == 4">
            <v-card>
              <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          exact
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>

                  <v-col 
                    cols="10" 
                    sm="9" 
                  >
                    Ganadores: {{ categoriaCompetencia.categoria }}
                  </v-col>

                  <v-col 
                    cols="12" 
                    sm="2"
                    align="end"
                  >
                    <template>
                      <v-btn 
                        exact 
                        color="primary" 
                        @click="open"
                      >
                        Registrar
                      </v-btn>
                    </template>
                  </v-col>

                  <template v-if="ganadoresWorldSkillsNacional.length > 0">
                    <v-col 
                      cols="12"
                      sm="4"
                      md="3"
                      offset-sm="8"
                      offset-md="9"
                    >
                      <v-text-field
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="Buscar Ganador"
                        hide-details
                        v-model="searchTable"
                      >
                      </v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>

              <v-data-table
                fixed-header
                height="480px"
                class="elevation-2"
                :headers="headersTable"
                :items="ganadoresWorldSkillsNacional"
                :search="searchTable"
                no-data-text="No hay ganadores registrados"
                :footer-props="{ 'items-per-page-options': [20, 50, -1] }"
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="detalles(item.id)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Detalles</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="editar(item.id)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Actualizar</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="activarAlertaEliminar(item.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>

                <template v-slot:top>
                  <v-dialog
                    persistent
                    scrollable
                    max-width="520px"
                    v-model="dialogForm"
                  >
                    <v-card :loading="loadingForm">
                      <v-card-title class="justify-center">
                        <p class="text-h5 font-weight-bold primary--text">
                          {{ formTitle }}
                        </p>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row justify="center">
                            <v-col 
                              cols="12" 
                              md="10"
                            >
                              <v-form 
                                ref="form" 
                                v-model="form"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Competencia"
                                  v-model="competenciaSeleccionada"
                                >
                                </v-text-field>

                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Habilidad"
                                  v-model="habilidadSeleccionada"
                                >
                                </v-text-field>

                                <v-text-field
                                  outlined
                                  dense
                                  :disabled="disabledForm"
                                  append-icon="mdi-magnify"
                                  type="number"
                                  label="Número de Identificación"
                                  v-model="identificacion"
                                  :rules="identificacionReglas"
                                  :loading="loadingTextField"
                                  :error-messages="mensajeIdentificacion"
                                  @keydown.enter="persona(identificacion)"
                                  @click:append="persona(identificacion)"
                                >
                                </v-text-field>

                                <template v-if="validarPersona == true">
                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Nombres"
                                    v-model="nombres"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Apellidos"
                                    v-model="apellidos"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Fecha de Nacimiento"
                                    v-model="fecha_nacimiento"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Género"
                                    v-model="genero"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Programa de Formación"
                                    v-model="programaFormacion"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="number"
                                    label="Número de Ficha"
                                    v-model="numeroFicha"
                                  >
                                  </v-text-field>

                                  <v-select
                                    outlined
                                    dense
                                    label="Tipo de Medalla*"
                                    :items="medallas"
                                    v-model="medalla"
                                    :rules="medallaReglas"
                                  >
                                  </v-select>

                                  <v-text-field
                                    outlined
                                    dense
                                    type="number"
                                    label="Puntaje"
                                    v-model="puntaje"
                                  >
                                  </v-text-field>

                                  <v-textarea
                                    outlined
                                    rows="4"
                                    label="Descripción"
                                    v-model="descripcion"
                                  >
                                  </v-textarea>
                                </template>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          color="error" 
                          @click="close"
                        >
                          Cancelar
                        </v-btn>

                        <template v-if="editedIndex === -1">
                          <v-btn 
                            text 
                            exact 
                            color="primary" 
                            @click="registrar"
                          >
                            Registrar
                          </v-btn>
                        </template>
                        
                        <template v-else>
                          <v-btn 
                            text 
                            exact 
                            color="primary" 
                            @click="actualizar"
                          >
                            Actualizar
                          </v-btn>
                        </template>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
            </v-card>
          </template>

          <!-- lider nacional -->
          <template v-if="usuario.perfil_id == 3 || usuario.perfil_id == 1">
            <v-card>
              <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          exact
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>

                  <v-col 
                    cols="10" 
                    sm="9"
                  >
                    Ganadores: {{ categoriaCompetencia.categoria }}
                  </v-col>

                  <v-col 
                    cols="12" 
                    sm="2"
                    align="end"
                  >
                    <template>
                      <v-btn 
                        exact 
                        color="primary" 
                        @click="open"
                      >
                        Registrar
                      </v-btn>
                    </template>
                  </v-col>

                  <template v-if="ganadoresWorldSkillsNacional.length > 0">
                    <v-col 
                      cols="12"
                      sm="4"
                      md="3"
                      offset-sm="8"
                      offset-md="9"
                    >
                      <v-text-field
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="Buscar Ganador"
                        hide-details
                        v-model="searchTable"
                      >
                      </v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>

              <v-data-table
                fixed-header
                height="480px"
                class="elevation-2"
                :headers="headersTable"
                :items="ganadoresWorldSkillsNacional"
                :search="searchTable"
                no-data-text="No hay ganadores registrados"
                :footer-props="{ 'items-per-page-options': [20, 50, -1] }"
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="detalles(item.id)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Detalles</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="editar(item.id)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Actualizar</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="activarAlertaEliminar(item.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>

                <template v-slot:top>
                  <v-dialog
                    persistent
                    scrollable
                    max-width="520px"
                    v-model="dialogForm"
                  >
                    <v-card :loading="loadingForm">
                      <v-card-title class="justify-center">
                        <p class="text-h5 font-weight-bold primary--text">
                          {{ formTitle }}
                        </p>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row justify="center">
                            <v-col 
                              cols="12" 
                              md="10"
                            >
                              <v-form 
                                ref="form" 
                                v-model="form"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Competencia"
                                  v-model="competenciaSeleccionada"
                                >
                                </v-text-field>

                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Habilidad"
                                  v-model="habilidadSeleccionada"
                                >
                                </v-text-field>

                                <v-text-field
                                  outlined
                                  dense
                                  :disabled="disabledForm"
                                  append-icon="mdi-magnify"
                                  type="number"
                                  label="Número de Identificación"
                                  v-model="identificacion"
                                  :rules="identificacionReglas"
                                  :loading="loadingTextField"
                                  :error-messages="mensajeIdentificacion"
                                  @keydown.enter="persona(identificacion)"
                                  @click:append="persona(identificacion)"
                                >
                                </v-text-field>

                                <template v-if="validarPersona == true">
                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Nombres"
                                    v-model="nombres"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Apellidos"
                                    v-model="apellidos"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Fecha de Nacimiento"
                                    v-model="fecha_nacimiento"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Género"
                                    v-model="genero"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Programa de Formación"
                                    v-model="programaFormacion"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="number"
                                    label="Número de Ficha"
                                    v-model="numeroFicha"
                                  >
                                  </v-text-field>

                                  <v-select
                                    outlined
                                    dense
                                    label="Tipo de Medalla*"
                                    :items="medallas"
                                    v-model="medalla"
                                    :rules="medallaReglas"
                                  >
                                  </v-select>

                                  <v-text-field
                                    outlined
                                    dense
                                    type="number"
                                    label="Puntaje"
                                    v-model="puntaje"
                                  >
                                  </v-text-field>

                                  <v-textarea
                                    outlined
                                    rows="4"
                                    label="Descripción"
                                    v-model="descripcion"
                                  >
                                  </v-textarea>
                                </template>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          color="error" 
                          @click="close"
                        >
                          Cancelar
                        </v-btn>

                        <template v-if="editedIndex === -1">
                          <v-btn 
                            text 
                            exact 
                            color="primary" 
                            @click="registrar"
                          >
                            Registrar
                          </v-btn>
                        </template>
                        
                        <template v-else>
                          <v-btn 
                            text 
                            exact 
                            color="primary" 
                            @click="actualizar"
                          >
                            Actualizar
                          </v-btn>
                        </template>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </template>

    <v-dialog 
      v-model="dialogDelete" 
      max-width="400px"
    >
      <v-card>
        <v-card-title class="justify-center text-h6">
          ¿Desea eliminar el ganador?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            text 
            color="primary" 
            @click="closeDelete"
          > 
            Cancelar 
          </v-btn>

          <v-btn 
            text 
            color="error" 
            @click="eliminar(temEvaluacion_id)"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    loaderTable: true,
    searchTable: null,
    loadingTextField: false,

    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    loadingForm: false,
    form: true,

    dialogForm: false,
    disabledForm: false,
    dialogDelete: false,

    competenciaSeleccionada: "",

    habilidadSeleccionada: "",

    identificacion: null,
    identificacionReglas: [
      (v) => !!v || "Escriba un número de identificación",
      (v) => v > 0 || "El número debe ser mayor a cero",
    ],
    mensajeIdentificacion: "",

    programaFormacion: null,
    programaFormacionReglas: [
      (v) => !!v || "Seleccione un programa de formación",
    ],

    numeroFicha: null,
    numeroFichaReglas: [
      (v) => !!v || "Escriba un número de ficha"
    ],

    medalla: null,
    medallas: ["ORO", "PLATA", "BRONCE", "EXCELENCIA"],
    medallaReglas: [
      (v) => !!v || "Seleccione una medalla"
    ],

    puntaje: null,
    // puntajeReglas: [
    //   (v) => v > 0 || "El número debe ser mayor a cero"
    // ],

    descripcion: null,

    ganadorId: null,
    participanteId: null,
    idPersona: null,
    nombres: null,
    apellidos: null,
    fecha_nacimiento: null,
    genero: null,
    validarPersona: false,

    headersTable: [
      {
        text: "Nombres",
        value: "nombres",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Apellidos",
        value: "apellidos",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },
      
      {
        text: "Medalla",
        value: "medalla",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Regional",
        value: "regional",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Centro de Formación",
        value: "centro_formacion",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ],

    editedIndex: -1,
    temEvaluacion_id: null,
  }),

  mounted() {
    const categoriaCompetencia = {
      competencia_id: this.$route.params.id_competencia,
      categoria_id: this.$route.params.id_categoria
    }

    this.$store.dispatch("crearCentrosFormacion")
    this.$store.dispatch("gestionarGanadorWorldSkills")
    this.$store.dispatch("gestionarParticipantesWorldskills")
    this.$store.dispatch("detallesCategoriaCompetencia", categoriaCompetencia)
    this.$store.dispatch("detallesCompetencia", this.$route.params.id_competencia)

    setTimeout(() => {
      this.loaderTable = false
    }, 1000);
  },

  computed: {
    usuario() {
      return this.$store.getters.usuario
    },

    centroFormacionUsuario() {
      return this.$store.getters.obtenerCentroFormacion(this.usuario.centro_formacion_id)
    },

    ganadoresWorldSkillsRegional() {
      const ganadoresWorldSkills = this.$store.getters.obtenerGanadoresWorldSkills(this.$route.params.id_competencia, this.$route.params.id_categoria)
      const ganadores = ganadoresWorldSkills.filter(ganador => ganador.regional_id == this.centroFormacionUsuario.regional_id)
      return ganadores
    },

    ganadoresWorldSkillsNacional() {
      return this.$store.getters.obtenerGanadoresWorldSkills(this.$route.params.id_competencia, this.$route.params.id_categoria)
    },

    formTitle() {
      return this.editedIndex === -1 ? "Registrar Ganador" : "Actualizar Ganador"
    },

    competencia() {
      return this.$store.getters.obtenerCompetencia
    },

    categoriaCompetencia() {
      return this.$store.getters.obtenerCategoriaCompetencia
    },

    participantesWorldSkills() {
      return this.$store.getters.obtenerParticipantesWorldskills(this.$route.params.id_competencia, this.$route.params.id_categoria)
    }
  },

  watch: {
    searchTable(newSearch) {
      this.$store.dispatch("guardarSearch", newSearch);
    },

    dialogForm(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  methods: {
    open() {
      this.dialogForm = true;
      this.competenciaSeleccionada = this.competencia.competencia;
      this.habilidadSeleccionada = this.categoriaCompetencia.categoria;
    },

    close() {
      this.dialogForm = false
      this.disabledForm = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1;
        this.validarPersona = false;
      });
      this.$refs.form.reset();
      this.mensajeIdentificacion = "";
    },

    persona(identificacion) {
      this.loadingTextField = true
      const participante = this.participantesWorldSkills.find(participante => participante.identificacion == identificacion)

      if(participante != null) {
        this.mensajeIdentificacion = ""
        this.idPersona          = participante.persona_id;
        this.participanteId     = participante.id;
        this.nombres            = participante.nombres;
        this.apellidos          = participante.apellidos;
        this.fecha_nacimiento   = participante.fecha_nacimiento;
        this.genero             = participante.genero;
        this.programaFormacion  = participante.programa_formacion;
        this.numeroFicha        = participante.numero_ficha;
        this.validarPersona     = true
        this.loadingTextField   = false
      }
      else {
        this.validarPersona = false;
        this.mensajeIdentificacion = "Número de identificación no registrado como participante para esta habilidad";
        setTimeout(() => {
          this.loadingTextField = false;
        }, 1000);
      }
    },

    registrar() {
      if (this.$refs.form.validate()) {
        if (this.validarPersona) {
          this.loadingForm = true;

          const ganador = {
            participante_id:  this.participanteId,
            persona_id:       this.idPersona,
            puntaje:          this.puntaje,
            medalla:          this.medalla,
            descripcion:      this.descripcion,
            competencia_id:   this.$route.params.id_competencia,
            categoria_id:     this.$route.params.id_categoria,
          };

          this.$store.dispatch("registrarGanadorWorldSkills", ganador)
          .then((response) => {
            if(response.status == "success") {
              let message = response.message.split(',')
              this.loadingForm = false;
              this.$refs.form.reset()
              this.close()
              this.alert = true;
              this.titleAlert = message[0];
              this.messageAlert = message[1];
              this.titleCard = "success--text";
              this.buttonCard = "success";
              this.loaderTable = true

              this.$store.dispatch("gestionarGanadorWorldSkills")
              setTimeout(() => {
                this.loaderTable = false
              }, 1000)
            } 
            else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              for (const error in response.message) {
                this.messageAlert = response.message[error][0]
              }
            }

            setTimeout(() => {
              this.alert = false
            }, 2000)
          })
          .catch(() => {});
        } 
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = "Número de identificación no encontrado";
          this.titleCard = "error--text";
          this.buttonCard = "error";

          setTimeout(() => {
            this.alert = false;
          }, 3000);
        }
      }
    },

    detalles(ganador) {
      this.$router.push({
        name: "ganador-detalle",
        params: {
          id_evaluacion: ganador,
          id_competencia: this.$route.params.id_competencia,
          id_categoria: this.$route.params.id_categoria,
        },
      });
    },

    editar(ganador) {
      this.editedIndex = 1;
      this.disabledForm = true
      this.$store.dispatch("editarGanadorWorldSkills", ganador)
      .then((response) => {
        this.ganadorId                = ganador
        this.participanteId           = response.participante.id_participante,
        this.competenciaSeleccionada  = this.competencia.competencia
        this.habilidadSeleccionada    = this.categoriaCompetencia.categoria
        this.identificacion           = response.participante.persona.identificacion
        this.nombres                  = response.participante.persona.primer_nombre+" "+response.participante.persona.segundo_nombre
        this.apellidos                = response.participante.persona.primer_apellido+" "+response.participante.persona.segundo_apellido
        this.fecha_nacimiento         = response.participante.persona.fecha_nacimiento
        this.genero                   = response.participante.persona.genero.genero
        this.programaFormacion        = response.participante.programa_formacion.programa_formacion
        this.numeroFicha              = response.participante.numero_ficha
        this.medalla                  = response.medalla
        this.puntaje                  = response.puntaje
        this.descripcion              = response.descripcion
        this.validarPersona           = true;
        this.dialogForm               = true;
      })
      .catch(() => {});     
    },

    actualizar() {
      if (this.$refs.form.validate()) {
        if (this.validarPersona) {
          const ganador = {
            id:               this.ganadorId,
            participante_id:  this.participanteId,
            persona_id:       this.idPersona,
            puntaje:          this.puntaje,
            medalla:          this.medalla,
            descripcion:      this.descripcion,
            competencia_id:   this.$route.params.id_competencia,
            categoria_id:     this.$route.params.id_categoria,
          };

          this.loadingForm = true
          this.$store.dispatch("actualizarGanadorWorldSkills", ganador)
          .then((response) => {
            if (response.status == "success") {
              let message = response.message.split(',')
              this.$refs.form.reset();
              this.loadingForm = false;
              this.close();
              this.alert = true;
              this.titleAlert = message[0];
              this.messageAlert = message[1];
              this.titleCard = "success--text";
              this.buttonCard = "success";
              this.loaderTable = true

              this.$store.dispatch("gestionarGanadorWorldSkills")
              setTimeout(() => {
                this.loaderTable = false
              }, 1000)
            } 
            else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              for (const error in response.message) {
                this.messageAlert = response.message[error][0]
              }
            }

            setTimeout(() => {
              this.alert = false
            }, 2000)
          })
          .catch(() => {});
        } 
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = "Número de identificación no encontrado";
          this.titleCard = "error--text";
          this.buttonCard = "error";
          setTimeout(() => {
            this.alert = false
          }, 3000)
        }
      }
    },

    activarAlertaEliminar(ganador) {
      this.temEvaluacion_id = ganador;
      this.dialogDelete = true;
    },

    eliminar(ganador) {
      this.dialogDelete = false;
      this.$store.dispatch("eliminarGanadorWorldSkills", ganador)
      .then((response) => {
        if(response.status == "success") {
          let message = response.message.split(',')
          this.alert = true;
          this.titleAlert = message[0];
          this.messageAlert = message[1];
          this.titleCard = "success--text";
          this.buttonCard = "success";
          this.loaderTable = true

          this.$store.dispatch("gestionarGanadorWorldSkills")
          setTimeout(() => {
            this.loaderTable = false
          }, 1000)
        } 
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.titleCard = "error--text";
          this.buttonCard = "error";
          for (const error in response.message) {
            this.messageAlert = response.message[error][0]
          }
        }

        setTimeout(() => {
          this.alert = false
        }, 2000);
      })
      .catch(() => {});
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    regresar() {
      this.$router.push({
        name: "asignar-habilidad",
        params: { id_competencia: this.$route.params.id_competencia },
      });
    },
  },
};
</script>
