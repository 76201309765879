import axios from "axios";
const foro = {
  state: () => ({
    foros: [],
    foro: [],
    mensajes: [],
  }),
  getters: {
    obtenerforos(state) {
      return state.foros;
    },
    obtenermensajes(state) {
      return state.mensajes;
    },
  },
  mutations: {
    enviarforos(state, foros) {
      state.foros = foros;
    },
    enviarmensajes(state, mensajes) {
      state.mensajes = mensajes;
    },
    
  },
  actions: {
    gestionarForos(context,a) {
      axios
        .get("api/getforum/" + a.id_usuario +"/"+ a.id_competencia)
        .then((response) => {
          const foross = [];
          if(response.data.status == 204){
            foross.push({
              mensaje: response.data.message
            })
          }
          response.data.results.forEach((foros) => { 
            foross.push({
              id:            foros.id_foro,
              foro:          foros.foro,
              descripcion:   foros.descripcion,
              id_usuario:    foros.usuario_id,
              usuario:       `${foros.nombres} ${foros.apellidos}`,
              id_habilidad:  foros.categoria_id,
              estado:        foros.estado,
              creado:        foros.creado,
            });
    
          });
          context.commit("enviarforos", foross);
        })
        .catch(() => {});
    },
    registrarForo(context,foro) {
      return new Promise((resolve, reject) => {
        axios
          .post("api/forum", foro)
          .then((response) => {
            resolve(response.data);
          })
          .catch(() => {
            reject();
          });
      });
    },
    gestionarMensajes(context, id_forum) {
      axios.get("api/getmessages/" + id_forum).then((res) => {
        const mensajes = [];
        res.data.results.forEach((mensaje) => {
          mensajes.push({
            id: mensaje.id_message,
            mensaje: mensaje.message,
            nombre:  `${mensaje.nombres} ${mensaje.apellidos}`,
            user_id: mensaje.user_id,
            forum: mensaje.forum_id,
            hora_envio: mensaje.hora_envio,
          });
        }
        );
        context.commit("enviarmensajes",mensajes);
        
      }).catch(() => {});
    },
    registrarMensajes(context,mensaje){
        return new Promise((resolve,reject)=>{
          axios.post("api/message",mensaje).then(
            (res)=>{
              resolve(res.data)
            }
          ).catch(()=>{
            reject()
          })
        })
    },
    async descargarDocumento(context,mensaje){
        return new Promise((resolve,reject)=>{
          axios.get('api/descargarArchivo/message/'+mensaje, {responseType: 'blob'}).
          then((res)=>{
            resolve(res)
          }).catch(()=>{
            reject()
          })
        })
    }
  },
};
export default foro;
