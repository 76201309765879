<template>
  <div>
    <v-dialog max-width="320" v-model="alert">
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="mb-2 white--text"
            @click="alert = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-data-table
      titleTable="Gestionar Personas"
      titleSearch="Buscar Personas"
      :loadingTable="loadingTable"
      :headersTable="headersTable"
      :itemsTable="personas"
      textTable="No hay personas registradas"
      :acciones="acciones"
      :rutas="rutas"
    >
    </app-data-table>
  </div>
</template>
  
  <script>
import DataTable from "../../../components/admin/DataTable.vue";

export default {
  data: () => ({
    loadingTable: true,
    dialogDelete: false,
    alert:        false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
      {
        text: "Nombre",
        value: "nombre_completo",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Genero",
        value: "genero",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },
      {
        text: "Correo",
        value: "correo",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },
      {
        text: "Telefono",
        value: "telefono_movil",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ],

    acciones: [
      {
        tooltip: "Actualizar",
        icono: "mdi-pencil",
        color: "primary",
        margen: "mr-2",
      },

      // {
      //   tooltip: "Eliminar",
      //   icono: "mdi-delete",
      //   color: "primary",
      //   click:(item) => {this.eliminar(item.id);this.$router.push('/personas')},
      // },
    ],
    rutas: ["/personas/editar/"],
  }),

  created() {
    this.$store.dispatch("gestionarPersona");
    setTimeout(() => {
      this.loadingTable = false;
    }, 1000);
  },

  computed: {
    personas() {
      return this.$store.getters.obtenerPersonas;
    },
  },

  components: {
    "app-data-table": DataTable,
  },

  methods: {
    deleteItemConfirm() {
      this.dialogDelete = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    eliminar(id) {
      this.dialogDelete = false

      this.$store.dispatch("eliminarPersona", id)
        .then((response) => {
          if (response.status == "success") {
            this.alert        = true
            this.titleAlert   = "Eliminado"
            this.messageAlert = response.message
            this.titleCard    = "success--text"
            this.buttonCard   = "success"

            this.$store.dispatch("obtenerPersonas")
            setTimeout(() => {
              this.loadingTable = false
            }, 1000)
          } 
          else {
            this.alert        = true
            this.titleAlert   = "Error"
            this.messageAlert = response.message
            this.titleCard    = "error--text"
            this.buttonCard   = "error"
          }

          setTimeout(() => {
            this.alert = false
          }, 1000)
        })
        .catch(() => {})
    },
  },
};
</script>