<template>
  <v-row justify="center">
    <v-col cols="12">
      <router-view></router-view>

      <!-- tabla de datos --> 
      <v-card>
        <v-card-title class="text-h5 font-weight-bold primary--text">
          <v-row align="center">
            <v-col 
              cols="12"
              sm="6"
            >
              {{ titleTable }}
            </v-col>
            <v-col 
              cols="12"
              sm="3"
              offset-sm="3"
            >
              <v-text-field
                outlined
                dense
                append-icon="mdi-magnify"
                :label="titleSearch"
                hide-details
                v-model="searchTable"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
  
        <v-data-table
          fixed-header
          locale="es"
          height="460px"
          class="elevation-2"
          :loading="loadingTable"
          :headers="headersTable"
          :items="itemsTable"
          :search="searchTable"
          :no-data-text="textTable"
          :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1] }"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip 
              v-for="(accion, i) in acciones" :key="i"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  :color="accion.color"
                  :class="accion.margen"
                  v-bind="attrs"
                  v-on="on"
                  @click="cambiarAccion(i, item.id)"
                >
                  {{ accion.icono }}
                </v-icon>
              </template>
              <span>{{ accion.tooltip }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    searchTable: null
  }),

  props: {
    titleTable:   String,
    titleSearch:  String,
    loadingTable: Boolean,
    headersTable: Array,
    itemsTable:   Array,
    textTable:    String,
    acciones:     Array,
    rutas:        Array
  },
  
  // Restaurar el valor de búsqueda desde el almacenamiento local si la ruta base es usuarios
  mounted() {    
    setTimeout(() => {
      const storedSearch = this.$store.getters.obtenerSearch;
      if (storedSearch) {
        this.searchTable = storedSearch;
      }
    }, 2)


  },
  watch: {
    searchTable(newSearch) {
      this.$store.dispatch("guardarSearch", newSearch);
    },
  },
  methods: {
    cambiarAccion(indice, item) {
      switch (indice) {
        case 0: 
          this.$router.push({
            path: this.rutas[0]+item
          });
          break;

        case 1: 
          this.$router.push({
            path: this.rutas[1]+item
          });
          break;

        case 2: 
          this.$router.push({
            path: this.rutas[2]+item
          });
          break;

        case 3: 
          this.$router.push({
            path: this.rutas[3]+item
          });
          break;

        case 4: 
          this.$router.push({
            path: this.rutas[4]+item
          });
          break;
        
        case 5: 
          this.$router.push({
            path: this.rutas[5]+item
          });
          break;

        default: 
          console.log("No hay ruta")
      }
    }
  }
}
</script>