<template>
  <!-- tabla de datos -->
  <app-data-table
    titleTable="Gestionar Categorias"
    titleSearch="Buscar Categoria"
    :loadingTable="loadingTable"
    :headersTable="headersTable"
    :itemsTable="categorias"
    textTable="No hay categorias registradas"
    :acciones="acciones"
    :rutas="rutas"
  >
  </app-data-table>
</template>

<script>
import DataTable from '../../../components/admin/DataTable.vue'

export default {
  data: () => ({
    loadingTable: true,
    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Categoria",
        value: "categoria",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      { 
        text: "Descripción",
        value: "descripcion",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ],

    acciones: [
      {
        tooltip: "Detalles",
        icono: "mdi-eye",
        color: "primary",
        margen: "mr-2"
      },

      {
        tooltip: "Actualizar",
        icono: "mdi-pencil",
        color: "primary"
      }
    ],

    rutas: [
      "/categorias/detalles/",
      "/categorias/actualizar/"
    ]
  }),

  created() {
    this.$store.dispatch("gestionarCategorias");

    setTimeout(() => {
      this.loadingTable = false;
    }, 1000);
  },

  computed: {
    categorias() {
      return this.$store.getters.obtenerCategorias;
    }
  },

  components: {
    "app-data-table": DataTable
  }
};
</script>