<template>
  <div>
    <!-- alerta -->
    <v-dialog 
      max-width="300" 
      v-model="alert"
    >
      <v-card>
        <v-card-title class="justify-center">
          <p 
            class="text-h5 font-weight-bold" 
            :class="titleCard"
          >
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn 
            :color="buttonCard" 
            class="mb-2 white--text" 
            @click="alert = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- formulario -->
    <v-row justify="center">
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Registrar Habilidad
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-text-field 
                    outlined 
                    dense 
                    type="number" 
                    label="Codigo de Habilidad*"
                    maxlength="3" 
                    counter 
                    autofocus
                    v-model="codigoHabilidad" 
                    :rules="codigoHabilidadReglas"
                  >
                  </v-text-field>

                  <v-text-field 
                    outlined 
                    dense 
                    type="text" 
                    label="Habilidad*" 
                    maxlength="100" 
                    counter 
                    v-model="habilidad"
                    :rules="habilidadReglas"
                  >
                  </v-text-field>

                  <v-autocomplete 
                    outlined 
                    multiple 
                    small-chips 
                    label="Programas de Formación"
                    :items="programasFormacion" 
                    item-value="id" 
                    item-text="programa_formacion" 
                    v-model="programaFormacion"
                  >
                  </v-autocomplete>

                  <v-textarea 
                    outlined 
                    type="text" 
                    rows="4" 
                    label="Descripción" 
                    v-model="descripcion"
                  >
                  </v-textarea>

                  <v-btn 
                    large 
                    block 
                    exact 
                    elevation="2" 
                    color="primary" 
                    class="mt-2 mb-6" 
                    @click="registrarHabilidad"
                  >
                    Registrar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,

    loadingForm:  false,
    form:         true,

    codigoHabilidad: "",
    codigoHabilidadReglas: [
      v => !!v || "Escriba un codigo para la habilidad",
      v => (v.length <= 3) || "La cantidad maxima de números es 3",
      v => (v > 0) || "El número debe ser mayor a cero"
    ],

    habilidad: null,
    habilidadReglas: [
      v => !!v || "Escriba un nombre para la habilidad"
    ],

    programaFormacion: [],

    descripcion: null
  }),

  mounted() {
    this.$store.dispatch("crearProgramasFormacion")
  },

  computed: {
    programasFormacion() {
      return this.$store.getters.obtenerProgramasFormacion
    }
  },

  methods: {
    registrarHabilidad() {
      if (this.$refs.form.validate()) {
        this.loadingForm = true

        const habilidad = {
          codigo_habilidad:       this.codigoHabilidad,
          categoria:              this.habilidad[0].toUpperCase() + this.habilidad.toLowerCase().substring(1),
          programa_formacion_id:  this.programaFormacion,
          descripcion:            this.descripcion
        }
  
        this.$store.dispatch("registrarHabilidad", habilidad)
        .then((response) => {
          if (response.status == "success") {
            this.loadingForm    = false
            this.alert          = true
            this.titleAlert     = "Registrada"
            this.messageAlert   = response.message
            this.titleCard      = "success--text"
            this.buttonCard     = "success"

            setTimeout(() => {
              this.$router.push({
                name: "habilidad-gestionar"
              })
            }, 1000)
          } 
          else {
            this.loadingForm = false
            this.alert = true
            this.titleAlert = "Error"
            this.titleCard  = "error--text"
            this.buttonCard = "error"
            for (const error in response.message) {
              this.messageAlert = response.message[error][0]
            }
          }

          setTimeout(() => {
            this.alert = false
          }, 1000)
        })
        .catch(() => {})
      }
    }
  }
}
</script>