import Inicio from './pages/public/Inicio'
import InicioSesion from './pages/auth/InicioSesion'


import TableroParticipante from './pages/admin/tablero/Participante'

import PerfilRegistrar from './pages/administracion/perfiles/Registrar'
import PerfilGestionar from './pages/administracion/perfiles/Gestionar'
import PerfilActualizar from './pages/administracion/perfiles/Actualizar'
import PerfilPermiso from './pages/administracion/perfiles/Permisos'

import UsuarioRegistrar from './pages/admin/usuarios/Registrar'
import UsuarioGestionar from './pages/admin/usuarios/Gestionar'
import UsuarioDetalles from './pages/admin/usuarios/Detalles'
import UsuarioActualizar from './pages/admin/usuarios/Actualizar'
import UsuarioPerfil from './pages/admin/usuarios/Perfil'
import UsuarioImportar from './pages/admin/usuarios/Importar'

import AliadoRegistrar from './pages/admin/aliados/Registrar'
import AliadoGestionar from './pages/admin/aliados/Gestionar'
import AliadoDetalles from './pages/admin/aliados/Detalles'
import AliadoActualizar from './pages/admin/aliados/Actualizar'

import CategoriaRegistrar from './pages/admin/categorias/Registrar'
import CategoriaGestionar from './pages/admin/categorias/Gestionar'
import CategoriaDetalles from './pages/admin/categorias/Detalles'
import CategoriaActualizar from './pages/admin/categorias/Actualizar'
import CategoriaAsignar from './pages/admin/categorias/Asignar'

import CupoGestionar from './pages/admin/cupos/Gestionar'
import CupoAsignado from './pages/admin/cupos/Asignado'
import CuposDetalles from './pages/admin/cupos/Detalles'

import LiderCompetencia from './pages/admin/lideres/Competencia'
import LiderCategoria from './pages/admin/lideres/Categoria'
import LiderImport from './pages/admin/lideres/importar'


import CompetenciaRegistrar from './pages/admin/competencias/Registrar'
import CompetenciaGestionar from './pages/admin/competencias/Gestionar'
import CompetenciaDetalles from './pages/admin/competencias/Detalles'
import CompetenciaActualizar from './pages/admin/competencias/Actualizar'

import InscripcionGestionar from './pages/admin/inscripciones/Gestionar'

import ParticipanteGestionar from './pages/admin/participantes/Gestionar'
import ParticipanteRegistrado from './pages/admin/competencias/Participante'
import ParticipanteDetalle from './pages/admin/participantes/Detalles'
import ParticipanteActualizar from './pages/admin/participantes/Actualizar'

import PersonasGestionar from './pages/worldskills/personas/Gestionar'
import PersonaRegistrar from './pages/worldskills/personas/Registrar'

import SenasoftReporte from './pages/admin/reportes/Senasoft.vue'

import TableroWorldSkills from './pages/worldskills/Tablero.vue'

import HabilidadGestionar from './pages/worldskills/habilidades/Gestionar'
import HabilidadRegistrar from './pages/worldskills/habilidades/Registrar'
import HabilidadDetalle from './pages/worldskills/habilidades/Detalles'
import HabilidadActualizar from './pages/worldskills/habilidades/Actualizar'

import HabilidadAsignar from './pages/worldskills/habilidades/Asignar'
import HabilidadDocumento from './pages/worldskills/habilidades/Documentos'

import CompetenciaWsRegistrar from './pages/worldskills/competencias/Registrar.vue'
import CompetenciaWsGestionar from './pages/worldskills/competencias/Gestionar.vue'
import CompetenciaWsDetalles from './pages/worldskills/competencias/Detalles.vue'
import CompetenciaWsActualizar from './pages/worldskills/competencias/Actualizar.vue'
import DocumentosGCompetencia from './pages/worldskills/competencias/Documentos.vue'

import ParticipanteWsGestionar from './pages/worldskills/participantes/Gestionar'
import ParticipanteWsDetalles from './pages/worldskills/participantes/Detalles'

import UsuarioWsGestionar from './pages/worldskills/usuarios/Gestionar'
import UsuariosWsRegistrar from './pages/worldskills/usuarios/Registrar'
import UsuariosWsDestalle from './pages/worldskills/usuarios/Detalles'
import UsuariosWsActualizar from './pages/worldskills/usuarios/Actualizar'
import UsuarioPerfilWs from './pages/worldskills/usuarios/Perfil'
import UsuariosWsImportarWs from './pages/worldskills/usuarios/Importar'

import ExpertoGestionar from './pages/worldskills/expertos_regional/Gestionar'
import ExpertoDetalles from './pages/worldskills/expertos_regional/Detalles'
import ExpertoNacionalGestionar from './pages/worldskills/expertos_nacional/Gestionar'
import ExpertoNacionalDetalle from './pages/worldskills/expertos_nacional/Detalles'

import SedeRegistrar from './pages/worldskills/sedes/Registrar'
import SedeGestionar from './pages/worldskills/sedes/Gestionar'
import SedeDetalle from './pages/worldskills/sedes/Detalles'
import SedeActualizar from './pages/worldskills/sedes/Actualizar'
import SedeAsignar from './pages/worldskills/sedes/Asignar'

import GanadorGestionar from './pages/worldskills/evaluaciones/GestionarGanador'
import GanadorDetalle from './pages/worldskills/evaluaciones/DetallesGanador'

import AcompananteGestionar from './pages/worldskills/acompanantes/Gestionar'
import AcompananteDetalles from './pages/worldskills/acompanantes/Detalles'

import ForowsRegistrar from './pages/worldskills/forows/registrar.vue'
import ForoVuetify from './pages/worldskills/forows/forovuetify.vue'
import MensajeVuetify from './pages/worldskills/forows/mensajevuetify.vue'

import WorldskillstReporte from './pages/worldskills/reportes/Reportes.vue'


export const routes = [
    // inicio publico 
    { path: '/', name: 'inicio', component: Inicio },
    
    // inicio de sesion
    { path: '/inicio-sesion', name: 'inicio-sesion', component: InicioSesion },


    /* ----- Senasoft ----- */

    // tablero
    { path: '/tablero', name: 'tablero', component: TableroParticipante, meta: {requiresAuth: true, roles: [1,2,3,4,5,6,7,9]} },

    // aliados
    { path: '/aliados', name: 'aliado-gestionar', component: AliadoGestionar, meta: { requiresAuth: true, roles: [1,3]} },
    { path: '/aliados/registrar', name: 'aliado-registrar', component: AliadoRegistrar, meta: { requiresAuth: true, roles: [1,3]} },
    { path: '/aliados/detalles/:id_aliado', name: 'aliado-detalles', component: AliadoDetalles, meta: { requiresAuth: true, roles: [1,3]} },
    { path: '/aliados/actualizar/:id_aliado', name: 'aliado-actualizar', component: AliadoActualizar, meta: { requiresAuth: true, roles: [1,3]} },

    // categorias
    { path: '/categorias', name: 'categoria-gestionar', component: CategoriaGestionar, meta: { requiresAuth: true, roles: [1,3]} },
    { path: '/categorias/registrar', name: 'categoria-registrar', component: CategoriaRegistrar, meta: { requiresAuth: true, roles: [1,3]} },
    { path: '/categorias/detalles/:id_categoria', name: 'categoria-detalles', component: CategoriaDetalles, meta: { requiresAuth: true, roles: [1,3]} },
    { path: '/categorias/actualizar/:id_categoria', name: 'categoria-actualizar', component: CategoriaActualizar, meta: { requiresAuth: true, roles: [1,3]} },
    { path: '/categorias/asignar/:id_competencia', name: 'categoria-asignar', component: CategoriaAsignar, meta: { requiresAuth: true, roles: [1,3]} },

    // competencias
    { path: '/competencias', name: 'competencia-gestionar', component: CompetenciaGestionar, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },
    { path: '/competencias/registrar', name: 'competencia-registrar', component: CompetenciaRegistrar, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },
    { path: '/competencias/detalles/:id_competencia', name: 'competencia-detalles', component: CompetenciaDetalles, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },
    { path: '/competencias/actualizar/:id_competencia', name: 'competencia-actualizar', component: CompetenciaActualizar, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },

    { path: '/inscripciones', name: 'inscripcion-gestionar', component: InscripcionGestionar, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },

    // cupos
    { path: '/cupos/asignados/:id_competencia', name: 'cupo-asignado', component: CupoAsignado, meta: { requiresAuth: true, roles: [1,2,3,4,5,6,7,8,9]} },
    { path: '/cupos/:id_categoria/:id_competencia', name: 'cupo-gestionar', component: CupoGestionar, meta: { requiresAuth: true, roles: [1,2,3,4,5,6,7,8,9]} },
    { path: '/cupos/Detalles/:id_categoria', name: 'cupo-detalle', component: CuposDetalles, meta: { requiresAuth: true, roles: [1,2,3,4,5,6,7,8,9]} },

    // participantes
    { path: '/participantes/inscritos/:id_competencia', name: 'participante-registrado', component: ParticipanteRegistrado, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },

    { path: '/participantes/:id_competencia/:id_categoria', name: 'participante-gestionar', component: ParticipanteGestionar, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },
    { path: '/participantes/detalles/:id_participante', name: 'participante-detalle', component: ParticipanteDetalle, meta: { requiresAuth: true, roles: [1,2,3,7]} },
    { path: '/participantes/actualizar/:id_participante', name: 'participante-actualizar', component: ParticipanteActualizar, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },

    // lideres
    { path: '/lideres/:id_competencia', name: 'lider-competencia', component: LiderCompetencia, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },
    { path: '/lideresCategoria/:id_competencia', name: 'lider-categoria', component: LiderCategoria, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },
    { path: '/lideresImportacion', name: 'lider-import', component: LiderImport, meta: { requiresAuth: true, roles: [1,2,3,7,9]} },

    // reportes
    { path: '/reportes', name: 'reporte', component: SenasoftReporte, meta: { requiresAuth: true, roles: [1,2,3,7,4,5,6,9]} },

    // usuarios
    { path: '/usuarios', name: 'usuario-gestionar', component: UsuarioGestionar },
    { path: '/usuarios/registrar', name: 'usuario-registrar', component: UsuarioRegistrar },
    { path: '/usuarios/detalles/:id_usuario', name: 'usuario-detalles', component: UsuarioDetalles },
    { path: '/usuarios/actualizar/:id_usuario', name: 'usuario-actualizar', component: UsuarioActualizar },
    { path: '/usuarios/perfil/:id_usuario', name: 'usuario-perfil', component: UsuarioPerfil },
    { path: '/usuarios/importar', name: 'usuario-importar', component: UsuarioImportar },

    // personas
    { path:'/personas/registrar/:experto', name:'personas-registrar', component:PersonaRegistrar },
    { path:'/personas/editar/:id', name:'personas-editar', component:PersonaRegistrar },
    { path:'/personas', name:'personas-gestionar', component:PersonasGestionar },


    /* ----- WorldSkills ----- */

    // tablero 
    { path: '/tablero', name: 'tablero-worldskills', component: TableroWorldSkills, meta: { requiresAuth: true, roles: [1,2,3,4,5,6,7,9]} },

    // habilidades
    { path: '/habilidades', name: 'habilidad-gestionar', component: HabilidadGestionar, meta: { requiresAuth: true, roles: [4,3,5,1,7,9]} },
    { path: '/habilidades/registrar', name: 'habilidad-registrar', component: HabilidadRegistrar, meta: { requiresAuth: true, roles: [3,5,1,7,9]} },
    { path: '/habilidades/detalles/:id_habilidad', name: 'habilidad-detalles', component: HabilidadDetalle, meta: { requiresAuth: true, roles: [4,3,5,6,1,7,9]} },
    { path: '/habilidades/actualizar/:id_habilidad', name: 'habilidad-actualizar', component: HabilidadActualizar, meta: { requiresAuth: true, roles: [4,3,1,7]} },

    { path: '/habilidades/documentos/:id_habilidad', name: 'habilidad-documentos', component: HabilidadDocumento, meta: { requiresAuth: true, roles: [4,3,1,7]} },
    { path: '/habilidades/asignar/:id_competencia', name: 'asignar-habilidad', component: HabilidadAsignar, meta: { requiresAuth: true, roles: [4,3,5,6,1,7,9]} },

    // competencias
    { path: '/competenciasws/gestionar', name: 'competenciaws-gestionar', component: CompetenciaWsGestionar, meta: { requiresAuth: true, roles: [4,3,6,5,1,7,9]} },
    { path: '/competenciasws/registrar', name: 'competenciaws-registrar', component: CompetenciaWsRegistrar, meta: { requiresAuth: true, roles: [5,3,1,7,4,9]} },
    { path: '/competenciasws/detalles/:id_competencia', name: 'competenciaws-detalles', component: CompetenciaWsDetalles,meta: { requiresAuth: true, roles: [4,3,5,6,1,7,9]} },
    { path: '/competenciasws/actualizar/:id_competencia', name: 'competenciaws-actualizar', component: CompetenciaWsActualizar, meta: { requiresAuth: true, roles: [3,5,1,7,9]} },
    { path: '/competenciasws/documentos/:id_competencia', name: 'competenciaws-documentos', component: DocumentosGCompetencia, meta: { requiresAuth: true, roles: [3,5,4,1,7,9]} },

    // sedes
    { path: '/sedes', name: 'registrar-sede', component: SedeRegistrar, meta: { requiresAuth: true, roles: [3,1,7]} },
    { path: '/sedes/gestionar/:id_competencia', name: 'gestionar-sede', component: SedeGestionar, meta: { requiresAuth: true, roles: [3,1,7]} },
    { path: '/sedes/detalles/:id_sede', name: 'detalles-sede', component: SedeDetalle, meta: { requiresAuth: true, roles: [3,1,7]} },
    { path: '/sedes/actualizar/:id_sede', name: 'actualizar-sede', component: SedeActualizar, meta: { requiresAuth: true, roles: [3,1,7]} },
    { path: '/sedes/actualizar/:id_sede', name: 'Actualizar-sede', component: SedeActualizar, meta: { requiresAuth: true, roles: [3,1,7]} },
    { path: '/sedes/asignar/:id_competencia', name: 'asignar-sede', component: SedeAsignar, meta: { requiresAuth: true, roles: [3,1,7]} },

    // participantes
    { path: '/participantesws/:id_competencia/:id_habilidad', name: 'participantews-gestionar', component: ParticipanteWsGestionar, meta: { requiresAuth: true, roles: [5,3,4,6,1,7,9]} },
    { path: '/participantesws/detalles/:id_participante', name: 'participantews-detalles', component: ParticipanteWsDetalles, meta: { requiresAuth: true, roles: [5,3,4,6,1,7,9]} },

    // ganadores
    { path: '/ganadoresws/gestionar/:id_competencia/:id_categoria', name: 'ganador-gestionar', component: GanadorGestionar, meta: { requiresAuth: true, roles: [5,3,4,1,7,9]} },
    { path: '/ganadoresws/detalle/:id_evaluacion', name: 'ganador-detalle', component: GanadorDetalle, meta: { requiresAuth: true, roles: [5,3,4,1,7,9]}},

    // expertos
    { path: '/expertosws/:id_competencia/:id_categoria', name: 'expertows-gestionar', component: ExpertoGestionar, meta: { requiresAuth: true, roles: [3,4,5,1,7,9]} },
    { path: '/expertosws/detalles/:id_experto', name: 'expertows-detalles', component: ExpertoDetalles, meta: { requiresAuth: true, roles: [3,4,5,9]} },
    { path: '/expertosnacionalws/:id_competencia/:id_categoria', name: 'expertows-nacional-gestionar', component: ExpertoNacionalGestionar, meta: { requiresAuth: true, roles: [3,4,5,1,7,9]} },
    { path: '/expertosnacionalws/detalle/:id_experto', name: 'expertows-nacional-detalles', component: ExpertoNacionalDetalle, meta: { requiresAuth: true, roles: [3,4,5,1,7,9]} },

    // acompanantes
    { path: '/acompanante/:id_competencia', name: 'gestionar-acompanante', component: AcompananteGestionar, meta: { requiresAuth: true, roles: [3,4,5,1,7,9]} },
    { path: '/acompanante/:id_acompanante/:id_competencia', name: 'detalles-acompanante', component: AcompananteDetalles, meta: { requiresAuth: true, roles: [3,4,5,1,7,9]} },

    // reportes
    { path: '/reportesws', name: 'reportesws', component: WorldskillstReporte, meta: { requiresAuth: true, roles: [1,2,3,7,4,5,6,9]} },

    // usuarios
    { path: '/usuariows/gestionar', name: 'gestionar-usuariows', component: UsuarioWsGestionar, meta: { requiresAuth: true, roles: [1]} },
    { path: '/usuariows/registrar', name: 'registrar-usuariows', component: UsuariosWsRegistrar, meta: { requiresAuth: true, roles: [1]} },
    { path: '/usuariows/detalles/:id_usuario', name: 'detalle-usuariows', component: UsuariosWsDestalle, meta: { requiresAuth: true, roles: [1]} },
    { path: '/usuariows/actualizar/:id_usuario', name: 'usuariows-actualizar', component: UsuariosWsActualizar, meta: { requiresAuth: true, roles: [1]} },
    { path: '/usuariows/perfil/:id_usuario', name: 'usuariows-perfil', component: UsuarioPerfilWs, meta: { requiresAuth: true, roles: [1,2,3,4,5,6,7,9]} },
    { path: '/usuariows/importar', name: 'usuario-ws-importar', component: UsuariosWsImportarWs, meta: { requiresAuth: true, roles: [1]} },


    /* ----- Administracion ----- */

    // perfiles
    { path: '/perfiles', name: 'perfil-gestionar', component: PerfilGestionar },
    { path: '/perfiles/registrar', name: 'perfil-registrar', component: PerfilRegistrar },
    { path: '/perfiles/actualizar/:id_perfil', name: 'perfil-actualizar', component: PerfilActualizar },
    { path: '/perfiles/permisos/:id_perfil', name: 'perfil-permiso', component: PerfilPermiso, meta: { requiresAuth: true, roles: [1]} },

    // foro
    { path: '/registroForows/:id_competencia', name: 'registrar-forows', component: ForowsRegistrar, meta: { requiresAuth: true, roles: [4,1,7]} },
    { path: '/foro/:id_usuario/:id_competencia', name: 'foro', component: ForoVuetify, meta: { requiresAuth: true, roles: [4,6,1,7]} },
    { path: '/mensaje/:id_forum', name: 'mensaje-foro', component: MensajeVuetify, meta: { requiresAuth: true, roles: [4,6,1,7]} }
]

