<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>
    
    <!-- formulario -->
    <v-row justify="center">
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Mi Perfil
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-select
                    outlined
                    dense
                    disabled
                    label="Perfil*"
                    :items="perfiles"
                    item-value="id"
                    item-text="perfil"
                    v-model="perfil"
                    :rules="perfilReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    disabled
                    label="Regional*"
                    :items="regionales"
                    item-value="id"
                    item-text="regional"
                    v-model="regional"
                    :rules="regionalReglas"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    disabled
                    label="Centro de Formación*"
                    :items="centrosFormacion"
                    item-value="id"
                    item-text="centro_formacion"
                    v-model="centroFormacion"
                    :rules="centroFormacionReglas"
                  >
                  </v-select>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Número de Identificación*"
                    min="6"
                    max="10"
                    maxlength="10"
                    counter
                    v-model="identificacion"
                    :rules="identificacionReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Nombres*"
                    maxlength="50"
                    counter
                    v-model="nombres"
                    :rules="nombresReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Apellidos*"
                    maxlength="50"
                    counter
                    v-model="apellidos"
                    :rules="apellidosReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Teléfono*"
                    min="0"
                    max="10"
                    maxlength="10"
                    counter
                    v-model="telefono"
                    :rules="telefonoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Correo Electrónico*"
                    maxlength="50"
                    counter
                    v-model="correo"
                    :rules="correoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    append-outer-icon="mdi-pencil"
                    :type="showPassword ? 'text' : 'password'"
                    label="Contraseña*"
                    v-model="contrasena"
                    :rules="contrasenaReglas"
                    @click:append="showPassword = !showPassword"
                    @click:append-outer="changePassword"
                  >
                  </v-text-field>

                  <template v-if="newPassword">
                    <v-text-field
                      outlined
                      dense
                      :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showNewPassword ? 'text' : 'password'"
                      label="Nueva Contraseña*"
                      v-model="nuevaContrasena"
                      :rules="nuevaContrasenaReglas"
                      @click:append="showNewPassword = !showNewPassword"
                    >
                    </v-text-field>

                    <v-text-field
                      outlined
                      dense
                      :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showConfirmNewPassword ? 'text' : 'password'"
                      label="Confirmar Nueva Contraseña*"
                      v-model="confirmarNuevaContrasena"
                      :rules="confirmarNuevaContrasenaReglas"
                      @click:append="showConfirmNewPassword = !showConfirmNewPassword"
                    >
                    </v-text-field>
                  </template>

                  <v-btn
                    block
                    large
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="actualizar"
                  >
                    Actualizar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Alert from '../../../components/admin/Alert.vue'

export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    loadingForm:  false,
    form:         true,
    showPassword: false,
    showNewPassword: false,
    showConfirmNewPassword: false,
    newPassword:  false,

    perfil: null,
    perfilReglas: [
      v => !!v || "Seleccione un perfil"
    ],

    regional: null,
    regionalReglas: [
      v => !!v || "Seleccione una regional"
    ],

    centroFormacion: null,
    centroFormacionReglas: [
      v => !!v || "Seleccione un centro de formación"
    ],

    identificacion: null,
    identificacionReglas: [
      v => !!v || "Escriba su número de identificación"
    ],

    nombres: null,
    nombresReglas: [
      v => !!v || "Escriba sus nombres"
    ],

    apellidos: null,
    apellidosReglas: [
      v => !!v || "Escriba sus apellidos"
    ],

    telefono: null,
    telefonoReglas: [
      v => !!v || "Escriba un número de telefono"
    ],

    correo: null,
    correoReglas: [
      v => !!v || "Escriba un correo electrónico",
      v => /.+@.+\..+/.test(v) || "Correo electrónico no válido",
    ],

    contrasena: null,
    contrasenaReglas: [
      v => !!v || "Confirme su contraseña"
    ],

    nuevaContrasena: null,
    nuevaContrasenaReglas: [
      v => !!v || "Escriba una nueva contraseña"
    ],
    
    confirmarNuevaContrasena: null
  }),

  created() {
    this.$store.dispatch("gestionarPerfiles")
    this.$store.dispatch("crearRegionales")
    this.$store.dispatch("crearCentrosFormacion")
    this.$store.dispatch("editarUsuario", this.$route.params.id_usuario)
    .then((response) => {
      this.perfil           = response.perfil_id,
      this.regional         = response.centro_formacion.regional_id
      this.centroFormacion  = response.centro_formacion_id
      this.identificacion   = response.identificacion;
      this.nombres          = response.nombres;
      this.apellidos        = response.apellidos;
      this.telefono         = response.telefono;
      this.correo           = response.correo;
    })
    .catch(() => {});
  },

  computed: {
    perfiles() {
      return this.$store.getters.obtenerPerfiles
    },

    regionales() {
      return this.$store.getters.obtenerRegionales;
    },

    centrosFormacion() {
      return this.$store.getters.obtenerCentrosFormacion(this.regional);
    },

    confirmarNuevaContrasenaReglas () {
      const reglas = []

      const regla1 = v => !!v || "Confirme la nueva contraseña"
      reglas.push(regla1)

      if (this.nuevaContrasena) {
        const regla2 =
          v => (!!v && v) === this.nuevaContrasena ||
            'La nueva contraseña y confirmar nueva contraseña no coinciden'
        reglas.push(regla2)
      }

      return reglas
    }
  },

  components: {
    "app-alert": Alert
  },

  methods: {
    changePassword () {
      if (this.newPassword) {
        this.newPassword = false
      }
      else {
        this.newPassword = true
      }
    },

    actualizar () {
      if (this.$refs.form.validate()) {
        const usuario = {
          id:                   this.$route.params.id_usuario,
          perfil_id:            this.perfil,
          centro_formacion_id:  this.centroFormacion,
          identificacion:       this.identificacion,
          nombres:              this.nombres,
          apellidos:            this.apellidos,
          telefono:             this.telefono,
          correo:               this.correo,
          password:             this.contrasena,
          new_password:         this.nuevaContrasena,
          confirm_new_password: this.confirmarNuevaContrasena
        }

        this.loadingForm = true;

        this.$store.dispatch("actualizarPerfilUsuario", usuario)
        .then((response) => {
          if (response.status == "success") {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Actualizado";
            this.messageAlert = response.message;
            this.titleCard    = "success--text";
            this.buttonCard   = "success";

            this.newPassword = false
          } 
          else if (response.code == "ERR-003") {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message;
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }
          else {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message.identificacion[0];
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }

          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {});
      }
    }
  }
};
</script>