<template>
  <!-- tabla de datos -->
  <app-data-table
    titleTable="Gestionar Aliados"
    titleSearch="Buscar Aliado"
    :loadingTable="loadingTable"
    :headersTable="headersAliados"
    :itemsTable="aliados"
    textTable="No hay aliados registrados"
    :acciones="acciones"
    :rutas="rutas"
  >
  </app-data-table>
</template>

<script>
import DataTable from '../../../components/admin/DataTable.vue'

export default {
  data: () => ({
    loadingTable: true,
    headersAliados: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Aliado",
        value: "aliado",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Dirección",
        value: "direccion",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Teléfono",
        value: "telefono",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Correo Electrónico",
        value: "correo",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Contacto del Aliado",
        value: "contacto_aliado",
        align: "start",
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      }
    ],

    acciones: [
      {
        tooltip: "Detalles",
        icono: "mdi-eye",
        color: "primary",
        margen: "mr-2"
      },

      {
        tooltip: "Actualizar",
        icono: "mdi-pencil",
        color: "primary",
      }
    ],

    rutas: [
      "/aliados/detalles/",
      "/aliados/actualizar/"
    ]
  }),

  created() {
    this.$store.dispatch("gestionarAliados");
    setTimeout(() => {
      this.loadingTable = false;
    }, 1000);
  },

  computed: {
    aliados() {
      return this.$store.getters.obtenerAliados;
    },
  },

  components: {
    "app-data-table": DataTable
  }
};
</script>