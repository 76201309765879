<template>
  <div>
    <!-- alerta -->
    <v-dialog max-width="280" v-model="alert">
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :color="buttonCard"
            class="mb-2 white--text"
            @click="alert = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="loaderTable">
      <v-skeleton-loader class="mx-auto" type="table"> </v-skeleton-loader>
    </template>
    <template v-else>
      <!-- tabla de datos -->
      <v-row justify="center">
        <v-col cols="12">
          <router-view></router-view>
          <v-card>
            <v-card-title class="text-h5 font-weight-bold primary--text">
              <v-row align="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      exact
                      color="primary"
                      class="mx-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="regresar"
                    >
                      <v-icon> mdi-arrow-left </v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar</span>
                </v-tooltip>
                <v-col cols="10" sm="11" md="7">
                  {{ competencia.competencia }}
                </v-col>

                <v-col cols="12" sm="10" md="3">
                  <v-text-field
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    label="Buscar Categoria"
                    hide-details
                    v-model="searchTable"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="1">
                  <v-btn exact color="primary" @click="abrirDialog">
                    Asignar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>

            <v-data-table
              fixed-header
              height="460px"
              class="elevation-2"
              :headers="headersTable"
              :items="categoriasCompetencia"
              :search="searchTable"
              :footer-props="{ 'items-per-page-options': [20, 50, -1] }"
            >
              <template v-slot:top>
                <v-dialog persistent v-model="dialogForm" max-width="520px">
                  <v-card :loading="loadingForm">
                    <v-card-title class="justify-center">
                      <p class="text-h5 font-weight-bold primary--text">
                        {{ formTitle }}
                      </p>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" md="10">
                            <v-form ref="form" v-model="form">
                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="text"
                                label="Competencia"
                                v-model="competenciaSeleccionada"
                              >
                              </v-text-field>

                              <template v-if="editedIndex === -1">
                                <v-select
                                  outlined
                                  dense
                                  label="Categoria*"
                                  :items="categorias"
                                  item-value="id"
                                  item-text="categoria"
                                  v-model="categoria"
                                  :rules="categoriaReglas"
                                >
                                </v-select>
                              </template>
                              <template v-else>
                                <v-select
                                  outlined
                                  dense
                                  disabled
                                  label="Categoria*"
                                  :items="categorias"
                                  item-value="id"
                                  item-text="categoria"
                                  v-model="categoria"
                                  :rules="categoriaReglas"
                                >
                                </v-select>
                              </template>

                              <v-select
                                outlined
                                dense
                                label="Modalidad*"
                                :items="modalidades"
                                item-value="id"
                                item-text="modalidad"
                                v-model="modalidad"
                                :rules="modalidadReglas"
                              >
                              </v-select>

                              <v-text-field
                                outlined
                                dense
                                type="number"
                                maxlength="2"
                                label="Número de Participantes*"
                                v-model="numeroParticipantes"
                                :rules="numeroParticipantesReglas"
                                @change="
                                  gruposParticipantes(numeroParticipantes)
                                "
                              >
                              </v-text-field>
                              <v-text-field
                                outlined
                                dense
                                disabled
                                type="number"
                                label="Número de Grupos"
                                v-model="cantidadGrupos"
                              >
                              </v-text-field>
                            </v-form>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="error" @click="cerrarDialog">
                        Cancelar
                      </v-btn>

                      <template v-if="editedIndex === -1">
                        <v-btn text exact color="primary" @click="asignar">
                          Asignar
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn text exact color="primary" @click="actualizar">
                          Actualizar
                        </v-btn>
                      </template>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>

              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      @click="editar(item.categoria_id, item.competencia_id)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Actualizar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      @click="
                        registrarCupo(item.categoria_id, item.competencia_id)
                      "
                    >
                      mdi-text-box-plus
                    </v-icon>
                  </template>
                  <span>Registrar Cupo</span>
                </v-tooltip>

                <template v-if="item.estado_inscripcion">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        medium
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          estadoInscripcion(item.categoria_id, item.competencia_id)
                        "
                      >
                        mdi-timer-outline
                      </v-icon>
                    </template>
                    <span>Finalizar Inscripciones</span>
                  </v-tooltip>
                </template>

                <template v-else>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        medium
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          estadoInscripcion(item.categoria_id, item.competencia_id)
                        "
                      >
                        mdi-timer-outline
                      </v-icon>
                    </template>
                    <span>Abrir Inscripciones</span>
                  </v-tooltip>
                </template>
                
                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        medium
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="activarAlertaEliminar(item.categoria_id, item.competencia_id)"
                      >
                        mdi-delete
                      </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip> -->

              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center text-h5">
          ¿Desea eliminar la categoria?
        </v-card-title
      >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeDelete">Cancelar </v-btn>
          <v-btn
            text
            color="error"
            @click="eliminar(temCategoria_id, temCompetencia_id)"
            >OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    loaderTable: true,
    searchTable: null,

    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    loadingForm: false,
    form: true,

    dialogForm: false,
    dialogDelete: false,

    temCategoria_id: null,
    temCompetencia_id: null,

    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Categoria",
        value: "categoria",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Modalidad",
        value: "modalidad",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "# Participantes",
        value: "numero_participantes",
        align: "start",
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "# Grupos",
        value: "cantidad_grupos",
        align: "start",
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ],
    competenciaSeleccionada: "",
    categoria: null,
    categoriaReglas: [(v) => !!v || "Seleccione una categoria"],
    numeroParticipantesReglas: [(v) => v > 0 || "Debe ingresar un número mayor"],
    modalidad: null,
    modalidadReglas: [(v) => !!v || "Seleccione una modalidad"],
    numeroParticipantes: 0,
    cantidadGrupos: null,
    cantidadGruposReglas: [(v) => v.float | v.length || "Numero de campo dado"],
    editedIndex: -1,
    asignarCategoria: false,
  }),

  mounted() {
    this.$store.dispatch(
      "detallesCompetencia",
      this.$route.params.id_competencia
    );

    this.$store.dispatch(
      "gestionarCategoriasCompetencia",
      this.$route.params.id_competencia
    );

    this.$store.dispatch("gestionarCategorias");
    this.$store.dispatch("crearModalidades");

    setTimeout(() => {
      this.loaderTable = false;
    }, 1000);
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Asignar Categoria"
        : "Actualizar Categoria Asignada";
    },

    categoriasCompetencia() {
      return this.$store.getters.obtenerCategoriasCompetencia;
    },

    competencia() {
      return this.$store.getters.obtenerCompetencia;
    },

    categorias() {
      return this.$store.getters.obtenerCategorias;
    },

    modalidades() {
      return this.$store.getters.obtenerModalidades;
    },

    cupoRegistrado() {
      return this.cupos.reduce((sum, cupo) => {
        return sum + cupo.numero_participantes;
      }, 0);
    },

    cupoDisponible() {
      return (
        this.categoriaCompetencia.numero_participantes - this.cupoRegistrado
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.cerrarDialog();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    gruposParticipantes(numero) {
      if (this.modalidad == 1) {
        this.cantidadGrupos = this.numeroParticipantes;
      } else if (this.modalidad == 2) {
        if (numero != 0) {
          const calculo = numero % 2;
          if (calculo == 0) {
            this.cantidadGrupos = numero / 2;
          } else {
            this.alert = true;
            this.titleAlert = "Error";
            this.messageAlert = "El número de participantes no coincide con la modalidad";
            this.titleCard = "error--text";
            this.buttonCard = "error";
          }
        }
      } else {
        if (numero != 0) {
          const calculo = numero % 3;
          if (calculo == 0) {
            this.cantidadGrupos = numero / 3;
          } else {
            this.alert = true;
            this.titleAlert = "Error";
            this.messageAlert = "El número de participantes no coincide con la modalidad";
            this.titleCard = "error--text";
            this.buttonCard = "error";
          }
        }
      }
    },

    abrirDialog() {
      this.dialogForm = true;
      this.competenciaSeleccionada = this.competencia.competencia;
    },

    editar(idCategoria, idCompetencia) {
      this.editedIndex = 1;

      const categoriaCompetencia = {
        competencia_id: idCompetencia,
        categoria_id: idCategoria,
      };

      this.$store
        .dispatch("editarCategoriaCompetencia", categoriaCompetencia)
        .then((response) => {
          this.competenciaSeleccionada = this.competencia.competencia;
          this.categoria = response.categoria_id;
          this.modalidad = response.modalidad_id;
          this.numeroParticipantes = response.numero_participantes;
          this.cantidadGrupos = response.cantidad_grupos;
        })
        .catch(() => {});

      this.dialogForm = true;
    },

    deleteItem(item) {
      this.editedIndex = this.categoriasCompetencia.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.categoriasCompetencia.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    cerrarDialog() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    asignar() {
      if (this.numeroParticipantes != 0) {
        switch (this.modalidad) {
          case 1:
            this.asignarCategoria = true;
            break;
          case 2:
            var calculo = this.numeroParticipantes % 2;
            if (calculo == 0) {
              this.numero_participantes
              this.asignarCategoria = true;


            } else {
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = "El número de participantes no coincide con la modalidad";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              this.asignarCategoria = false;
            }
            break;
          case 3:
          var calculo2 = this.numeroParticipantes % 3;
            if (calculo2 == 0) {
              this.numero_participantes
              this.asignarCategoria = true;
            } else {
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = "El número de participantes no coincide con la modalidad";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              this.asignarCategoria = false;
            }
            break;
          default:
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = "Debe seleccionar una modalidad";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              this.asignarCategoria = false;
        }
      }else {
        this.alert = true;
        this.titleAlert = "Error";
        this.messageAlert = "El número de participantes no coincide con la modalidad";
        this.titleCard = "error--text";
        this.buttonCard = "error";
      }


      if(this.asignarCategoria === true) {
         
          if (this.$refs.form.validate()) {
            const categoriaCompetencia = {
              competencia_id: this.$route.params.id_competencia,
              categoria_id: this.categoria,
              modalidad_id: this.modalidad,
              numero_participantes: this.numeroParticipantes,
              cantidad_grupos: this.cantidadGrupos,
            };
            this.$store
              .dispatch("asignarCategoriaCompetencia", categoriaCompetencia)
              .then((response) => {
                if (response.status == "success") {
                  this.loadingForm = false;
                  this.cerrarDialog();
                  this.alert = true;
                  this.titleAlert = "Asignada";
                  this.messageAlert = response.message;
                  this.titleCard = "success--text";
                  this.buttonCard = "success";


                  this.$store.dispatch(
                    "gestionarCategoriasCompetencia",
                    this.$route.params.id_competencia
                  );
                } else {
                  this.loadingForm = false;
                  this.alert = true;
                  this.titleAlert = "Error";
                  this.messageAlert = response.message.categoriaCompetencia;
                  this.titleCard = "error--text";
                  this.buttonCard = "error";
                }
                setTimeout(() => {
                  this.alert = false;
                }, 1000);
              })
              .catch(() => {});
          }
      }

    },

    actualizar() {
      if (this.numeroParticipantes != 0) {
        switch (this.modalidad) {
          case 1:
            this.asignarCategoria = true;
            break;
          case 2:
            var calculo = this.numeroParticipantes % 2;
            if (calculo == 0) {
              this.numero_participantes
              this.asignarCategoria = true;
            } else {
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = "El número de participantes no coincide con la modalidad";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              this.asignarCategoria = false;
            }
            break;
          case 3:
          var calculo2 = this.numeroParticipantes % 3;
            if (calculo2 == 0) {
              this.numero_participantes
              this.asignarCategoria = true;
            } else {
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = "El número de participantes no coincide con la modalidad";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              this.asignarCategoria = false;
            }
            break;
          default:
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = "Debe seleccionar una modalidad";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              this.asignarCategoria = false;
        }
      }else {
        this.alert = true;
        this.titleAlert = "Error";
        this.messageAlert = "El número de participantes no coincide con la modalidad";
        this.titleCard = "error--text";
        this.buttonCard = "error";
      }


      if(this.asignarCategoria === true) {
        if (this.$refs.form.validate()) {
          const categoriaCompetencia = {
            competencia_id: this.$route.params.id_competencia,
            categoria_id: this.categoria,
            modalidad_id: this.modalidad,
            numero_participantes: this.numeroParticipantes,
            cantidad_grupos: this.cantidadGrupos,
          };
          this.loadingForm = true;
          this.$store
            .dispatch("actualizarCategoriaCompetencia", categoriaCompetencia)
            .then((response) => {
              if (response.status == "success") {
                this.loadingForm = false;
                this.cerrarDialog();
                this.alert = true;
                this.titleAlert = "Actualizada";
                this.messageAlert = response.message;
                this.titleCard = "success--text";
                this.buttonCard = "success";
                this.$store.dispatch(
                  "gestionarCategoriasCompetencia",
                  this.$route.params.id_competencia
                );
              } 
              else {
                this.loadingForm = false;
                this.alert = true;
                this.titleAlert = "Error";
                this.messageAlert = response.message.categoriaCompetencia;
                this.titleCard = "error--text";
                this.buttonCard = "error";
              }
              setTimeout(() => {
                this.alert = false;
              }, 1000);
            })
            .catch(() => {});
        }
      }
    },

    registrarCupo(idCategoria, idCompetencia) {
      this.$router.push({
        name: "cupo-gestionar",
        params: { id_categoria: idCategoria, id_competencia: idCompetencia },
      });
    },

    estadoInscripcion(idCategoria, idCompetencia) {
      const categoriaCompetencia = {
        categoria: idCategoria,
        competencia: idCompetencia
      }

      this.$store.dispatch("estadoInscripcion", categoriaCompetencia)
        .then((response) => {
          if (response.status == "success") {
            this.alert = true;
            this.titleAlert = "Actualizada";
            this.messageAlert = response.message;
            this.titleCard = "success--text";
            this.buttonCard = "success";
            this.$store.dispatch("gestionarCategoriasCompetencia", this.$route.params.id_competencia);
          } 
          else {
            this.alert = true;
            this.titleAlert = "Error";
            this.messageAlert = response.message.categoriaCompetencia;
            this.titleCard = "error--text";
            this.buttonCard = "error";
          }
          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {});
    },

    activarAlertaEliminar(categoria_id, competencia_id) {
      this.temCategoria_id = categoria_id;
      this.temCompetencia_id = competencia_id;
      this.dialogDelete = true;
    },

    eliminar(idCategoria, idCompetencia) {
      this.dialogDelete = false;
      const categoriaCompetencia = {
        categoria_id: idCategoria,
        competencia_id: idCompetencia,
      };
      this.$store
        .dispatch("eliminarCategoriaCompetencia", categoriaCompetencia)
        .then((response) => {
          if (response.status == "success") {
            this.alert = true;
            this.titleAlert = "Eliminada";
            this.messageAlert = response.message;
            this.titleCard = "success--text";
            this.buttonCard = "success";

            this.$store.dispatch(
              "gestionarCategoriasCompetencia",
              this.$route.params.id_competencia
            );
          } else {
            this.alert = true;
            this.titleAlert = "Error";
            this.messageAlert = response.message.cupo;
            this.titleCard = "error--text";
            this.buttonCard = "error";
          }
          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {});
    },

    regresar() {
      this.$router.push({
        name: "competencia-gestionar",
      });
    },
  },
};
</script>
