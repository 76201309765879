<template>
  <v-row>
    <v-col
      sm="1"
      lg="2"
      class="hidden-xs-only"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            fixed
            exact
            color="primary"
            v-bind="attrs" 
            v-on="on" 
            @click="regresar"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>Regresar</span>
      </v-tooltip>
    </v-col>
    <v-col cols="12" sm="10" lg="8">
      <v-card outlined elevation="2">
        <v-card-title class="justify-center">
          <p class="text-h4 font-weight-bold primary--text mt-2">
            Detalles Ganador
          </p>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Identificación</p>
              <p class="text-subtitle-1">
                {{ participante.identificacion }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Nombres</p>
              <p class="text-subtitle-1">
                {{ participante.nombres }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Apellidos</p>
              <p class="text-subtitle-1">
                {{ participante.apellidos }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Télefono</p>
              <p class="text-subtitle-1">
                {{ participante.telefono }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Correo Electrónico</p>
              <p class="text-subtitle-1">
                {{ participante.correo }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Fecha de Nacimiento</p>
              <p class="text-subtitle-1">
                {{ participante.fecha_nacimiento }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Género</p>
              <p class="text-subtitle-1">
                {{ participante.genero }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Programa de Formación</p>
              <p class="text-subtitle-1">
                {{ participante.programa_formacion }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Número de Ficha</p>
              <p class="text-subtitle-1">
                {{ participante.numero_ficha }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Medalla</p>
              <p class="text-subtitle-1">
                {{ participante.medalla }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Puntaje</p>
              <template v-if="participante.puntaje != null">
                <p>
                  {{participante.puntaje}}
                </p>
              </template>
              <template v-else>
                <p class="text-subtitle-1">
                  No registrado
                </p>
              </template>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Descripcion</p>
              <template v-if="participante.descripcion != null">
                <p>
                  {{participante.descripcion}}
                </p>
              </template>
              <template v-else>
                <p class="text-subtitle-1">
                  No registrada
                </p>
              </template>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Regional</p>
              <p class="text-subtitle-1">
                {{ participante.regional }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Centro de Formación</p>
              <p class="text-subtitle-1">
                {{ participante.centro_formacion }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Habilidad</p>
              <p class="text-subtitle-1">
                {{ participante.categoria }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Competencia</p>
              <p class="text-subtitle-1">
                {{ participante.competencia }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    mounted() {
      this.$store.dispatch("gestionarGanadorWorldSkills", this.$route.params.id_competencia)    
    },

    computed: {
      participante() {
        return this.$store.getters.obtenerGanadorWorldSkills(this.$route.params.id_evaluacion)
      },
    },

    methods: {
      regresar() {
        this.$router.push({
          name: "ganador-gestionar",
          params: { 
            id_categoria: this.$route.params.id_categoria, 
            id_competencia:  this.$route.params.id_competencia
          }
        });
      }
    }
  }
</script>