import axios from 'axios'

const autenticacion = {
    state: () => ({
        usuario: null,
        auth: false,
        role: null
    }),

    getters: {
        usuario: (state) => {
            return state.usuario
        },

        auth: (state) => {
            return state.auth
        },
        role:(state)=>{
            return state.role
        }
    },

    mutations: {
        enviarUsuario (state, usuario) {
            state.usuario = usuario.usuario
            state.auth = usuario.auth
            state.role=usuario.usuario.perfil_id
        }
    },

    actions: {
        iniciarSesion(context, usuario) {
            return new Promise((resolve, reject) => {
                axios.get("sanctum/csrf-cookie")
                .then(() => {
                    axios.post("login", usuario)
                    .then(() => {
                        context.dispatch("autenticar")
                        .then(() => {
                            resolve()
                        })
                        .catch(() => {}) 
                    })
                    .catch(() => {
                        reject()
                    }) 
                })
                .catch(() => {}) 
            })
        },

        autenticar(context) {
            return new Promise((resolve, reject) => {
                axios.get("api/user")
                .then((response) => {
                    const usuario = {
                        usuario: response.data,
                        auth: true
                    }
                    context.commit("enviarUsuario", usuario)
                    resolve() 
                })
                .catch(() => {
                    reject()
                })
            })
        },

        cerrarSesion(context) {
            return new Promise((resolve, reject) => {
                axios.post("logout")
                .then(() => {
                    const usuario = {
                        usuario: null,
                        auth: false
                    }
                    context.commit("enviarUsuario", usuario)
                    resolve()
                })
                .catch(() => {
                    reject()
                })
            }) 
        }
    }
}

export default autenticacion