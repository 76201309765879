<template>
  <v-dialog 
    max-width="320" 
    v-model="alert"
  >
    <v-card>
      <v-card-title class="justify-center">
        <p 
          class="font-weight-bold text-h5" 
          :class="titleCard"
        >
          {{ titleAlert }}
        </p>
      </v-card-title>
      <v-card-text class="text-h6 text-center">
        {{ messageAlert }}
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn 
          :color="buttonCard" 
          class="mb-2 white--text" 
          @click="(alert = !alert)"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    alert:        Boolean,
    titleCard:    String,
    titleAlert:   String,
    messageAlert: String,
    buttonCard:   String
  }
};
</script>