<template>
  <v-row justify="center">
    <v-col cols="12">
      <router-view></router-view>
      <!-- tabla de datos --> 
      <v-card>
        <v-card-title class="text-h5 font-weight-bold primary--text">
          <v-row align="center">
            <v-col 
              cols="12"
              sm="4"
            >
              Gestionar Usuarios
            </v-col>
            <v-col 
              cols="12"
              sm="4"
              offset-sm="4"
            >
              <v-text-field
                outlined
                dense
                append-icon="mdi-magnify"
                label="Buscar Usuario"
                hide-details
                v-model="searchTable"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
  
        <v-data-table
          fixed-header
          height="460px"
          class="elevation-2"
          :loading="cargar"
          :headers="headersTable"
          :items="usuarios"
          :search="searchTable"
          no-data-text="No hay usuarios registrados"
          :footer-props="{ 'items-per-page-options': [100, 500, -1] }"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="detalles(item.id)"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>Detalles</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="actualizar(item.id)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    searchTable: null,
    loadingTable: true,

    headersTable: [
      {
        text: "Identificacion",
        value: "identificacion",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Nombres",
        value: "nombres",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Apellidos",
        value: "apellidos",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Correo Electrónico",
        value: "correo",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Perfil",
        value: "perfil",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ]
  }),

  mounted() {
    this.$store.dispatch("gestionarUsuarios")
    setTimeout(() => {
      this.loadingTable = false
      const storedSearch = this.$store.getters.obtenerSearch;
      if (storedSearch) {
        this.searchTable = storedSearch;
      }
    }, 1000)
  },

  watch: {
    searchTable(newSearch) {
      this.$store.dispatch("guardarSearch", newSearch);
    },
  },

  computed: {
    usuarios () {
      return this.$store.getters.obtenerUsuarios;
    },

    cargar(){
      return this.$store.getters.obtenercargarUsuario;
    }
  },

  methods: {
    detalles (idUsuario) {
      this.$router.push({ 
        name: 'usuario-detalles',
        params: { id_usuario: idUsuario }
      })
    },

    actualizar (idUsuario) {
      this.$router.push({ 
        name: 'usuario-actualizar',
        params: { id_usuario: idUsuario }
      })
    }
  }
}
</script>