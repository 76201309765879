import axios from 'axios'

const usuarios = {
    state: () => ({
        usuarios: [],
        usuario: "",
        usr: null,
        usuariosws: [],
        usuariows: "",
        cargar:true,
        cargarUsuarios:true,
        // searchTable: ""
    }),

    getters: {
        // obtenerSearch(state) {
        //     return state.searchTable
        // },

        obtenerUsuarios(state) {
            return state.usuarios
        },

        obtenerUsuarioWs (state) {
            return state.usuariows
        },
       
        obtenerUsuario (state) {
            return state.usuario
        },

        obtenerUsuariosWs(state){
            return state.usuariosws
        },

        obtenerUsuarioExperto: (state) => (identificacion) => {
            return state.usuariosws.find(usuariows => usuariows.identificacion == identificacion)
        },
        obtenercarga(state){
            return state.cargar
        },
        obtenercargarUsuario(state){
            return state.cargarUsuarios
        },
    },

    mutations: {
        enviarUsuarios(state, usuarios) {
            state.usuarios = usuarios
        },

        enviarUsuarioWs(state, usuariows) {
            state.usuariows = usuariows
        },

        enviarUsu(state, usuario) {
            state.usuario = usuario
        },

        importarUsuarios(state, usr){
            state.usr = usr
        },

        enviarUsuariosWs(state, usuariosws){
            state.usuariosws = usuariosws
        },
        enviarcarga(state,cargando){
            state.cargar=cargando
        },
        enviarcargaUsuario(state,cargando){
            state.cargarUsuarios=cargando
        },
        // enviarSearchTable(state, search){
        //     state.searchTable=search
        // }
    },

    actions: {
        registrarUsuario(context, usuario) {
            return new Promise((resolve, reject) => {
                axios.post("api/usuarios", usuario)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        // guardarSearch(context, search){
        //     context.commit("enviarSearchTable", search)
        // },

        gestionarUsuarios(context) {
            axios.get("api/usuarios")
            .then((response) => {
                const usuarios = []
                for (let i = 0; i < response.data.results.length; i++) {
                    usuarios.push({
                        indice: (i+1),
                        id:                     response.data.results[i].id_usuario,
                        perfil_id:              response.data.results[i].perfil.id_perfil,
                        perfil:                 response.data.results[i].perfil.perfil,
                        centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
                        regional_id:            response.data.results[i].centro_formacion.regional_id,
                        identificacion:         response.data.results[i].identificacion,
                        nombres:                response.data.results[i].nombres,
                        apellidos:              response.data.results[i].apellidos,
                        telefono:               response.data.results[i].telefono,
                        correo:                 response.data.results[i].correo
                    })
                }
                context.commit("enviarUsuarios", usuarios)
                context.commit("enviarcargaUsuario",false)
            })
            .catch(() => {})
        },

        detallesUsuario(context, usuario) {
            axios.get("api/usuarios/"+usuario)
            .then((response) => {
                const usuario = {
                    id:                     response.data.results.id_usuario,
                    perfil_id:              response.data.results.perfil.id_perfil,
                    perfil:                 response.data.results.perfil.perfil,
                    regional_id:            response.data.results.centro_formacion.regional.id_regional, 
                    regional:               response.data.results.centro_formacion.regional.regional, 
                    centro_formacion_id:    response.data.results.centro_formacion.id_centro_formacion,
                    centro_formacion:       response.data.results.centro_formacion.centro_formacion,
                    identificacion:         response.data.results.identificacion,
                    nombres:                response.data.results.nombres,
                    apellidos:              response.data.results.apellidos,
                    telefono:               response.data.results.telefono,
                    correo:                 response.data.results.correo,
                }
                context.commit("enviarUsu", usuario)
            })
            .catch(() => {})
        },

        editarUsuario(context, usuario) {
            return new Promise((resolve, reject) => {
                axios.get("api/usuarios/"+usuario+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarUsuario(context, usuario) {
            return new Promise((resolve, reject) => {
                axios.put('api/usuarios/'+usuario.id, usuario)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarPerfilUsuario(context, usuario) {
            return new Promise((resolve, reject) => {
                axios.put('api/perfilUsuario/'+usuario.id, usuario)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        importarUsuario(context, usr) {
            const formData = new FormData();
            formData.append('file', usr);
            return new Promise((resolve, reject) => {
                axios.post("api/importarExcel", formData, { headers: {'Content-Type': 'multipart/form-data'} })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarUsuariosWs(context){
            axios.get("api/usuarioWS")
            .then((response) => {
                const usuariosws = []
                for (let i = 0; i < response.data.results.length; i ++){
                    usuariosws.push({
                        indice: (i+1),
                        id:                     response.data.results[i].id_usuario,
                        perfil_id:              response.data.results[i].perfil.id_perfil,
                        perfil:                 response.data.results[i].perfil.perfil,
                        centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
                        regional_id:            response.data.results[i].centro_formacion.regional_id,
                        identificacion:         response.data.results[i].identificacion,
                        nombres:                response.data.results[i].nombres,
                        apellidos:              response.data.results[i].apellidos,
                        telefono:               response.data.results[i].telefono,
                        correo:                 response.data.results[i].correo
                    })
                }
                context.commit("enviarUsuariosWs", usuariosws)
                context.commit("enviarcarga",false)
            })
            .catch(() => {})
        },

        registrarUsuarioWs(context, usuariows) {
            return new Promise((resolve, reject) => {
                axios.post("api/usuarioWS", usuariows)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },
        
        editarUsuarioWs(context, usuariows) {
            return new Promise((resolve, reject) => {
                axios.get("api/usuarioWS/"+usuariows+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarUsuarioWs(context, usuariows) {
            return new Promise((resolve, reject) => {
                axios.put('api/usuarioWS/'+usuariows.id, usuariows)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarPerfilUsuarioWs(context, usuariows) {
            return new Promise((resolve, reject) => {
                axios.put('api/perfilUsuarioWS/'+usuariows.id, usuariows)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        detallesUsuarioWs(context, usuariows) {
            axios.get("api/usuarioWS/"+usuariows)
            .then((response) => {
                const usuariows = {
                    id:                     response.data.results.id_usuario,
                    perfil_id:              response.data.results.perfil.id_perfil,
                    perfil:                 response.data.results.perfil.perfil,
                    regional_id:            response.data.results.centro_formacion.regional.id_regional, 
                    regional:               response.data.results.centro_formacion.regional.regional, 
                    centro_formacion_id:    response.data.results.centro_formacion.id_centro_formacion,
                    centro_formacion:       response.data.results.centro_formacion.centro_formacion,
                    identificacion:         response.data.results.identificacion,
                    nombres:                response.data.results.nombres,
                    apellidos:              response.data.results.apellidos,
                    telefono:               response.data.results.telefono,
                    correo:                 response.data.results.correo,
                }
                context.commit("enviarUsuarioWs", usuariows)
            })
            .catch(() => {})
        },

        importarUsuarioWs(context, usr) {
            const formData = new FormData();
            formData.append('usuarioWs', usr);
            return new Promise((resolve, reject) => {
                axios.post("api/importarUsuarioWs", formData, { headers: {'Content-Type': 'multipart/form-data'} })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        //async downloadUsuarios(context,usuario){
        // descargarPlantillaUsuarios (context, usuariosw) {
        //     return new Promise((resolve, reject) => {
        //         axios.post("api/reportes/plantillaUsuariosWsExport", usuariosw, { responseType: 'blob' })
        //         .then((response) => {
        //             resolve(response.data)
        //         })
        //         .catch(() => {
        //             reject()
        //         })
        //     })
        // },

        async downloadedoc(context,usuarios){
            return new Promise((resolve, reject) => {
               axios.post("api/reportes/plantillaUsuariosWsExport", usuarios, {responseType: 'blob'})
                .then((response) => {
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
            })
        }    
        
    }
}

export default usuarios