<template>
  <v-row>
    <v-col
      sm="1"
      lg="2"
      class="hidden-xs-only"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            fixed
            exact
            color="primary"
            v-bind="attrs" 
            v-on="on" 
            @click="regresar"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>Regresar</span>
      </v-tooltip>
    </v-col>
    <v-col 
      cols="12" 
      sm="10" 
      lg="8"
    >
      <v-card 
        outlined 
        elevation="2"
      >
        <v-card-title class="justify-center">
          <p class="text-h4 font-weight-bold primary--text mt-2">
            Detalles Usuario
          </p>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col
              v-for="(item, i) in usuario" 
              :key="i"
              cols="12" 
              sm="10" 
              lg="8"
            >
              <p class="text-h6 font-weight-bold">
                {{ item.label }}
              </p>
              <p class="text-subtitle-1">
                {{ item.value }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  created () {
    this.$store.dispatch("detallesUsuario", this.$route.params.id_usuario)
  },

  computed: {
    usuario () {
      const detallesUsuario = this.$store.getters.obtenerUsuario
      const detalles = [
        {
          label: "Regional",
          value: detallesUsuario.regional
        },

        {
          label: "Centro de Formación",
          value: detallesUsuario.centro_formacion
        },

        {
          label: "Perfil",
          value: detallesUsuario.perfil
        },

        {
          label: "Identificación",
          value: detallesUsuario.identificacion
        },

        {
          label: "Nombres",
          value: detallesUsuario.nombres
        },

        {
          label: "Apellidos",
          value: detallesUsuario.apellidos
        },

        {
          label: "Teléfono",
          value: detallesUsuario.telefono
        },

        {
          label: "Correo Electrónico",
          value: detallesUsuario.correo
        }
      ]
      return detalles;
    }
  },

  methods: {
    regresar() {
      this.$router.push({
        name: "usuario-gestionar"
      })
    }
  }
}
</script>