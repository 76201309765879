<template>
  <div>
    <!-- alerta -->
    <v-dialog max-width="340" v-model="alert">
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :color="buttonCard"
            class="mb-2 white--text"
            @click="alert = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- alerta persona -->
    <v-dialog max-width="380" v-model="alertPersona">
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            No se encuentra a la persona
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          ¿Desea registrarla?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="mb-2 white--text"
            @click="registroPersona"
          >
            Si
          </v-btn>
          <v-btn
            color="error"
            class="mb-2 white--text"
            @click="alertPersona = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cargador tabla -->
    <template v-if="loaderTable">
      <v-skeleton-loader 
        class="mx-auto" 
        type="table"
      > 
      </v-skeleton-loader>
    </template>
    <template v-else>
      <!-- tabla -->
      <v-row justify="center">
        <v-col cols="12">
          <router-view></router-view>

          <!-- lider nacional -->
          <template v-if="usuario.perfil_id == 3 || usuario.perfil_id == 1">
            <v-card>
              <v-card-title
                class="text-h6 text-md-h5 font-weight-bold primary--text"
              >
                <v-row align="center">
                  <v-col 
                    cols="2" 
                    sm="1"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          exact
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="regresar"
                        >
                          <v-icon> mdi-arrow-left </v-icon>
                        </v-btn>
                      </template>
                      <span>Regresar</span>
                    </v-tooltip>
                  </v-col>

                  <v-col 
                    cols="10" 
                    sm="9"
                  >
                    Experto Nacional: {{ habilidadCompetenciaWS.categoria }}
                  </v-col>

                  <v-col 
                    cols="12" 
                    sm="2"
                    align="end"
                  >
                    <v-btn 
                      exact 
                      color="primary" 
                      @click="open"
                    >
                      Registrar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-data-table
                fixed-header
                height="480px"
                class="elevation-2"
                :headers="headersTable"
                :items="expertosNacionalesWorldSkills"
                no-data-text="No hay experto nacional registrado"
                :footer-props="{ 'items-per-page-options': [10, 20, -1] }"
              >
              <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="detalles(item.id)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Detalles</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="editar(item.id)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Actualizar</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="activarAlertaEliminar(item.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>

                <template v-slot:top>
                  <v-dialog 
                    persistent 
                    v-model="dialogForm" 
                    max-width="520px"
                  >
                    <v-card :loading="loadingForm">
                      <v-card-title class="justify-center">
                        <p class="text-h5 font-weight-bold primary--text">
                          {{ formTitle }}
                        </p>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row justify="center">
                            <v-col 
                              cols="12"
                              sm="10"
                            >
                              <v-form 
                                ref="form" 
                                v-model="form"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Competencia"
                                  v-model="competenciaSeleccionada"
                                >
                                </v-text-field>

                                <v-text-field
                                  outlined
                                  dense
                                  disabled
                                  type="text"
                                  label="Habilidad"
                                  v-model="habilidadSeleccionada"
                                >
                                </v-text-field>

                                <v-select
                                  outlined
                                  dense
                                  label="Regional*"
                                  :items="regionales"
                                  item-value="id"
                                  item-text="regional"
                                  v-model="regional"
                                  :rules="regionalReglas"
                                >
                                </v-select>

                                <v-select
                                  outlined
                                  dense
                                  label="Centro de Formación*"
                                  :items="centrosFormacion"
                                  item-value="id"
                                  item-text="centro_formacion"
                                  v-model="centroFormacion"
                                  :rules="centroFormacionReglas"
                                >
                                </v-select>

                                <v-text-field
                                  outlined
                                  dense
                                  :disabled="disabledForm"
                                  append-icon="mdi-magnify"
                                  type="number"
                                  label="Número de Identificación"
                                  v-model="identificacion"
                                  :rules="identificacionReglas"
                                  :loading="loadingTextField"
                                  :error-messages="mensajeIdentificacion"
                                  @keydown.enter="persona(identificacion)"
                                  @click:append="persona(identificacion)"
                                >
                                </v-text-field>

                                <template v-if="validarPersona == true">
                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Nombres"
                                    v-model="nombres"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Apellidos"
                                    v-model="apellidos"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Fecha de Nacimiento"
                                    v-model="fecha_nacimiento"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    type="text"
                                    label="Genero"
                                    v-model="genero"
                                  >
                                  </v-text-field>

                                  <v-text-field
                                    outlined
                                    dense
                                    type="text"
                                    label="Cargo*"
                                    maxlength="100"
                                    counter
                                    v-model="cargo"
                                    :rules="cargoReglas"
                                  >
                                  </v-text-field>

                                  <v-select
                                    outlined
                                    dense
                                    label="Talla de Camiseta*"
                                    :items="tallas"
                                    v-model="talla"
                                    :rules="tallaReglas"
                                  >
                                  </v-select>
                                </template>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          color="error" 
                          @click="close"
                        >
                          Cancelar
                        </v-btn>

                        <template v-if="editedIndex === -1">
                          <v-btn 
                            text 
                            exact 
                            color="primary" 
                            @click="registrar"
                          >
                            Registrar
                          </v-btn>
                        </template>

                        <template v-else>
                          <v-btn 
                            text 
                            exact 
                            color="primary" 
                            @click="actualizar"
                          >
                            Actualizar
                          </v-btn>
                        </template>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog 
                    v-model="dialogDelete" 
                    max-width="480px"
                  >
                    <v-card>
                      <v-card-title class="justify-center text-h5">
                        ¿Desea eliminar el experto nacional?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          color="primary" 
                          @click="closeDelete"
                        >
                          Cancelar
                        </v-btn>

                        <v-btn 
                          text 
                          color="error" @click="eliminar()"
                        > 
                          OK 
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    loaderTable: true,
    searchTable: null,
    loadingTextField: false,

    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    alertPersona: false,

    loadingForm: false,
    form: true,
    dialogForm: false,
    disabledForm: false,
    dialogDelete: false,

    competenciaSeleccionada: "",
    habilidadSeleccionada: "",

    regional: null,
    regionalReglas: [
      (v) => !!v || "Seleccione una regional"
    ],

    centroFormacion: null,
    centroFormacionReglas: [
      (v) => !!v || "Seleccione un centro de formación"
    ],

    identificacion: null,
    identificacionReglas: [
      (v) => !!v || "Escriba un número de identificación"
    ],
    mensajeIdentificacion: "",

    cargo: null,
    cargoReglas: [
      (v) => !!v || "Escriba un cargo"
    ],

    talla: null,
    tallas: ["XS", "S", "M", "L", "XL", "XXL"],
    tallaReglas: [
      (v) => !!v || "Seleccione una talla"
    ],

    id_experto: null,
    validarPersona: false,
    idExpertoNacional: null,
    idPersona: null,
    nombres: null,
    apellidos: null,
    fecha_nacimiento: null,
    genero: null,

    headersTable: [
      {
        text: "Nombres",
        value: "nombres",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Apellidos",
        value: "apellidos",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Cargo",
        value: "cargo",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Regional",
        value: "regional",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Centro de Formación",
        value: "centro_formacion",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ],

    editedIndex: -1,
  }),

  mounted() {
    this.$store.dispatch("crearRegionales");
    this.$store.dispatch("crearCentrosFormacion");
    this.$store.dispatch("gestionarExpertosNacionales");
    this.$store.dispatch("gestionarCompetenciaWorldSkills");
    this.$store.dispatch("gestionarHabilidadesCompetencia", this.$route.params.id_competencia);
  
    setTimeout(() => {
      this.loaderTable = false;
    }, 1000);
  },

  computed: {
    expertosNacionalesWorldSkills() {
      return this.$store.getters.obtenerExpertosNacionalesWorldSkills(this.$route.params.id_competencia, this.$route.params.id_categoria)
    },

    formTitle() {
      return this.editedIndex === -1 ? "Registrar Experto Nacional" : "Actualizar Experto Nacional";
    },

    habilidadCompetenciaWS() {
      return this.$store.getters.obtenerHabilidadCompetenciaWorldSkills(this.$route.params.id_competencia, this.$route.params.id_categoria);
    },

    usuario() {
      return this.$store.getters.usuario;
    },

    regionales() {
      return this.$store.getters.obtenerRegionales;
    },

    centrosFormacion() {
      return this.$store.getters.obtenerCentrosFormacion(this.regional);
    },
  },

  watch: {
    searchTable(newSearch) {
      this.$store.dispatch("guardarSearch", newSearch);
    },

    dialogForm(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  methods: {
    open() {
      this.disabled = false;
      this.dialogForm = true;
      this.competenciaSeleccionada = this.habilidadCompetenciaWS.competencia;
      this.habilidadSeleccionada = this.habilidadCompetenciaWS.categoria;
    },

    deleteItemConfirm() {
      this.dialogDelete = false;
    },

    close() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.validarPersona = false;
      });
      this.disabledForm = false
      this.$refs.form.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    persona(identificacion) {
      this.$store.dispatch("crearExperto", identificacion)
      .then((response) => {
        this.loadingTextField = true;
        if(response != null) {
          this.mensajeIdentificacion = ""
          setTimeout(() => {
            this.idPersona        = response.id_persona;
            this.nombres          = response.primer_nombre + " " + response.segundo_nombre;
            this.apellidos        = response.primer_apellido + " " + response.segundo_apellido;
            this.fecha_nacimiento = response.fecha_nacimiento;
            this.genero           = response.genero.genero;
            this.loadingTextField = false;
          }, 1000);
          this.validarPersona = true;
        } 
        else {
          this.alertPersona = true;
          this.mensajeIdentificacion = "Número de identificación no encontrado";
          setTimeout(() => {
            this.loadingTextField = false;
          }, 1000);

          this.validarPersona = false;
        }
      })
      .catch(() => {});
    },

    registrar() {
      if (this.$refs.form.validate()) {
        if (this.validarPersona) {
          const expertoNacional = {
            competencia_id:       this.$route.params.id_competencia,
            categoria_id:         this.$route.params.id_categoria,
            centro_formacion_id:  this.centroFormacion,
            tipo_lider_id:        5,
            persona_id:           this.idPersona,
            cargo:                this.cargo,
            talla_camiseta:       this.talla
          };

          this.loadingForm = true;
          this.$store.dispatch("registrarExpertoNacional", expertoNacional)
          .then((response) => {
            if (response.status == "success") {
              let message = response.message.split(',')
              this.$refs.form.reset();
              this.loadingForm = false;
              this.close();
              this.alert = true;
              this.titleAlert = message[0];
              this.messageAlert = message[1];
              this.titleCard = "success--text";
              this.buttonCard = "success";
              this.loaderTable = true

              this.$store.dispatch("gestionarExpertosNacionales");
              setTimeout(() => {
                this.loaderTable = false;
              }, 1000);
            } 
            else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              for (const error in response.message) {
                this.messageAlert = response.message[error][0]
              }
            }

            setTimeout(() => {
              this.alert = false;
            }, 2000);
          })
          .catch(() => {});
        } 
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.alertPersona = true;
          this.messageAlert = "Número de identificación no encontrado";
          this.titleCard = "error--text";
          this.buttonCard = "error";

          setTimeout(() => {
            this.alert = false;
          }, 2000);
        }
      }
    },

    detalles(idExperto) {
      this.$router.push({
        name: "expertows-nacional-detalles",
        params: { id_experto: idExperto },
      });
    },

    editar(idExperto) {
      this.$store.dispatch("editarExpertoNacional", idExperto)
      .then((response) => {
        this.editedIndex = 1;
        this.disabledForm = true;
        this.idExpertoNacional        = idExperto;
        this.competenciaSeleccionada  = this.habilidadCompetenciaWS.competencia;
        this.habilidadSeleccionada    = this.habilidadCompetenciaWS.categoria;
        this.regional                 = response.centro_formacion.regional_id;
        this.centroFormacion          = response.centro_formacion_id;
        this.idPersona                = response.persona_id;
        this.identificacion           = response.persona.identificacion;
        this.nombres                  = response.persona.primer_nombre+" "+response.persona.segundo_nombre;
        this.apellidos                = response.persona.primer_apellido+" "+response.persona.segundo_apellido;
        this.fecha_nacimiento         = response.persona.fecha_nacimiento;
        this.genero                   = response.persona.genero.genero;
        this.cargo                    = response.cargo;
        this.talla                    = response.talla_camiseta;

        this.validarPersona = true;
        this.dialogForm     = true;
      })      
      .catch(() => {})
    },

    actualizar() {
      if (this.$refs.form.validate()) {
        if (this.validarPersona) {
          const expertoNacional = {
            id:                   this.idExpertoNacional,
            competencia_id:       this.$route.params.id_competencia,
            categoria_id:         this.$route.params.id_categoria,
            centro_formacion_id:  this.centroFormacion,
            tipo_lider_id:        5,
            persona_id:           this.idPersona,
            cargo:                this.cargo,
            talla_camiseta:       this.talla
          };

          this.loadingForm = true;
          this.$store.dispatch("actualizarExpertoNacional", expertoNacional)
          .then((response) => {
            if (response.status == "success") {
              let message = response.message.split(',')
              this.$refs.form.reset();
              this.loadingForm = false;
              this.close();
              this.alert = true;
              this.titleAlert = message[0];
              this.messageAlert = message[1];
              this.titleCard = "success--text";
              this.buttonCard = "success";
              this.loaderTable = true;

              this.$store.dispatch("gestionarExpertosNacionales");
              setTimeout(() => {
                this.loaderTable = false;
              }, 1000);
            } 
            else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.titleCard = "error--text";
              this.buttonCard = "error";
              for (const error in response.message) {
                this.messageAlert = response.message[error][0]
              }
            }

            setTimeout(() => {
              this.alert = false;
            }, 2000);
          })
          .catch(() => {});
        } 
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.alertPersona = true;
          this.messageAlert = "Número de identificación no encontrado";
          this.titleCard = "error--text";
          this.buttonCard = "error";
          setTimeout(() => {
            this.alert = false;
          }, 2000);
        }
      }
    },

    activarAlertaEliminar(idexperto) {
      this.id_experto = idexperto;
      this.dialogDelete = true;
    },

    eliminar() {
      this.dialogDelete = false;
      this.$store.dispatch("eliminarExpertoNacional", this.id_experto)
      .then((response) => {
        if (response.status == "success") {
          let message = response.message.split(',')
          this.alert = true;
          this.titleAlert = message[0];
          this.messageAlert = message[1];
          this.titleCard = "success--text";
          this.buttonCard = "success";
          this.$store.dispatch("gestionarExpertosNacionales");
          this.loaderTable = true;

          setTimeout(() => {
            this.loaderTable = false;
          }, 1000);
        }
        else {
          this.alert = true;
          this.titleAlert = "Error";
          this.titleCard = "error--text";
          this.buttonCard = "error";
          for (const error in response.message) {
            this.messageAlert = response.message[error][0]
          }
        }

        setTimeout(() => {
          this.alert = false;
        }, 2000);
      })
      .catch(() => {});
    },

    regresar() {
      this.$router.push({
        name: "asignar-habilidad",
        params: { id_competencia: this.$route.params.id_competencia },
      });
    },

    registroPersona() {
      this.$router.push({
        name: "personas-registrar",
        params: { experto: "4" }
      });
    },
  },
};
</script>
