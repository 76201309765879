<template>
   <v-row justify="center">
    <v-col cols="12">
      <router-view></router-view>

      <!-- tabla de datos --> 
      <v-card>
        <v-card-title class="text-h5 font-weight-bold primary--text">
          <v-row align="center">
            <v-col 
              cols="2"
              md="1"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    exact
                    color="primary"
                    v-bind="attrs" 
                    v-on="on" 
                    @click="regresar"
                  >
                    <v-icon>
                    mdi-arrow-left
                    </v-icon>
                  </v-btn>
                </template>
                <span>Regresar</span>
              </v-tooltip>
            </v-col>
            <v-col 
              cols="12"
              sm="4"
            >
              Participantes Registrados
            </v-col>
            <v-col 
              cols="12"
              sm="4"
              offset-sm="3"
            >
              <v-text-field
                outlined
                dense
                append-icon="mdi-magnify"
                label="Buscar Participante"
                hide-details
                v-model="searchTable"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
  
        <v-data-table
          fixed-header
          locale="es"
          height="460px"
          class="elevation-2"
          :loading="loadingTable"
          :headers="headersTable"
          :items="participantes"
          :search="searchTable"
          no-data-text="No hay participantes registrados"
          :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1] }"
        >
          <!--
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="primary"
                    v-bind="attrs" 
                    v-on="on"
                    @click="detalles(item.persona_id)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Detalles</span>
              </v-tooltip>
            </template>
          -->
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  data: () => ({
    loadingTable: true,
    searchTable: null,
    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Nombres",
        value: "nombres",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Apellidos",
        value: "apellidos",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Correo Electrónico",
        value: "correo",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Categoria",
        value: "categoria",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Regional",
        value: "regional",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      }
    ],
  }),

  created() {
    this.$store.dispatch("gestionarParticipantesCompetencia", this.$route.params.id_competencia)
    //this.$store.dispatch("gestionarParticipantes")
    setTimeout(() => {
      this.loadingTable = false;
    }, 1000);
  },

  computed: {
    participantes() {
      return this.$store.getters.obtenerParticipantes;
    },
  },

  methods: {
    detalles(idParticipante) {
      this.$router.push({
        name: "participante-detalle",
        params: { id_participante : idParticipante }
      });
    },

    regresar() {
      this.$router.push({
        name: "competencia-gestionar"
      });
    }
  }
};
</script>