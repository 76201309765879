<template>
  <!-- tabla de datos -->
  <v-row justify="center">
    <v-col cols="12">
      <router-view></router-view>

      <!-- tabla de datos --> 
      <v-card>
        <v-card-title class="text-h5 font-weight-bold primary--text">
          <v-row align="center">
            <v-col 
                cols="2"
                md="1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      exact
                      color="primary"
                      v-bind="attrs" 
                      v-on="on" 
                      @click="regresar"
                    >
                      <v-icon>
                        mdi-arrow-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar</span>
                </v-tooltip>
              </v-col>
            <v-col 
              cols="12"
              sm="4"
            >
              Gestionar Cupos Asignados
            </v-col>
            <v-col 
              cols="12"
              sm="4"
              offset-sm="3"
            >
              <v-text-field
                outlined
                dense
                append-icon="mdi-magnify"
                label="Buscar Categoria"
                hide-details
                v-model="searchTable"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
  
        <v-data-table
          fixed-header
          locale="es"
          height="460px"
          class="elevation-2"
          :loading="loadingTable"
          :headers="headersTable"
          :items="cupos"
          textTable="No hay cupos asignados"
          :items-per-page="10"
          :search="searchTable"
          :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1] }"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="detallesCategoria(item.categoria_id)"
                >
                mdi-eye
                </v-icon>
              </template>
              <span>Detalles</span>
            </v-tooltip>

            <template v-if="item.estado_inscripcion">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="registrarParticipantes(item.competencia_id, item.categoria_id)"
                  >
                    mdi-account-plus
                  </v-icon>
                </template>
                <span>Registrar Participantes</span>
              </v-tooltip>
            </template>

            <template v-else>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="primary"
                    class="mr-2"
                    disabled
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-account-plus
                  </v-icon>
                </template>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  data: () => ({
    loadingTable: true,
    searchTable: null,
    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Categoria",
        value: "categoria",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Cupo Asignado",
        value: "numero_participantes",
        align: "start",
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ]
  }),

  mounted() {
    const cupoCentroFormacion = {
      competencia_id: this.$route.params.id_competencia,
      centro_formacion_id: this.usuario.centro_formacion_id 
    }

    this.$store.dispatch("gestionarCuposCentroFormacion", cupoCentroFormacion);
    this.$store.dispatch("gestionarCategoriasCompetencia", this.$route.params.id_competencia);

    setTimeout(() => {
      this.loadingTable = false;
    }, 1000);
  },

  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },

    cupoAsignado() {
      return this.$store.getters.obtenerCuposCentroFormacion;
    },

    categoriasCompetencia() {
      return this.$store.getters.obtenerCategoriasCompetencia;
    },

    cupos() {
      let categorias = []
      this.cupoAsignado.forEach((cupo, index) => {
        this.categoriasCompetencia.forEach((categoriaCompetencia) => {
          if (cupo.categoria_id == categoriaCompetencia.categoria_id)
          categorias.push({
            indice:               (index+1),
            id_cupo:              cupo.id_cupo,
            competencia_id:       cupo.competencia_id, 
            competencia:          cupo.competencia,
            categoria_id:         cupo.categoria_id,
            categoria:            cupo.categoria,
            centro_formacion_id:  cupo.centro_formacion_id,
            centro_formacion:     cupo.centro_formacion,   
            numero_participantes: cupo.numero_participantes,
            estado_inscripcion:   categoriaCompetencia.estado_inscripcion
          })
        })

      })
      return categorias
    }
  },

  methods: {
    registrarParticipantes(idCompetencia, idCategoria) {
      this.$router.push({
        name: "participante-gestionar",
        params: {id_competencia: idCompetencia, id_categoria: idCategoria}
      });
    },

    detallesCategoria(idCategoria){
      this.$router.push({
        name: "cupo-detalle",
        params: {id_categoria: idCategoria}
      })
    },

    regresar() {
      this.$router.push({
        name: "inscripcion-gestionar"
      });
    }
  }
};
</script>