<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>
    <v-row>
      <!-- boton atras -->
      <v-col
        sm="1"
        lg="2"
        class="hidden-xs-only"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              fixed
              exact
              color="primary"
              v-bind="attrs" 
              v-on="on" 
              @click="regresar"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
      </v-col>

      <!-- formulario -->
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Actualizar Perfil
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Perfil*"
                    maxlength="50"
                    counter
                    v-model="perfil"
                    :rules="perfilReglas"
                  >
                  </v-text-field>

                  <v-textarea
                    outlined
                    rows="4"
                    label="Descripción"
                    v-model="descripcion"
                  >
                  </v-textarea>

                  <v-btn
                    large
                    block
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    exact
                    @click="actualizar"
                  >
                    Actualizar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Alert from '../../../components/admin/Alert.vue'
export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,

    form: true,
    loadingForm: false,

    perfil: null,
    perfilReglas: [
      (v) => !!v || "Escriba un Perfil"
    ],

    descripcion:  null
  }),

  components: {
    "app-alert": Alert
  },

  mounted() {
    const perfil = this.$store.getters.obtenerPerfil(this.$route.params.id_perfil);
    this.perfil       = perfil.perfil
    this.descripcion  = perfil.descripcion
  },
  
  methods: {
    actualizar() {
      if (this.$refs.form.validate()) {
        this.loadingForm = true

        const perfil = {
          id:           this.$route.params.id_perfil,
          perfil:       this.perfil[0].toUpperCase() + this.perfil.toLowerCase().substring(1),
          descripcion:  this.descripcion
        }

        this.$store.dispatch("actualizarPerfil", perfil)
        .then((response) => {
          if(response.status == "success") {
            let message = response.message.split(',')
            this.$refs.form.reset();
            this.loadingForm = false
            this.alert = true
            this.titleAlert = message[0]
            this.messageAlert = message[1]
            this.titleCard = "success--text"
            this.buttonCard = "success"

            setTimeout(() => {
              this.$router.push({
                name: "perfil-gestionar"
              })
            }, 1000)
          }
          else {
            this.loadingForm = false
            this.alert = true
            this.titleAlert = "Error"
            this.titleCard = "error--text"
            this.buttonCard = "error"
            for (const error in response.message) {
              this.messageAlert = response.message[error][0]
            }
          }

          setTimeout(() => {
            this.alert = false
          }, 2000)
        })
        .catch(() => {})
      }
    },

    regresar() {
      this.$router.push({
        name: "perfil-gestionar" 
      })
    }
  }
}
</script>