<template>
  <div>
    <!-- alerta -->
    <v-dialog max-width="280" v-model="alert">
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :color="buttonCard"
            class="mb-2 white--text"
            @click="alert = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- tabla de datos -->
    <v-row justify="center">
      <v-col cols="12">
        <router-view></router-view>
        <v-card>
          <v-card-title class="text-h5 font-weight-bold primary--text">
            <v-row align="center">
              <v-col 
                cols="12"
                sm="6"
              >
                Gestionar Sedes
              </v-col>

              <v-col 
                cols="12" 
                sm="3"
                offset-sm="3"
              >
                <v-text-field
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  label="Buscar Sede"
                  hide-details
                  v-model="searchTable"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            fixed-header
            locale="es"
            height="460px"
            class="elevation-2"
            :loading="loadingTable"
            :headers="headersTable"
            :items="sedes"
            :search="searchTable"
            no-data-text="No hay sedes registradas"
            :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1] }"
          >
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="detalles(item.id)"
                  >
                    mdi-eye 
                  </v-icon>
                </template>
                <span>Detalles</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="Actualizar(item.id)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Actualizar</span>
              </v-tooltip>

              <v-tooltip top >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="activarAlertaEliminar(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center text-h5">
          ¿ Desea eliminar la sede ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeDelete">Cancelar </v-btn>
          <v-btn text color="error" @click="eliminarSede(id_sede)"
            >OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    loadingTable: true,
    searchTable: null,
    loadingTextField: false,

    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    loadingForm: false,
    form: true,

    dialogForm: false,
    dialogDelete: false,

    id_sede: null,
    sedess: [],

    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Sede",
        value: "sede",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Lugar",
        value: "lugar",
        align: "start",
        width: "15%",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Dirección",
        value: "direccion",
        align: "start",
        width: "15%",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Responsable",
        value: "responsable",
        align: "start",
        sortable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
      {
        text: "Centro Formación",
        value: "centro_formacion",
        align: "start",
        sortable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
      
    ],

    editedIndex: -1,
  }),

  mounted() {
    this.$store.dispatch("gestionarSedes");
    setTimeout(() => {
      this.loadingTable = false;
      const storedSearch = this.$store.getters.obtenerSearch;
      if (storedSearch) {
        this.searchTable = storedSearch;
      }
    }, 2)
  },

  computed: {
    sedes() {
      return this.$store.getters.obtenerSedes;
    },
  },

  watch: {
    searchTable(newSearch) {
      // localStorage.setItem('searchTable', newSearch);
      this.$store.dispatch("guardarSearch", newSearch);
    },
    dialogForm(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    open() {
      this.dialogForm = true;
      this.competenciaSeleccionada = this.competencia.competencia;
      this.liderSeleccionado = this.tiposLider.tipo_lider;
    },

    close() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.validarPersona = false;
      this.mensajeIdentificacion = "";
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    activarAlertaEliminar(idsede) {
      this.id_sede = idsede;
      this.dialogDelete = true;
    },

    eliminarSede() {
      this.dialogDelete = false
      this.$store.dispatch("eliminarSede", this.id_sede)
        .then((response) => {
          if (response.status == "success") {
            this.alert = true;
            this.titleAlert = "Eliminado";
            this.messageAlert = response.message;
            this.titleCard = "success--text";
            this.buttonCard = "success";

            setTimeout(() => {
              this.loadingTable = false;
            }, 1000);
          } 
          else {
            this.alert = true;
            this.titleAlert = "Error";
            this.messageAlert = response.message;
            this.titleCard = "error--text";
            this.buttonCard = "error";
            this.dialogDelete = false

          }
          this.$store.dispatch("gestionarSedes");
          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {});
    },

    detalles(idsede){
      this.$router.push({
        name: "detalles-sede",
        params: { id_sede: idsede }
      });
    },

    Actualizar(idsede){
      this.$router.push({
        name: "actualizar-sede",
        params: { id_sede: idsede }
      });
    }
  },
};
</script>
