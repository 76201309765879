<template>
  <!-- tabla de datos -->
  <v-row justify="center">
    <v-col cols="12">
      <router-view></router-view>

      <!-- tabla de datos --> 
      <v-card>
        <v-card-title class="text-h5 font-weight-bold primary--text">
          <v-row align="center">
            <v-col 
              cols="12"
              sm="4"
            >
              Gestionar Inscripciones
            </v-col>
            <v-col 
              cols="12"
              sm="4"
              offset-sm="4"
            >
              <v-text-field
                outlined
                dense
                append-icon="mdi-magnify"
                label="Buscar Competencia"
                hide-details
                v-model="searchTable"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
  
        <v-data-table
          fixed-header
          locale="es"
          height="460px"
          class="elevation-2"
          :loading="loadingTable"
          :headers="headersTable"
          :items="competencias"
          :items-per-page="10"
          :search="searchTable"
          :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1] }"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="registrarLider(item.id)"
                >
                  mdi-account-plus
                </v-icon>
              </template>
              <span>Registrar Lideres</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  @click="cupoAsignado(item.id)"
                >
                  mdi-text-box-plus
                </v-icon>
              </template>
              <span>Cupo Asignado</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  data: () => ({
    loadingTable: true,
    searchTable: null,
    headersTable: [
      {
        text: "#",
        value: "indice",
        align: "start",
        sortable: false,
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Competencia",
        value: "competencia",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Fecha Inicio",
        value: "fecha_inicio",
        align: "start",
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Fecha Fin",
        value: "fecha_fin",
        align: "start",
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Lugar",
        value: "lugar",
        align: "start",
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Dirección",
        value: "direccion",
        align: "start",
        filterable:false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },
    ]
  }),

  created() {
    this.$store.dispatch("gestionarCompetencias");

    setTimeout(() => {
      this.loadingTable = false;
    }, 1000);
  },

  computed: {
    competencias() {
      return this.$store.getters.obtenerCompetencias;
    },
  },

  methods: {
    registrarLider(idCompetencia) {
      this.$router.push({
        name: "lider-categoria",
        params: { id_competencia: idCompetencia }
      });
    },
    cupoAsignado(idCompetencia) {
      this.$router.push({
        name: "cupo-asignado",
        params: { id_competencia: idCompetencia }
      });
    }
  }
};
</script>