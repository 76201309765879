import axios from "axios";
const ENV_PERSONAS = "enviarPersonas";
const ENV_PERSONA = "enviarPersona";
const personas = {
  state: () => ({
    personas: [],
    persona: "",
  }),
  getters: {
    obtenerPersonas(state) {
      return state.personas;
    },
    obtenerPersona(state) {
      return state.persona;
    },
  },
  mutations: {
    [ENV_PERSONAS](state, personas) {
      state.personas = personas;
    },
    [ENV_PERSONA](state, persona) {
      state.persona = { ...persona };
    },
  },
  actions: {
    gestionarPersona({ commit }) {
      axios
        .get("api/persona")
        .then((res) => {
          const personas = [];
          res.data.results.forEach((data, index) => {
            personas.push({
              indice: index + 1,
              id: data.id_persona,
              genero: data.genero.genero,
              nombre_completo: `${data?.primer_nombre} ${data?.segundo_nombre}`,
              primer_apellido: data.primer_apellido,
              segundo_apellido: data.segundo_apellido,
              direccion: data.direccion,
              telefono_movil: data.telefono_movil,
              correo: data.correo,
            });
          });
          commit(ENV_PERSONAS, personas);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async detallePersona({ commit }, id) {
      try {
        const res = await axios.get(`api/persona/${id}`);
        const { results } = res.data;
        commit(ENV_PERSONA, results);
      } catch (error) {
        console.log(error);
      }
    },

    registrarPersona(context, persona) {
      return new Promise((resolve, reject) => {
        axios
          .post("api/persona", persona)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(console.log(err));
          });
      });
    },

    actulaizarPersona(context, persona) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/persona/${persona.id}`,persona)
          .then((res) => {
            resolve(res.data)
          }).catch((err) => {
            reject(console.log(err))
          }
          )
      })
    },
    eliminarPersona(context, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`api/persona/${id}`)
          .then((res) => {
            resolve(res.data)
          }).catch((err) => {
            reject(console.log(err))
          }
          )
      })
    },
  },
};
export default personas;
