import axios from 'axios'

const cupos = {
    state: () => ({
        categorias: [],
        cupos: [],
        cuposCentroFormacion: [],
        cupoCentroFormacion: ""
    }),

    getters: {
        obtenerCategoriaCupo: (state) => (id) => {
            return state.categorias.find(categoria => categoria.id_categoria == id)
        },

        obtenerCategoriasCupo(state) {
            return state.categorias
        },

        obtenerCupos: (state) => {
            return state.cupos
        },

        obtenerCuposCentroFormacion(state) {
            return state.cuposCentroFormacion
        },

        obtenerCupoCentroFormacion(state) {
            return state.cupoCentroFormacion
        }
    },

    mutations: {
        enviarCategorias(state, categorias) {
            state.categorias = categorias
        },

        enviarCupos(state, cupos) {
            state.cupos = cupos
        },

        enviarCuposCentroFormacion(state, cuposCentroFormacion) {
            state.cuposCentroFormacion = cuposCentroFormacion
        },

        enviarCupoCentroFormacion(state, cupoCentroFormacion) {
            state.cupoCentroFormacion = cupoCentroFormacion
        },
    },

    actions: {
        crearCupo(context) {
            axios.get("api/cupos/crear")
            .then((response) => {
                const categorias = []

                for (let i = 0; i < response.data.results.categorias.length; i++) {
                    categorias.push({
                        id_categoria:   response.data.results.categorias[i].id_categoria,
                        categoria:      response.data.results.categorias[i].categoria
                    })
                }

                context.commit("enviarCategorias", categorias)
            })
            .catch(() => {})
        },
        
        registrarCupo(context, cupo) {
            return new Promise((resolve, reject) => {
                axios.post("api/cupos/"+cupo.competencia_id+"/categorias/"+cupo.categoria_id, cupo)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarCupos(context, categoriaCompetencia) {
            axios.get("api/cupos/"+categoriaCompetencia.competencia_id+"/categorias/"+categoriaCompetencia.categoria_id)
            .then((response) => {
                const cupos = []
                for (let i = 0; i < response.data.results.length; i++) {
                    cupos.push({
                        indice: (i+1),
                        id_cupo:                response.data.results[i].id_cupo,
                        categoria_id:           response.data.results[i].categoria.id_categoria,
                        categoria:              response.data.results[i].categoria.categoria,
                        regional_id:            response.data.results[i].centro_formacion.regional.id_regional,
                        regional:               response.data.results[i].centro_formacion.regional.regional,
                        centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
                        numero_participantes:   response.data.results[i].numero_participantes
                    })
                }
                context.commit("enviarCupos", cupos)
            })
            .catch(() => {})
        },

        eliminarCupo(context, cupo) {
            return new Promise((resolve, reject) => {
                axios.delete("api/cupos/"+cupo)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarCuposCentroFormacion(context, cupoCentroFormacion) {
            axios.get("api/cuposCentroFormacion/"+cupoCentroFormacion.competencia_id+"/"+cupoCentroFormacion.centro_formacion_id)
            .then((response) => {
                const cuposCentroFormacion = []
                for (let i = 0; i < response.data.results.length; i++) {
                    cuposCentroFormacion.push({
                        indice: (i+1),
                        id_cupo:                response.data.results[i].id_cupo,
                        competencia_id:         response.data.results[i].competencia.id_competencia,
                        competencia:            response.data.results[i].competencia.competencia,
                        categoria_id:           response.data.results[i].categoria.id_categoria,
                        categoria:              response.data.results[i].categoria.categoria,
                        centro_formacion_id:    response.data.results[i].centro_formacion.id_centro_formacion,
                        centro_formacion:       response.data.results[i].centro_formacion.centro_formacion,
                        numero_participantes:   response.data.results[i].numero_participantes
                    })
                }
                context.commit("enviarCuposCentroFormacion", cuposCentroFormacion)
            })
            .catch(() => {})
        },

        detalleCupoCentroFormacion(context, cupo) {
            axios.get("api/cuposCentroFormacion/"+cupo.competencia_id+"/"+cupo.categoria_id+"/"+cupo.centro_formacion_id)
            .then((response) => {
                const cupoCentroFormacion = {
                    id_cupo:                response.data.results.id_cupo,
                    competencia_id:         response.data.results.competencia.id_competencia,
                    competencia:            response.data.results.competencia.competencia,
                    categoria_id:           response.data.results.categoria.id_categoria,
                    categoria:              response.data.results.categoria.categoria,
                    centro_formacion_id:    response.data.results.centro_formacion.id_centro_formacion,
                    centro_formacion:       response.data.results.centro_formacion.centro_formacion,
                    numero_participantes:   response.data.results.numero_participantes
                }
                
                context.commit("enviarCupoCentroFormacion", cupoCentroFormacion)
            })
            .catch(() => {})
        },
    }
}

export default cupos