import Vue from 'vue'
import Vuex from 'vuex'
import autenticacion from './modules/autenticacion'
import administracion from './modules/administracion'
import perfiles from './modules/perfiles'
import usuarios from './modules/usuarios'
import tablero from './modules/tablero'
import aliados from './modules/aliados'
import categorias from './modules/categorias'
import cupos from './modules/cupos'
import lideres from './modules/lideres'
import competencias from './modules/competencias'
import participantes from './modules/participantes'
import reportes from './modules/reportes'
import habilidades from './modules/habilidades'
import sedes from './modules/sedes'
import ganadores from './modules/ganadores'
import expertos from './modules/expertos'
import acompanantes from './modules/acompanantes'
import expertos_nacional from './modules/expertos_nacional'
import foro from './modules/foro'
import personas from './modules/personas'
import search from './modules/search'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    autenticacion,
    administracion,
    perfiles,
    usuarios,
    tablero,
    aliados,
    categorias,
    cupos,
    lideres,
    competencias,
    participantes,
    reportes,
    habilidades,
    sedes,
    ganadores,
    expertos,
    acompanantes,
    expertos_nacional,
    foro,
    personas,
    search
  }
})
