import axios from 'axios'

const categorias = {
    state: () => ({
        categorias:                     [],
        categoria:                      "",
        programasFormacionCategoria:    [],
        aliadosCategoria:               [],
        categoriasCompetencia:          [],
        categoriaCompetencia:           "",
        documento:                      [],
    }),

    getters: {
        obtenerCategorias(state) {
            return state.categorias
        },

        obtenerCategoria(state) {
            return state.categoria
        },

        obtenerProgramasFormacionCategoria(state) {
            return state.programasFormacionCategoria
        },

        obtenerAliadosCategoria(state) {
            return state.aliadosCategoria
        },

        obtenerCategoriasCompetencia(state) {
            return state.categoriasCompetencia
        },

        obtenerCategoriaCompetencia(state) {
            return state.categoriaCompetencia
        },
        obtenerDoc(state){
            return state.documento
        }
    },

    mutations: {
        enviarCategorias(state, categorias) {
            state.categorias = categorias
        },

        enviarCategoria(state, categoria) {
            state.categoria = categoria
        },

        enviarProgramasFormacionCategoria(state, programasFormacionCategoria) {
            state.programasFormacionCategoria = programasFormacionCategoria
        },

        enviarAliadosCategoria(state, aliadosCategoria) {
            state.aliadosCategoria = aliadosCategoria
        },

        enviarCategoriasCompetencia(state, categoriasCompetencia) {
            state.categoriasCompetencia = categoriasCompetencia
        },

        enviarCategoriaCompetencia(state, categoriaCompetencia) {
            state.categoriaCompetencia = categoriaCompetencia
        },
        enviarDocumento(state,doc){
            state.documento=doc
        },
    },

    actions: {
        registrarCategoria(context, categoria) {
            return new Promise((resolve, reject) => {
                axios.post("api/categorias", categoria, { headers: {'Content-Type': 'multipart/form-data'} })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarCategorias(context) {
            axios.get("api/categorias")
            .then((response) => {
                const categorias = []
                for (let i = 0; i < response.data.results.length; i++) {
                    categorias.push({
                        indice: (i+1),
                        id:                         response.data.results[i].id_categoria,
                        categoria:                  response.data.results[i].categoria,
                        // logotipo:                   response.data.results[i].logotipo,
                        descripcion:                response.data.results[i].descripcion,
                        documento_lineamientos:     response.data.results[i].documento_lineamientos
                    })
                }
                context.commit("enviarCategorias", categorias)
            })
            .catch(() => {})
        },

        detallesCategoria(context, categoria) {
            axios.get("api/categorias/"+categoria)
            .then((response) => {
                const categoria = response.data.results.categoria

                const programasFormacionCategoria = []
                for (let i = 0; i < response.data.results.programasFormacion.length; i++) {
                    programasFormacionCategoria.push({
                        indice: (i+1),
                        id:                 response.data.results.programasFormacion[i].programa_formacion.id_programa_formacion,
                        programa_formacion: response.data.results.programasFormacion[i].programa_formacion.programa_formacion,
                        area_tematica_id:   response.data.results.programasFormacion[i].programa_formacion.area_tematica_id  
                    })
                }
                const aliadosCategoria = []
                for (let i = 0; i < response.data.results.aliados.length; i++) {
                    aliadosCategoria.push({
                        indice: (i+1),
                        id:     response.data.results.aliados[i].aliado.id_aliado,
                        aliado: response.data.results.aliados[i].aliado.aliado
                    })
                }
                const documentos=response.data.results.documento
                console.log(response.data.results.documento);

                context.commit("enviarCategoria", categoria)
                context.commit("enviarProgramasFormacionCategoria", programasFormacionCategoria)
                context.commit("enviarAliadosCategoria", aliadosCategoria)
                context.commit("enviarDocumento",documentos)
            })
            .catch(() => {})
        },

        editarCategoria(context, categoria) {
            return new Promise((resolve, reject) => {
                axios.get("api/categorias/"+categoria+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },
        
        actualizarCategoria(context, categoria) {
            return new Promise((resolve, reject) => {
                axios.post("api/categorias/"+categoria.get("id"), categoria, { headers: {'Content-Type': 'multipart/form-data'} })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        asignarCategoriaCompetencia(context, categoriaCompetencia) {
            return new Promise((resolve, reject) => {
                axios.post("api/competencias/"+categoriaCompetencia.competencia_id+"/categorias", categoriaCompetencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarCategoriasCompetencia(context, competencia) {
            axios.get("api/competencias/"+competencia+"/categorias")
            .then((response) => {
                const categoriasCompetencia = []
                for (let i = 0; i < response.data.results.length; i++) {
                    categoriasCompetencia.push({
                        indice: (i+1),
                        categoria_id:           response.data.results[i].categoria.id_categoria,
                        categoria:              response.data.results[i].categoria.categoria,
                        numero_participantes:   response.data.results[i].numero_participantes,
                        cantidad_grupos:        response.data.results[i].cantidad_grupos,
                        modalidad_id:           response.data.results[i].modalidad.id_modalidad,
                        modalidad:              response.data.results[i].modalidad.modalidad,
                        competencia_id:         response.data.results[i].competencia.id_competencia,
                        competencia:            response.data.results[i].competencia.competencia,
                        estado_inscripcion:     response.data.results[i].estado_inscripcion
                    })
                }
                context.commit("enviarCategoriasCompetencia", categoriasCompetencia)
            })
            .catch(() => {})
        },

        detallesCategoriaCompetencia(context, categoriaCompetencia) {
            axios.get("api/competencias/"+categoriaCompetencia.competencia_id+"/categorias/"+categoriaCompetencia.categoria_id)
            .then((response) => {
                const categoriaCompetencia = {
                    categoria_id:           response.data.results.categoria.id_categoria,
                    categoria:              response.data.results.categoria.categoria,
                    competencia_id:         response.data.results.competencia.id_competencia,
                    competencia:            response.data.results.competencia.competencia,
                    numero_participantes:   response.data.results.numero_participantes,
                    cantidad_grupos:        response.data.results.cantidad_grupos                    
                }

                context.commit("enviarCategoriaCompetencia", categoriaCompetencia)
            })
            .catch(() => {})
        },

        editarCategoriaCompetencia(context, categoriaCompetencia) {
            return new Promise((resolve, reject) => {
                axios.get("api/competencias/"+categoriaCompetencia.competencia_id+"/categorias/"+categoriaCompetencia.categoria_id+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarCategoriaCompetencia(context, categoriaCompetencia) {
            return new Promise((resolve, reject) => {
                axios.put("api/competencias/"+categoriaCompetencia.competencia_id+"/categorias/"+categoriaCompetencia.categoria_id, categoriaCompetencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        estadoInscripcion(context, categoriaCompetencia) {
            return new Promise((resolve, reject) => {
                axios.put("api/competencias/"+categoriaCompetencia.competencia+"/categorias/"+categoriaCompetencia.categoria+"/estadoInscripcion", categoriaCompetencia)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        eliminarCategoriaCompetencia(context,categoriaCompetencia) {
            return new Promise((resolve, reject) => {
                axios.delete("api/competencias/"+categoriaCompetencia.competencia_id+"/categorias/"+categoriaCompetencia.categoria_id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        async downloadDocumentoLineamentos(context,categoria){
            return new Promise((resolve, reject) => {
               axios.get("api/download/"+categoria.categoria_id, {responseType: 'blob'})
                .then((response) => {
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
            })        
        }
    }
}

export default categorias