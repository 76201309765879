<template>
  <app-detail-card
    titleCard="Competencia"
    :itemsCard="competencia"
    rutaRegresar="competencia-gestionar"
  >
  </app-detail-card>
</template>
<script>
import DetailCard from '../../../components/admin/DetailCard.vue'

export default {
  created() {
    this.$store.dispatch("detallesCompetencia", this.$route.params.id_competencia)
  },

  computed: {
    competencia() {
      const detalles = this.$store.getters.obtenerCompetencia;
      const competencia = [
        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Centro de Formación",
          value:  detalles.centro_formacion
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Competencia",
          value:  detalles.competencia
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Descripción",
          value:  detalles.descripcion
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Fecha Inicio",
          value:  detalles.fecha_inicio
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Fecha Fin",
          value:  detalles.fecha_fin
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Lugar",
          value:  detalles.lugar
        },

        {
          col:    "12",
          colSm:  "10",
          colLg:  "8",
          label:  "Dirección",
          value:  detalles.direccion
        }

      ];

      return competencia;
    },
  },

  components: {
    "app-detail-card": DetailCard
  }
};
</script>