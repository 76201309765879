<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleCard="titleCard"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :buttonCard="buttonCard"
    >
    </app-alert>

    <!-- formulario -->
    <v-row justify="center">
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="font-weight-bold text-h4 primary--text mt-4">
              Registrar Sede
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-select 
                      outlined 
                      dense 
                      label="Regional*" 
                      :items="regionales" 
                      item-value="id" 
                      item-text="regional"
                      v-model="regional" 
                    >
                  </v-select>

                  <v-select 
                      outlined 
                      dense 
                      label="Centro de Formación*" 
                      :items="centrosFormacion" 
                      item-value="id"
                      item-text="centro_formacion" 
                      v-model="centroFormacion" 
                    >
                  </v-select>
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Sede*"
                    maxlength="100"
                    counter
                    autofocus
                    v-model="sede"
                    :rules="sedeRules"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Lugar*"
                    maxlength="100"
                    counter
                    v-model="lugar"
                    :rules="lugarRules"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Dirección*"
                    maxlength="50"
                    counter
                    v-model="direccion"
                    :rules="direccionRules"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Responsable*"
                    maxlength="100"
                    counter
                    v-model="responsable"
                    :rules="responsableRules"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Teléfono*"
                    v-model="telefono"
                    maxlength="10"
                    counter
                    :rules="telefonoRules"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Correo Electrónico*"
                    maxlength="50"
                    counter
                    v-model="correo"
                    :rules="correoRules"
                  >
                  </v-text-field>

                  <v-btn
                    block
                    large
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="registrar"
                  >
                    Registrar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Alert from '@/components/admin/Alert.vue'

export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    loadingForm:  false,
    form:         true,

    sede: null,
    sedeRules: [
      v => !!v || "Escriba una sede"
    ],

    lugar: null,
    lugarRules: [
      v => !!v || "Escriba un lugar"
    ],

    direccion: null,
    direccionRules: [
      v => !!v || "Escriba una dirección"
    ],

    responsable: null,
    responsableRules: [
      v => !!v || "Escriba un responsable"
    ],

    telefono: "",
    telefonoRules: [
      v => !!v || "Escriba un número de telefono",
      v => v.length == 10 || "El número de telefono debe tener 10 caracteres"
    ],
    
    correo: null,
    correoRules: [
      v => !!v || "Escriba un correo electrónico",
      v => /.+@.+\..+/.test(v) || "Correo electrónico no válido",
    ],
    regional: null,
    centroFormacion: null,
  }),

  mounted(){
    this.$store.dispatch("crearRegionales");
    this.$store.dispatch("crearCentrosFormacion");
  },

  computed:{
    regionales(){
      return this.$store.getters.obtenerRegionales
    },
    centrosFormacion() {
      return this.$store.getters.obtenerCentrosFormacion(this.regional);
    },
  },

  components: {
    "app-alert": Alert
  },

  methods: {
    registrar() {
      if (this.$refs.form.validate()) {
        const sede ={
          centro_formacion_id:        this.centroFormacion,
          sede:                       this.sede,
          lugar:                      this.lugar,
          direccion:                  this.direccion,
          responsable:                this.responsable,
          telefono_responsable:       this.telefono,
          correo_responsable:         this.correo
        }
        
        this.loadingForm = true;
        this.$store.dispatch("registrarSede", sede)
        .then((response) => {
          if (response.status == "success") {
            this.$refs.form.reset();
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Registrada";
            this.messageAlert = response.message;
            this.titleCard    = "success--text";
            this.buttonCard   = "success";
            setTimeout(() => (this.$router.push('/sedes/gestionar')), 1500)
          } 
          else {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message.sede;
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }

          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {
        });
      }
    }
  }
}
</script>

<style>
</style>