<template>
  <div>
    <!-- cargador tabla -->
    <template v-if="loaderTable">
      <v-skeleton-loader 
        class="mx-auto" 
        type="table"
      >
      </v-skeleton-loader>
    </template>
    <template v-else>
      <!-- tabla -->

      <!-- lider regional -->
      <template v-if="usuario.perfil_id == 5">
        <v-card>
          <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
            <v-row>
              <v-col 
                cols="12" 
                sm="6"
              >
                Gestionar Competencia
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            fixed-header
            locale="es"
            height="480px"
            class="elevation-2"
            :loading="loadingTable"
            :headers="headersTable"
            :items="competenciasWorldSkillsRegional"
            :search="searchTable"
            no-data-text="No hay competencia registrada"
            :footer-props="{ 'items-per-page-options': [10, 20, -1] }"
          >
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="detalle(item.id)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Detalles</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="asociarHabilidad(item.id)"
                  >
                    mdi-playlist-plus
                  </v-icon>
                </template>
                <span>Habilidades Asociadas</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="registrarAcompanante(item.id)"
                  >
                    mdi-account-plus
                  </v-icon>
                </template>
                <span>Registrar Acompañantes</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </template>

      <!-- experto nacional -->
      <template v-if="usuario.perfil_id == 4">
        <v-card>
          <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
            <v-row>
              <v-col 
                cols="12" 
                sm="6"
              >
                Gestionar Competencias 
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            fixed-header
            locale="es"
            height="480px"
            class="elevation-2"
            :loading="loadingTable"
            :headers="headersTable"
            :items="competenciasWorldSkillsNacional"
            :search="searchTable"
            no-data-text="No hay competencias registradas"
            :footer-props="{ 'items-per-page-options': [10, 20, -1] }"
          >
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="detalle(item.id)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Detalles</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="asociarHabilidad(item.id)"
                  >
                    mdi-playlist-plus
                  </v-icon>
                </template>
                <span>Habilidad Asociada</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </template>

      <!-- lider nacional -->
      <template v-if="usuario.perfil_id == 3 || usuario.perfil_id == 1">
        <v-card>
          <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
            <v-row>
              <v-col 
                cols="12" 
                sm="9"
              >
                Gestionar Competencias 
              </v-col>

              <template v-if="competenciasWorldSkills.length > 0">
                <v-col 
                  cols="12" 
                  sm="3"
                >
                  <v-text-field 
                    outlined 
                    dense 
                    append-icon="mdi-magnify" 
                    label="Buscar Competencia" 
                    hide-details
                    v-model="searchTable"
                  >
                  </v-text-field>
                </v-col>
              </template>
            </v-row>
          </v-card-title>
          <v-data-table
            fixed-header
            locale="es"
            height="480px"
            class="elevation-2"
            :loading="loadingTable"
            :headers="headersTable"
            :items="competenciasWorldSkills"
            :search="searchTable"
            no-data-text="No hay competencias registradas"
            :footer-props="{ 'items-per-page-options': [20, 50, -1] }"
          >
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="detalle(item.id)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Detalles</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="actualizar(item.id)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Actualizar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="registrarDocumento(item.id)"
                  >
                    mdi-cloud-upload
                  </v-icon>
                </template>
                <span>Registrar Documentos</span>
              </v-tooltip>

              <template v-if="item.tipo_competencia_id == 2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="registrarSede(item.id)"
                    >
                      mdi-map-marker-multiple
                    </v-icon>
                  </template>
                  <span>Registrar Sedes</span>
                </v-tooltip>
              </template>              

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="asociarHabilidad(item.id)"
                  >
                    mdi-playlist-plus
                  </v-icon>
                </template>
                <span>Asociar Habilidades</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="registrarAcompanante(item.id)"
                  >
                    mdi-account-plus
                  </v-icon>
                </template>
                <span>Registrar Acompañantes</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </template>

      <!-- experto regional -->
      <template v-if="usuario.perfil_id == 6">
        <v-card>
          <v-card-title class="text-h6 text-md-h5 font-weight-bold primary--text">
            <v-row>
              <v-col 
                cols="12" 
                sm="6"
              >
                Gestionar Competencias 
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            fixed-header
            locale="es"
            height="480px"
            class="elevation-2"
            :loading="loadingTable"
            :headers="headersTable"
            :items="competenciasWorldSkillsNacional"
            :search="searchTable"
            no-data-text="No hay competencias registradas"
            :footer-props="{ 'items-per-page-options': [10, 20, -1] }"
          >
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="detalle(item.id)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Detalles</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="asociarHabilidad(item.id)"
                  >
                    mdi-playlist-plus
                  </v-icon>
                </template>
                <span>Habilidad Asociada</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    loaderTable:  true,
    loadingTable: false,
    searchTable:  "",
    headersTable: [
      {
        text: "Competencia",
        value: "competencia",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"]
      },

      {
        text: "Fecha de Inicio",
        value: "fecha_inicio",
        align: "start",
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"]
      },

      {
        text: "Fecha de Finalización",
        value: "fecha_fin",
        align: "start",
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"]
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"]
      }
    ]
  }),
  
  mounted() {
    this.$store.dispatch("gestionarCompetenciaWorldSkills")

    setTimeout(() => {
      this.loaderTable = false
    }, 1000)
  },

  computed: {
    competenciasWorldSkillsRegional() {
      return this.$store.getters.obtenerCompetenciasWorldSkills.filter(competencia => competencia.tipo_competencia_id == 3)
    },

    competenciasWorldSkillsNacional() {
      return this.$store.getters.obtenerCompetenciasWorldSkills.filter(competencia => competencia.tipo_competencia_id == 2 || competencia.tipo_competencia_id == 4)
    },

    competenciasWorldSkills() {
      return this.$store.getters.obtenerCompetenciasWorldSkills
    },

    usuario() {
      return this.$store.getters.usuario
    }
  },

  methods: {
    detalle(competencia) {
      this.$router.push({
        name: "competenciaws-detalles",
        params: { id_competencia: competencia }
      });
    },

    actualizar(competencia) {
      this.$router.push({
        name: "competenciaws-actualizar",
        params: { id_competencia: competencia }
      });
    },

    registrarDocumento(competencia) {
      this.$router.push({
        name: "competenciaws-documentos",
        params: { id_competencia: competencia }
      })
    },

    registrarSede(competencia) {
      this.$router.push({
        name: "asignar-sede",
        params: { id_competencia: competencia }
      });
      this.$store.dispatch("clearSearchB"); 
    },

    asociarHabilidad(competencia) {
      this.$router.push({
        name: "asignar-habilidad",
        params: { id_competencia: competencia }
      });
      this.$store.dispatch("clearSearchB"); 
    },

    registrarAcompanante(competencia) {
      this.$router.push({
        name: "gestionar-acompanante",
        params: { id_competencia: competencia }
      })
    },

    foro(competencia) {
      this.$router.push({
      name: "foro",
      params: {
        id_usuario: this.usuario.id_usuario,
        id_competencia: competencia
      }
      })
    }
  }
};
</script>
