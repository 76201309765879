import axios from 'axios'

const administracion = {
    state: () => ({
        modulosUsuario:     [],
        modulos:            [],
        regionales:         [],
        centrosFormacion:   [],
        areasTematicas:     [],
        programasFormacion: [],
        modalidades:        [],
        tiposLider:         [],
        cargos:             [],
        modulosPermisos:    [],
        tipoCompetencias:   [],
        tipoAcompanante: []
    }),

    getters: {
        obtenerModulosUsuario(state) {
            return state.modulosUsuario
        },

        obtenerModulos(state) {
            return state.modulos
        },

        obtenerRegionales(state) {
            return state.regionales
        },
        
        obtenerCentrosFormacion: (state) => (id) => {
            return state.centrosFormacion.filter(centroFormacion => centroFormacion.regional_id == id)
        },

        obtenerCentroFormacion: (state) => (id) => {
            return state.centrosFormacion.find(centroFormacion => centroFormacion.id == id)
        },

        obtenerAreasTematicas(state) {
            return state.areasTematicas
        },
        
        obtenerAreaTematica: (state) => (id) => {
            return state.areasTematicas.find(areaTematica => areaTematica.id == id)
        },

        obtenerProgramasFormacion: (state) => {
            return state.programasFormacion
        },

        obtenerModalidades(state) {
            return state.modalidades
        },

        obtenerTiposLiderCompetencia: (state) => (id) =>  {
            return state.tiposLider.filter(tipoLider => tipoLider.id < id)
        },

        obtenerTiposLiderCategoria(state) {
            return state.tiposLider.filter(tipoLider => tipoLider.id < 10)
        },

        obtenerIdentificaciones(state) {
            const identificaciones = []
            for (let i = 0; i < state.personas.length; i++) {
                identificaciones.push(
                    state.personas[i].identificacion,
                )
            }
            return identificaciones
        },

        obtenerCargos(state){
            return state.cargos
        },

        obtenerTipoLider(state){
            return state.tiposLider
        },

        obtenerModulosPermisos(state) {
            return state.modulosPermisos
        },
        
        obtenerTipoCompetencias(state) {
            return state.tipoCompetencias
        },
        
        obtenerTipoAcompanante(state) {
            return state.tipoAcompanante
        },
    },

    mutations: {
        enviarModulosUsuario(state, modulosUsuario) {
            state.modulosUsuario = modulosUsuario
        },

        enviarModulos(state, modulos) {
            state.modulos = modulos
        },

        enviarRegionales(state, regionales) {
            state.regionales = regionales
        },

        enviarCentrosFormacion(state, centrosFormacion) {
            state.centrosFormacion = centrosFormacion
        },

        enviarAreasTematicas(state, areasTematicas) {
            state.areasTematicas = areasTematicas
        },

        enviarProgramasFormacion(state, programasFormacion) {
            state.programasFormacion = programasFormacion
        },

        enviarModalidades(state, modalidades) {
            state.modalidades = modalidades
        },

        enviarTiposLider(state, tiposLider) {
            state.tiposLider = tiposLider
        },

        enviarCargos(state, cargos){
            state.cargos = cargos
        },

        enviarModulosPermisos(state, modulosPermisos) {
            state.modulosPermisos = modulosPermisos
        },

        enviarTipoCompetencias(state, tipoCompetencias) {
            state.tipoCompetencias = tipoCompetencias
        },

        enviarTipoAcompanante(state, tipoAcompanante) {
            state.tipoAcompanante = tipoAcompanante
        }
    },

    actions: {
        crearModulosUsuario(context, usuario) {
            axios.get("api/modulos/"+usuario)
            .then((response) => {
                const modulosUsuario = response.data.results
                context.commit("enviarModulosUsuario", modulosUsuario)
            })
            .catch(() => {})
        },

        crearModulos(context) {
            axios.get("api/modulos")
            .then((response) => {
                const modulos = response.data.results
                context.commit("enviarModulos", modulos)
            })
            .catch(() => {})
        },

        crearRegionales(context) {
            axios.get("api/regionales")
            .then((response) => {
                const regionales = []
                for (let i = 0; i < response.data.results.length; i++) {
                    regionales.push({
                        id:         response.data.results[i].id_regional,
                        regional:   response.data.results[i].regional
                    })
                }
                context.commit("enviarRegionales", regionales)
            })
            .catch(() => {})
        },

        crearCentrosFormacion(context) {
            axios.get("api/centrosFormacion")
            .then((response) => {
                const centrosFormacion = []
                for (let i = 0; i < response.data.results.length; i++) {
                    centrosFormacion.push({
                        id:                 response.data.results[i].id_centro_formacion,
                        centro_formacion:   response.data.results[i].centro_formacion,
                        regional_id:        response.data.results[i].regional.id_regional,
                        regional:           response.data.results[i].regional.regional
                    })
                }
                context.commit("enviarCentrosFormacion", centrosFormacion)
            })
            .catch(() => {})
        },

        crearAreasTematicas(context) {
            axios.get("api/areasTematicas")
            .then((response) => {
                const areasTematicas = []
                for (let i = 0; i < response.data.results.length; i++) {
                    areasTematicas.push({
                        id:             response.data.results[i].id_area_tematica,
                        area_tematica:  response.data.results[i].area_tematica
                    })
                }

                context.commit("enviarAreasTematicas", areasTematicas)
            })
            .catch(() => {})
        },

        crearProgramasFormacion(context) {
            axios.get("api/programasFormacion")
            .then((response) => {
                const programasFormacion = []
                for (let i = 0; i < response.data.results.length; i++) {
                    programasFormacion.push({
                        id:                     response.data.results[i].id_programa_formacion,
                        programa_formacion:     response.data.results[i].programa_formacion,
                        area_tematica_id:       response.data.results[i].area_tematica_id
                    })
                }

                context.commit("enviarProgramasFormacion", programasFormacion)
            })
            .catch(() => {})
        },

        crearModalidades(context) {
            axios.get("api/modalidades")
            .then((response) => {
                const modalidades = []
                for (let i = 0; i < response.data.results.length; i++) {
                    modalidades.push({
                        id:         response.data.results[i].id_modalidad,
                        modalidad:  response.data.results[i].modalidad,
                    })
                }

                context.commit("enviarModalidades", modalidades)
            })
            .catch(() => {})
        },

        crearCargos(context){
            axios.get("api/cargos")
            .then((response) => {
                const cargos = []
                for (let i = 0; i < response.data.results.length; i++){
                    cargos.push({
                        id: response.data.results[i].id_cargo,
                        cargo: response.data.results[i].cargo
                    })
                }
                context.commit("enviarCargos", cargos)
            })
        },

        crearTiposLider(context) {
            axios.get("api/tiposLider")
            .then((response) => {
                const tiposLider = []
                for (let i = 0; i < response.data.results.length; i++) {
                    tiposLider.push({
                        id:         response.data.results[i].id_tipo_lider,
                        tipo_lider: response.data.results[i].tipo_lider,
                    })
                }

                context.commit("enviarTiposLider", tiposLider)
            })
            .catch(() => {})
        },

        crearPersonas(context, identificacion) {
            return new Promise((resolve, reject) => {
                axios.get("api/personas/"+identificacion)
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        crearExperto(context, identificacion) {
            return new Promise((resolve, reject) => {
                axios.get("api/expertoNacional/"+identificacion)
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },
        
        crearExpertoRegional(context, identificacion) {
            return new Promise((resolve, reject) => {
                axios.get("api/expertoRegional/"+identificacion)
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },
        
        crearTiposCompetencia(context) {
            axios.get("api/tiposCompetencia")
            .then((response) => {
                const tipoCompetencias = []
                for (let i = 0; i < response.data.results.length; i++) {
                    if (response.data.results[i].id_tipo_competencia !=1){
                        tipoCompetencias.push({
                            id              : response.data.results[i].id_tipo_competencia,
                            tipo_competencia: response.data.results[i].tipo_competencia,
                        })
                    }
                }

                context.commit("enviarTipoCompetencias", tipoCompetencias)
            })
            .catch(() => {})
        },

        modulosPermisos(context) {
            axios.get("api/modulos")
            .then((response) => {        
                const modulosPermisos = [];
                response.data.results.forEach(modulo => {
                    modulosPermisos.push({
                        id:                 modulo.id_modulo,
                        modulo:             modulo.modulo,
                        modulo_hijo:        modulo.modulo_hijo.modulo,
                        hijo_hijo:          modulo.modulo_hijo.modulo
                    })
                })
                context.commit("enviarModulosPermisos", modulosPermisos)

            })
            .catch(() => {})
        },

        crearTipoAcompanante(context) {
            axios.get("api/tipoAcompanante")
            .then((response) => {
                const tipoAcompanante = []
                for (let i = 0; i < response.data.results.length; i++) {
                    tipoAcompanante.push({
                        id:                     response.data.results[i].id_tipo_acompanante,
                        tipo_acompanante:       response.data.results[i].tipo_acompanante
                    })
                }
                context.commit("enviarTipoAcompanante", tipoAcompanante)
            })
            .catch(() => {})
        },
    }
}

export default administracion