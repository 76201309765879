<template>
  <v-row 
    no-gutters 
    class="fill-height"
  >
    <v-col 
      cols="12" 
      md="6" 
      lg="7" 
      class="hidden-sm-and-down"
    >
      <v-card 
        tile 
        mx-auto 
        height="100%" 
        elevation="1"
      >
        <v-img
          src="@/assets/sigefLogin.png"
          height="80%"
        >
        </v-img>
      </v-card>
    </v-col>
    <v-col 
      cols="12" 
      md="6" 
      lg="5"
    >
      <v-card 
        mx-auto 
        height="100%" 
        elevation="1" 
        tile color="#f6f7ff"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col 
              cols="12" 
              sm="10" 
              lg="8"
            >
              <p class="text-subtitle-1 text-lg-h5 font-weight-bold primary--text title-login">Inicio de Sesión</p>

              <v-form 
                ref="form" 
                v-model="form"
              >
                <v-text-field
                  outlined
                  type="text"
                  label="Correo Electrónico"
                  class="mt-10"
                  v-model="correo"
                  :rules="correoReglas"
                >
                </v-text-field>

                <v-text-field
                  outlined
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  label="Contraseña"
                  v-model="contrasena"
                  :rules="contrasenaReglas"
                  :loading="loadingTextField"
                  :error-messages="mensajeSesion"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="iniciarSesion"
                >
                </v-text-field>

                <v-btn
                  block
                  x-large
                  exact
                  elevation="2"
                  color="primary"
                  class="mt-2"
                  @click="iniciarSesion"
                >
                  Ingresar
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    form: true,
    showPassword: false,
    loadingTextField: false,
    mensajeSesion: "",

    correo: null,
    correoReglas: [
      v => !!v || "Escriba un correo electrónico",
      v => /.+@.+\..+/.test(v) || "Correo electrónico no válido",
    ],

    contrasena: null,
    contrasenaReglas: [
      v => !!v || "Escriba una contraseña",
    ]
  }),

  computed: {
    usuario () {
      return this.$store.getters.usuario
    }
  },

  methods: {
    iniciarSesion () {
      const usuario = {
        correo:   this.correo,
        password: this.contrasena
      }

      if (this.$refs.form.validate()) {
        this.loadingTextField = true
        this.$store.dispatch("iniciarSesion", usuario)
        .then(() => {
          this.loadingTextField = false
          if (this.usuario.perfil_id == 2) {
            this.$router.push({ name: 'inscripcion-gestionar' })
          }
          else if(this.usuario.perfil_id == 3 || this.usuario.perfil_id == 5 || this.usuario.perfil_id == 6 || this.usuario.perfil_id == 4 ||this.usuario.perfil_id == 7){
            this.$router.push({ name: 'inicio' })
          }
          else {
            this.$router.push({ name: 'tablero' })
          }  
        })
        .catch(() => {
          if (this.usuario == null) {
            this.loadingTextField = false
            this.mensajeSesion = "Correo electrónico o contraseña incorrectos"
          }
        })
      }      
    }
  }
};
</script>

<style scoped>
.icono-card {
  margin-top: 60%;
}
.title-login {
  margin-top: 28%;
}
</style>