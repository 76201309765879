<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>

    <!-- formulario -->
    <v-row>
      <v-col sm="1" lg="2" class="hidden-xs-only">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              fixed
              exact
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="regresar"
            >
              <v-icon> mdi-arrow-left </v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" sm="10" lg="8">
        <v-card outlined elevation="2" :loading="loadingForm">
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Actualizar Aliado
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" sm="10" lg="8">
                <v-form ref="form" v-model="form">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Aliado*"
                    maxlength="150"
                    counter
                    v-model="aliado"
                    :rules="aliadoReglas"
                  >
                  </v-text-field>

                  <v-file-input
                    outlined
                    dense
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    accept="image/png, image/jpeg"
                    label="Logotipo"
                    truncate-length="50"
                    show-size
                    counter
                    v-model="logotipo"
                    :rules="logotipoReglas"
                  >
                  </v-file-input>

                  <v-textarea
                    outlined
                    rows="4"
                    label="Descripción"
                    v-model="descripcion"
                  >
                  </v-textarea>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Dirección*"
                    maxlength="50"
                    counter
                    v-model="direccion"
                    :rules="direccionReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    maxlength="10"
                    counter
                    label="Teléfono*"
                    v-model="telefono"
                    :rules="telefonoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Correo Electrónico*"
                    maxlength="50"
                    counter
                    v-model="correo"
                    :rules="correoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Contacto del Aliado"
                    maxlength="25"
                    counter
                    v-model="contactoAliado"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    maxlength="10"
                    counter
                    label="Teléfono contacto Aliado"
                    v-model="telefonoContactoAliado"
                    :rules="telefonoContactoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Correo Electrónico"
                    maxlength="50"
                    counter
                    v-model="correoContactoAliado"
                    :rules="correoContactoAliadoReglas"
                  >
                  </v-text-field>

                  <v-btn
                    large
                    block
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="actualizar"
                  >
                    Actualizar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Alert from "../../../components/admin/Alert.vue";

export default {
  data: () => ({
    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    loadingForm: false,
    form: true,

    aliado: null,
    aliadoReglas: [(v) => !!v || "Escriba un aliado"],

    logotipo: null,
    logotipoReglas: [
      (v) =>
        !v ||
        v.size < 1000000 ||
        "El tamaño del logotipo debe ser menor que 1 MB",
    ],

    descripcion: null,

    direccion: null,
    direccionReglas: [(v) => !!v || "Escriba una dirección"],

    telefono: null,
    telefonoReglas: [
      (v) => !!v || "Escriba un número de teléfono",
      (v) =>
        !v || v.length <= 10 || "La cantidad de numeros debe ser menor a 10",
    ],
    telefonoContactoReglas: [
      (v) =>
        !v || v.length <= 10 || "La cantidad de numeros debe ser menor a 10",
    ],
    correo: null,
    correoReglas: [
      (v) => !!v || "Escriba un correo electrónico",
      (v) => /.+@.+\..+/.test(v) || "Correo electrónico no válido",
    ],

    contactoAliado: null,

    telefonoContactoAliado: null,

    correoContactoAliado: null,
    correoContactoAliadoReglas: [
      (v) => !v || /.+@.+\..+/.test(v) || "Correo electrónico no válido",
    ],
  }),

  created() {
    this.$store
      .dispatch("editarAliado", this.$route.params.id_aliado)
      .then((response) => {
        this.aliado = response.aliado;
        this.descripcion =
          response.descripcion == "No registrada" ? "" : response.descripcion;
        this.direccion = response.direccion;
        this.telefono = response.telefono;
        this.correo = response.correo;
        this.contactoAliado =
          response.contacto_aliado == "No registrado"
            ? ""
            : response.contacto_aliado;
        this.telefonoContactoAliado =
          response.telefono_contacto_aliado == "No registrado"
            ? ""
            : response.telefono_contacto_aliado;
        this.correoContactoAliado =
          response.correo_contacto_aliado == "No registrado"
            ? ""
            : response.correo_contacto_aliado;
      })
      .catch(() => {});
  },

  components: {
    "app-alert": Alert,
  },

  methods: {
    actualizar() {
      if (this.$refs.form.validate()) {
        const aliado = new FormData();
        aliado.set("_method", "PUT");
        aliado.set("id", this.$route.params.id_aliado);
        aliado.set("aliado", this.aliado);
        aliado.set("logotipo", this.logotipo);
        aliado.set(
          "descripcion",
          this.descripcion != "" ? this.descripcion : null
        );
        aliado.set("direccion", this.direccion);
        aliado.set("telefono", this.telefono);
        aliado.set("correo", this.correo);
        aliado.set(
          "contacto_aliado",
          this.contactoAliado != "" ? this.contactoAliado : null
        );
        aliado.set(
          "telefono_contacto_aliado",
          this.telefonoContactoAliado != "" ? this.telefonoContactoAliado : null
        );
        aliado.set(
          "correo_contacto_aliado",
          this.correoContactoAliado != "" ? this.correoContactoAliado : null
        );

        this.loadingForm = true;

        this.$store
          .dispatch("actualizarAliado", aliado)
          .then((response) => {
            if (response.status == "success") {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Actualizado";
              this.messageAlert = response.message;
              this.titleCard = "success--text";
              this.buttonCard = "success";
              setTimeout(() => {
                this.$router.push({
                  name: "aliado-gestionar",
                });
              }, 2500);
            } else {
              this.loadingForm = false;
              this.alert = true;
              this.titleAlert = "Error";
              this.messageAlert = response.message.aliado[0];
              this.titleCard = "error--text";
              this.buttonCard = "error";
            }

            setTimeout(() => {
              this.alert = false;
            }, 1000);
          })
          .catch(() => {});
      }
    },

    regresar() {
      this.$router.push({
        name: "aliado-gestionar",
      });
    },
  },
};
</script>