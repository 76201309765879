<template>
  <v-row>
    <v-col
      sm="1"
      lg="2"
      class="hidden-xs-only"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            fixed
            exact
            color="primary"
            v-bind="attrs" 
            v-on="on" 
            @click="regresar"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>Regresar</span>
      </v-tooltip>
    </v-col>
    <v-col 
      cols="12" 
      sm="10" 
      lg="8"
    >
      <v-card outlined elevation="2">
        <v-card-title class="justify-center">
          <p class="text-h4 font-weight-bold primary--text mt-2">
            Detalles Experto Nacional
          </p>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Identificación</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.identificacion }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Nombres</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.nombres }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Apellidos</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.apellidos }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Télefono</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.telefono_movil }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Correo Electrónico</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.correo }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Fecha de Nacimiento</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.fecha_nacimiento }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Género</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.genero }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Talla de Camiseta</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.talla_camiseta }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Regional</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.regional }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Centro de Formación</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.centro_formacion }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Habilidad</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.categoria }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Competencia</p>
              <p class="text-subtitle-1">
                {{ expertoNacional.competencia }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({}),

  mounted() {
    this.$store.dispatch("detallesExpertoNacional", this.$route.params.id_experto)
  },

  computed: {
    expertoNacional() {
      return this.$store.getters.obtenerExpertoNacionalWorldSkills
    }
  },

  methods: {
    regresar() {
      this.$router.push({
        name: "expertows-nacional-gestionar",
        params: { id_competencia: this.expertoNacional.competencia_id, id_categoria: this.expertoNacional.categoria_id }
      });
    }
  }
};
</script>