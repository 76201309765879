<template>
  <div>
    <!-- alerta -->
    <v-dialog max-width="280" v-model="alert">
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :color="buttonCard"
            class="mb-2 white--text"
            @click="alert = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cargador tabla -->
    <template v-if="loaderTable">
      <v-skeleton-loader class="mx-auto" type="table"> </v-skeleton-loader>
    </template>
    <template v-else>

      <!-- tabla -->
      <v-row justify="center">
        <v-col cols="12">
          <router-view></router-view>
          <v-card>
            <v-card-title
              class="text-h6 text-sm-h5 font-weight-bold primary--text"
            >
              <v-row align="center">
                <v-col 
                  cols="2" 
                  sm="1"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        small
                        exact
                        color="primary"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="regresar"
                      >
                        <v-icon> mdi-arrow-left </v-icon>
                      </v-btn>
                    </template>
                    <span>Regresar</span>
                  </v-tooltip>
                </v-col>

                <template v-if="usuario.perfil_id == 3 || usuario.perfil_id == 1">
                  <v-col cols="8" sm="11" md="6">
                      Sedes: {{ competencia.competencia }}
                    </v-col>

                  <v-col cols="8" sm="10" md="3">
                    <v-text-field
                      v-if="sedes.length > 0"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      label="Buscar Sede"
                      hide-details
                      v-model="searchTable"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" sm="2" align="end">
                    <v-btn exact color="primary" @click="abrirDialog">
                      Registrar
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>

            <v-data-table
              fixed-header
              locale="es"
              height="460px"
              class="elevation-2"
              :headers="headersTable"
              :items="sedes"
              :search="searchTable"
              no-data-text="No hay sedes registradas"
              :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1] }"
            >
            <template v-slot:top>
              <v-dialog 
                persistent
                v-model="dialogForm" 
                max-width="520px"
              >
                <v-card :loading="loadingForm">
                  <v-card-title class="justify-center">
                    <p class="text-h5 font-weight-bold primary--text">
                      {{ formTitle }}
                    </p>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row justify="center">
                        <v-col cols="12" md="10">
                          <v-form 
                            ref="form" 
                            v-model="form"
                          >
                            <v-text-field  
                              outlined
                              dense
                              disabled
                              type="text"
                              label="Competencia"
                              v-model="competenciaSeleccionada"
                            >
                            </v-text-field>

                            <v-select
                              outlined
                              dense
                              label="Regional*"
                              :items="regionales"
                              item-value="id"
                              item-text="regional"
                              v-model="regional"
                            >
                            </v-select>

                            <v-select
                              outlined
                              dense
                              label="Centro de Formación*"
                              :items="centrosFormacion"
                              item-value="id"
                              item-text="centro_formacion"
                              v-model="centroFormacion"
                            >
                            </v-select>

                            <v-text-field
                              outlined
                              dense
                              type="text"
                              label="Sede*"
                              maxlength="100"
                              counter
                              v-model="sede"
                              :rules="sedeRules"
                            >
                            </v-text-field>

                            <v-text-field
                              outlined
                              dense
                              type="text"
                              label="Lugar*"
                              maxlength="100"
                              counter
                              v-model="lugar"
                              :rules="lugarRules"
                            >
                            </v-text-field>

                            <v-text-field
                              outlined
                              dense
                              type="text"
                              label="Dirección*"
                              maxlength="50"
                              counter
                              v-model="direccion"
                              :rules="direccionRules"
                            >
                            </v-text-field>

                            <v-text-field
                              outlined
                              dense
                              type="text"
                              label="Responsable*"
                              maxlength="100"
                              counter
                              v-model="responsable"
                              :rules="responsableRules"
                            >
                            </v-text-field>

                            <v-text-field
                              outlined
                              dense
                              type="number"
                              label="Teléfono*"
                              v-model="telefono"
                              maxlength="10"
                              counter
                              :rules="telefonoRules"
                            >
                            </v-text-field>

                            <v-text-field
                              outlined
                              dense
                              type="text"
                              label="Correo Electrónico*"
                              maxlength="50"
                              counter
                              v-model="correo"
                              :rules="correoRules"
                            >
                            </v-text-field>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="cerrarDialog">
                      Cancelar
                    </v-btn>

                    <template v-if="editedIndex === -1">
                      <v-btn text exact color="primary" @click="registrar">
                        Registrar
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn text exact color="primary" @click="actualizar">
                        Actualizar
                      </v-btn>
                    </template>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="detalles(item.id)"
                  >
                    mdi-eye 
                  </v-icon>
                </template>
                <span>Detalles</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="editar(item.id)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Actualizar</span>
              </v-tooltip>

              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      @click="activarAlertaEliminar(item.id)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
            </template>
          </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center text-h5"
          >¿Desea eliminar la sede?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeDelete">Cancelar</v-btn>
          <v-btn
            text
            color="error"
            @click="eliminar(temSede_id, temCompetencia_id)"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
  <script>
export default {
  data: () => ({
    loaderTable: true,
    searchTable: null,

    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,
    loadingForm: false,
    form: true,

    dialogForm: false,
    dialogDelete: false,

    temSede_id: null,
    temCompetencia_id: null,

    competenciaSeleccionada: null ,

    idSede: null,
    sede: null,
    sedeRules: [
      v => !!v || "Escriba un nombre para la sede"
    ],

    lugar: null,
    lugarRules: [
      v => !!v || "Escriba un lugar"
    ],

    direccion: null,
    direccionRules: [
      v => !!v || "Escriba una dirección"
    ],

    responsable: null,
    responsableRules: [
      v => !!v || "Escriba un responsable"
    ],

    telefono: "",
    telefonoRules: [
      v => !!v || "Escriba un número de telefono",
      v => (v > 0) || "El número debe ser mayor a cero",
      v => !v || v.length == 10 || "El número de telefono debe tener 10 caracteres"
    ],
    
    correo: null,
    correoRules: [
      v => !!v || "Escriba un correo electrónico",
      v => /.+@.+\..+/.test(v) || "Correo electrónico no válido",
    ],
    regional: null,
    centroFormacion: null,

    editedIndex: -1,

    headersTable: [
      {
        text: "Sede",
        value: "sede",
        align: "start",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Lugar",
        value: "lugar",
        align: "start",
        width: "15%",
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Dirección",
        value: "direccion",
        align: "start",
        width: "15%",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Regional",
        value: "regional",
        align: "start",
        sortable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Centro Formación",
        value: "centro_formacion",
        align: "start",
        sortable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        filterable: false,
        class: ["text-subtitle-1", "font-weight-black"],
      } 
    ]
  }),

  mounted() {
    this.$store.dispatch("gestionarSedes", this.$route.params.id_competencia);
    this.$store.dispatch("detalleCompetenciaWorldSkills", this.$route.params.id_competencia);
    this.$store.dispatch("crearRegionales");
    this.$store.dispatch("crearCentrosFormacion");

    setTimeout(() => {
      this.loaderTable = false;
      const storedSearch = this.$store.getters.obtenerSearch;
      if (storedSearch) {
        this.searchTable = storedSearch;
      }
    }, 1000)
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar Sede"
        : "Actualizar Sede";
    },

    usuario() {
      return this.$store.getters.usuario;
    },

    sedes() {
      return this.$store.getters.obtenerSedes;
    },

    regionales(){
      return this.$store.getters.obtenerRegionales
    },

    centrosFormacion() {
      return this.$store.getters.obtenerCentrosFormacion(this.regional);
    },
  
    competencia() {
      return this.$store.getters.obtenerCompetenciaWorldSkills;
    }
  },

  watch: {
    searchTable(newSearch) {
      this.$store.dispatch("guardarSearch", newSearch);
    },
    dialog(val) {
      val || this.cerrarDialog();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  methods: {
    abrirDialog() {
      this.dialogForm = true;
      this.competenciaSeleccionada = this.competencia.competencia;
    },

    deleteItem(item) {
      this.editedIndex = this.sedes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.sedes.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    cerrarDialog() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    registrar() {
      if (this.$refs.form.validate()) {
        const sede = {
          centro_formacion_id:  this.centroFormacion,
          sede:                 this.sede[0].toUpperCase() + this.sede.toLowerCase().substring(1),
          lugar:                this.lugar,
          direccion:            this.direccion,
          responsable:          this.responsable,
          telefono_responsable: this.telefono,
          correo_responsable:   this.correo,
          id_competencia:       this.$route.params.id_competencia
        };

        this.loadingForm = true;
        this.$store.dispatch("registrarSede", sede)
          .then((response) => {
            if (response.status == "success") {
              this.$refs.form.reset();
              this.loadingForm  = false;
              this.alert        = true;
              this.titleAlert   = "Registrada";
              this.messageAlert = response.message;
              this.titleCard    = "success--text";
              this.buttonCard   = "success";
              this.dialogForm   = false

              this.$store.dispatch("gestionarSedes", this.$route.params.id_competencia);

              setTimeout(() => {
                this.loaderTable = false
              }, 1000);
            } 
            else {
              this.loadingForm  = false;
              this.alert        = true;
              this.titleAlert   = "Error";
              this.messageAlert = response.message.sede[0];
              this.titleCard    = "error--text";
              this.buttonCard   = "error";
            }

            setTimeout(() => {
              this.alert = false;
            }, 1000);
          })
          .catch(() => {});
      }
    },
  
    regresar() {
      window.history.back()
    },

    detalles(idsede){
      this.$router.push({
        name: "detalles-sede",
        params: { id_sede: idsede }
      });
    },

    editar(sede) {
      this.dialogForm   = true
      this.editedIndex  = 1
      this.disabled     = true 

      this.$store.dispatch("editarsede", sede)
      .then((response) => {
        this.competenciaSeleccionada  = this.competencia.competencia
        this.idSede                   = sede
        this.regional                 = response.centro_formacion.regional.id_regional
        this.centroFormacion          = response.centro_formacion.id_centro_formacion
        this.sede                     = response.sede
        this.lugar                    = response.lugar
        this.direccion                = response.direccion
        this.responsable              = response.responsable
        this.telefono                 = response.telefono_responsable
        this.correo                   = response.correo_responsable
      })
    },

    actualizar(){
      if (this.$refs.form.validate()) {
        const sede = {
          id_sede:              this.idSede,
          centro_formacion_id:  this.centroFormacion,
          sede:                 this.sede[0].toUpperCase() + this.sede.toLowerCase().substring(1),
          lugar:                this.lugar,
          direccion:            this.direccion,
          responsable:          this.responsable,
          telefono_responsable: this.telefono,
          correo_responsable:   this.correo,
          id_competencia:       this.$route.params.id_competencia
        };

        this.loadingForm = true
        this.$store.dispatch("actualizarSede", sede)
        .then((response) => {
          if (response.status == "success") {
            this.loadingForm = false;
            this.alert        = true;
            this.titleAlert   = "Actualizada";
            this.messageAlert = response.message;
            this.titleCard    = "success--text";
            this.buttonCard   = "success";

            this.$store.dispatch("gestionarSedes", this.$route.params.id_competencia);
            setTimeout(() => {
              this.loaderTable = false
            }, 1000);
          } 
          else {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message.sede[0];
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }

          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {});
      }
    },

    activarAlertaEliminar(sede_id) {     
      this.temSede_id = sede_id;
      this.temCompetencia_id = this.$route.params.id_competencia;
      this.dialogDelete = true;
    },

    eliminar(idSede, idCompetencia) {
      this.dialogDelete = false;
      const sede = {
        sede_id:        idSede,
        competencia_id: idCompetencia,
      };
      
      this.$store.dispatch("eliminarSede", sede)
      .then((response) => {
        if (response.status == "success") {
          this.alert        = true;
          this.titleAlert   = "Eliminada";
          this.messageAlert = response.message;
          this.titleCard    = "success--text";
          this.buttonCard   = "success";
          this.$store.dispatch("gestionarSedes", this.$route.params.id_competencia);

          setTimeout(() => {
            this.loaderTable = false
          }, 1000);
        } 
        else {
          this.alert        = true;
          this.titleAlert   = "Error";
          this.messageAlert = response.message;
          this.titleCard    = "error--text";
          this.buttonCard   = "error";
        }
        setTimeout(() => {
          this.alert = false;
        }, 1000);
      })
      .catch(() => {});
    }
  }
};
</script>