import axios from 'axios'

const reportes = {
    state: () => ({
    }),

    getters: {  
    },

    mutations: {   
    },

    actions: {
        reporteSenasoft(context, reporte) {
            return new Promise((resolve, reject) => {
                axios.post("api/reportes/senasoft", reporte, { responseType: 'blob' })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        reporteWorldSkills(context, reporte) {
            return new Promise((resolve, reject) => {
                axios.post("api/reportes/ReportesWorldSkills", reporte, { responseType: 'blob' })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        // descargarReporteCategorias (context, participante) {
        //     return new Promise((resolve, reject) => {
        //         axios.post("api/reportes/categorias", participante, { responseType: 'blob' })
        //         .then((response) => {
        //             resolve(response.data)
        //         })
        //         .catch(() => {
        //             reject()
        //         })
        //     })
        // },

        // descargarReporteCupos (context, cupo) {
        //     return new Promise((resolve, reject) => {
        //         axios.post("api/reportes/cupos", cupo, { responseType: 'blob' })
        //         .then((response) => {
        //             resolve(response.data)
        //         })
        //         .catch(() => {
        //             reject()
        //         })
        //     })
        // },

        // descargarReporteParticipantes (context, participante) {
        //     return new Promise((resolve, reject) => {
        //         axios.post("api/reportes/participantes", participante, { responseType: 'blob' })
        //         .then((response) => {
        //             resolve(response.data)
        //         })
        //         .catch(() => {
        //             reject()
        //         })
        //     })
        // },

        // descargarReporteLideres (context, lider) {
        //     return new Promise((resolve, reject) => {
        //         axios.post("api/reportes/lideres", lider, { responseType: 'blob' })
        //         .then((response) => {
        //             resolve(response.data)
        //         })
        //         .catch(() => {
        //             reject()
        //         })
        //     })
        // }
    }
}

export default reportes