<template>
  <v-row no-gutters>
    <v-col 
      cols="12" 
      align="center"
    >
      <div class="contenedor-imagen d-flex justify-end align-end" :style="{ backgroundImage: `url(${require('@/assets/sigefHome.png')})` }">
        <!-- Contenido adicional -->
        <v-col
        cols="2" 
        class="pa-9"
        align="center">
          <v-img 
          max-width="200"
          src="@/assets/logoFabrica.png"
        />
        </v-col>

      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      // imagenFondo: '../assets/sigefHome.png' // Ruta de la imagen de fondo (puedes omitir esta línea)
    };
  }
};
</script>

<style scoped>
.contenedor-imagen {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 94vh;
  width: auto;
}
</style>
