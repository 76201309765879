import axios from "axios";

const expertos_nacional = {
  state: () => ({
    expertosNacionales: [],
    expertoNacional: {}
  }),

  getters: {
    obtenerExpertosNacionalesWorldSkills: (state) => (competencia, habilidad) => {
      return state.expertosNacionales.filter(expertoNacional => expertoNacional.competencia_id == competencia && expertoNacional.categoria_id == habilidad)
    },

    obtenerExpertoNacionalWorldSkills: (state) => {
      return state.expertoNacional
    },

    obtenerExpertoNacionalCompetencia: (state) => (competencia, identificacion) => {
      return state.expertosNacionales.find(expertoNacional => expertoNacional.competencia_id == competencia && expertoNacional.identificacion == identificacion)
    },

    obtenerExpertoNacionalIdentificacion: (state) => (identificacion) => {
      return state.expertosNacionales.find(expertoNacional => expertoNacional.identificacion == identificacion)
    },
  },

  mutations: {
    enviarExpertosNacionales(state, expertosNacionales) {
      state.expertosNacionales = expertosNacionales
    },

    enviarExpertoNacional(state, expertoNacional) {
      state.expertoNacional = expertoNacional
    }
  },

  actions: {
    registrarExpertoNacional(context, expertoNacional) {
      return new Promise((resolve, reject) => {
        axios.post("api/expertosNacionalWorldskills", expertoNacional)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },

    gestionarExpertosNacionales(context) {
      axios.get("api/expertosNacionalWorldskills")
      .then((response) => {
        const expertosNacionales = []
        response.data.results.forEach((response) => {
          expertosNacionales.push({
            id:                   response.id_lider,
            competencia_id:       response.competencia.id_competencia,
            competencia:          response.competencia.competencia,
            categoria_id:         response.categoria.id_categoria,
            categoria:            response.categoria.categoria,
            tipo_lider_id:        response.tipo_lider.id_tipo_lider,
            tipo_lider:           response.tipo_lider.tipo_lider,
            centro_formacion_id:  response.centro_formacion.id_centro_formacion,
            centro_formacion:     response.centro_formacion.centro_formacion,
            regional_id:          response.centro_formacion.regional.id_regional,
            regional:             response.centro_formacion.regional.regional,
            persona_id:           response.persona.id_persona,
            identificacion:       response.persona.identificacion,
            nombres:              response.persona.primer_nombre+ " " +response.persona.segundo_nombre,
            apellidos:            response.persona.primer_apellido+ " " +response.persona.segundo_apellido,
            telefono_movil:       response.persona.telefono_movil,
            correo:               response.persona.correo,
            fecha_nacimiento:     response.persona.fecha_nacimiento,
            genero:               response.persona.genero.genero,
            cargo:                response.cargo,
            talla_camiseta:       response.talla_camiseta
          })
        });

        context.commit("enviarExpertosNacionales", expertosNacionales)
      })
      .catch(() => { })
    },

    detallesExpertoNacional(context, expertoNacional) {
      axios.get("api/expertosNacionalWorldskills/" + expertoNacional)
      .then((response) => {
        const experto = response.data.results
        const expertoNacional = {
          id:                     experto.id_lider,
          competencia_id:         experto.competencia.id_competencia,
          competencia:            experto.competencia.competencia,
          categoria_id:           experto.categoria.id_categoria,
          categoria:              experto.categoria.categoria,
          tipo_lider_id:          experto.tipo_lider.id_tipo_lider,
          tipo_lider:             experto.tipo_lider.tipo_lider,
          centro_formacion_id:    experto.centro_formacion.id_centro_formacion,
          centro_formacion:       experto.centro_formacion.centro_formacion,
          regional_id:            experto.centro_formacion.regional.id_regional,
          regional:               experto.centro_formacion.regional.regional,
          persona_id:             experto.persona.id_persona,
          identificacion:         experto.persona.identificacion,
          nombres:                experto.persona.primer_nombre+" "+experto.persona.segundo_nombre,
          apellidos:              experto.persona.primer_apellido+" "+experto.persona.segundo_apellido,
          telefono_movil:         experto.persona.telefono_movil,
          fecha_nacimiento:       experto.persona.fecha_nacimiento,
          correo:                 experto.persona.correo,
          genero:                 experto.persona.genero.genero,
          cargo:                  experto.cargo,
          talla_camiseta:         experto.talla_camiseta
        }

        context.commit("enviarExpertoNacional", expertoNacional)
      })
      .catch(() => {})
    },

    editarExpertoNacional(context, expertoNacional) {
      return new Promise((resolve, reject) => {
        axios.get("api/expertosNacionalWorldskills/" + expertoNacional)
        .then((response) => {
          resolve(response.data.results)
        })
        .catch(() => {
          reject()
        })
      })
    },

    actualizarExpertoNacional(context, experto) {
      return new Promise((resolve, reject) => {
        axios.put("api/expertosNacionalWorldskills/" + experto.id, experto)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    },

    eliminarExpertoNacional(context, expertonacional) {
      return new Promise((resolve, reject) => {
        axios.delete("api/expertosNacionalWorldskills/" + expertonacional)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
      })
    }
  }
}

export default expertos_nacional