<template>
  <v-row>
    <v-col
      sm="1"
      lg="2"
      class="hidden-xs-only"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            fixed
            exact
            color="primary"
            v-bind="attrs" 
            v-on="on" 
            @click="regresar"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>Regresar</span>
      </v-tooltip>
    </v-col>
    <v-col cols="12" sm="10" lg="8">
      <v-card outlined elevation="2">
        <v-card-title class="justify-center">
          <p class="text-h4 font-weight-bold primary--text mt-2">
            Detalles Experto Regional
          </p>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Identificación</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.identificacion }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Nombres</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.nombres }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Apellidos</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.apellidos }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Télefono</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.telefono_movil }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Correo Electrónico</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.correo }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Fecha de Nacimiento</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.fecha_nacimiento }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Genero</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.genero }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Cargo</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.cargo }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Talla de Camiseta</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.talla_camiseta }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Regional</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.regional }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Centro de Formación</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.centro_formacion }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Habilidad</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.categoria }}
              </p>
            </v-col>

            <v-col cols="12" sm="10" lg="8">
              <p class="text-h6 font-weight-bold">Competencia</p>
              <p class="text-subtitle-1">
                {{ expertoRegional.competencia }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("gestionarExpertosRegionales")
  },

  computed: {
    expertoRegional() {
      return this.$store.getters.obtenerExpertoRegionalWorldSkills(this.$route.params.id_experto);
    },
  },

  methods: {
    regresar() {
      this.$router.push({
        name: "expertows-gestionar",
        params: { id_competencia: this.expertoRegional.competencia_id, id_categoria: this.expertoRegional.categoria_id}
      });
    }
  }
};
</script>