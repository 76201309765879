<template>
  <div>
    <!-- alerta -->
    <v-dialog 
      max-width="280" 
      v-model="alert"
    >
      <v-card>
        <v-card-title class="justify-center">
          <p class="text-h5 font-weight-bold" :class="titleCard">
            {{ titleAlert }}
          </p>
        </v-card-title>
        <v-card-text class="text-h6 text-center">
          {{ messageAlert }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :color="buttonCard"
            class="mb-2 white--text"
            @click="alert = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Reportes
            </p>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col 
                cols="6" 
                offset="3"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-select
                    outlined
                    label="Competencia*"
                    :items="competenciasWorldSkills"
                    item-value="id"
                    item-text="competencia"
                    v-model="competencia"
                    :rules="competenciaReglas"
                  >
                  </v-select>

                  <v-checkbox
                    label="Seleccionar Todo"
                    v-model="seleccion"
                    @click="seleccionarTodo"
                  >
                  </v-checkbox>

                  <v-checkbox
                    label="Participantes"
                    value="participante"
                    v-model="worldskills"
                  >
                  </v-checkbox>

                  <v-checkbox
                    label="Ganadores"
                    value="ganador"
                    v-model="worldskills"
                  >
                  </v-checkbox>

                  <v-checkbox
                    label="Expertos Regionales"
                    value="expertoRegional"
                    v-model="worldskills"
                  >
                  </v-checkbox>

                  <v-checkbox
                    label="Acompañantes"
                    value="acompanante"
                    v-model="worldskills"
                  >
                  </v-checkbox>

                  <v-checkbox
                    label="Expertos Nacionales"
                    value="expertoNacional"
                    v-model="worldskills"
                  >
                  </v-checkbox>

                  <v-btn
                    large
                    block
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="reporteWorldSkills()"
                  >
                    Descargar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    alert: false,
    titleAlert: null,
    messageAlert: null,
    titleCard: null,
    buttonCard: null,

    form: true,

    seleccion: false,

    competencia: null,
    competenciaReglas: [
      (v) => !!v || "Seleccione una competencia"
    ],

    worldskills: [],
  }),

  mounted() {
    this.$store.dispatch("gestionarCompetenciaWorldSkills");
    this.$store.dispatch("gestionarHabilidades");
  },

  computed: {
    usuario() {
      return this.$store.getters.usuario
    },

    competenciasWorldSkills() {
      return this.$store.getters.obtenerCompetenciasWorldSkills
    },

    competenciaSeleccionada() {
      return this.$store.getters.obtenerCompetenciaWS(this.competencia);
    },
  },

  methods: {
    seleccionarTodo() {
      if(this.seleccion == true) {
        this.worldskills.push(
          "participante",
          "ganador",
          "expertoRegional",
          "acompanante",
          "expertoNacional"
        )
      }
      else {
        this.worldskills.splice(0)
      }
    },

    reporteWorldSkills() {
      if(this.$refs.form.validate()) {
        if(this.worldskills.length > 0) {
          const reporte = {
            id_competencia: this.competencia,
            reporte:        this.worldskills
          }

          this.$store.dispatch("reporteWorldSkills", reporte)
          .then((response) => {
            const urlReporte = window.URL.createObjectURL(new Blob([response]))

            const link = document.createElement("a");
            link.href = urlReporte;
            link.setAttribute("download", "Reporte Competencia.xlsx");
            document.body.appendChild(link);
            link.click();
            this.worldskills = [];
            this.seleccion = false;
          })
          .catch(() => {});
        } 
        else {
          this.loadingForm = false;
          this.alert = true;
          this.titleAlert = "Error";
          this.messageAlert = "Seleccione items para descargar";
          this.titleCard = "error--text";
          this.buttonCard = "error";

          setTimeout(() => {
            this.alert = false;
          }, 2000);
        }
      }
    }
  }
};
</script>

<style scoped>
</style>