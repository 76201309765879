import axios from 'axios'

const perfiles = {
    state: () => ({
        perfiles: [],
        perfil: {},
    }),

    getters: {
        obtenerPerfiles: (state) => {
            return state.perfiles
        },

        obtenerPerfil: (state) => (id) => {
            return state.perfiles.find(perfil => perfil.id == id)
        }
    },

    mutations: {
        enviarPerfiles(state, perfiles) {
            state.perfiles = perfiles
        },

        enviarPerfil(state, perfil) {
            state.perfil = perfil
        }
    },

    actions: {
        registrarPerfil(context, perfil) {
            return new Promise((resolve, reject) => {
                axios.post("api/perfiles", perfil)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        gestionarPerfiles(context) {
            axios.get("api/perfiles")
            .then((response) => {
                const perfiles = []
                response.data.results.forEach(result => {
                    perfiles.push({
                        id:             result.id_perfil,
                        perfil:         result.perfil,
                        descripcion:    result.descripcion
                    })
                });
                context.commit("enviarPerfiles", perfiles)
            })
            .catch(() => {})
        },

        editarPerfil(context, perfil) {
            return new Promise((resolve, reject) => {
                axios.get("api/perfiles/"+perfil+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarPerfil(context, perfil) {
            return new Promise((resolve, reject) => {
                axios.put("api/perfiles/"+perfil.id, perfil)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        editarPermiso(context, perfil) {
            return new Promise((resolve, reject) => {
                axios.get("api/perfiles/permisos/"+perfil+"/editar")
                .then((response) => {
                    resolve(response.data.results)
                })
                .catch(() => {
                    reject()
                })
            })
        },

        actualizarPermiso(context, permisosPerfil) {
            return new Promise((resolve, reject) => {
                axios.put("api/perfiles/permisos/"+permisosPerfil.perfil, permisosPerfil)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(() => {
                    reject()
                })
            })
        }
    }
}

export default perfiles