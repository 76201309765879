<template>
  <div>
    <!-- alerta -->
    <app-alert
      :alert="alert"
      :titleAlert="titleAlert"
      :messageAlert="messageAlert"
      :titleCard="titleCard"
      :buttonCard="buttonCard"
    >
    </app-alert>
    
    <!-- formulario -->
    <v-row justify="center">
      <v-col 
        cols="12" 
        sm="10" 
        lg="8"
      >
        <v-card 
          outlined 
          elevation="2" 
          :loading="loadingForm"
        >
          <v-card-title class="justify-center">
            <p class="text-h4 font-weight-bold primary--text mt-2">
              Registrar Aliado
            </p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col 
                cols="12" 
                sm="10" 
                lg="8"
              >
                <v-form 
                  ref="form" 
                  v-model="form"
                >
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Aliado*"
                    maxlength="150"
                    counter
                    autofocus
                    v-model="aliado"
                    :rules="aliadoReglas"
                  >
                  </v-text-field>

                  <v-file-input
                    outlined
                    dense
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    accept="image/png, image/jpeg"
                    label="Logotipo"
                    truncate-length="50"
                    counter
                    show-size
                    v-model="logotipo"
                    :rules="logotipoReglas"
                  >
                  </v-file-input>

                  <v-textarea
                    outlined
                    rows="4"
                    label="Descripción"
                    v-model="descripcion"
                  >
                  </v-textarea>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Dirección*"
                    maxlength="50"
                    counter
                    v-model="direccion"
                    :rules="direccionReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Teléfono*"
                    v-model="telefono"
                    maxlength="10"
                    counter
                    :rules="telefonoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Correo Electrónico*"
                    maxlength="50"
                    counter
                    v-model="correo"
                    :rules="correoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Contacto del Aliado"
                    maxlength="100"
                    counter
                    v-model="contactoAliado"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Teléfono Contacto Aliado"
                    maxlength="10"
                    counter
                    v-model="telefonoContactoAliado"
                    :rules="telefonoReglas"
                  >
                  </v-text-field>

                  <v-text-field
                    outlined
                    dense
                    type="text"
                    label="Correo Electrónico"
                    maxlength="50"
                    counter
                    v-model="correoContactoAliado"
                  >
                  </v-text-field>

                  <v-btn
                    block
                    large
                    exact
                    elevation="2"
                    color="primary"
                    class="mt-2 mb-6"
                    @click="registrar"
                  >
                    Registrar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Alert from '../../../components/admin/Alert.vue'

export default {
  data: () => ({
    alert:        false,
    titleAlert:   null,
    messageAlert: null,
    titleCard:    null,
    buttonCard:   null,
    loadingForm:  false,
    form:         true,
    
    aliado: null,
    aliadoReglas: [
      v => !!v || "Escriba un aliado"
    ],

    logotipo: null,
    logotipoReglas: [
      v => !v || v.size < 1000000 || "El tamaño del logotipo debe ser menor que 1 MB"
    ],

    descripcion: null,

    direccion: null,
    direccionReglas: [
      v => !!v || "Escriba una dirección"
    ],

    telefono: null,
    telefonoReglas: [
      v => !!v || "Escriba un número de teléfono",
      v => !v || v.length <= 10 || "La cantidad de numeros debe ser menor a 10",
      v => !v || v > 0 || "El número no puede ser negativo"
    ],

    correo: null,
    correoReglas: [
      v => !!v || "Escriba un correo electrónico",
      v => /.+@.+\..+/.test(v) || "Correo electrónico no válido",
    ],

    contactoAliado: null,
    telefonoContactoAliado: null,
    telefonoContactoAliadoreglas: [
    v => !v || v.length <= 10 || "La cantidad de numeros debe ser menor a 10"
    ],

    correoContactoAliado: null
  }),

  components: {
    "app-alert": Alert
  },

  methods: {
    registrar() {
      if (this.$refs.form.validate()) {
        const aliado = new FormData();
        aliado.set("aliado",                    this.aliado);
        aliado.set("logotipo",                  this.logotipo);
        aliado.set("descripcion",               this.descripcion);
        aliado.set("direccion",                 this.direccion);
        aliado.set("telefono",                  this.telefono);
        aliado.set("correo",                    this.correo);
        aliado.set("contacto_aliado",           this.contactoAliado);
        aliado.set("telefono_contacto_aliado",  this.telefonoContactoAliado);
        aliado.set("correo_contacto_aliado",    this.correoContactoAliado);

        this.loadingForm = true;

        this.$store.dispatch("registrarAliado", aliado)
        .then((response) => {
          if (response.status == "success") {
            this.$refs.form.reset();
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Registrado";
            this.messageAlert = response.message;
            this.titleCard    = "success--text";
            this.buttonCard   = "success";
            setTimeout(() => {
                this.$router.push({
                  name: "aliado-gestionar",
                });
              }, 2500);
          } 
          else {
            this.loadingForm  = false;
            this.alert        = true;
            this.titleAlert   = "Error";
            this.messageAlert = response.message.aliado[0];
            this.titleCard    = "error--text";
            this.buttonCard   = "error";
          }

          setTimeout(() => {
            this.alert = false;
          }, 1000);
        })
        .catch(() => {});
      }
    }
  }
};
</script>